import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 15,
    width: '100%',
    padding: 4
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  porciento: {
    width: '100%',
    marginBottom: 15
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  formControlPadding: {
    marginBottom: 15,
    width: '100%',
    padding: 4
  },
  padding: {
    marginBottom: 15
  },
  btnRegistrar: {
    marginBottom: 15
  }
}))

export default useStyles
