import Menu from '@components/portal/guide/Menu'
import { Container, Content } from './styles'

const LayoutGuide = ({ children, optionMenu }) => {
  return (
    <Container>
      <Menu optionMenu={optionMenu} />
      <Content> {children} </Content>
    </Container>
  )
}

export default LayoutGuide
