import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import InputText from '@components/inputs/Text'
import Loader from '@components/Loader'
import Button from '@components/Button'
import TextareaAutosize from '@mui/material/TextareaAutosize'

import http from '@utils/fetch-client'
import { useAlert } from '@hooks/useAlert'
import useStyles from './stylesUI'

import { SendMailContainer, SendMail } from './styles'

const initialState = { fullname: '', mail: '', phone: '', msg: '', subject: '' }

const ContactUsEmail = () => {
  const classes = useStyles()
  const [state, setState] = useState(initialState)
  const [loadingSendEmail, setLoadingSendEmail] = useState(false)
  const [Alert, setAlert] = useAlert({})

  const handleBlur = ({ name, value }) => {
    setState({ ...state, [name]: value })
  }

  const validEmail = value => {
    const regex = /^(.+@.+\..+)$/
    return regex.test(value)
  }

  const handleSendMail = () => {
    console.log({ state })
    const valid = Object.values(state).reduce((acc, curr) => acc && Boolean(curr), true)
    if (!valid) return setAlert({ status: true, severity: 'error', msg: 'Todos los campos son requeridos.' })

    let errorHTML = ''
    if (!validEmail(state.mail)) errorHTML += '<li> El correo ingresado no es válido.</li>'
    if (errorHTML) return setAlert({ status: true, severity: 'error', msg: errorHTML })

    setLoadingSendEmail(true)
    http.post('api/usuario/sendEmail', state)
      .then(({ status, data }) => {
        console.log({ status, data })

        if (status !== 200) throw Error('Disculpe, ocurrió un error recuperando la contraseña. Por favor intente más tarde.')
        setAlert({
          status: true,
          severity: 'success',
          msg: `<b>${state.fullname}</b> su correo sobre <b>${state.subject}</b> nos ha sido <b>enviado</b>, gracias por comunicarse con nosotros.`
        })
      })
      .catch(err => {
        console.error(err)
        setAlert({
          status: true,
          severity: 'error',
          msg: 'Ha Ocurrido un <b>error</b> enviando su correo. Por favor Intente más tarde. Gracias.'
        })
      })
      .finally(() => {
        setState(initialState)
        setLoadingSendEmail(false)
      })
  }

  return (
    <SendMailContainer>
      <h3>¿Requiere información sobre otro tema?</h3>
      <SendMail>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <InputText
              required
              id='subject'
              // value={state.fullname}
              handleBlur={handleBlur}
              label='Asunto'
              name='subject'
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              required
              id='fullname'
              // value={state.fullname}
              handleBlur={handleBlur}
              label='Nombre Completo'
              name='fullname'
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              required
              id='mail'
              // value={state.mail}
              handleBlur={handleBlur}
              label='Correo Electrónico'
              name='mail'
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              required
              id='phone'
              // value={state.phone}
              handleBlur={handleBlur}
              label='Teléfono de contacto'
              name='phone'
            />
          </Grid>

          <Grid item xs={12}>
            <TextareaAutosize
              aria-label='minimum height'
              rowsmin={3}
              name='msg'
              onBlur={({ target }) => handleBlur(target)}
              placeholder='Escriba su mensaje y envíelo si no hay un medio especifico para lo que requiere'
            />
          </Grid>
        </Grid>
      </SendMail>

      <div style={{ height: '100px', width: '350px' }}>
        {loadingSendEmail ? <Loader /> : <Alert />}
      </div>

      <Button
        label='Enviar'
        color='ipp'
        style={{ width: '90%' }}
        disabled={loadingSendEmail}
        onClick={handleSendMail}
      />
    </SendMailContainer>
  )
}

export default ContactUsEmail
