import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 50px auto;

    h1{
        color: ${({ theme }) => theme.color.bluePrimary}
    }

    a{
        text-decoration: none;
        color: ${({ theme }) => theme.color.black};
    }

    section{
        margin-bottom: 50px;
        padding: 1px 20px;
        margin-bottom: 50px;
        border-radius: 10px;
        background: white;
    } 
`

export const Body = styled.div`
    display: none;
    padding-bottom: 1rem;
`
export const ConatinerButton = styled.div`
    display: flex;
    justify-content: center;
    margin: 0.5rem 0rem;

    div{
      cursor: pointer;
      border-radius: 0.5rem;
      background-color: #cdcdcc;
      padding: 0.5rem 1rem;
      
      :hover{
        background-color: #dededc;
      }
    }
`
