import React, { useState, useEffect } from 'react'

import Button from '@components/Button'
import Loader from '@components/Loader'
import { useAlert } from '@hooks/useAlert'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { useStateValue } from '@context/index.js'
import http from '@utils/fetch-client'

import { Container, ContainerBtn, Sedes, Sede, SubTitle } from './styles'
import useStyles from './stylesUI'

const JornadaHorarios = ({handleStep}) => {
  const [{ user }, dispatch] = useStateValue()
  const [step, setStep] = useState(1)
  const [sedes, setSedes] = useState({selected:0, data:[]})
  const [horarios, setHorarios] = useState({selected:0, data:[]})
  const [MyAlert, setInfo] = useAlert({})
  const [loading, setLoading] = useState(true)
  const [loadingHorarios, setLoadingHorarios] = useState(false)

  console.log({sedes, horarios})
  useEffectAbortable(({ aborted }) => {
    const url = `api/jornadas/clinicas`
    http.get(url)
    .then(({ status, data }) => {
      if(aborted) return
      if (status !== 200) throw new Error(data)
      setSedes(state => ({...state, data:data.data}))
    })
    .catch(err => {
      console.error(`Error [JornadaHorarios]: ${err}`)
      setInfo({ status: true, severity: 'error', msg: 'Disculpe ocurrió un error consultando las <b>Clínicas</b>, intente mas tarde.' })
    })
    .finally(() => setLoading(false))
  }, [setInfo])

  const handleSede = coClinica => () => {
    console.log({coClinica})
    setLoadingHorarios(true)
    setSedes(state => ({...state, selected:coClinica}))
    setStep(2)
    const url = `api/jornadas/1/horarios/${coClinica}`
    http.get(url)
    .then(({ status, data }) => {
      console.log({status, data})
      if (status !== 200) return setInfo({ status: true, severity: 'error', msg: data.error })
      setHorarios({selected:0, data:data.data})
      setInfo({ status: true, severity: 'success', msg: `Se consultaron con exito los <b>Horarios disponibles</b>.` })
    })
    .catch(err => {
      console.error(`Error [JornadaHorarios - handelSede]: ${err}`)
      let msg = 'Disculpe ocurrió un error consultando los <b>Horarios</b> de la clínica seleccionada, intente mas tarde.'
      setInfo({ status: true, severity: 'error', msg })
    })
    .finally(() => setLoadingHorarios(false))
  }

  const handleHorario = coHorario => () => {
    console.log({coHorario})
    setHorarios(state => ({...state, selected:coHorario}))
  }

  const getNameSedeCurrent = () => {
    let sede = sedes.data.find(s => s.co_clinica === sedes.selected)   
    if(!sede) return '(No hay sede seleccionada)'
    return `(${sede['nb_clinica']})`
  }

  const getHorarioCurrent = () => {
    let horario = horarios.data.find(s => s.co_jor_horario === horarios.selected)   
    if(!horario) return '(No hay horario seleccionado)'
    return `${horario.dia} ${horario.fecha.split('T')[0]}`
  }
  
  const renderSedes = () => (<>
    <SubTitle> {`Sede donde se realizará los exámenes de laboratorio`} </SubTitle>
    <Sedes>
      {sedes.data.map(s => (
        <Sede
          key={s.co_clinica}
          active={sedes.selected === s.co_clinica ? 1 : 0}
          onClick={handleSede(s.co_clinica)}
        >
          <h3>{s.nb_clinica}</h3>
          <div>{s.tx_direccion}</div>
        </Sede>
      ))}
    </Sedes>
  </>)

  const renderHorarios = () => (<>
    <SubTitle> {`Día de la cita ${getNameSedeCurrent()}`}  </SubTitle>
    <Sedes>
      {horarios.data.map(h => (
        <Sede
          key={h.co_clinica}
          active={horarios.selected === h.co_jor_horario ? 1 : 0}
          onClick={handleHorario(h.co_jor_horario)}
        >
          <h3>{h.dia}</h3>
          <div>{h.hora_cita}</div>
          <div>{h.fecha.split('T')[0]}</div>
        </Sede>
      ))}
    </Sedes>
  </>)

  const refreshHorarios = () => {
    setLoadingHorarios(true)
    const url = `api/jornadas/1/horarios/${sedes.selected}`
    console.log({url})
    http.get(url)
    .then(({ status, data }) => {
      console.log(`[JornadaHorarios - refreshHorarios]:`, {status, data})
      if (status !== 200) throw new Error(data)
      setHorarios({selected:0, data:data.data})
      setInfo({ status: true, severity: 'success', msg: `Se consultaron con exito los <b>Horarios disponibles</b>.` })
    })
    .catch(err => {
      console.error(`[JornadaHorarios - refreshHorarios] Err: ${err}`)
      setHorarios({selected:0, data:[]})
      setInfo({ status: true, severity: 'error', msg: `Disculpe ocurrió un error refrescando los <b>Horarios disponibles</b>, intente mas tarde.` })
    })
    .finally(() => setLoadingHorarios(false))
  }

  const submit = () => {
    if(horarios.selected === 0) return setInfo({ status: true, severity: 'error', msg: 'Debe seleccionar un <b>Horarios</b> para poder solicitar la cita.'})
    setLoadingHorarios(true)

    const url = `api/jornadas/cita/${horarios.selected}/${user['co_usuario']}`
    http.post(url)
    .then(({ status, data }) => {
      console.log('[JornadaHorarios - submit]', {status, data})
      if (status === 402){
        console.log(getHorarioCurrent())
        setLoadingHorarios(null)
        setInfo({ status: true, severity: 'warning', msg: `El horario del <b>(${getHorarioCurrent()})</b>, ya no tiene citas disponibles. espere un momento y se actualizaran los horarios`})
        setTimeout(() => refreshHorarios(), 1000 * 6)
        return
      }    
      if (status !== 200) throw new Error(data)
      setLoadingHorarios(false)
      handleStep()
    })
    .catch(err => {
      console.error(`Error [JornadaHorarios - submit]: ${err}`)
      setInfo({ status: true, severity: 'error', msg: `Disculpe ocurrió un error asugnando su cita al <b>Horario (${horarios.selected})</b>, intente mas tarde.`})
      setLoadingHorarios(false)
    })
  }

  if(step === 1) return (
    <Container>
        {renderSedes()}
    </Container>
  )
console.log({loadingHorarios})
  return (
    <Container>
      {loadingHorarios
        ?<Loader />
        :horarios.length
          ?<p>No hay horarios Disponibles, Seleccione otra clínica o comuníquese con nuestras oficinas</p>
          :<>
          {loadingHorarios === null ?<Loader /> :renderHorarios()}
          <div> {loading ? <Loader /> : <MyAlert />} </div>
      
          <ContainerBtn>
            <Button
              label='Volver a Sedes'
              onClick={() => setStep(1)}
              color='danger'
              disabled={loadingHorarios !== false}
              />
            <Button
              label='Guardar y Continuar'
              onClick={submit}
              color='primary'
              disabled={loadingHorarios !== false}
              />
          </ContainerBtn>
          </>
      }
    </Container>
  )
}

export default JornadaHorarios
