import styled from 'styled-components'

export const Container = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 50px auto;

    h1{
        color: ${({ theme }) => theme.color.bluePrimary}
    }

    a{
        text-decoration: none;
        color: ${({ theme }) => theme.color.black};
    }

    section{
        overflow: auto;
        margin-bottom: 50px;
        padding: 1px 20px;
        margin-bottom: 50px;
        border-radius: 10px;
        background: white;
    }
`

export const DivWeeks = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 0.3rem;
    
    div{
        margin: 0rem 0.5rem;
    }
`

export const Row = styled.div`
    min-height: 250px;
`

export const ContainerProfesionals = styled.div`
    margin-top: 40px;
`