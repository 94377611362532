import styled from 'styled-components'

const time = {
  width: '50px',
  padding: '10px 5px',
  margin: '5px'
}

export const Container = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 90vw;
    max-width: 1000px;
    margin: ${({ top }) => top ? '20px auto 0' : '-20px auto 20px'};


    @media (min-width: 920px) {
        width: 75%;
    }
`

export const Shedules = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

`

export const Status = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 0;
    font-size: 14px;

    b{
        color: ${({ theme, color }) => theme.color[color]}
    }
`

export const Time = styled.div`
    width: ${time.width};
    padding: ${time.padding};
    margin: ${time.margin};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.bluePrimary};
    text-align: center;
    vertical-align: middle;
    cursor: pointer; 
    background-color: ${({ theme, active }) => active === 1 ? theme.color.bluePrimary : theme.color.white};
    color: ${({ theme, active }) => active === 1 ? theme.color.white : theme.color.bluePrimary};



    &:hover{
        background-color: ${({ theme }) => theme.color.bluePrimary};
        color: ${({ theme }) => theme.color.white};
    }
`

export const TimeDisabled = styled.div`
    width: ${time.width};
    /* opacity: 0.3; */
    padding: ${time.padding};
    margin: ${time.margin};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.danger};
    text-align: center;
    vertical-align: middle;
    background-color: ${({ theme }) => theme.color.lightGray};
    color: ${({ theme }) => theme.color.danger};
`

export const TimeIPP = styled.div`
    width: ${time.width};
    opacity: 0.5;
    padding: ${time.padding};
    margin: ${time.margin};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.bluePrimary};
    text-align: center;
    vertical-align: middle;
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.bluePrimary};
`

export const TimeDisabledIPP = styled.div`
    width: ${time.width};
    opacity: 0.5;
    padding: ${time.padding};
    margin: ${time.margin};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.danger};
    text-align: center;
    vertical-align: middle;
    background-color: ${({ theme }) => theme.color.lightGray};
    color: ${({ theme }) => theme.color.danger};
`

export const TimeReserved = styled.div`
    width: ${time.width};
    opacity: 0.5;
    padding: ${time.padding};
    margin: ${time.margin};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.warning};
    text-align: center;
    vertical-align: middle;
    background-color: ${({ theme }) => theme.color.lightGray};
    color: ${({ theme }) => theme.color.warning};
`
