import { useState } from 'react'

import Loader from '@components/Loader'
import CitaCard from '@components/portal/CitaCard'
import PhotoFrameDocument from '@components/PhotoFrameDocument'
import StatusCita from '@components/StatusCita'

import config from '@utils/config'
import http from '@utils/fetch-client'
import { isObjectEmpty } from '@utils/functions'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { Container, ContainerStatus, Info, InfoDetails, Form } from './styles'

const CitaDetail = ({ id }) => {
  const [loading, setLoading] = useState(true)
  const [cita, setCita] = useState({})
  const [banks, setBanks] = useState([])
  const [authorized, setAuthorized] = useState(true)
  // const {payment} = cita
  // console.log('CitaDetail', { cita, banks })
  // console.log('cita', Object.keys(cita))

  useEffectAbortable(mount => { // Obtener Cita
    http.get(`api/cita/${id}`)
      .then(({ status, data }) => {
        if (mount.aborted) return
        console.log({data})
        if(status === 401) {
          setAuthorized(false)
          throw { status, data }
        }
        if (status !== 200 || isObjectEmpty(data)) throw JSON.stringify({ status, data })
        
        setCita({ ...data, profesional: {} })
      })
      .catch(err => {
        console.error('[CitaDetail] Obtener Cita: ', err)
      })
      .finally(() => setLoading(false))
  }, [id])

  useEffectAbortable(({ aborted }) => { // Obtener Bancos
    http.get('api/cita/bancos')
      .then(({ status, data }) => {
        if (aborted) return
        if (status !== 200) throw Error('Disculpe, ocurrió un error cargando los bancos. Por favor intente más tarde.')
        setBanks(data)
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  const getBank = coBanco => {
    const bank = banks.find(bank => bank?.co_banco === coBanco)
    return bank?.nb_banco || 'Código no encontrado'
  }

  const haveObservations = cita => (
    !cita.observaciones_salud || ['null', null].includes(cita.observaciones_salud)
  )

  const haveObservationsPayment = cita => (
    !cita.observaciones || cita.observaciones === 'null'
  )

  const renderInauthorized = () => (
    <Container>
        <h2 style={{color:'#d93530'}}> Acceso NO Permitido</h2>
        <p>
          Disculpe, la cita a la que intenta acceder no es suya, por favor dirijasé a su listado de Citas.
        </p>
    </Container>
  )


  if(!authorized) return renderInauthorized()

  return (
    <>
      {loading
        ? <Loader />
        : !Object.keys(cita).length
            ? (
              <Container>
                <h4> Disculpe, NO se logró obtener información de esta solicitud. Por favor refresque o intente mas tarde.</h4>
              </Container>)
            : (
              <Container>
                <ContainerStatus>
                  <StatusCita status={cita?.status} />
                </ContainerStatus>

                {/*
                  <Status status={cita?.status}>
                    {showStatus(cita?.status)}
                  </Status>
                */}
                <CitaCard cita={cita} />

                <Info>
                  <div>
                    <h1>Detalles del Pago:</h1>
                    {cita.comprobante &&
                      <PhotoFrameDocument
                        src={`${config.statics.citas}/${cita.comprobante || 'default'}`}
                        alt={`file${cita.comprobante}`}
                      />
                    }
                  </div>

                  <InfoDetails>
                    {cita?.factura
                      ? (
                        <Form>
                          <div>
                            <b>Banco:</b>
                            <span>{getBank(cita.factura.co_banco)}</span>
                          </div>

                          <div>
                            <b>Documento:</b>
                            <span>{cita.tx_documento}</span>
                          </div>

                          <div>
                            <b>Monto:</b>
                            <span>{cita.factura.mn_fact_ben}</span>
                          </div>

                          <div>
                            <b>Referencia:</b>
                            <span>{cita.factura.nu_registro}</span>
                          </div>
                        </Form>)

                      : haveObservationsPayment(cita)
                        ? null
                        : (
                          <div>
                            <h3>Observaciones de Pago:</h3>
                            <div>{cita.observaciones}</div>
                          </div>)}
                  </InfoDetails>
                </Info>

                <Info>
                  <div>
                    <h1>Observaciones de la consulta:</h1>
                    {haveObservations(cita)
                      ? <div>No hay observaciones</div>
                      : <ul> <li>{cita.observaciones_salud}</li> </ul>}
                  </div>
                </Info>

              </Container>)}
    </>
  )
}

export default CitaDetail
