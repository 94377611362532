import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: 'nav';
    z-index: 1;
    overflow-y: auto;
    background-color: ${({ theme }) => theme.color.bluePrimary};
    box-shadow: 0 0 5px 5px ${({ theme }) => theme.color.shadow};

    @media(min-width: 750px){
        height:100vh;
    }
`

export const Header = styled.div`
    display: flex;
    width: calc(100% - 30px);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* width: calc(100% - 20px); */
    padding: 10px 15px;
    
    svg {
        margin: 5px 5px;
        font-size: 25px;
        color: ${({ theme }) => theme.color.ligthGray};
        cursor: pointer;
        &:hover{
            color: ${({ theme }) => theme.color.white};
        };   
        
        @media (min-width: 750px) {
            display: none;
        }
    }
    
    @media (min-width: 750px) {
        justify-content: center;
    }
`

export const Img = styled.img`
    margin-top: 5px;
    height: 45px;
    width: 45px;

    @media (min-width: 750px) {
        height: 60px;
        width: 60px;
    }
`

export const NavContainer = styled.div`
    display: ${({ showMenuNone }) => showMenuNone ? '' : 'none'};
    
    @media (min-width: 750px) {
        margin-top: 40px;
        display: block;

        width: ${({ showMenu }) => showMenu ? '' : '50px'};

        span{
            display: ${({ showMenu }) => showMenu ? '' : 'none'};
        }

        li{
            min-width: ${({ showMenu }) => showMenu ? '200px' : ''};
        }
    }
`

export const Nav = styled.ul`
    font-size: 20px;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
`

export const SubNav = styled.ul`
    font-size: 18px;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    display: none;
`

export const Option = styled.li`
    margin: 0px;
    padding: 0px;
    padding: 10px 10px 10px 0;
    text-indent: 15px;
    font-weight: 500;
    /* border-bottom: 1px solid ${({ theme }) => theme.color.gray}; */
    cursor: pointer;
    color: ${({ theme }) => theme.color.ligthGray};
    
    a{
        text-decoration: none;
        /* color: ${({ theme, salir }) => salir ? `${theme.color.danger}` : `${theme.color.white}`}; */
        color: ${({ theme, active }) => active ? theme.color.white : theme.color.ligthGray};
        display: flex; 
        
        &:hover{
            color: ${({ theme }) => theme.color.white};
        }
    }

    svg {
        font-size: 20px;
        &:hover{
            color: ${({ theme }) => theme.color.white};
        };   
    }


    @media (min-width: 750px) {
        display: ${({ small }) => small && 'none'};
    }

`

export const Icon = styled.div`
    width: 40px;
`

export const Label = styled.span`
`
