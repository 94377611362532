import React from 'react'
import {
  ContainerModal, ContainerModalImportant, ModalBody, Header, HeaderImg,
  Content, Img, ImgNotice, MsgNotice, Footer
} from './styles'
import useStyles from './stylesUI'
import logo from './solologo.png'

import Modal from '@mui/material/Modal'
import config from '@utils/config'

const MyModal = ({ open, setOpen, title, children, important, banner }) => {
  const classes = useStyles()
  const onErrorImg = ({ target }) => {
    target.onerror = null
    target.src = '/post-default.jpg'
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      {important
        ? <ContainerModalImportant className={classes.paperImportant}>
          <Header>
            <HeaderImg>
              <Img src={logo} alt={`Mensaje de Emergencia - ${title}`} />
            </HeaderImg>
            <h3>{title || '¡Importante!'} </h3>
          </Header>
          <Content>
            {(open && banner) &&
              <ImgNotice
                src={banner ? `${config.statics.posts}/${banner}` : '/post-default.jpg'}
                alt='imagen de la noticia emergente'
                onError={onErrorImg}
              />}

            <MsgNotice dangerouslySetInnerHTML={{ __html: children }} />
          </Content>

          <Footer>
            <h5>Instituto de Previción del Profesorado</h5>
          </Footer>
        </ContainerModalImportant>

        : <ContainerModal className={classes.paper}>
          <Header>
            <h3 id='simple-modal-title' className={classes.title}> {title} </h3>
          </Header>
          <ModalBody id='simple-modal-description'>
            {children}
          </ModalBody>
        </ContainerModal>}
    </Modal>
  )
}

export default MyModal
