import React from 'react'

import { Router, Redirect } from '@reach/router'
import { useStateValue } from '@context/index.js'

import NotFound from '@pages/NotFound'
import Home from '@pages/Home'
import Login from '@pages/Login'
import Posts from '@pages/Posts'
import PostsDetail from '@pages/PostsDetail'
import Directory from '@pages/Directory'
import ChangeDataUser from '@pages/portal/ChangeDataUser'

import Admin from './Admin'
import Portal from './Portal'

const Routes = () => {
  const [{ user }] = useStateValue()
  console.log('Routes', { user })
  const ultimoLogin = user?.ultimo_login
  // console.log(ultimoLogin)
  
  return (
    <Router>
      <NotFound default />
      <Redirect from='/' to='/home' noThrow />
      <Home path='/home' />
      <Posts path='/noticias' />
      <Posts path='/noticias/page/:page' />
      <PostsDetail path='/noticias/:id' />
      <Directory path='/directory' />

      {user && <Redirect from='/login' to='/portal' noThrow />}
      <Login path='/login' />

      {(!user || user?.co_tipo === 1) && <Redirect from='/admin/*' to='/home' noThrow />}
      {user?.co_tipo === 4 && <Redirect from='/admin/citas/*' to='/admin/home' noThrow />}
      {(user?.co_tipo === 5) && <Redirect from='/admin/reembolsos/*' to='/admin/home' noThrow />}
      {(user?.co_tipo === 5) && <Redirect from='/admin/cartasAval/*' to='/admin/home' noThrow />}
      <Admin path='/admin/*' />

      {!user && <Redirect from='/cambiarClave' to='/login' noThrow />}
      <ChangeDataUser path='/cambiarClave' />
      {/* {!ultimoLogin && <Redirect from='/portal/*' to='/cambiarClave' noThrow />} */}

      {!user && <Redirect from='/portal/*' to='/home' noThrow />}
      {(user?.co_tipo !== 1) && <Redirect from='/portal/citas/*' to='/portal' noThrow />}
      {(user?.co_tipo !== 1) && <Redirect from='/portal/samhoi/reembolsos/*' to='/portal' noThrow />}
      {(user?.co_tipo !== 1) && <Redirect from='/portal/samhoi/cartasAval/*' to='/portal' noThrow />}
      {(user?.co_tipo !== 1) && <Redirect from='/portal/cobertura/*' to='/portal' noThrow />}
      <Portal path='/portal/*' />

      {/* {user && <Redirect from='/admin/login' to='/admin' noThrow />}
      <LoginAdmin path='/login' /> */}
    </Router>
  )
}

export default Routes
