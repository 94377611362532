
export const platforms = [
  {
    id:0,
    name: 'YouTube'
  },
  {
    id:1,
    name: 'DailyMotion'
  },
]

export const videos = {
  cartasReembolsos: {
    modificarSolicitud: {
      url: 'https://youtu.be/1_VqHmQINmo',
      title: 'Carta Aval o Reembolso - Modificar Solicitud',
      platform: platforms[0]
    },
    revisionSolicitud: {
      url: 'https://youtu.be/QU5W3IYrwUg',
      title: 'Carta Aval o Reembolso - Revision de Solicitud',
      platform: platforms[0]
    },
    revisionSolicitudModificada: {
      url: 'https://youtu.be/SNvCoDhTf5k',
      title: 'Carta Aval o Reembolso - Revision de Solicitud Modificada',
      platform: platforms[0]
    },
    samhoi: {
      url: 'https://youtu.be/TWMGfmqJ0J0',
      title: 'Carta Aval o Reembolso . SAMHOI',
      platform: platforms[0]
    },
    solicitud: {
      url: 'https://youtu.be/azCD439nym8',
      title: 'Carta Aval o Reembolso - Solicitud',
      platform: platforms[0]
    }
  },
  citas: {
    revision: {
      url: 'https://youtu.be/mH_WExgTpDs',
      title: 'Citas - Revision',
      platform: platforms[0]
    },
    solicitudRevisada: {
      url: 'https://youtu.be/d6qcJ2T8YEs',
      title: 'Citas - Solicitud revisada',
      platform: platforms[0]
    },
    solicitudSimultanea: {
      url: 'https://youtu.be/d_m0fgKBMuI',
      title: 'Citas - Solicitud simultánea (caso especial)',
      platform: platforms[0]
    },
    /* solicitud: {
      url: 'https://youtu.be/KnAsmo-hE7s',
      title: 'Citas - Solicitud',
      platform: platforms[0]
    }, */
    solicitud: {
      url: 'https://youtu.be/dncNuh8YnBw',
      title: 'Citas - Solicitud',
      platform: platforms[0]
    }
  },
  general: {
    login: {
      url: 'https://youtu.be/jriOBHjwLYs',
      title: 'Ingresar al Portal',
      platform: platforms[0]
    },
    estructuraPrincipalCompleta: {
      url: 'https://youtu.be/7BtbPExYv3o',
      title: 'Estructura principal de la app completa',
      platform: platforms[0]
    },
    estructuraPrincipal: {
      url: 'https://youtu.be/msJ7aaX1bq4',
      title: 'Estructura principal de la app',
      platform: platforms[0]
    },
    rutasProtegidas: {
      url: 'https://youtu.be/RfVcj-Z5nho',
      title: 'Rutas Protegidas',
      platform: platforms[0]
    }
  }
}
