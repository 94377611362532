import styled from 'styled-components'

export const Container = styled.div`  
    /* width:100%; */
    padding: 5px 0;
    font-weight: 500;
    color: #444;
`

export const Option = styled.li`  
    /* width:calc(100% - 20px); */
    padding: 2px 0;
    font-weight: 400;
    padding-left: 20px;
    list-style-type: none;
    text-decoration: none;
    &:hover{color: ${({ theme }) => theme.color.bluePrimary}}
    &::before{content: '-'}
`
