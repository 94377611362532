import styled from 'styled-components'

/* export const Container = styled.span`
    color: ${({ theme, status }) =>
      (status === 1) ? theme.color.inProgress
      : (status === 2) ? theme.color.success
      : (status === 3) ? theme.color.danger
      : theme.color.gray
    };
` */
export const Container = styled.div`  
    width: 100px;
    font-weight: ${({ header }) => header ? 'bold' : '500'};
    justify-content: space-between;
    text-align: center;
    color: ${({ theme, status }) =>
        (status === -1) ? theme.color.black
        : (status === 0) ? theme.color.danger
        : (status === 1) ? theme.color.black
        : (status === 2) ? theme.color.inProgress
        : (status === 3) ? theme.color.success
        : (status === 4) ? theme.color.danger
        : (status === 5) ? theme.color.inProgress
        : (status === 6) ? theme.color.success
        : theme.color.black
    }
`
