import React, { useState } from 'react'
import { navigate } from '@reach/router'

import Layout from '@components/admin/Layout'
import Loader from '@components/Loader'
import DateRangeSelector from '@components/DateRangeSelector'
import PieChartsFull from '@components/charts/PieChartsFull'
import TableReembolsos from '@components/TableReembolsos'
import Filters from '@components/Filters'

import http from '@utils/fetch-client'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { useAlert } from '@hooks/useAlert'

import { Container } from './styles'

const Reembolsos = () => {
  const [reembolsos, setReembolsos] = useState({})
  const [loading, setLoading] = useState(true)
  const [Alert, setAlert] = useAlert({})

  useEffectAbortable(({ aborted }) => {
    http.get('api/solicitud?co_tipo=1')
      .then(({ status, data }) => {
        if (aborted) return
        // console.log('[Reembolsos]', { status, data })
        if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')
        setLoading(false)

        data = data.map(solicitud => ({
          ...solicitud,
          encargado: solicitud.encargado || -1,
          co_solicitud: solicitud.co_solicitud || -1
        }))

        data = {
          'En Espera': data.filter(r => r.co_status_web === 2),
          Confirmadas: data.filter(r => r.co_status_web === 3),
          Rechazadas: data.filter(r => r.co_status_web === 4),
          'En SAMHOI': data.filter(r => r.co_status_web === 5)
        }
        setReembolsos(data)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }, [setAlert])

  const goToReport = (fi, ff) => {
    navigate(`reembolsos/report/${fi}/${ff}`)
  }

  return (
    <Layout
      active='reembolsos'
      title='Reembolsos'
    >
      <Container>
        <Alert />
        <DateRangeSelector handleClick={goToReport} />

        {loading
          ? <Loader />
          : (
            <>
              <PieChartsFull
                data={Object.entries(reembolsos).reduce((acc, [key, value]) => {
                  return acc.concat({
                    title: key,
                    value: value.length
                  })
                }, [])}
                styles={{ width: 300, height: 200, marginBottom: '1rem' }}
              />

              <Filters
                nameFilter='reembolsos'
                data={Object.values(reembolsos).reduce((acc, curr) => acc.concat(curr), [])}
                handleSetData={() => { }}
                options={[
                  { label: 'Encargados', name: 'encargado' },
                  { label: 'Códigos', name: 'co_solicitud' },
                  { label: 'Fechas', name: 'fecha_creado', typeValue: 'dateUTC' }
                ]}
              />

              {Object.entries(reembolsos).map(([key, value]) =>
                <TableReembolsos key={key} name={key} data={value} />
              )}
            </>)}
      </Container>
    </Layout>
  )
}

export default Reembolsos
