import styled from 'styled-components'

export const PhotoFrame = styled.img`  
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    display: ${({ display }) => !display && 'none'};
    margin: 30px;
    border: 0px solid #666;
    box-shadow: 0 2px 5px #666;
    cursor: ${({ cursor }) => cursor ? 'pointer' : 'inherit'};
`
