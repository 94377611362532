import { useState } from 'react'
import { Link, navigate } from '@reach/router'
import http from '@utils/fetch-client'
import { useAlert } from '@hooks/useAlert'

// import Calendar from '../../../components/Calendar'
import Layout from '@components/Layout'
import ActivityChart from '@components/portal/ActivityChart'
import CitaRequest from '@components/portal/CitaRequest'
import CitaDetail from '@components/portal/CitaDetail'
import CitasList from '@components/portal/CitasList'
import LegendCita from '@components/LegendCita'
import LegendCitaSchedule from '@components/LegendCitaSchedule'
import Loader from '@components/Loader'

const MAINTENANCE = false

const Citas = ({ id, solicitud, detail }) => {
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [Alert, setAlert] = useAlert({})
  const [AlertMaintenance] = useAlert({
    status: true, severity: 'info', isClose: false,
    msg: `<b> Módulo en mantenimiento: </b> Disculpe, se está realizando pruebas de seguridad para mantener 
    sus datos seguros al momento de pagar la cita, en un par de días estará disponible.`})

  const handleCreate = () => {
    setLoadingCreate(false)
    http.put('api/cita')
      .then(({ status, data }) => {
        if (status !== 201) throw new Error(status)
        navigate(`/portal/citas/solicitud/${data.co_cita}`)
      })
      .catch(err => {
        const msg = 'disculpe, NO se logró crear la solicitud, por favor intente mas tarde o comuníquese con nuestras oficinas.'
        console.error(`${err}: ${msg}`)
        setAlert({ status: true, severity: 'error', msg: msg })
      })
      .finally(() => setLoadingCreate(false))
  }

  const showTitle = () => solicitud
    ? <div><Link to='/portal/citas'> Citas </Link> / Solicitud {id && `[cód: ${id}]`} </div>
    : detail
      ? <div><Link to='/portal/citas'> Citas </Link> / Detalle [cód: {id}] </div>
      : 'Citas'

  if(MAINTENANCE){
    return(
      <Layout
        title={'Citas'}
        active='citas'
      >
        <ActivityChart
          title={showTitle()}
          marginTop={solicitud && '5px'}
        > 
          <AlertMaintenance />
        </ActivityChart>
      </Layout>
    )
  }    

  return (
    <Layout
      title={solicitud ? `Solicitando Cita ${id || ''}` : solicitud ? `Cita ${id}` : 'Citas'}
      active='citas'
    >
      {/* <ContainerInfo>
                <Calendar date={new Date()} />
            </ContainerInfo> */}

      {solicitud &&
        <LegendCitaSchedule top />}

      {loadingCreate && <Loader />}
      <Alert />

      {!solicitud && <LegendCita top />}

      <ActivityChart
        title={showTitle()}
        label='Solicitar'
        labelTooltip='Solicitar Cita'
        handleClick={(solicitud || detail || loadingCreate) ? null : handleCreate}
        marginTop={solicitud && '5px'}
      >
        {solicitud
          ? <CitaRequest id={id} />
          : detail
            ? <CitaDetail id={id} />
            : <CitasList />}
      </ActivityChart>

    </Layout>
  )
}

export default Citas
