import styled from 'styled-components'

export const StyledContainer = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    /* transition: 0.5s padding; */
`

export const StyledSeparador = styled.hr`
    width: 80%;

    @media (min-width: 720px) {
        margin: 50px auto;
    }
`

export const StyledContainerFinish = styled.div`
    width: 80%;
    margin: auto;
    padding:50px;
    display: flex;
    justify-content: center;
`

export const StyledContainerLink = styled.div`
    min-height: 200px;
`

export const StyledContainerSAMHOI = styled.div`
    width: 80%;
    margin: auto;
    padding:50px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`

export const StyledStatusMain = styled.div`
    display: flex;
    padding: 5px 0;
    width: 85%;
    margin: 15px auto;
    font-size: 18px;
    border-left: solid 2px ${({ theme }) => theme.color.bluePrimary};

    span{
        padding: 0 5px;
    }
`

export const StyledRow = styled.div`
    padding: 5px 0;
    width: 85%;
    margin: 5px auto 15px;
`

export const StyledSubTitle = styled.h3`
    font-size: 18px;
    width: 85%;
    font-weight: 400;
    padding: 0 5px;
    margin: 0 auto;
    border-left: solid 2px ${({ theme }) => theme.color.bluePrimary};
`

/* export const StyledProgress = styled.div`
    position: absolute;
    width: 80%;
    height: 40px;
    transform: translateX(-20%);
    ` */
