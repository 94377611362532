import styled from 'styled-components'

const width = '200px'

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 50px auto 0 auto;
    padding-top: 30px;
`
/* 
export const Container = styled.section`
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }
`
 */
export const ContainerImg = styled.div`
    padding: 0 20px;
    margin: 0 auto;
`

export const ContainerData = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding: 25px 20px;
    width: calc(100% - 20px);
`

export const Fullname = styled.h2`
    color: ${({ theme }) => theme.color.bluePrimary};
    font-size: 22px;
    margin: 0;
`

export const DataUser = styled.h2`
    color: ${({ theme }) => theme.color.gray};
    font-size: 18px;
    margin: 0 0 30px 0;
`

export const LabelFile = styled.label`
    width: ${width};
    height: 280px;
    margin: 10px auto;
    cursor: pointer;
`

export const File = styled.input`
    width: 0px;
    height: 0px;
`

export const PreviewImg = styled.img`
    width: ${width};
    height: 200px;
    margin: auto;
    object-fit: cover;
    object-position: top;
    margin-bottom: 20px;
    border-radius: 50%;
    box-shadow: 0 5px 10px #666;
`

export const ContainerBtn = styled.div`
    padding: 0.5rem 20px 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Title = styled.h1`
    width: 80%;
    font-size: 2rem;
    text-align: center;
    margin: 0 auto 1.5rem;
    color: ${({ theme }) => theme.color.bluePrimary};
`
export const SubTitle = styled.h2`
    margin: 1rem 0;
    width: 90%;
    font-size: 1.5rem;
    border-left: solid 2px ${({ theme }) => theme.color.bluePrimary};
    // margin: 0 auto 1.5rem;
    padding: 0 0.3rem;
    color: ${({ theme }) => theme.color.bluePrimary};
`

export const Separador = styled.hr`
    width: 80%;
    margin: 25px auto;
`

export const ContainerDocument = styled.div`
    margin: 1rem auto;
    overflow-y: scroll;
    padding: 0.8rem;
    max-width: 700px;
    height: 280px;
`

export const ContainerCheck = styled.div`
    margin: auto;
`


