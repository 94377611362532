import { useState } from 'react'
import { navigate } from '@reach/router'

import { useStateValue } from '../context'
import http from '../utils/fetch-client'

export const useLogin = (setInfo) => {
  const [, dispatch] = useStateValue()
  const [form, setForm] = useState({
    username: '',
    password: '',
    email: '',
    usernameForgot: '',
    requesting: false
  })

  const handleChange = ({ name, value }) => {
    console.log({ name, value })
    setForm({ ...form, [name]: value })
  }

  const login = e => {
    e.preventDefault()
    const { username, password } = form
    if (!username || !password) {
      return setInfo({ status: true, severity: 'error', msg: 'Debe ingresar usuario y contraseña para ingresar al portal.' })
    }

    setForm({ ...form, requesting: true })
    http.post('api/login', { username, password })
      .then(({ status, data }) => {
        console.log('[LOGIN]', { status, data })
        if (status === 401) throw Error(data.message)
        if (status !== 200) throw JSON.stringify({ status, data })
        if (!data.user.usuario) {
          return setInfo({ status: true, severity: 'error', msg: 'Disculpe no se ha recibido información del usuario, por favor reintente ingrear' })
        }

        dispatch({ type: 'LOGIN', payload: data })

        if (data.user.usuario.co_tipo === 1) {
          navigate('portal/perfil', { replace: true })
        } else navigate('admin/home')
      })
      .catch(err => {
        console.error(`[login] ${err}`)
        setInfo({
          status: true,
          severity: 'error',
          msg: err.name === 'Error'
            ? err.message
            : 'Disculpe ha ocurrido un <b>error</b>, por favor comuníquese con nosotros a las <b>oficinas del IPP</b>.'
        })
      })
      .finally(() => setForm({ ...form, requesting: false }))
  }

  return { form, login, handleChange }
}
