import { useState } from 'react'
import { Link } from '@reach/router'

import { useStateValue } from '@context'
import NotificationsIcon from '@components/admin/NotificationsIcon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faHome, faListAlt, faClipboard, faRedo, faUsers, faSignOutAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { Container, Img, Header, NavContainer, Nav, Option, Icon, Label } from './styles'
import logo from './solologo.png'

const NavArea = ({ active, showMenu }) => {
  const [{ user }, dispatch] = useStateValue()
  const [showMenuNone, setShowMenuNone] = useState(window.innerWidth >= 750 ? 1 : 0)
  const toogleMenuNone = () => setShowMenuNone(showMenuNone ? 0 : 1)
  // console.log('[NavArea]', user.co_tipo)
  const logout = () => dispatch({ type: 'LOGOUT' })

  return (
    <Container>
      <Header>
        <FontAwesomeIcon icon={faBars} onClick={toogleMenuNone} />

        <Link to='/portal'>
          <Img src={logo} alt='logo del IPP' />
        </Link>

        <Link to='/admin/notifications'>
          <NotificationsIcon />
        </Link>
      </Header>

      <NavContainer showMenu={showMenu} showMenuNone={showMenuNone}>
        <Nav>
          {/* <Option small>
              <Link to='/admin/notificaciones'>
                  <Icon>
                      <FontAwesomeIcon icon={faBell} />
                  </Icon>
                  <Label> Notificaciones </Label>
              </Link>
          </Option> */}

          <Option active={active === 'home'}>
            <Link to='/admin/home'>
              <Icon>
                <FontAwesomeIcon icon={faHome} />
              </Icon>
              <Label> Home </Label>
            </Link>
          </Option>

          {user.co_tipo === 4
            ? null
            : (
              <>
                <Option active={active === 'citas'}>
                  <Link to='/admin/citas'>
                    <Icon>
                      <FontAwesomeIcon icon={faListAlt} />
                    </Icon>
                    <Label> Citas </Label>
                  </Link>
                </Option>

                <Option active={active === 'emi'}>
                  <Link to='/admin/EMI'>
                    <Icon>
                      <FontAwesomeIcon icon={faListAlt} />
                    </Icon>
                    <Label> EMI </Label>
                  </Link>
                </Option>
              </>)}

          {user.co_tipo === 5
            ? null
            : (
              <>
                <Option active={active === 'reembolsos'}>
                  <Link to='/admin/reembolsos'>
                    <Icon>
                      <FontAwesomeIcon icon={faRedo} />
                    </Icon>
                    <Label> Reembolsos </Label>
                  </Link>
                </Option>

                <Option active={active === 'cartas-aval'}>
                  <Link to='/admin/cartasAval'>
                    <Icon>
                      <FontAwesomeIcon icon={faFileAlt} />
                    </Icon>
                    <Label> Cartas Aval </Label>
                  </Link>
                </Option>
              </>)}

          <Option active={active === 'post'}>
            <Link to='/admin/posts'>
              <Icon>
                <FontAwesomeIcon icon={faClipboard} />
              </Icon>
              <Label> Post </Label>
            </Link>
          </Option>

          <Option active={active === 'users'}>
            <Link to='/admin/users'>
              <Icon>
                <FontAwesomeIcon icon={faUsers} />
              </Icon>
              <Label> Usuarios </Label>
            </Link>
          </Option>

          <Option onClick={logout} salir>
            <Link to='/home'>
              <Icon>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </Icon>
              <Label> Salir </Label>
            </Link>
          </Option>
        </Nav>
      </NavContainer>
    </Container>
  )
}

export default NavArea
