import { useEffect, useRef } from 'react'
import Select from '@components/inputs/Select'
import Button from '@components/Button'

import { useStateValue } from '@context'
import { getDate, getDateUTC } from '@utils/date'

import {
  Container, ContainerOptions, Options,
  ContainerSelected, ContainerSelectedInfo
} from './styles'

const Filters = ({ data = [], nameFilter, visible = true, handleSetData, options = [] }) => {
  const [{ filters }, dispatch] = useStateValue()
  const filtersOptionsRef = useRef(null)
  // const [filters, setFilters] = useState({})
  // const [dataFilters, setDataFilters] = useState([])
  // const orderByDate = (a, b) => (Date.parse(a['fecha_cita']) < Date.parse(b['fecha_cita'])) ?-1 :1

  /* useEffect(() => {
    dispatch({
      type: `filters_${nameFilter}`,
      payload: {}
    })
  }, []) */

  useEffect(() => {
    handleSetData(state => ({
      ...state,
      search: state.data.filter(notification => validateByFields(notification, filters[nameFilter]))
    }))
  }, [filters, handleSetData])

  const validateByFields = (item, filters) => {
    return Object.entries(filters).reduce((acc, [key, option]) => {
      if ([0, '0'].includes(option.value)) return acc
      if (option.value === undefined) return false

      if (option.type === 'date') return acc && getDate(item[key]) === option.value
      if (option.type === 'dateUTC') return acc && getDateUTC(item[key]) === option.value
      else return acc && item[key]?.toString() === option.value
    }, true)
  }

  const handleFilters = target => {
    let { name, value, label, typeValue } = target
    label = label || target.querySelector(`select[name='${name}'] option[value='${value}']`).text
    typeValue = typeValue || target.querySelector(`select[name='${name}'] option[value='${value}']`).dataset.type

    dispatch({
      type: `filters_${nameFilter}`,
      payload: { ...filters[nameFilter], [name]: { value, label, type: typeValue } }
    })
  }

  const renderOption = {
    select: ({ label, typeValue, name }) => (
      <Select
        label={`${label} (Todo)`}
        name={name}
        value={filters[nameFilter][name]?.value || 0}
        options={data.reduce((acc, curr) => {
          let value = (typeValue === 'date')
            ? getDate(curr[name])
            : (typeValue === 'dateUTC') ? getDateUTC(curr[name]) : curr[name]

          const key = value
          if (curr[name] === -1) value = `Sin ${label}`
          if (acc.some(({ opcionID }) => opcionID === key)) return acc

          return acc.concat({ opcionID: key, opcionDes: value, type: typeValue })
        }, [])}
        handleChange={handleFilters}
      />)
  }

  const handleDelete = name => () => handleFilters({ name, value: 0, label: true, typeValue: 'delete' })

  const haveFilters = filters => {
    if (!Object.entries(filters).length) return false

    return Object.entries(filters).reduce((acc, [key, option]) => {
      const isValid = !['0', 0, undefined].includes(option.value)
      return acc || isValid
    }, false)
  }
  
  const handleToogle = () => {
    const display = filtersOptionsRef.current.style.display
    filtersOptionsRef.current.style.display = ['none', ''].includes(display) ? 'flex' : 'none'
  }

  if (!nameFilter) return null

  return (
    <Container visible={visible ? 1 : 0}>
      <ContainerSelected>
        <ContainerSelectedInfo>
          {!haveFilters(filters[nameFilter])
            ? <h4> Sin filtros </h4>

            : Object.entries(filters[nameFilter]).map(([name, option]) => {
              if ([0, '0', undefined].includes(option.value)) return null
              return (
                <div key={name} onClick={handleDelete(name)}>
                  <div>X</div> {option.label}
                </div>
              )
            })}
        </ContainerSelectedInfo>

        <Button
          label='Mostrar/Ocultar Filtros'
          color='secondary'
          variant='outlined'
          onClick={handleToogle}
        />
      </ContainerSelected>

      <ContainerOptions ref={filtersOptionsRef}>
        {options.map(option => {
          if (!option.type) option.type = 'select'
          return (
            <Options key={option.name}>
              {renderOption[option.type](option)}
            </Options>
          )
        })}
      </ContainerOptions>

    </Container>
  )
}

export default Filters
