import { useState } from 'react'
import Button from '@components/Button'
import { Container, Status, Time, TimeDisabled, TimeIPP, TimeDisabledIPP, TimeReserved, Shedules } from './styles'

const LegendCitaShedule = ({ top }) => {
  const [show, setShow] = useState(false)

  const handleToogle = () => setShow(show => !show)

  return (
    <Container top={top}>
      <Button
        label={show ? 'Ocultar leyenda de Horarios' : 'Mostrar leyenda de Horarios'}
        color='secondary'
        onClick={handleToogle}
      />

      {show && <Shedules>
        <Status color='gray'>
          <Time>00:00</Time> Horario Disponible (Web).
        </Status>

        <Status color='primary'>
          <TimeDisabled>00:00</TimeDisabled> Horario No Disponible (Web).
        </Status>

        <Status color='danger'>
          <Time active={1}>00:00</Time> Horario Seleccionado (Web).
        </Status>

        <Status>
          <TimeIPP>00:00</TimeIPP> Horario Disponible (Instalaciones).
        </Status>

        <Status>
          <TimeDisabledIPP>00:00</TimeDisabledIPP> Horario No Disponible.
        </Status>

        <Status>
          <TimeReserved>00:00</TimeReserved> Horario Reservado para el IPP.
        </Status>
      </Shedules>}
    </Container>
  )
}

export default LegendCitaShedule
