import { Helmet } from 'react-helmet'

import Header from '@components/Header'
import Footer from '@components/Footer'
import GoToTheTop from '@components/GoToTheTop'

import { Container, MainContainer, Main } from './styles'

const Layout = ({ children, title, subtitle, active, goTop, noOptions }) => {
  const isPortal = window.location.pathname.match('portal')

  return (
    <>
      <Helmet>
        {title && <title>{title} | IPP-UCV </title>}
        {subtitle && <meta name='description' content={subtitle} />}
      </Helmet>

      <Container>
        <Header active={active} noOptions={noOptions} />
        <MainContainer>
          <Main id='main'>
            {children}
          </Main>
        </MainContainer>
        {!isPortal && <Footer noOptions={noOptions} />}
      </Container>

      {goTop && <GoToTheTop />}
    </>
  )
}

export default Layout
