import { useState } from 'react'
import { navigate } from '@reach/router'
import moment from 'moment'

import Layout from '@components/admin/Layout'
import Loader from '@components/Loader'
import DateRangeSelector from '@components/DateRangeSelector'
import PieChartsFull from '@components/charts/PieChartsFull'
import TableJorCitasMedicas from '@components/TableJorCitasMedicas'
import Filters from '@components/Filters'
import { getDate, getDateUTC } from '@utils/date'

import http from '@utils/fetch-client'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { useAlert } from '@hooks/useAlert'

import { Container } from './styles'

const INITIAL_STATE = { pacientes: { pendientes:[], listos:[] } }
const CLINICAS = [
  { co_clinica:1, nb_clinica:'Caracas', nb_abrev_cli:'CCS' }, 
  { co_clinica:2, nb_clinica:'Maracay', nb_abrev_cli:'MCY' },
  { co_clinica:3, nb_clinica:'CEAPUCV Caracas', nb_abrev_cli:'CEA' }, 
  { co_clinica:4, nb_clinica:'EMI Caracas', nb_abrev_cli:'CCS' }, 
  { co_clinica:5, nb_clinica:'EMI Maracay', nb_abrev_cli:'MCY' }
]

const JornadasCitasLaboratorio = ({ coClinica }) => {
  console.log({coClinica})
  const [loading, setLoading] = useState(true)
  // const [pacientes, setPacientes] = useState(INITIAL_STATE.pacientes)
  const [pacientes, setPacientes] = useState({ pendientes:[], listos:[] })
  const [Alert, setAlert] = useAlert({})

  const orderByDate = (a, b) =>
    (Date.parse(a.fecha_cita) < Date.parse(b.fecha_cita)) ? -1 : 1


  // ?coClinica=1&coEspecial=D1058E49-FFBC-ED11-910B-000C2914A2E9&fecha_inicial=2023-04-27&fecha_final=2023-04-30

  useEffectAbortable(({ aborted }) => { // Obtener Cita
    http.get(`api/jornadas/${coClinica}/citas/medica/laboratorio`)
      .then(({ status, data }) => {
        if (aborted) return
        if (status !== 200 || !Object.keys(data).length) throw JSON.stringify({ status, data })
        
        setPacientes(data.data)
      })
      .catch(err => {
        console.error('[JornadasCitasLaboratorio] citas: ', err)
        setAlert({ status: true, severity: 'error', msg: JSON.parse(err).data.error })
      })
      .finally(() => setLoading(false))
  }, [])


  const handleCitaLaboratorioRecibido = (co_jor_cita, co_usuario, co_jor_horario, username, paciente) => {
    setLoading(true)
    let body = { coJorFecha: 1, observaciones: null }

    http.post(`api/jornadas/citas/fechas/${co_jor_cita}`, body)
    .then(({ status, data }) => {
      console.log('[JornadasCitasLaboratorio] citas', { status, data })
      if (status !== 201) throw JSON.stringify({ status, data })
      
      let newPacientes  = JSON.parse(JSON.stringify(pacientes))
      let updatedIndex = newPacientes.pendientes.findIndex(e => e['co_jor_cita'] == co_jor_cita)
      let updatedPaciente = newPacientes.pendientes.splice(updatedIndex,1)[0]
      newPacientes.listos.push(updatedPaciente)
    
      setAlert({ status: true, severity: 'success', msg: `Los resultados de la cita <b>${co_jor_cita}</b> se recibieron exitosamente.` })
      setPacientes(newPacientes)
    })
    .catch(err => {
      console.error('[JornadasCitasLaboratorio] citas: ', err)
      setAlert({ status: true, severity: 'error', msg: err })
    })
    .finally(() => setLoading(false))
  }
 
  console.log({pacientes})

  return (
    <Layout
      active='emi'
      title='EMI Citas Médicas'
    >
      <Container>
        <h1> {CLINICAS[coClinica-1]?.nb_clinica} | Resultados </h1>
        <Alert />

        {loading
          ? <Loader />
          : (
            <>
              {/* <PieChartsFull
                data={Object.entries(citas).reduce((acc, [key, value]) => {
                  if (!key || key === 'null') return acc
                  return acc.concat({
                    title: key,
                    value: value.length
                  })
                }, [])}
                styles={{ width: 300, height: 200, marginBottom: '1rem' }}
              />
 */}
              {/* <Filters
                nameFilter='citas'
                data={Object.values(citas).reduce((acc, curr) => acc.concat(curr), [])}
                handleSetData={() => { }}
                options={[
                  { label: 'Profesional', name: 'medico' },
                  { label: 'Paciente', name: 'paciente' },
                  { label: 'Encargados', name: 'encargado' },
                  { label: 'Códigos', name: 'co_cita_web' },
                  { label: 'Fechas Creado', name: 'fecha_creado_web', typeValue: 'dateUTC' },
                  { label: 'Fecha Citas', name: 'fecha_cita', typeValue: 'date' }
                ]}
              />
               */}
               
              <TableJorCitasMedicas 
                key={`jorCitaLabPend${Date().toString()}`} 
                label={'Resultados'} 
                root={'EMIlaboratorio'} 
                name={'pendientes'} 
                data={pacientes.pendientes} 
                handleClick={handleCitaLaboratorioRecibido} 
                action={'Recibido'} 
                // disabled={!isToday(horario?.fecha_cita)}
              />
              
              <TableJorCitasMedicas 
                key={`jorCitaLabCita${Date().toString()}`} 
                label={'Resultados'} 
                root={'EMIlaboratorio'} 
                name={'listos'} 
                data={pacientes.listos} 
                handleClick={() => {}} 
                action={null} 
              />


            </>)}
      </Container>
    </Layout>
  )
}

export default JornadasCitasLaboratorio
