import { useState, useRef } from 'react'
import { Link } from '@reach/router'

import Filters from '@components/Filters'
import Tooltip from '@mui/material/Tooltip'
import { usePagination } from '@hooks/usePagination'
import { getDate } from '@utils/date'
// import Filters from '@components/Filters'
import { Container, Rows, Body, Codigo, Item, Title, CreatedAt } from './styles'

const TableJorHorarios = ({ name, data }) => {
  const [Pagination, idx] = usePagination(data.length, 6)
  const [myData, setMyData] = useState({ data, search: data })
  const bodyRef = useRef(null)

  const handleToogle = () => {
    const display = bodyRef.current.style.display
    bodyRef.current.style.display = ['none', ''].includes(display) ? 'block' : 'none'
  }

  if (!name || name === 'null') return null

  return (
    <Container>
      <section>
        <Tooltip title={`Mostrar/Ocultar Horarios (${name})`} aria-label={`Mostrar/Ocultar Horarios (${name})`}>
          <h1 onClick={handleToogle}> Horarios ({name}) ({myData.search.length} de {data.length})</h1>
        </Tooltip>

       {/*  {!data.length ? null
          : <Filters
            nameFilter='citas'
            data={data}
            handleSetData={setMyData}
            options={[
              { label: 'Profesional', name: 'medico' },
              { label: 'Paciente', name: 'paciente' },
              { label: 'Encargados', name: 'encargado' },
              { label: 'Códigos', name: 'co_cita_web' },
              { label: 'Fechas Creado', name: 'fecha_creado_web', typeValue: 'dateUTC' },
              { label: 'Fecha Citas', name: 'fecha_cita', typeValue: 'date' }
            ]}
            visible={false}
          />}
 */}
        <Body ref={bodyRef}>
          {!myData.search.length ? <h3>NO HAY HORARIOS</h3>
            : <>
              <Item header>
                {/* <Codigo>Código</Codigo> */}
                <Title> Clínica </Title>
                <CreatedAt> Día </CreatedAt>
                <CreatedAt> Fecha </CreatedAt>
                <CreatedAt> Atendidos </CreatedAt>
              </Item>

              <Rows>
                {myData.search.slice(idx.start, idx.end).map((
                  { co_jor_horario, clinica, dia, fecha, atender, atendidos }
                ) => (
                  <Link to={`/admin/EMI/citas/${co_jor_horario}`} key={co_jor_horario}>
                    <Item key={co_jor_horario}>
                      {/* <Codigo> {co_cita_web} </Codigo> */}
                      <Title> {clinica} </Title>
                      <CreatedAt> {dia} </CreatedAt>
                      <CreatedAt> {getDate(fecha)} </CreatedAt>
                      <CreatedAt> {`${atendidos||0} de ${atender||0}`} </CreatedAt>
                    </Item>
                  </Link>
                ))}
              </Rows>
              <Pagination />
            </>}
        </Body>
      </section>
    </Container>

  )
}

export default TableJorHorarios
