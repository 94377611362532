import styled from 'styled-components'

export const Container = styled.div`
    width: 85%;
    margin: 50px auto;

    h1{
        color: ${({ theme }) => theme.color.bluePrimary}
    }

    a{
        text-decoration: none;
        color: ${({ theme }) => theme.color.black};
    }

    section{
        margin-bottom: 50px;
        padding: 1px 20px;
        margin-bottom: 50px;
        border-radius: 10px;
        background: white;
    }
`

export const Rows = styled.div`
    min-height: 550px;
    @media(min-width: 750px){
        min-height: 200px;
    }
`

export const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 250px;

    @media(min-width: 750px ){
        justify-content: flex-start;
    }
`

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @media(min-width: 520px ){
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Post = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin: auto;
    padding: 8px 5px;
    cursor: pointer;
    text-decoration: none;
    justify-content: space-between;
    font-weight: ${({ header }) => header ? '500' : ''};
    font-size: ${({ header }) => header ? '18px' : '15px'};
    
    &:hover{
        background-color: ${({ header }) => header ? '' : '#eee'}    
    }

    @media(min-width: 750px ){  
        flex-direction: row;
    }
`

export const CreatedAt = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    @media(min-width: 750px ){  
        width: 130px;
    }
`

export const UpdatedAt = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    @media(min-width: 750px ){  
        width: 130px;
    }
`

export const Status = styled.div`
    display: flex;
    width: 80px;
    justify-content: center;
    align-items: center;
    color: ${({ theme, published, header }) => header ? theme.color.black
        : published ? theme.color.success : theme.color.danger}
`
