import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Button from '@components/Button'
import Loader from '@components/Loader'
import InputText from '@components/inputs/Text'

import { useAlert } from '@hooks/useAlert'
import { useStateValue } from '@context/index.js'
import http from '@utils/fetch-client'

import { ContainerBtn } from './styles'
import useStyles from './stylesUI'

const initialState = {
  email: '',
  phone: '',
  phoneHab: '',
  fotoPerfil: '',
  valid: false
}

const ChangeDataUser = ({userData}) => {
  const [{ user }, dispatch] = useStateValue()
  const classes = useStyles()
  const [dataUser, setDataUser] = useState(initialState)
  const [MyAlert, setInfo] = useAlert({})
  const [loading, setLoading] = useState(false)
  const isAdminChange = Boolean(userData)

  useEffect(() => {
    if(isAdminChange) return setDataUser({
      email: userData.tx_correo ? userData.tx_correo.trim() : '',
      phone: userData.tx_tel_cel ? userData.tx_tel_cel.trim() : '',
      phoneHab: userData.tx_telf_hab ? userData.tx_telf_hab.trim() : '',
      direccion: userData.tx_direc_hab ? userData.tx_direc_hab.trim() : '',
      coUsuario: userData.co_usuario,
      valid: false
    })
    
    setDataUser({
      email: user.tx_correo ? user.tx_correo.trim() : '',
      phone: user.tx_tel_cel ? user.tx_tel_cel.trim() : '',
      phoneHab: user.tx_telf_hab ? user.tx_telf_hab.trim() : '',
      direccion: user.tx_direc_hab ? user.tx_direc_hab.trim() : '',
      valid: false
    })
  }, [user, userData])
  /* const validPhone = value => {
    const regex = /^\d+$/
    return regex.test(value)
  } */

  const validEmail = value => {
    const regex = /^(.+@.+\..+)$/
    return regex.test(value)
  }

  const validPerfil = () => {
    let valid = true
    if (!dataUser.phone) valid = false
    // if(!validPhone(info.phoneHab)) valid = false
    if (!validEmail(dataUser.email)) valid = false
    return valid
  }

  const handleEmail = ({ name, value }) => {
    setDataUser({ ...dataUser, [name]: value })
  }

  const handlePhone = ({ name, value }) => {
    setDataUser({ ...dataUser, [name]: value })
  }

  const submit = () => {
    setLoading(true)
    const { email, phone, phoneHab, direccion, coUsuario } = dataUser

    let errorHTML = ''
    if (!email) errorHTML += '<li>Debe ingresar un <b>email</b></li>'
    if (!phone) errorHTML += '<li>Debe ingresar un número de <b>telefono</b></li>'
    if (errorHTML) {
      setLoading(false)
      return setInfo({ status: true, severity: 'error', msg: errorHTML })
    }

    const data = {
      nuevoCorreo: email,
      celular: phone,
      habitacion: phoneHab,
      direccion,
      coUsuario,
    }

    http.patch('api/usuario/actualizarInformacionContacto', JSON.stringify(data), {
      'Content-Type': 'application/json'
    })
      .then(({ status, data }) => {
        if (status !== 200) throw Error('Disculpe, no se logró actualizar su información de contacto')
        setInfo({ status: true, severity: 'success', msg: 'Su información de contacto <b>ha sido actualizada</b>.' })
        const dataUpdate = {
          tx_correo: data.nuevoCorreo,
          tx_tel_cel: data.celular,
          tx_direc_hab: data.direccion,
          tx_telf_hab: data.habitacion
        }
        setDataUser({ ...dataUser, ...dataUpdate })
        if(!isAdminChange) dispatch({ type: 'user', payload: { ...user, ...dataUpdate } })
      })
      .catch(err => {
        setInfo({ status: true, severity: 'error', msg: err })
      }).finally(() => setLoading(false))
  }

  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} md={6}>
          <InputText
            id='email'
            value={dataUser.email}
            handleChange={handleEmail}
            label='Correo Electronico'
            name='email'
            required
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputText
            id='phone'
            value={dataUser.phone}
            handleChange={handlePhone}
            label='Teléfono'
            name='phone'
            required
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputText
            id='phoneHab'
            value={dataUser.phoneHab}
            handleChange={handlePhone}
            label='Teléfono Habitación'
            name='phoneHab'
          />
        </Grid>
        <Grid item xs={12}>
          <InputText
            id='direccion'
            value={dataUser.direccion}
            handleChange={handlePhone}
            label='Dirección'
            name='direccion'
          />
        </Grid>
      </Grid>

      <div> {loading ? <Loader /> : <MyAlert />} </div>

      <ContainerBtn>
        <Button
          label='Guardar Cambios'
          onClick={submit}
          color='primary'
          disabled={loading || !validPerfil()}
        />
      </ContainerBtn>
    </>
  )
}

export default ChangeDataUser
