import React, { useState } from 'react'
import { Link } from '@reach/router'
import Helmet from 'react-helmet'

import NavArea from '@components/admin/NavArea'
import NotificationsIcon from '@components/admin/NotificationsIcon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faBell } from '@fortawesome/free-solid-svg-icons'
import { Container, Content, Header, Main } from './styles'

const Layout = ({ children, title, subtitle, active }) => {
  const [showMenu, setShowMenu] = useState(0)
  // const [ showMenu, setShowMenu ] = useState(window.innerWidth >= 750 ?1 :0)
  const toogleMenu = () => setShowMenu(showMenu ? 0 : 1)

  return (
    <>
      <Helmet>
        {title && <title> {title} | IPP-ADMIN </title>}
        {subtitle && <meta name='description' content={subtitle} />}
      </Helmet>

      <Container>
        <NavArea active={active} showMenu={showMenu} toogleMenu={toogleMenu} />

        <Content>
          <Header>
            <FontAwesomeIcon icon={faBars} className='bar' onClick={toogleMenu} />
            <Link to='/admin/notifications'>
              <NotificationsIcon header />
            </Link>
          </Header>

          <Main>
            {children}
          </Main>
        </Content>
      </Container>
    </>
  )
}

export default Layout
