// import StatusRequest from '@components/StatusRequest'
import { useState, useRef } from 'react'
import { Link } from '@reach/router'

import Filters from '@components/Filters'
import Tooltip from '@mui/material/Tooltip'
import { usePagination } from '@hooks/usePagination'
import { getDate } from '@utils/date'
import { Container, Rows, Body, Item, Codigo, Title, CreatedAt, UpdatedAt } from './styles'

const TableReembolsos = ({ name, data }) => {
  const [Pagination, idx] = usePagination(data.length, 4)
  const [myData, setMyData] = useState({ data, search: data })
  const bodyRef = useRef(null)

  const handleToogle = () => {
    const display = bodyRef.current.style.display
    bodyRef.current.style.display = ['none', ''].includes(display) ? 'block' : 'none'
  }

  return (
    <Container>
      <section>
        <Tooltip title={`Mostrar/Ocultar Reembolsos (${name})`} aria-label={`Mostrar/Ocultar Reembolsos (${name})`}>
          <h1 onClick={handleToogle}>Reembolsos ({name}) ({myData.search.length} de {data.length})</h1>
        </Tooltip>

        {!data.length
          ? null
          : (
            <Filters
              nameFilter='reembolsos'
              data={data}
              handleSetData={setMyData}
              options={[
                { label: 'Encargados', name: 'encargado' },
                { label: 'Códigos', name: 'co_solicitud' },
                { label: 'Fechas', name: 'fecha_creado', typeValue: 'dateUTC' }
              ]}
              visible={false}
            />)}

        <Body ref={bodyRef}>
          {!myData.search.length
            ? <h3>NO HAY SOLICITUDES</h3>
            : (
              <>
                <Item header>
                  <Codigo>Código</Codigo>
                  <Title>Titulo</Title>
                  <CreatedAt>Fecha de Creación</CreatedAt>
                  <UpdatedAt>Encargado</UpdatedAt>
                  <UpdatedAt>Última Actualización</UpdatedAt>
                </Item>

                <Rows>
                  {myData.search.slice(idx.start, idx.end).map(r => (
                    <Link to={`/admin/reembolsos/${r.co_solicitud}`} key={r.co_solicitud}>
                      <Item>
                        <Codigo>{r.co_solicitud}</Codigo>
                        <Title>{r.concepto}</Title>
                        <CreatedAt>{getDate(r.fecha_creado)}</CreatedAt>
                        <UpdatedAt>{r.encargado === -1 ? 'N/D' : r.encargado || 'N/D'}</UpdatedAt>
                        <UpdatedAt>{getDate(r.fecha_editado)}</UpdatedAt>
                        {/* <Status> <StatusRequest status={co_status_web}/> </Status> */}
                      </Item>
                    </Link>
                  ))}
                </Rows>

                <Pagination />
              </>)}
        </Body>
      </section>
    </Container>

  )
}

export default TableReembolsos
