import { useState, useEffect, useRef } from 'react'
import { Link, navigate } from '@reach/router'

import Button from '@components/Button'
import Tooltip from '@mui/material/Tooltip'
import InputText from '@components/inputs/Text'
import InputSelect from '@components/inputs/Select'
import { usePagination } from '@hooks/usePagination'
import { getDateUTC } from '@utils/date'
import Grid from '@material-ui/core/Grid'
// import Filters from '@components/Filters'
import { Container, Rows, Header, Body, Status, Item, Title, CreatedAt, UpdatedAt } from './styles'

const dataFilters = {
  search: { nombre:'', publicado:'0' },
  data: {
    publicado: [
      {opcionID:'0', opcionDes:'Todos'},
      {opcionID:true, opcionDes:'Publicado'},
      {opcionID:false, opcionDes:'Borrador'}
    ]
  }
}

const TableAllies = ({ name, data }) => {
  const [Pagination, idx, reset] = usePagination(data.length, 5)
  const [myData, setMyData] = useState({full: data, data })
  const [filters, setFilters] = useState(dataFilters)
  const bodyRef = useRef(null)

  useEffect(() => {
    console.log('TableAllies')
    setMyData({ full: data, data })
  }, [data])

  useEffect(() => {
    const newMyData = JSON.parse(JSON.stringify(myData))
    newMyData.data = newMyData.full.filter(item => {            
      const { nombre, publicado } = item
      console.log({filters, nombre, publicado})
      let valid = true
      if(valid && filters.search.publicado !== '0') valid = publicado?.toString() === filters.search.publicado
      if(valid && filters.search.nombre !== ''){
          const regex = new RegExp(filters.search.nombre, 'gi')
          valid = ( regex.test(nombre.toString()) )
      } 
      return valid
    })
    reset()
    setMyData(newMyData)
  }, [filters]) 

  const handleToogle = () => {
    const display = bodyRef.current.style.display
    bodyRef.current.style.display = ['none', ''].includes(display) ? 'block' : 'none'
  }

  const handleBlur = ({name, value}) => {
    const newFilters = JSON.parse(JSON.stringify(filters))
    newFilters.search[name] = value
    setFilters(newFilters)
  }
  
  const createClinic = () => navigate('/admin/clinic/create')
  
  if (!name || name === 'null') return null
console.log({myData})
  
  return (
    <Container>
      <section>
        <Header>
          <Tooltip title='Mostrar/Ocultar Clínicas' aria-label='Mostrar/Ocultar Clínicas'>
            <h1 onClick={handleToogle}> Aliados ({myData.data.length} de {myData.full.length})</h1>
          </Tooltip>
          <Button
            label='Agregar Clínica'
            color='success'
            onClick={createClinic}
          />
        </Header>


        <Body ref={bodyRef}>
          <Rows>
            <Grid item xs={12} sm={4}> 
              <InputText
                id='nombre'
                name='nombre'
                label='Búsqueda por nombre de Aliado'
                handleBlur={handleBlur}
              />
            </Grid> 
            <Grid item xs={12} sm={4}> 
              <InputSelect 
                name='publicado'
                label='publicado'
                value={filters.search.publicado}
                defaultOption={'0'}
                options={filters.data.publicado}
                handleChange={handleBlur}
              />
          </Grid> 
          </Rows>

          {!myData.data.length ? <h3>NO HAY SOLICITUDES</h3>
            : <>
              <Item header>
                <Title>Titulo</Title>
                <CreatedAt>Fecha de creación</CreatedAt>
                <UpdatedAt>Fecha de modificación</UpdatedAt>
                <Status header> Estatus</Status>
              </Item>

              <Rows>
                {myData.data.slice(idx.start, idx.end).map((
                  { co_clinica, nombre, fecha_creado, fecha_editado, publicado }
                ) => (
                  <Link to={`/admin/clinic/${co_clinica}`} key={co_clinica}>
                    <Item>
                      <Title>{nombre}</Title>
                      <CreatedAt>{getDateUTC(fecha_creado)}</CreatedAt>
                      <UpdatedAt>{getDateUTC(fecha_editado)}</UpdatedAt>
                      <Status published={publicado}>{publicado ? 'Publicado' : 'borrador'}</Status>
                    </Item>
                  </Link>
                ))}
              </Rows>
              <Pagination />
            </>}
        </Body>
      </section>
    </Container>

  )
}

export default TableAllies
