export const proccessRequest = {
  selected: 1,
  data: [
    {
      id: 1,
      required: true,
      type: 'text',
      title: 'Concepto',
      name: 'concepto',
      formats: 'JPG, JPEG, PNG, PDF',
      description: 'En el siguiente espacio deberá indicar una breve descripción sobre su solicitud de reembolso'
    },
    {
      id: 2,
      required: true,
      type: 'file',
      title: 'Informe Médico',
      name: 'informe-medico',
      formats: 'JPG, JPEG, PNG, PDF',
      description: `En el siguiente espacio deberá ingresar el Informe médico amplio y detallado En el caso de cirugías programadas, el informe médico deberá explicar con detalle; el procedimiento, tratamiento o cirugía a la cual fue sometido el paciente; debe
            incluir además la fecha (vigente), la firma y sello húmedo del médico tratante. En
            el caso de los estudios diagnósticos, la orden emitida por el médico tratante
            deberá incluir la fecha además de la firma y el sello húmedo del médico tratante.`
    },
    {
      id: 3,
      required: false,
      type: 'file',
      title: 'Orden Médica',
      name: 'orden-medica',
      formats: 'JPG, JPEG, PNG, PDF',
      description: 'En el siguiente espacio deberá ingresar la orden médica.'
    },
    {
      id: 4,
      required: true,
      type: 'file',
      title: 'Factura Fiscal',
      name: 'factura-fiscal',
      formats: 'JPG, JPEG, PNG, PDF',
      description: 'En el siguiente espacio deberá ingresar el Presupuesto y Factura (formato SENIAT) desglosado de la clínica afiliada, incluyendo honorarios médicos. Este será sometido a Baremo de precios justos y razonables.'
    },
    {
      id: 5,
      required: true,
      type: 'file',
      title: 'Cédula de Identidad',
      name: 'cedula',
      formats: 'JPG, JPEG, PNG, PDF',
      description: 'En el siguiente espacio deberá ingresar la cédula de identidad del solicitante.'
    },
    {
      id: 6,
      required: true,
      type: 'file',
      title: 'Instrumento de Pago',
      name: 'instrumento-pago',
      formats: 'JPG, JPEG, PNG, PDF',
      description: 'En el siguiente espacio deberá ingresar la forma de pago. Si es en divisas copia de los billetes o una carta explicativa'
    }
  ]
}

export const requestIntro = () => (<>
  <h2>Instrucciones</h2>

  <h4>
    Para que el Reembolso pueda proceder el afiliado deberá consignar los siguientes documentos:
  </h4>

  <ul>
    <li>
      Informe médico amplio y detallado En el caso de cirugías programadas, el informe médico
      deberá explicar con detalle; el procedimiento, tratamiento o cirugía a la cual fue sometido el paciente; debe incluir además la fecha (vigente), la firma y sello húmedo del médico tratante. En el caso de los estudios diagnósticos, la orden emitida por el médico tratante deberá incluir la fecha además de la firma y el sello húmedo del médico tratante.
    </li>

    <li>
      Copia de los informes de los resultados de los exámenes complementarios que soporten el diagnóstico.
    </li>

    <li>
      Presupuesto y Factura (formato SENIAT) desglosado de la clínica afiliada, incluyendo honorarios médicos Este será sometido a Baremo de precios justos y razonables.
    </li>

    <li> Copia de la cédula de identidad del titular y/o familiar. </li>

    <li>
      En el caso de reembolsos para siniestros no cubiertos por otras compañías aseguradoras, se deberá consignar la constancia de finiquito emitida por la empresa.
    </li>
  </ul>
  {/*
    <ul>
        <li>
            Informe médico amplio y detallado En el caso de cirugías programadas, el informe médico
            deberá explicar con detalle; el procedimiento, tratamiento o cirugía a la cual fue sometido
            el paciente; debe incluir además la fecha (vigente), la firma y sello húmedo del médico
            tratante. En el caso de los estudios diagnósticos, la orden emitida por el médico tratante
            deberá incluir la fecha además de la firma y el sello húmedo del médico tratante.
        </li>

        <li> Copia de los informes de los resultados de los exámenes complementarios que soporten el diagnóstico. </li>

        <li>
            Presupuesto y Factura (formato SENIAT) desglosado de la clínica afiliada,
            incluyendo honorarios médicos Este será sometido a Baremo de precios justos y razonables.
        </li>

        <li> Copia de la cédula de identidad del titular y/o familiar. </li>

        <li>
            En el caso de facturas emitidas por siniestros en el extranjero, las mismas deberán ser
            presentadas ante notario público del país de origen o validarse en el Consulado de Venezuela
            en ese país.
        </li>

        <li>
            En el caso de cuidados especiales o fisioterapias a domicilio se deberá consignar, cada
            vez que se solicite el Reembolso, la fotocopia de la cédula de identidad del profesional
            que presta el servicio junto con la fotocopia del certificado o diploma.
        </li>

        <li>
            En el caso de reembolsos para siniestros no cubiertos por otras compañías aseguradoras,
            se deberá consignar la constancia de finiquito emitida por la compañía de seguros o
            administradora de salud correspondiente.
        </li>
    </ul> */}
</>)
