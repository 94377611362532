import React, { useState } from 'react'
import PhotoFrameDocumentPDF from '@components/PhotoFrameDocumentPDF'
import { useStateValue } from '@context'
import { PhotoFrame } from './styles'

const PhotoFrameDocument = ({ src, description, cursor, alt, horizontal, width = '200px', height = '280px' }) => {
  const [loading, setLoading] = useState(true)
  const [, dispatch] = useStateValue()

  // console.log({ src, description, cursor, alt, horizontal })

  const OrientationImage = ({ target }) => {
    const { naturalHeight, naturalWidth } = target
    if (naturalWidth > naturalHeight) {
      target.style.height = width
      target.style.width = height
    }
    // target.style.display = 'block'
    setLoading(false)
  }

  const handleClick = () => {
    dispatch({
      type: 'modalImage',
      payload: {
        open: true,
        src,
        alt: alt || description,
        description: description || alt
      }
    })
  }

  const handleError = e => {
    console.log('error load', { e })
  }

  const showFile = () => {
    // console.log('showFile', {src, description})
    const isPDF = /(.pdf)$/i

    if (isPDF.exec(src)) {
      return <PhotoFrameDocumentPDF file={src} />
    }

    return (
      <PhotoFrame
        cursor={cursor ? 1 : 0}
        src={src}
        alt={alt || description}
        width={width}
        height={height}
        onClick={handleClick}
        onLoad={!horizontal && OrientationImage}
        display={loading ? 0 : 1}
      />
    )
  }

  return (
    <>
      {showFile()}

      <PhotoFrame
        cursor={cursor ? 1 : 0}
        src='/img-default.jpg'
        alt='imagen por defecto'
        width={width}
        height={height}
        onLoad={!horizontal && OrientationImage}
        onError={handleError}
        display={loading ? 1 : 0}
      />
    </>
  )
}

export default PhotoFrameDocument
