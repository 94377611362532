import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    bottom: 70px;
    right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    z-index: 2000;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.color.shadow};
    box-shadow: 2px 2px 5px ${({ theme }) => theme.color.shadow};
    font-size: 30px;
    background: white;
    cursor: pointer;
`
