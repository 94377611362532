import styled from 'styled-components'

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px 25px;  
    height: calc(100% - 30px);
    font-size: 20px;
    
    h3{
        margin: 10px 0;
        font-size: 30px;
    }  
`

export const Content = styled.div`
    height: 65%;
    width: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: justify;
    /* -webkit-scrollbar: none; */

    &::-webkit-scrollbar{ width: 10px; }
    &::-webkit-scrollbar-thumb {
        background:  ${({ theme }) => theme.color.gray};
        border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: ${({ theme }) => theme.color.bluePrimary};
    }

    /* div{ width: 100% } */

    p{
        font-size: 16px;
        margin: 0 20px;
        text-indent: 10px;
    }

    li{
        color: ${({ theme }) => theme.color.gray};
        font-size: 16px;
        margin-bottom: 5px;
    }
    li span{
        color: #000;
    }
/* 
    @media(min-width: 650px){
        div{ width: 50% }
    } */
`

export const Header = styled.div`
    display: flex;
`

export const Title = styled.h3`
    color: ${({ theme }) => theme.color.bluePrimary};
`

export const Subtitle = styled.h3`
    width: 100%;
    font-size: 22px;
    color: ${({ theme }) => theme.color.gray};
    font-weight: 700;
    text-align: center;
    margin: 5px 0;
`

export const NavContainer = styled.div`
    display: flex;   
    height: 10%;
    justify-content: center;
    align-items: center;
`

export const Nav = styled.div`
    width: 10px;
    height: 10px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 50%;
    background-color: ${({ theme, selected }) => (selected) ? theme.color.bluePrimary : theme.color.ligthGray}
`
