import styled from 'styled-components'

export const ServiceContainer = styled.section`
  /* min-height: 400px;  */
  margin: 5rem auto;
  
  @media (min-width: 720px) {
    margin-bottom: 2rem;
  }
`

export const Container = styled.div`
    display: none;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media (min-width: 720px) {
        flex-direction: ${({ even }) => (even) ? 'row' : 'row-reverse'};
        justify-content: space-around;
    }
`

export const ContainerImg = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 720px) {
        width: 50%;
    }
`

export const Img = styled.img`
    border-radius: 10px;
    box-shadow: 0rem 0.5rem 0.6rem rgb(32 33 36 / 28%);
    
    @media (max-width: 520px) {
      width: 90% !important;
    }
`

export const Description = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: auto;
    padding: 0rem;
    
    div{
      text-align: justify;
    }
    
    h3{
      font-size: 1.3rem;
      margin-left: 12%;
      color: ${({ theme }) => theme.color.bluePrimary}
    }
    
    p{
      width: 76%;
      text-indent: 0.6rem;
      text-align: justify;
      margin: 0 auto;
    }
    
    button{
      width: 70%;
      margin: 1.25rem auto 0;
    }
    
    @media (min-width: 720px) {
      width: 50%;
      padding: 1.95rem;
    }
`
