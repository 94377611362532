import React from 'react'
import { Router, Redirect } from '@reach/router'

import NotFound from '@pages/NotFound'
import General from '@pages/portal/guide/General'
import Citas from '@pages/portal/guide/Citas'
import Reembolsos from '@pages/portal/guide/Reembolsos'
import Cartas from '@pages/portal/guide/Cartas'

const Portal = () => (
  <Router>
    <NotFound default />
    <Redirect from='/' to='general' noThrow />
    <General path='general' />
    <Citas path='citas' />
    <Reembolsos path='reembolsos' />
    <Cartas path='cartasAval' />

  </Router>
)

export default Portal
