import user from './user'
import profesionals from './profesionals'
const profesionalsInfo = profesionals.map(({ horarios, ...profesional }) => profesional)

const citas = [
  {
    id: 1,
    service: { cod: 8, name: 'Diagnóstico Médico' },
    date: '28/08/2020',
    profesional: profesionalsInfo[0],
    payment: {
      destino: '212125',
      monto: '15002222',
      origen: '012545151515151',
      referencia: '1254',
      titular: 'Jean Carlos',
      type: 2
    },
    status: 1,
    user: user
  },
  {
    id: 2,
    service: { cod: 24, name: 'Otorrinolaringología' },
    date: '28/08/2020',
    profesional: profesionalsInfo[1],
    payment: {
      file: 'https://betwarrior.zendesk.com/hc/article_attachments/360037112971/mceclip5.png',
      type: 1
    },
    status: 2,
    user: user
  },
  {
    id: 3,
    service: { cod: 7, name: 'Cuidados Paliativos' },
    date: '28/08/2020',
    profesional: profesionalsInfo[2],
    payment: {
      destino: '212125',
      monto: '15002222',
      origen: '012545151515151',
      referencia: '1254',
      titular: 'Jean Carlos',
      type: 2
    },
    status: 3,
    user: user
  },
  {
    id: 4,
    service: { cod: 6, name: 'Cuidados Ambulatorios' },
    date: '28/08/2020',
    payment: {
      file: 'https://betwarrior.zendesk.com/hc/article_attachments/360037112971/mceclip5.png',
      type: 1
    },
    profesional: profesionalsInfo[3],
    status: 2,
    user: user
  },
  {
    id: 5,
    service: { cod: 24, name: 'Otorrinolaringología' },
    date: '28/08/2020',
    profesional: profesionalsInfo[1],
    payment: {
      file: 'https://betwarrior.zendesk.com/hc/article_attachments/360037112971/mceclip5.png',
      type: 1
    },
    status: 2,
    user: user
  },
  {
    id: 6,
    service: { cod: 7, name: 'Cuidados Paliativos' },
    date: '28/08/2020',
    profesional: profesionalsInfo[2],
    payment: {
      destino: '212125',
      monto: '15002222',
      origen: '012545151515151',
      referencia: '1254',
      titular: 'Jean Carlos',
      type: 2
    },
    status: 3,
    user: user
  },
  {
    id: 7,
    service: { cod: 6, name: 'Cuidados Ambulatorios' },
    date: '28/08/2020',
    payment: {
      file: 'https://betwarrior.zendesk.com/hc/article_attachments/360037112971/mceclip5.png',
      type: 1
    },
    profesional: profesionalsInfo[3],
    status: 2,
    user: user
  },
  {
    id: 8,
    service: { cod: 24, name: 'Otorrinolaringología' },
    date: '28/08/2020',
    profesional: profesionalsInfo[1],
    payment: {
      file: 'https://betwarrior.zendesk.com/hc/article_attachments/360037112971/mceclip5.png',
      type: 1
    },
    status: 2,
    user: user
  },
  {
    id: 9,
    service: { cod: 7, name: 'Cuidados Paliativos' },
    date: '28/08/2020',
    profesional: profesionalsInfo[2],
    payment: {
      destino: '212125',
      monto: '15002222',
      origen: '012545151515151',
      referencia: '1254',
      titular: 'Jean Carlos',
      type: 2
    },
    status: 3,
    user: user
  },
  {
    id: 10,
    service: { cod: 6, name: 'Cuidados Ambulatorios' },
    date: '28/08/2020',
    payment: {
      file: 'https://betwarrior.zendesk.com/hc/article_attachments/360037112971/mceclip5.png',
      type: 1
    },
    profesional: profesionalsInfo[3],
    status: 2,
    user: user
  }
]

export default citas
