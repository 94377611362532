import { useState } from 'react'
import { navigate } from '@reach/router'
import Pagination from '@mui/material/Pagination'

export const usePagination = (amount, amountPerPage = 5, url) => {
  const [page, setPage] = useState(0)
  const [index, setIndex] = useState({ start: 0, end: amountPerPage })
  // let data =  news.slice(index, index + 5)

  const handleChange = (event, value) => {
    // console.log('value', value)
    const index = (value) ? (value - 1) * amountPerPage : 0
    setIndex({ start: index, end: index + amountPerPage })
    if (url) navigate(`/noticias/page/${value}`)
    else setPage(value)
  }

  const reset = () => {
    setIndex({ start: 0, end: amountPerPage })
  }

  // console.log(status, msg, severity)
  const MyPagination = () => (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
      <Pagination
        count={Math.ceil(amount / amountPerPage)}
        page={(page) ? parseInt(page) : 1}
        onChange={handleChange}
      />

      <div style={{ color: 'gray', display: 'flex', alignItems: 'center' }}>
        {`${index.start + 1}-${index.end > amount ? amount : index.end} de ${amount}`}
      </div>
    </div>
  )
  return ([MyPagination, index, reset])
}
