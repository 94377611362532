import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    margin: 0.5rem auto;

    h1{
        color: ${({ theme }) => theme.color.bluePrimary};
        cursor: pointer;
    }

    a{
        text-decoration: none;
        color: ${({ theme }) => theme.color.black};
    }

    section{
        width: 100%;
        overflow: auto;
        /* margin-bottom: 50px; */
        padding: 1px 20px;
        margin-bottom: 50px;
        border-radius: 10px;
        background: white;
    }
`

export const Rows = styled.div`
    /* width: 100%; */
    min-height: 430px;
    @media(min-width: 750px){
        min-height: 180px;
    }
`

export const Body = styled.div`
    // display: none;
`

export const Item = styled.div`
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;    
    padding: 8px 10px;
    /* cursor: pointer; */
    text-decoration: none;
    justify-content: space-between;
    font-weight: ${({ header }) => header ? '500' : ''};
    font-size: ${({ header }) => header ? '18px' : '15px'};
    
    div{
        margin-bottom: 2px;
    }

    &:hover{
        background-color: ${({ header }) => header ? '' : '#eee'}    
    }

    @media(min-width: 750px){
        flex-direction: row;
    }
`

export const BodySection = styled.div`
    max-height: 200px;
    overflow: auto;

    &::-webkit-scrollbar{
        width: 10px;    
    }
    &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.color.gray};
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: hsla(206, 40%, 60%, 1);
    }
`

export const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    @media(min-width: 750px){
        width: 100px;
        text-align: left;
        justify-content: flex-start;
    }

    @media(min-width: 930px){
        width: 250px;
    }
    
`

export const CreatedAt = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-align: center;
    
    @media(min-width: 750px){
        width: 150px;
    }

    @media(min-width: 800px){
        width: 170px;
    }
`

export const UpdatedAt = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    
    @media(min-width: 750px){
        width: 150px;
    }

    @media(min-width: 800px){
        width: 170px;
    }
`

export const Status = styled.div`
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme, status, header }) =>
        header ? theme.color.black
        : (status === 0) ? theme.color.danger
        : (status === 1) ? theme.color.secondary
        : (status === 2) ? theme.color.primary
        : theme.color.success
    }
`

export const Codigo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
`
