import React, { useRef } from 'react'
import { Link } from '@reach/router'

import { useStateValue } from '@context'
import config from '@utils/config'

import { NavBar, SubNavBar, Option, OptionImg, Img } from './styles'

const NavPortal = ({ active }) => {
  const [{ user }, dispatch] = useStateValue()
  const samhoi = useRef(null)
  const isBeneficiary = user.co_tipo === 1
  const logout = () => dispatch({ type: 'LOGOUT' })

  const handleToogle = ref => () => {
    const value = ref.current.style.display
    ref.current.style.display = (value === 'none' || value === '') ? 'flex' : 'none'
  }

  return (
    <NavBar>
      {!isBeneficiary &&
        <Option>
          <Link to='/admin'> Admin </Link>
        </Option>}

      {/* 1: Beneficiario */}
      {isBeneficiary && (
        <>
          <Option selected={active === 'citas'}>
            <Link to='/portal/citas'>
              Citas
            </Link>
          </Option>

          <Option selected={active === 'samhoi'} onClick={handleToogle(samhoi)}>
            SAMHOI
            <SubNavBar ref={samhoi}>
              <Option>
                <Link to='/portal/samhoi/reembolsos'>
                  Reembolsos
                </Link>
              </Option>

              <Option>
                <Link to='/portal/samhoi/cartasAval'>
                  Carta Aval
                </Link>
              </Option>
            </SubNavBar>
          </Option>

          <Option selected={active === 'cobertura'}>
            <Link to='/portal/cobertura'>
              Cobertura
            </Link>
          </Option>

          <Option selected={active === 'jornadas'}>
            <Link to='/portal/EMI'>
              EMI
            </Link>
          </Option>
        </>)}

      <Option selected={active === 'guia'}>
        <Link to='/portal/guia'> Guía al Usuario </Link>
      </Option>

      <Option>
        <Link to='/home'> Inicio </Link>
      </Option>

      <Option onClick={logout} logout>Salir</Option>



      <Option>
        <Link to='/portal/perfil'> Perfil </Link>
      </Option>

      {/* <OptionImg>

        <div>
        <Link to='/portal/perfil'>
          <Img
            src={user.file
              ? user.file
              : user.foto_perfil
              ? `${config.statics.users}/${user.foto_perfil}`
                : '/perfil-default.png'}
                alt={`Foto de Perfil de ${user.nb_benefi?.trim()}`}
          />
        </Link>
        </div>
        <span>{user['nb_benefi'] || user['username'] }</span>
        
      </OptionImg> */}

    </NavBar>
  )
}

export default NavPortal
