import styled from 'styled-components'

export const Container = styled.section`
    width: 85%;
    margin: auto;
    margin-bottom: 50px;
    padding: 1px 20px;
    margin-bottom: 50px;
    border-radius: 10px;
    background: white;

`
export const Title = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
 
    span{
        font-size: 18px;
        margin-left: 10px;
    }
`
