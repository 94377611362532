export const valores = [
  {
    id: 1,
    title: 'Valores',
    subtitle: 'La Creación de futuro',
    content: `<div>
                <p>Entendida como un propósito y, a la vez, una cualidad intrínseca de nuestra acción,  encaminada a la renovación permanente con el fin de consolidar aun más nuestra institución.</p>
            </div>`
  },
  {
    id: 2,
    title: 'Valores',
    subtitle: 'Autorresponsabilidad',
    content: `<div>
                <p> Concebida como el ejercicio pleno y  consciente de la libertad personal, manifestada no solo en el cumplimiento de los deberes y compromisos, sino también el reto constante de la auto superación laboral y personal.</p>
            </div>`
  },
  {
    id: 3,
    title: 'Valores',
    subtitle: 'El Compromiso',
    content: `<div>
                <p>Implica asumir el Instituto como algo nuestro, con el cual estamos altamente identificados, sirviendo como portavoces de su misión y sus valores</p>
            </div>`
  },
  {
    id: 4,
    title: 'Valores',
    subtitle: 'Honestidad',
    content: `<div>
                <p>Entendida como el manejo ético  y transparente  de los procesos institucionales propiciando la  rectitud y pulcritud  de las actuaciones individuales y  colectivas.</p>
            </div>`
  },
  {
    id: 5,
    title: 'Valores',
    subtitle: 'El Respeto',
    content: `<div>
                <p>Entendido como el reconocimiento a la diversidad de opiniones, con el fin de  fomentar  la iniciativa personal y la expresión libre de las ideas, propiciando que a cada quien se le tome en cuenta como persona y en la labor que desarrolla en la Institución, a fin de  crear el ambiente de confianza que se requiere para cumplir con la misión que les ha sido encomendada.</p>
            </div>`
  },
  {
    id: 6,
    title: 'Valores',
    subtitle: 'La Equidad',
    content: `<div>
                <p>Entendida como trato igualitario y en correspondencia con el rol desempeñado que constituya la base sobre la cual se desarrolle el proceder con objetividad, imparcialidad e integridad, buscando siempre el bien común que a todos  atañe por igual, sin distingo de nivel o jerarquía.</p>
            </div>`
  },
  {
    id: 7,
    title: 'Valores',
    subtitle: 'La Solidaridad',
    content: `<div>
                <p>Concebida como la participación solidaria expresada en el trabajo en equipo, en la colaboración recíproca y en el aprecio y el respeto por lo que hace cada quien, buscando con ellos ser más productivos y lograr una mejor calidad de gestión.</p>
            </div>`
  },
  {
    id: 8,
    title: 'Valores',
    subtitle: 'La Igualdad',
    content: `<div>
                <p>Expresada en un trato similar para el desarrollo de cada  trabajador o persona, sin discriminación de sexo, etnia, clase social, credo,  capacidad intelectual o física.</p>
            </div>`
  },
  {
    id: 9,
    title: 'Valores',
    subtitle: 'La Responsabilidad Social',
    content: `<div>
                <p>Entendida como el compromiso institucional de contribuir de manera solidaria con el bienestar social de los agremiados y el personal que labora en la institución, concibiendo nuestra función como una actividad sin fines de lucro  que contribuye con el desarrollo nacional, la formación del talento humano, cuya actuación garantice la continuidad de nuestro proyecto y una efectiva prestación de servicio a nuestros usuarios.</p>
            </div>`
  },
  {
    id: 10,
    title: 'Valores',
    subtitle: 'La Disciplina',
    content: `<div>
                <p>Entendida como el compromiso institucional y de cada uno de sus miembros de cumplir con sus responsabilidades dentro de los parámetros temporales y los objetivos establecidos.</p> 
            </div>`
  }
]

export const aboutUs = `<p>

Somos el Instituto de Previsión Social del profesorado de la Universidad Central de Venezuela (IPP), organismo sin fines de lucro dedicado a la protección social del profesor y su grupo familiar, fundado el 24 de octubre de 1958, como logro social que consagró la Acción Gremial como la lucha de los profesores por alcanzar mejoras salariales, condiciones adecuadas para el trabajo académico y la generación de programas de bienestar social para el personal académico de la UCV.
</p>

<p>
En el transcurso de estos 63 años muchos han sido los logros alcanzados, pues de proveer inicialmente atención primaria en salud médica-odontológica, hemos incorporar al servicio distintas especialidades. Uno de los logros más importante lo constituyó, sin duda alguna, la creación del Sistema Médico Hospitalaria Integral (SAMHOI), sistema auto administrado por el IPP. De igual manera la creación del Laboratorio de la Salud, el Servicio de Fisiatría ubicados en la Casa del Profesor, la Proveeduría Farmacéutica (Caracas- Maracay) y la atención médica domiciliaria incorporada como programa a través de SAMHOI.
</p>

<p>
A solicitud de la Gerencia de Protección Social y con la aprobación del Consejo de Directores del IPP se crea la presente página WEB de la Gerencia de Protección Social, contando para ello con el apoyo de la Coordinación de Tecnología de la Información y el trabajo de un equipo asesor interdisciplinario ucevista.
</p>

<p>
En esta primera etapa constituye una mejoría sustancial y la accesibilidad de los servicicos que les prestamos a nuestros afiliados, ya que a través de ella se podrá, solicitar en línea las citas médicas y odontológicas que se prestan en nuestra sede, así como también obtener información sobre los especialistas médicos extra muro con los cuales tenemos alianza. 
</p>

<p>
En el campo de SAMHOI se podrá solicitar tanto la carta aval como los reembolsos. La página mantendrá información actualizada sobre las clínicas afiliadas, así como también el teléfono de contacto con el servicio de atención médica domiciliaria.
</p>

<p>
Y en el área de los servicios funerarios (SICOF), a través de la página se podrá consultar los afiliados y su respectivo plan.
</p>
`
/*<p>
Finalmente, los imponderables que afecten los servicios que prestamos se notificarán en la apertura de la página y todos los APUCV INFORMA o desde la Presidencia que se relacionen con los servicios que prestamos serán subidos a la sección de noticias de esta página.
</p>
*/

export const services = `<p>El instituto reafirma su compromiso con la seguridad social de sus afiliados y la calidad en 
    el desarrollo de todas sus actividades, es por ello que a pesar de la crítica situación 
    financiera y social que sufrimos actualmente y con el propósito de facilitar el acceso a los 
    servicios de atención médica primaria, HCM y odontología, ponemos hoy a disposición de 
    nuestros afiliados la primera etapa de nuestra página web. La cual permitirá solicitar 
    citas médicas y odontológicas, así como también, solicitar y hacer el seguimiento a las 
    cartas avales y reembolsos que se introducirán a través de ella.
    </p>
    `
export const eliminado = `<p>
    Bajo el nuevo modelo de atención, el servicio médico atenderá en nuestra sede las siguientes 
    especialidades: Medicina Interna, Pediatría y Psiquiatría, las demás especialidades serán 
    cubiertas bajo baremo, los procedimientos establecidos ya han sido comunicados en el APUCV 
    INFORMA #1133, no obstante, los mismos se detallan al iniciar sesión en portal de esta página en la sección Guía al Usuario.
    </p>`

export const objetivoGPS = '<p>Gestionar, administrar y realizar el control interno de las políticas de protección social definidas por el Consejo de Directores del IPP, destinadas a favorecer el bienestar social de sus afiliados.</p>'
