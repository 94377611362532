import React, { useEffect, useState } from 'react'
import { useStateValue } from '@context'
import { getUser } from '@utils/getUser'
import http from '@utils/fetch-client'
import handleError from '@utils/handleError'
import Routes from '@routes'
import config from '@config'

import LoadingMain from '@components/LoadingMain'
import Modal from '@components/Modal' // Para noticias emergentes
import ModalImage from '@components/ModalImage'
import IsOffline from '@components/IsOffline'
import InfoIsDev from '@components/InfoIsDev'

const App = () => {
  const [{ user }, dispatch] = useStateValue()
  const [open, setOpen] = useState(false)
  const [notice, setNotice] = useState({})
  console.log({config})

  useEffect(() => {
    if (user === undefined) {
      getUser()
        .then(data => {
          dispatch({
            type: 'LOGIN',
            payload: data
          })
        })
    }
  }, [user, dispatch])

  useEffect(() => {
    http.get('api/post/1')
      .then(({ status, data }) => {
        if (status !== 200) throw Error('Disculpe, ocurrió un error consultados el Post Important. Por favor intente más tarde.')
        setNotice(data[0])
        if (data[0].publicado) setOpen(true)
      })
      .catch(err => handleError({ err, file: 'App', ref: 'useEffect' }))
  }, [])

  if (user === undefined) return (<LoadingMain />)

  return (
    <>
      {config.isDev && <InfoIsDev />}
      <Routes />
      <IsOffline />
      <ModalImage />

      <Modal
        open={open}
        setOpen={setOpen}
        title={notice.titulo}
        banner={notice.banner}
        important
      >
        {notice.cuerpo}
      </Modal>

    </>
  )
}

export default App
