const citaCreating = {
  id: 1,
  service: { co_clinica: 0, type: '' },
  sede: { co_clinica: 0 },
  cita: {
    co_cita: 0,
    co_cita_web: 0,
    sede: '',
    date: '',
    time: '',
    service: { type: 0 },
    profesional: {
      id: '',
      name: '',
      paerfil: ''
    },
    payment: {
      type: 1,
      c2p: {
        cedulaType: 'V',
        cedula: '',
        celular: '',
        banco: 0,
        otp: ''
      },
      file: '',
      tddMercantil: {
        cedulaType: 'V',
        cedula: '',
        celular: '',
        type: '',
        cardNumber: '',
        cvv: '',
        key: '',
      }
    }
  },
  status: 1,
  type: 0
}

export default citaCreating
