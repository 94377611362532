import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'

import PhotoFrameDocument from '@components/PhotoFrameDocument'
import StatusRequest from '@components/StatusRequest'
import StatusDoc from '@components/StatusDoc'
import Button from '@components/Button'
import Loader from '@components/Loader'

import http from '@utils/fetch-client'
import config from '@utils/config'
import { useAlert } from '@hooks/useAlert'

import {
  Container, ContainerTypeDocument, ContainerDocument, Title, StatusMain,
  Files, Document, Separador, Info, Row
} from './styles'
import './styles.css'

const ReembolsoDetail = ({ id }) => {
  const [reembolso, setReembolso] = useState({})
  const [loading, setLoading] = useState(true)
  const [Alert, setAlert] = useAlert({})

  const requestIsReject = reembolso.co_status_web === 4
  const requestIsSAMHOI = reembolso.co_status_web === 5

  useEffect(() => {
    http.get(`api/solicitud/${id}`)
      .then(({ status, data }) => {
        // console.log(status, data)
        if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')
        setReembolso(data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }, [id, setAlert])

  const getStatus = files => {
    const valido = files.reduce((acc, curr) => acc && curr.co_status === 2, true)
    const rechazado = files.reduce((acc, curr) => acc || curr.co_status === 3, false)
    return valido ? 2 : rechazado ? 3 : 1
  }

  const goToRequest = co_solicitud => () => {
    navigate(`/portal/samhoi/reembolsos/solicitud/${co_solicitud}`)
  }

  return (
    <Container>
      <Alert />

      {loading ? <Loader />
        : !Object.keys(reembolso).length ? null : <>
          <StatusMain>
            <span>Esta Solicitud está</span>
            <StatusRequest status={reembolso.co_status_web} />
          </StatusMain>
          {!requestIsSAMHOI ? null : <Row>Descripción del estatus: {reembolso.tx_status}</Row>}

          {Object.entries(reembolso.documentos).map(([name, files], index) => (
            <ContainerTypeDocument key={name + id}>
              {index ? <Separador /> : null}
              <Title>
                <h2>
                  {name.replace('-', ' ')} <StatusDoc status={getStatus(files)} />
                </h2>

              </Title>

              <Files>
                {files.map(({ co_documento, co_status, direccion, observaciones }) => (
                  <ContainerDocument key={`file${name}${id}-${co_documento}`}>
                    <Document>
                      <PhotoFrameDocument
                        src={`${config.statics.documents}/${direccion}`}
                        alt={`file${name}${id}`}
                      />
                    </Document>
                    <Info>
                      <StatusDoc status={co_status} />
                      {co_status === 3 && <p> <b>Observación: </b> {observaciones} </p>}
                    </Info>
                  </ContainerDocument>
                ))}
              </Files>
            </ContainerTypeDocument>
          ))}

          {requestIsReject &&
            <Button
              label='Modificar'
              color='primary'
              onClick={goToRequest(reembolso.co_solicitud)}
            />}
        </>}
    </Container>
  )
}

export default ReembolsoDetail
