import React from 'react'
import { PhotoFrame } from './styles'
import { setUrl } from '@utils/functions'
import config from '@utils/config'

const PHOTO_DEFAULT = '/perfil-default.png'

const PhotoFrameProfile = ({ 
  size,
  width='50px',
  height='50px',
  margin='5px',
  src=PHOTO_DEFAULT, 
  description='foto de perfil' 
}) => {
  const getUrlProfile = url => setUrl(url, config.statics.users)

  return( <PhotoFrame
    height={size || height}
    width={size || width}
    margin={margin}
    src={getUrlProfile(src || PHOTO_DEFAULT)}
    alt={description}
  />)
}

export default PhotoFrameProfile
