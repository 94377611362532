import React, { useState, useEffect, useRef } from 'react'
import { navigate } from '@reach/router'

import Layout from '@components/admin/Layout'
import Loader from '@components/Loader'
import Modal from '@mui/material/Modal'
import MyEditor from '@components/MyEditor'
import Button from '@components/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import http from '@utils/fetch-client'
import { getDateTimeUTC } from '@utils/date'
import { useAlert } from '@hooks/useAlert'
import config from '@utils/config'

import useStyles from './stylesUI'
import {
  Container, Dates, ContainerEditor, ContainerMyEditor, ContainerGuardar, ContainerModal,
  ContainerPublished, Header, Estatus, PreviewImg, LabelFile, File, ModalBody
} from './styles'

const fixedServices = ['2']

const ServiceEdit = (props) => {
  const { id } = props
  const classes = useStyles()
  const [state, setState] = useState({
    titulo: '',
    imagen: '',
    cuerpo: undefined,
    file: null
  })
  const [loading, setLoading] = useState(true)
  const [Alert, setAlert] = useAlert({})
  const [AlertDelete, setAlertDelete] = useAlert({})
  const [open, setOpen] = useState(false)
  const deleteRef = useRef(null)

  const isCreating = () => (id === 'create')

  useEffect(() => {
    if (!isCreating()) {
      http.get(`api/servicio/${id}`)
        .then(({ status, data }) => {
          if (status !== 200) throw Error(`Disculpe, ocurrió un error consultados el Post #${id}. Por favor intente más tarde.`)
          setLoading(false)
          setState(data[0])
        })
        .catch(err => {
          setLoading(false)
          setAlert({ status: true, severity: 'error', msg: err })
        })
    } else {
      setLoading(false)
      setState({ cuerpo: '', titulo: '' })
    }
  }, [id])

  const handlePublish = () => {
    setLoading(true)
    http.patch(`api/servicio/${id}/publicar`)
      .then(({ status, data }) => {
        if (status !== 200) throw Error(`Disculpe, ocurrió un error publicando el Servicio #${id}. Por favor intente más tarde.`)
        setLoading(false)
        setState(data.servicio)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }

  const handleUnpublish = () => {
    setLoading(true)
    http.patch(`api/servicio/${id}/despublicar`)
      .then(({ status, data }) => {
        if (status !== 200) throw Error(`Disculpe, ocurrió un error colocando como borrador el Servicio #${id} como borrador. Por favor intente más tarde.`)
        setLoading(false)
        setState(data.servicio)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }

  const handleUpdate = () => {
    setLoading(true)
    let errorHTML = ''
    if (!state.titulo) errorHTML += '<li>Debe ingresarle un <b>titulo</b> al Servicio.</li>'
    if (!state.cuerpo) errorHTML += '<li>Debe ingresarle un <b>contenido</b> al Servicio.</li>'

    if (errorHTML) {
      setLoading(false)
      return setAlert({ status: true, severity: 'error', msg: errorHTML })
    }

    const body = new FormData()
    body.append('titulo', state.titulo)
    body.append('cuerpo', state.cuerpo)
    if (state.file) body.append('imagen', state.file)
    http.patch(`api/servicio/${id}`, body)
      .then(({ status, data }) => {
        if (status !== 200) throw Error(`Disculpe, ocurrió un error publicando el Post #${id}. Por favor intente más tarde.`)
        setLoading(false)
        setAlert({ status: true, severity: 'success', msg: 'Servicio <b>actualizado exitosamente</b>' })
        setState({ ...state, ...data.servicio })
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }

  const handleDelete = () => {
    const value = deleteRef.current.value
    let errorHTML = ''
    if (!value) errorHTML += '<li>Debe ingresar el codigo del Servicio para eliminarlo.</li>'
    else if (value !== id) errorHTML += '<li>El codigo ingresado no coincide con el del Servicio.</li>'

    if (errorHTML) {
      setAlertDelete({ status: true, severity: 'error', msg: errorHTML })
    } else {
      setOpen(false)
      setLoading(true)
      http.delete(`api/servicio/${id}`)
        .then(({ status, data }) => {
          if (status !== 200) throw Error(`Disculpe, ocurrió un error eliminando el Servicio #${id}. Por favor intente más tarde.`)
          navigate('/admin/home')
        })
        .catch(err => {
          setLoading(false)
          setAlert({ status: true, severity: 'error', msg: err })
        })
    }
  }

  const handleChange = ({ target }) => {
    setState({ ...state, [target.name]: target.value })
  }

  const handleContent = content => {
    setState({ ...state, cuerpo: content })
  }

  const handleSubmitCreate = () => {
    setLoading(true)
    let errorHTML = ''
    if (!state.titulo) errorHTML += '<li>Debe ingresarle un <b>titulo</b> al Servicio.</li>'
    if (!state.cuerpo) errorHTML += '<li>Debe ingresarle un <b>contenido</b> al Servicio.</li>'

    if (errorHTML) {
      setLoading(false)
      return setAlert({ status: true, severity: 'error', msg: errorHTML })
    }

    const body = new FormData()
    body.append('titulo', state.titulo)
    body.append('cuerpo', state.cuerpo)
    if (state.file) body.append('imagen', state.file)

    http.put('api/servicio', body)
      .then(({ status, data }) => {
        if (status !== 201) throw Error('Disculpe, ocurrió un error creando el Post. Por favor intente más tarde.')
        setAlert({ status: true, severity: 'success', msg: `El Servicio #${data.co_servicio} ha sido creado.` })
        navigate(`/admin/service/${data.co_servicio}`)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }

  const renderImage = ({ target }) => {
    const file = target.files[0]
    if (file) {
      console.log(file)
      console.log(URL.createObjectURL(file))
      setState({
        ...state,
        file: file,
        imagen: URL.createObjectURL(file)
      })
    }
  }

  const onErrorImg = ({ target }) => {
    target.onerror = null
    target.src = '/post-default.jpg'
  }

  const isFixedServices = serviceID => {
    return fixedServices.some(e => e === serviceID)
  }

  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false)

  return (
    <Layout
      active='home'
      title={`Servicio ${id}`}
    >
      {loading ? <Loader />
        : <ContainerEditor>
          <Header>
            <Grid item xs={12} md={3}>
              {!isCreating() &&
                <Estatus published={state.publicado}>
                  {state.publicado ? `Publicado - Servicio#${id}` : `Borrador - Servicio#${id}`}
                </Estatus>}
            </Grid>

            <Grid item xs={12} md={3}>
              {!isCreating() &&
                <ContainerPublished>
                  <Button
                    label={state.publicado ? 'Borrador' : 'Publicar'}
                    color={state.publicado ? 'secondary' : 'success'}
                    onClick={state.publicado ? handleUnpublish : handlePublish}
                  />
                </ContainerPublished>}
            </Grid>

            <Grid item xs={12} md={3}>
              {(!isCreating() && !isFixedServices(id)) &&
                <ContainerPublished>
                  <Button
                    label='Eliminar'
                    color='danger'
                    onClick={handleOpenModal}
                  />
                </ContainerPublished>}
            </Grid>

            <Grid item xs={12} md={3}>
              <ContainerGuardar>
                {isCreating()
                  ? <Button
                    label='Agregar Servicio'
                    color='success'
                    onClick={handleSubmitCreate}
                  />

                  : <Button
                    label='Guardar Cambios'
                    color='primary'
                    onClick={handleUpdate}
                  />}
              </ContainerGuardar>
            </Grid>

          </Header>

          <Alert />

          {!isCreating() &&
            <Dates>
              <div><b>Creado:</b>  {getDateTimeUTC(state.fecha_creado)} </div>
              <div><b>Modificado:</b> {getDateTimeUTC(state.fecha_editado)} </div>
            </Dates>}

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                required
                id='titulo'
                value={state.titulo}
                className={classes.formControl}
                onChange={handleChange}
                label='Título'
                name='titulo'
              />
            </Grid>

            {/* <Grid item xs={12} md={4} >
                            <TextField
                                id="alt"
                                value={state.alt}
                                className={classes.formControl}
                                onChange={handleChange}
                                label="Descripción Img"
                                name="alt" />
                        </Grid> */}
          </Grid>

          <Container>
            {/* <Grid container className={classes.root} spacing={2} > */}
            <LabelFile htmlFor='document'>
              <PreviewImg
                id='img'
                src={state.file ? URL.createObjectURL(state.file)
                  : state.imagen ? `${config.statics.services}/${state.imagen}`
                    : '/post-default.jpg'}
                onError={onErrorImg}
              />
            </LabelFile>

            <File
              type='file'
              id='document'
              name='document'
              accept='image/*'
              onChange={renderImage}
            />
            {/* </Grid> */}

            <ContainerMyEditor>
              {state.cuerpo === undefined ? null
                : <MyEditor
                  id={id}/*
                                title={title}
                                src={src}
                                alt={alt} */
                  content={state.cuerpo}
                  handleContent={handleContent}
                />}
            </ContainerMyEditor>
          </Container>
        </ContainerEditor>}

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <ContainerModal className={classes.paper}>
          <h2 id='simple-modal-title' className={classes.title}> ¿Seguro quiere eliminar este Servicio? </h2>
          <ModalBody id='simple-modal-description'>
            <TextField
              required
              id='delete'
              ref={deleteRef}
              className={classes.formControl}
              label='Ingrese el codigo del Servicio'
              onChange={({ target }) => deleteRef.current.value = target.value}
              name='delete'
            />

            <AlertDelete />
            <Button
              label='Eliminar'
              color='danger'
              onClick={handleDelete}
            />
          </ModalBody>
        </ContainerModal>
      </Modal>
    </Layout>
  )
}

export default ServiceEdit
