import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const Content = styled.div`
  height: calc(100vh - 140px);
  width: 100%;
  padding: 0px 30px 15px;
  overflow: auto;
  text-align: justify;

  section {
    display: flex;
    flex-direction: column;
    justify-content: start;
    min-height: 100vh;
    margin: 1.5rem 0;
    padding: 2rem 0;

    h2{
      border-left: 0.3rem solid ${({ theme }) => theme.color.bluePrimary} ;
      margin: 1rem 1rem 2rem;
      padding-left: 1rem;
    }
  }

  h1{
    text-align: center;
    color: ${({ theme }) => theme.color.bluePrimary};
  }

  p{
    text-indent: 1rem;

  }

  img{
    align-self: center;
  }
  
  a{
    /* text-decoration: underline; */
    color: ${({ theme }) => theme.color.bluePrimary};
    cursor: help;
  }
`
