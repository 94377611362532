import http from './fetch-client'

export const getUser = () => {
  return new Promise((resolve, reject) => {
    const id = window.localStorage.getItem('id')

    if (window.localStorage.getItem('token') && id) {
      http.get(`api/usuario/${id}`)
        .then(({ status, data }) => {
          if (status !== 200) throw (status)
          if (data.usuario) {
            resolve({
              token: window.localStorage.getItem('token'),
              user: data
            })
          } else {
            // reject(new Error('No se recibio el usuario'))
            resolve({
              token: null,
              user: { usuario: null, titular: null, beneficiarios: null }
            })
          }
        })
        .catch(err => {
          resolve({
            token: null,
            user: { usuario: null, titular: null, beneficiarios: null }
          })
        })
    } else {
      resolve({
        token: null,
        user: { usuario: null, titular: null, beneficiarios: null }
      })
    }
  })
}
