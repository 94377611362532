import styled from 'styled-components'

export const Content = styled.section`
    display: flex;
    width: 100%;
    min-height: 70vh;
    padding-top: 30px;
`

export const Back = styled.div`
    display: flex;
    width: 100%;
    color: ${({ theme }) => theme.color.gray};
    
    &:hover{
        color: ${({ theme }) => theme.color.bluePrimary};
    }; 
`

export const Post = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 70px);
    padding: 0 20px;
    
    h2, h3, h4, h5, h6 {
        color: #444;
    }

    @media (min-width: 720px) {
        width: 70%;
    }
`

export const PostsMenu = styled.div`
    width: 30%;
    top: 70px;
    position: sticky;
    flex-shrink: 0;
    display: none;

    @media (min-width: 720px) {
        display: block;
    }
`

export const ContainerMenu = styled.section`
    /* width:100%; */
    border-left: 2px solid ${({ theme }) => theme.color.shadow};
    padding-left: 20px;
`

export const PostContent = styled.div`  
    margin: 30px 15px 15px 15px;
    text-align: justify;
    /* color: ${({ theme }) => theme.color.bluePrimary}; */
    /* font-size: 26px; */
`

export const Title = styled.h1`  
    color: ${({ theme }) => theme.color.bluePrimary};
    font-size: 30px;
    margin-top: 0;
`

export const Img = styled.img`  
    width:100%;
    max-width: 700px;
    height: 200px;
    border-radius: 10px;
    object-fit: fill;
`

export const Date = styled.div`  
    color: ${({ theme }) => theme.color.gray};
    font-weight: 500;
    width: 100%;
    max-width: 700px;
    text-align: left;
`
