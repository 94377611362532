import React, { useRef, useEffect } from 'react'
import { navigate } from '@reach/router'
import { ServiceContainer, Container, ContainerImg, Img, Description } from './styles'
import './styles.css'

import Button from '@components/Button'
import config from '@utils/config'

const Service = ({ index, title, description, src, alt, navigatePath }) => {
  const isEven = () => !!((index % 2))
  const serviceRef = useRef()

  const onErrorImg = ({ target }) => {
    target.onerror = null
    target.src = '/post-default.jpg'
  }

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const { intersectionRatio, target } = entries[0]
      const service = target.querySelector('.service')
      if (['', 'none'].some(d => d === service.style.display)) {
        if (target) {
          if (intersectionRatio > 0.45) {
            service.style.display = 'flex'
            service.style.display = 'flex'
          } else {
            service.style.display = 'none'
          }
        }
      }
    }, { threshold: 0.45, root: null })

    observer.observe(serviceRef.current)
  }, [])

  const OrientationImage = ({ target }) => {
    const { naturalHeight, naturalWidth } = target
    if (naturalWidth > naturalHeight) {
      target.style.height = '19rem'
      target.style.width = '25rem'
    } else {
      target.style.height = '25rem'
      target.style.width = '19rem'
    }
  }

  return (
    <ServiceContainer ref={serviceRef} className='service-container'>
      <Container className={`service ${(isEven()) ? 'right' : 'left'}`} even={isEven()}>
        <ContainerImg>
          <Img
            src={src ? `${config.statics.services}/${src}` : '/post-default.jpg'}
            alt={alt}
            onError={onErrorImg}
            onLoad={OrientationImage}
          />
        </ContainerImg>

        <Description>
          <h3>{title}</h3>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          {navigatePath &&
            <Button
              label='VER DIRECTORIO'
              color='secondary'
              onClick={() => navigate(navigatePath)}
            />}
        </Description>
      </Container>
    </ServiceContainer>
  )
}
export default Service
