import React from 'react'
import { Container, Header, Add, AddLabel, Body } from './styles'

import Tooltip from '@mui/material/Tooltip'
// import { Link } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const ActivityChart = ({ title, marginTop, children, label, labelTooltip, handleClick }) => {
  return (
    <Container mt={marginTop}>
      <Header>
        {title}

        {handleClick &&
          <Tooltip title={labelTooltip} aria-label={labelTooltip}>
            <Add onClick={handleClick}>
              <FontAwesomeIcon icon={faPlus} />
              <AddLabel>{label}</AddLabel>
            </Add>
          </Tooltip>}
      </Header>
      <Body> {children} </Body>
    </Container>
  )
}

export default ActivityChart
