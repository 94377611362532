import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'

import PhotoFrameDocument from '@components/PhotoFrameDocument'
import StatusRequest from '@components/StatusRequest'
import StatusDoc from '@components/StatusDoc'
import Button from '@components/Button'
import Loader from '@components/Loader'

import http from '@utils/fetch-client'
import config from '@utils/config'
import { useAlert } from '@hooks/useAlert'

import {
  Container, ContainerTypeDocument, ContainerDocument, Title,
  Files, Document, Separador, Info, StatusMain,
  StyledRow
} from './styles'
import './styles.css'

const ReembolsoDetail = ({ id }) => {
  const [cartaAval, setCartaAval] = useState(null)
  const [loading, setLoading] = useState(true)
  const [Alert, setAlert] = useAlert({})

  // console.log('cartaAval', cartaAval)
  useEffect(() => {
    http.get(`api/solicitud/${id}`)
      .then(({ status, data }) => {
        // console.log(status, data)
        if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')
        setCartaAval(data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }, [id, setAlert])

  const getStatus = (files) => {
    const valido = files.reduce((prev, curr) => prev && curr.co_status === 2, true)
    const rechazado = files.reduce((prev, curr) => prev || curr.co_status === 3, false)

    return valido ? 2 : rechazado ? 3 : 1
  }

  const goToRequest = coSolicitud => () => {
    navigate(`/portal/samhoi/cartasAval/solicitud/${coSolicitud}`)
  }

  return (
    <Container>
      <Alert />
      {loading
        ? <Loader />
        : cartaAval && (
          <>
            <StatusMain>
              <span>Esta Carta Aval está</span>
              <StatusRequest status={cartaAval.co_status_web} />
            </StatusMain>
            <StyledRow>Descripción del estatus: {cartaAval.tx_status}</StyledRow>

            {Object.entries(cartaAval.documentos).map(([name, files], index) => (
              <ContainerTypeDocument key={name + id}>
                {index ? <Separador /> : null}
                <Title>
                  <h2>
                    {name.replace('-', ' ')} <StatusDoc status={getStatus(files)} />
                  </h2>
                </Title>

                <Files>
                  {files.map(({ co_documento, co_status, direccion, observaciones }) => (
                    <ContainerDocument key={`file${name}${id}-${co_documento}`}>
                      <Document>
                        <PhotoFrameDocument
                          src={`${config.statics.documents}/${direccion}`}
                          alt={`file${name}${id}`}
                        />
                      </Document>
                      <Info>
                        <StatusDoc status={co_status} />
                        {co_status === 3 && <p> <b>Observación: </b> {observaciones} </p>}
                      </Info>
                    </ContainerDocument>
                  ))}
                </Files>
              </ContainerTypeDocument>
            ))}

            {cartaAval.co_status_web === 4 &&
              <Button
                label='Modificar'
                color='primary'
                onClick={goToRequest(cartaAval.co_solicitud)}
              />}
          </>)}
    </Container>
  )
}

export default ReembolsoDetail
