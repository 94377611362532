import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @media(min-width: 750px ){  
        flex-direction: row;
    }
    
`
export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;  
`

export const LegendItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0.2rem 0;
`

export const LegendColor = styled.div`
    background-color: ${({ color }) => color || 'transparent'};
    border-radius: 50%;
    min-width: 1rem;
    height: 1rem;    
`

export const LegendLabel = styled.div`
    display: flex;
    margin-left: 0.2rem;
    font-weight: ${({ bold }) => bold ? '600' : '400'};    
`
