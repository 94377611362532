import { useState } from 'react'
import { Link, navigate } from '@reach/router'
import moment from 'moment-timezone'

import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'

import Button from '@components/Button'
import Timer from '@components/Timer'
import Loader from '@components/Loader'
import CitaCard from '@components/portal/CitaCard'
import Progress from '@components/portal/Progress'
import Required from '@components/portal/Required'
import PhotoFrameDocumentPDF from '@components/PhotoFrameDocumentPDF'
import InputText from '@components/inputs/Text'
import InputSelect from '@components/inputs/Select'

import { useStateValue } from '@context/index.js'
import { useAlert } from '@hooks/useAlert'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import citaCreatingInitial from '@mocks/citaCreating'

import { requestIntro, proccessRequest, cedulaTypes, banksId, searchBank, tddTypes, expMes, getExpAno } from '@utils/static/citas'
import http from '@utils/fetch-client'
import config from '@utils/config'
import { getWeek, getWeeksAppointment } from '@utils/date'
import { usePagination } from '@hooks/usePagination'

import {
  Container, ContainerDocuments, FinishTitle, LinkDetail, ContainerIntro,
  Title, Specialties, Specialtie, Payment, PaymentMethods, PreviewImg, LabelFile, File,
  Sedes, Sede, Volver, DocumentName, Row, ContainerTimer,
  ContainerProfesionals, PaymentOptions,
  Form, PaymentOption, Resume, DivWeeks
} from './styles'
// import useStyles from './stylesUI'
const minutesOfWaiting = config.isDev ?5 :10
const limitTime = 1000 * 60 * minutesOfWaiting // minutos a milisegundos

const getMilliseconds = (editedDate, currentDate) => {
  const diff = (Date.parse(currentDate) - Date.parse(editedDate))
  const diffSeconds = diff / 1000
  let rest = limitTime - diff
  rest = rest < 0 ? 0 : rest
  const isValid = diffSeconds <= (minutesOfWaiting * 60)
  return { diff, rest, isValid }
}

const MAINTENANCE = false
const HOUR_OPEN = config.isDev ?0 :6
const HOUR_CLOSE =  config.isDev ?25 :15
const DAY_OPEN = config.isDev ?0 :1
const DAY_CLOSE = config.isDev ?6 :5
const MINUTES_CHECK_AVAILABILITY = config.isDev ?1 :1

const CitaRequest = ({ id }) => {
  // console.log({id})
  const [{ user }] = useStateValue()
  const [citaCreating, setCitaCreating] = useState(citaCreatingInitial)
  const [loading, setLoading] = useState(true)
  const [available, setAvailable] = useState(true)
  const [authorized, setAuthorized] = useState(true)
  const [errorRequest, setErrorRequest] = useState(false)
  const [loadingType, setLoadingType] = useState(true)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [sedes, setSedes] = useState([])
  const [weeks, setWeeks] = useState({data:[], options:[], selected:1})
  const [types, setTypes] = useState([])
  const [miliseconds, setMiliseconds] = useState({ rest: 0, isValid: true })
  const [especialidades, setEspecialidades] = useState({})
  const [doctores, setDoctores] = useState({week:'current', data: []})
  const [steps, setSteps] = useState(proccessRequest)
  const [documents, setDocuments] = useState(false)
  // const [MyAlert, setInfo] = useAlert({})
  const [MyAlertPayment, setAlertPayment] = useAlert({})
  const [Alert, setAlert] = useAlert({})
  const [AlertSucces] = useAlert({
    status: true,
    severity: 'success',
    isClose: false,
    msg: `<span> Su Solicitud de <b>Cita</b> fue completada exitosamente y su pago fue aprobado.
    Le hemos notificado al correo (${user.tx_correo || 'Disculpe, usted No tiene correo registrado, por favor actualícelo en su perfil'})
    y al telefono (${user.tx_tel_cel || 'Disculpe, usted No tiene telefono registrado, por favor actualícelo en su perfil'}).</span>`
  })
  const [Pagination, idx, resetDoctors] = usePagination(doctores.data.length, 3)
  
  // Observa si el horario es disponible
  useEffectAbortable(({aborted}) => {
    const date = moment().tz('America/Caracas')
    const day = date.day()
    const hour = date.hour() + 1
    // const hour = date.format('HH')
    
    const availableDay  =  DAY_OPEN <= day  && day <= DAY_CLOSE 
    const availableTime = HOUR_OPEN <= hour && hour < HOUR_CLOSE 
    console.log({availableDay, DAY_OPEN, DAY_CLOSE, day})
    console.log({availableTime, HOUR_OPEN, HOUR_CLOSE, hour})
    
    if(availableDay && availableTime){
      const interval = setInterval(() => {
        const date = moment().tz('America/Caracas')
        const day = date.day()
        const hour = date.hour() + 1
        // const hour = date.format('HH')
        const availableDay = day>=DAY_OPEN && day<=DAY_CLOSE 
        const availableTime = hour>=HOUR_OPEN && hour<HOUR_CLOSE
        if(!availableDay || !availableTime) setAvailable(false)
      }, 1000 * 60 * MINUTES_CHECK_AVAILABILITY)
      return () => clearInterval(interval)
    }else setAvailable(false)
  }, [])

  useEffectAbortable(mount => { // Obtener Cita
    // console.log(`api/cita/${id}`)
    http.get(`api/cita/${id}`)
      .then(({ status, data }) => {
        if (mount.aborted) return


        if (status !== 200 || !data){
          if(status === 401) setAuthorized(false)
          else setErrorRequest(true)
          throw { status, data }
        } 
        // let miliseconds = {rest:0, isValid: true}
        // console.log({ status, data })

        if (data?.fecha_cita && data?.status === 'En creación') {
          const { diff, rest, isValid } = getMilliseconds(data.fecha_editado_web, data.fecha_actual)
          // miliseconds = {diff, rest, isValid}

          if (!isValid) return deleteAndCreateCita()
          else {
            setMiliseconds({ diff, rest, isValid })
            setDocuments(true)
            setSteps({ ...steps, selected: 4 })
          }
        }

        const newCita = JSON.parse(JSON.stringify(citaCreating))
        newCita.cita = { ...newCita.cita, ...data }

        setCitaCreating(newCita)
        // console.log({ newCita })

        setSteps({ ...steps, selected: 0 })
        setAlert({ status: false })
      })
      .catch(err => {
        const msg = err.status === 401
          ? 'disculpe, <b>no se encontró la cita</b>, será redirigido a la lista de citas, por favor realice una nueva solicitud.'
          : 'disculpe, <b>ocurrió un error en la aplicación</b>, por favor intente nuevamente o comuníquese con nuestras oficinas.'
        console.error('[CitaRequest] Obtener Cita: ', err)

        if(err.status === 401){
          setTimeout(() => { navigate(`/portal/citas`)}, 1000 * 60 * 0.3)
        }
        setAlert({ status: true, severity: 'error', msg: msg })
      })
      .finally(() => setLoading(false))
  }, [id])

  useEffectAbortable(mount => { // Obtener Sedes
    // console.log('obtner sedes')
    http.get('api/cita/sedes')
      .then(({ status, data }) => {
        if (mount.aborted) return
        if (status !== 200) throw new Error(status)
        setSedes(data.filter(({ co_clinica }) => co_clinica < 2))
      })
      .catch(err => {
        const msg = 'disculpe, no se encontró la solicitud, por favor intente mas tarde o comuníquese con nuestras oficinas.'
        console.error(`${err}: ${msg}`)
        setAlert({ status: true, severity: 'error', msg: msg })
      })
      .finally(() => setLoading(false))
  }, [])

  useEffectAbortable(mount => { // Obtener tipos de consultas
    // console.log('obtener tipos de consulta')
    http.get('api/cita/tipoConsulta')
      .then(({ status, data }) => {
        if (mount.aborted) return
        if (status !== 200) throw new Error(status)
        setTypes(data)
      })
      .catch(err => {
        const msg = 'disculpe, no se obtuvieron los tipos de solicitud, por favor intente mas tarde o comuníquese con nuestras oficinas.'
        console.error(`${err}: ${msg}`)
        setAlert({ status: true, severity: 'error', msg: msg })
      })
      .finally(() => setLoadingType(false))
  }, [])

  useEffectAbortable(mount => { // Rellenar selector de semanas
    const weeksAux = getWeeksAppointment()
    const options = weeksAux.map((w, idx) => ({
      opcionID: idx+1, type: 'text',
      opcionDes: `${w[0][0]}-${w[0][1]}-${w[0][2]} al ${w[1][0]}-${w[1][1]}-${w[1][2]}`
    }))
    setWeeks({data:weeksAux, options, selected:1})
  }, [])

  const deleteAndCreateCita = async () => {
    try {
      const { status, data } = await http.delete(`api/cita/${id}`)
      console.log('delete', { status, data })
      if (status !== 200) throw new Error(status)

      const res = await http.put('api/cita')
      console.log('create', { res })
      if (res.status !== 201) throw new Error(res.status)
      // debugger
      navigate(`/portal/citas/solicitud/${res.data.co_cita}`)
    } catch (err) {
      const msg = 'disculpe, NO se logró crear la solicitud, por favor intente mas tarde o comuníquese con nuestras oficinas.'
      console.error(`${err}: ${msg}`)
    }
  }

  const fetchEspecialidades = clinicaID => {
    setLoading(true)
    http.get(`api/cita/especialidades?sede=${clinicaID}`)
      .then(({ status, data }) => {
        if (status !== 200) throw new Error(status)
        console.log({data})
        const addOdontologia = []
        const especialidadesOdontologicas = [
          'F7A476F3-9ED2-4C19-90F8-F22BFA543245', // Cirugia Bucal
          '8010AC8C-CA04-4BC8-841E-F78F3EA14DE9' // Odontologia Protesica
        ]
        const especialidadesNoWeb = [
          'D1058E49-FFBC-ED11-910B-000C2914A2E9', // Examen Médico Integral (EMI)
          'AF930348-83F5-ED11-9122-000C29FCF0DA', // Atención Biopsias
          'FC0A2F77-14CC-4B9C-9F4B-1BBB79663A82', // Medicina Familiar
          '646948CF-1D49-406C-B4C5-BE00119AD0FF' //Salud Ocupacional
          // '090CC85F-B1D2-46BD-A895-FA6DB67E4535' // Psicologia Clinica - Psiquiatria
        ]
        // elimina las especialidades Odontologicas de Medico Cirujano y las agrega a Odontología
        data['Médico Cirujano'] = data['Médico Cirujano'].filter(e => {
          if (especialidadesOdontologicas.includes(e.co_especial)) addOdontologia.push(e)
          return !(
            especialidadesOdontologicas.includes(e.co_especial)
            || especialidadesNoWeb.includes(e.co_especial)
          )
        })
        // Agrega las especialidades Odontologicas a Odontología
        data['Odontología'] = data['Odontología'].concat(addOdontologia)


        setLoading(false)
        setEspecialidades(data)
      })
      .catch(err => {
        const msg = 'disculpe, no se encontró las especialidades, por favor intente mas tarde o comuníquese con nuestras oficinas.'
        console.error(`${err}: ${msg}`)
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: msg })
      })
  }

  const fetchDoctors = (clinicaID, especialidadID, week) => {
    setLoading(true)
    resetDoctors()
    const [fi, ff] = weeks.data[week]
    const fechaInicial = `${fi[2]}-${fi[1]}-${fi[0]}`
    const fechaFinal = `${ff[2]}-${ff[1]}-${ff[0]}`
    const url = `api/cita/medicos?sede=${clinicaID}&especialidad=${especialidadID}&fecha_inicial=${fechaInicial}&fecha_final=${fechaFinal}`
    http.get(url)
      .then(({ status, data }) => {
        if (status !== 200) throw new Error(status)
        // console.log({status, data})
        let drs = data.reduce((acc, curr) => {
          return (Object.keys(curr.horarios).length) ? [...acc, curr] : acc
        }, [])

        if(especialidadID === 'A9041EE1-EBD0-47AD-A7C9-97FDDC26F168'){
          const noOdontologos = [ // "A9041EE1-EBD0-47AD-A7C9-97FDDC26F168"
            '6BBB2D17-3C32-49A1-BEF8-AE83EF8BE4BA',  //Arelys Gonzales
            'BDDE72B5-BCE5-43EE-A263-A94E9692F907' // Karina Gomez
          ]
          drs = drs.filter(e => { return !noOdontologos.includes(e.co_prof_int) })
        }

        setDoctores({week, data: drs})
        // setDoctores({week: week ?'next' :'current' ,data: drs})
      })
      .catch(err => {
        const msg = `disculpe, no se encontraron los doctores relacionados con ${citaCreating.service.name}, por favor intente mas tarde o comuníquese con nuestras oficinas.`
        console.error(`${err}: ${msg}`)
        setAlert({ status: true, severity: 'error', msg: msg })
      })
      .finally(() => setLoading(false))
  }

  const changeStep = index => () => setSteps({ ...steps, selected: index })

  const handleSede = coClinica => () => {
    const newCita = JSON.parse(JSON.stringify(citaCreating))
    const sede = sedes.find(s => s.co_clinica === coClinica)
    newCita.sede = sede
    fetchEspecialidades(sede.co_clinica)
    setCitaCreating(newCita)
    setSteps({ ...steps, selected: 1 })
  }

  const goServiceType = () => {
    const newCita = JSON.parse(JSON.stringify(citaCreating))
    newCita.service.type = ''
    setCitaCreating(newCita)
  }

  const handleServiceType = service => () => {
    const newCita = JSON.parse(JSON.stringify(citaCreating))
    newCita.service.type = service
    setCitaCreating(newCita)
  }

  const handleService = service => () => {
    const newCita = JSON.parse(JSON.stringify(citaCreating))
    newCita.service.co_especial = service.co_especial
    newCita.service.name = service.nombre
    fetchDoctors(citaCreating.sede.co_clinica, service.co_especial, 0)
    setCitaCreating(newCita)
    setWeeks({...weeks, selected:1})
    setSteps({ ...steps, selected: 2 })
  }
  /* 
  const handleServiceCurrentWeek = () => {
    const newCita = JSON.parse(JSON.stringify(citaCreating))
    fetchDoctors(citaCreating.sede.co_clinica, newCita.service.co_especial)
  } */

  const handleWeekDoctors = ({name, value}) => {
    console.log({value})
    const newCita = JSON.parse(JSON.stringify(citaCreating))
    setWeeks(state => ({...state, selected:value}))
    fetchDoctors(citaCreating.sede.co_clinica, newCita.service.co_especial, value-1)
  }

  const handleDatetime = (profesional, date, time, fecha_cita) => () => {
    const cod = profesional.co_prof_int + date + time
    const [, fecha_cita] = date.split(' ')
    setCitaCreating(state => {
      const newCita = JSON.parse(JSON.stringify(state))
      newCita.fecha = {
        // payment: { type: 0 },
        cod,
        date,
        fecha_cita,
        co_horario: time.co_horario,
        time: time.hh_cita,
        profesional,
        medico: `${profesional.nb_profes} ${profesional.nb_ape_prof}`
      }
      return newCita
    })
    setSteps({ ...steps, selected: 3 })
  }

  const handleType = (type) => () => {
    setCitaCreating(state => {
      // console.log('state handleTYpe', state)
      const newCita = JSON.parse(JSON.stringify(state))
      newCita.type = type
      // console.log('handleType', state.sede.co_clinica)
      newCita.cita = {
        // ...newCita.cita,
        payment: { ...newCita.cita.payment },
        profesional: state.fecha.profesional,
        // códigos para DB
        co_cita_web: state.cita.co_cita_web,
        fecha_cita: state.fecha.fecha_cita,
        co_clinica: state.sede.co_clinica,
        co_especialidad: state.service.co_especial,
        co_horario: state.fecha.co_horario,
        co_prof_med: state.fecha.profesional.co_prof_int,
        co_tipo_con: type.co_tipo_con,
        // para mosrar en detalle
        medico: state.fecha.medico,
        medico_perfil: state.fecha.profesional.perfil,
        medico_sexo: state.fecha.profesional.in_sexo,
        tipo_consulta: type.tx_status,
        especialidad: state.service.name,
        clinica: state.sede.nb_clinica,
        direccion: state.sede.tx_direccion,
        date: state.fecha.date,
        time: state.fecha.time
      }
      // console.log('newCita.cita handleTYpe', newCita.cita)

      handleSubmit(newCita.cita)
      return newCita
    })
  }

  const changeOptionPaymet = option => () => {
    setAlertPayment({ status: false, severity: '', msg: '' })
    const newCita = JSON.parse(JSON.stringify(citaCreating))
    newCita.cita.payment.type = option

    setCitaCreating(newCita)
  }
  // C2P
  const handleChangePaymentC2P = ({name, value}) => {
    const newCita = JSON.parse(JSON.stringify(citaCreating))
    newCita.cita.payment.c2p[name] = value

    setCitaCreating(newCita)
  }

  const handlePaymentC2P = () => {
    let {cedulaType, cedula, celular, banco, otp } = citaCreating.cita.payment.c2p
    const amountProd = citaCreating.cita?.monto || 0
    const amountDev = 0.1
    const amount = config.isDev ?amountDev :amountProd
    // console.log({cedulaType, cedula, celular, banco, otp })

    if(!cedula || !celular || !banco || !otp) return setAlertPayment({ status: true, severity: 'error', msg: 'Todos los campos son requeridos' })
    if(!(celular.startsWith('0') && celular.length == 11)) return setAlertPayment({ status: true, severity: 'error', 
      msg: 'El número celular no es valido ' })
    if(amount === 0) return setAlertPayment({ status: true, severity: 'error', msg: 'La cita no tiene monto a pagar.' })
    
    setLoadingPayment(true)
    celular = '58'.concat(celular.slice(1))
    const cita = citaCreating.cita
    const dataEmail = getDataForEmail(cita)
    const ci = `${cedulaType}${cedula}`

    const date = new Date()
    const year = date.getFullYear().toString().slice(-2)
    let month = date.getMonth() + 1
    if(month < 10) month = `0${month}` 
    let coClinica = cita['co_clinica']
    if(coClinica < 10) coClinica = `0${coClinica}` 
    const citaID = `C${id}`
    let txFactBen = `W${coClinica}${year}${month}-${citaID}`
    if(config.isDev) txFactBen = 'D' + txFactBen
  
    const data = new FormData()
    data.append('amount', amount)
    data.append('destination_bank_id', banco)
    data.append('destination_mobile_number', celular)
    data.append('destination_id', ci)
    data.append('invoice_number', citaID)
    data.append('tx_fact_ben', txFactBen)
    data.append('otp', otp)

    // Datos para factura
    data.append('co_banco', searchBank(banco).idIpp)
    data.append('monto', amount)
    http.post(`api/payment/mercantil/payC2P/${id}${dataEmail}`, data)
      .then(({ status, data }) => {
        if (status !== 201){
          if(data.error) return setAlertPayment({ status: true, severity: 'error', msg: data.error }) 
          throw new Error(status)
        } 
        
        setCitaCreating(citaCreating => ({
          ...citaCreating,
          cita: {
            ...citaCreating.cita,
            status: 'Confirmada'
          },
          status: 3
        }))
      })
      .catch(err => {
        const msg = 'disculpe, No se logró pagar la cita, por favor intente mas tarde o comuníquese con nuestras oficinas si el error persiste.'
        console.error(`${err}: ${msg}`)
        setAlertPayment({ status: true, severity: 'error', msg: msg })
      })
      .finally(() => setLoadingPayment(false))
  }

  const handlePayment = () => {
    const payment = citaCreating.cita.payment

    if (payment.file) {
      setLoadingPayment(true)
      const cita = citaCreating.cita
      const dataEmail = getDataForEmail(cita)

      const data = new FormData()
      data.append('comprobante', payment.file)
      http.patch(`api/cita/${cita.co_cita_web}/comprobante${dataEmail}`, data)
        .then(({ status }) => {
          if (status !== 200) throw new Error(status)
          // const newCita = JSON.parse(JSON.stringify(citaCreating))
          /* const newCita = {
          ...citaCreating,
          status: 2
        } */
          setCitaCreating(citaCreating => ({
            ...citaCreating,
            cita: {
              ...citaCreating.cita,
              status: 'Paga'
            },
            status: 2
          }))
          // setAlertPayment({ status: true, severity: 'error', msg: 'Debe Adjuntar su comprobante de pago para finalizar la solicitud' })
        })
        .catch(err => {
          const msg = 'disculpe, No se logró enviar su pago, por favor intente mas tarde o comuníquese con nuestras oficinas.'
          console.error(`${err}: ${msg}`)
          setAlertPayment({ status: true, severity: 'error', msg: msg })
        })
        .finally(() => setLoadingPayment(false))
    } else {
      setAlertPayment({ status: true, severity: 'error', msg: 'Por favor debe subir una foto de su pago' })
    }

    /* if (payment.type === 1) {
      console.log(payment.file)
      if (!payment.file) return setInfo({ status: true, severity: 'error', msg: 'Por favor debe subir una imagen para comporobar el pago' })
      const newCita = JSON.parse(JSON.stringify(citaCreating))
      newCita.status = 2
      setCitaCreating(newCita)
    } else if (payment.type === 2) {
      const inputs = formRef.current.querySelectorAll('input')

      let valid = true
      inputs.forEach(({ name }) => {
        console.log(name, citaCreating.cita.payment[name])
        if (!citaCreating.cita.payment[name]) valid = false
      })
      if (!citaCreating.cita.payment.destino) valid = false
      if (!valid) return setInfo({ status: true, severity: 'error', msg: 'Por favor debe llenar todo el formulario con los datos del pago' })

      setInfo({ status: true, severity: 'success', msg: 'Su solicitud ha sido enviada con exito, por favor espere y esté atento del estado de la misma, le estaremos notificando en los proximos minutos' })
      const newCita = JSON.parse(JSON.stringify(citaCreating))
      newCita.status = 2
      setCitaCreating(newCita)
    } else {
      setInfo({ status: true, severity: 'error', msg: 'Por favor debe seleccionar un método de pago' })
    } */
  }
  // TDD Mercantil
  const handleChangePaymentTDDMercantil = ({name, value}) => {
    const newCita = JSON.parse(JSON.stringify(citaCreating))
    newCita.cita.payment.tddMercantil[name] = value

    setCitaCreating(newCita)
  }

  const handleChangePaymentTDDMercantilgetAuth = ({name, value}) => {
    const newCita = JSON.parse(JSON.stringify(citaCreating))
    newCita.cita.payment.tddMercantil[name] = value

    setCitaCreating(newCita)
    if(name === 'cardNumber') return getAuth({cardNumber:value, cedula:null}) 
    if(name === 'cedula') return getAuth({cardNumber:null, cedula:value}) 
  }

  const getAuth = (params) => {
    let { cardNumber, cedula, cedulaType } = citaCreating.cita.payment.tddMercantil
    cardNumber = params['cardNumber'] === null ?cardNumber :params['cardNumber']
    cedula = params['cedula'] === null ?cedula :params['cedula']
    
    if(!cardNumber) return setAlertPayment({ status: true, severity: 'info', 
      msg: 'Debe haber ingresado el número de tarjeta para solicitar su clave de pago.' })
    
    if(!cedula) return setAlertPayment({ status: true, severity: 'info', 
      msg: 'Debe haber ingresado el número de cédula para solicitar su clave de pago.' })
    
    setLoadingPayment(true)
    const data = new FormData()
    data.append('card_number', cardNumber) 
    data.append('customer_id', cedulaType+cedula)
    http.post(`api/payment/mercantil/getAuth`, data)
      .then(({ status, data }) => {
        if (status !== 200){
          if(data.error) return setAlertPayment({ status: true, severity: 'error', msg: data.error }) 
          throw new Error(status)
        } 

        const dataDecipher = data.data['authentication_info'].decipher 
        const {twofactor_field_type, twofactor_label, twofactor_lenght, twofactor_type} = dataDecipher
        console.log({twofactor_field_type, twofactor_label, twofactor_lenght, twofactor_type})
        setAlertPayment({ status: true, severity: 'success', msg: twofactor_label })
      })
      .catch(err => {
        const msg = 'disculpe, No se logró solicitar la clave, por favor intente mas tarde o comuníquese con nuestras oficinas si el error persiste.'
        console.error(`${err}: ${msg}`)
        setAlertPayment({ status: true, severity: 'error', msg: msg })
      })
      .finally(() => setLoadingPayment(false))
  }

  const handlePaymentC2PMercantilKey = () => {
    let {cedulaType, cedula, celular } = citaCreating.cita.payment.c2p
    if(!cedula || !celular) return setAlertPayment({ status: true, severity: 'error', 
      msg: 'Debe haber ingresado el número de cédula y de celular para solicitar su clave de pago.' })

    setLoadingPayment(true)
    const data = new FormData()
    celular = '58'.concat(celular.slice(1))
    data.append('destination_id', `${cedulaType}${cedula}`) 
    data.append('destination_mobile_number', celular)

    http.post(`api/payment/mercantil/keyRequest`, data)
      .then(({ status, data }) => {
        if (status !== 200){
          if(data.error) return setAlertPayment({ status: true, severity: 'error', msg: data.error }) 
          throw new Error(status)
        } 
        setAlertPayment({ status: true, severity: 'success', msg: `Su <b>clave fue enviada</b> con exito al <b>${celular}</b>` })
      })
      .catch(err => {
        const msg = 'disculpe, No se logró pagar la cita, por favor intente mas tarde o comuníquese con nuestras oficinas si el error persiste.'
        console.error(`${err}: ${msg}`)
        setAlertPayment({ status: true, severity: 'error', msg: msg })
      })
      .finally(() => setLoadingPayment(false))
  }

  const handlePaymentTDDMercantil = () => {
    console.log('tddmercantil', citaCreating.cita.payment.tddMercantil)
    const {cedulaType, cedula, tddType, cvv, cardNumber, expAno, expMes, key } = citaCreating.cita.payment.tddMercantil
    console.log({cedulaType, cedula, tddType, cvv, cardNumber, expAno, expMes, key })
    const amountProd = citaCreating.cita?.monto || 0
    const amountDev = 0.1
    const amount = config.ENV === "development" ?amountDev :amountProd

    if(!cedula || !tddType || !cvv || !cardNumber || !expAno || !expMes || !key) return setAlertPayment({ status: true, severity: 'error', msg: 'Todos los campos son requeridos' })

    if(amount === 0) return setAlertPayment({ status: true, severity: 'error', msg: 'La cita no tiene monto a pagar.' })
    
    setLoadingPayment(true)
    const cita = citaCreating.cita
    const dataEmail = getDataForEmail(cita)
    
    const date = new Date()
    const year = date.getFullYear().toString().slice(-2)
    let month = date.getMonth() + 1
    if(month < 10) month = `0${month}` 
    let coClinica = cita['co_clinica']
    if(coClinica < 10) coClinica = `0${coClinica}` 
    const citaID = `C${id}`
    const txFactBen = `W${coClinica}${year}${month}-${citaID}`
    
    const data = new FormData()
    data.append('amount', amount)
    data.append('card_number', cardNumber)
    data.append('customer_id',  `${cedulaType}${cedula}`)
    data.append('expiration_date', `${expAno}/${expMes}`)
    data.append('cvv', cvv)
    data.append('account_type', tddType)
    data.append('invoice_number', citaID)
    data.append('tx_fact_ben', txFactBen)
    data.append('key', key)
    data.append('keyType', 'otp')
    // data.append('otp', key)
    // data.append('keyType', 'clavetelefonica')

    // datos para factura
    data.append('co_banco', searchBank(105).idIpp) // 105 = Mercantil
    data.append('monto', amount)
    
    http.post(`api/payment/mercantil/payTDD/${id}${dataEmail}`, data)
      .then(({ status, data }) => {
        if (status !== 201){
          if(data.error) return setAlertPayment({ status: true, severity: 'error', msg: data.error }) 
          throw new Error(status)
        } 
        
        setCitaCreating(citaCreating => ({
          ...citaCreating,
          cita: {
            ...citaCreating.cita,
            status: 'Confirmada'
          },
          status: 3
        }))
      })
      .catch(err => {
        const msg = 'disculpe, No se logró pagar la cita, por favor intente mas tarde o comuníquese con nuestras oficinas si el error persiste.'
        console.error(`${err}: ${msg}`)
        setAlertPayment({ status: true, severity: 'error', msg: msg })
      })
      .finally(() => setLoadingPayment(false))
  }

  const renderImage = ({ target }) => {
    const file = target.files[0]
    const newCita = JSON.parse(JSON.stringify(citaCreating))
    newCita.cita.payment.file = file

    if (file) {
      const fileType = file.type
      const allowedExtensions = /(.jpg|.jpeg|.png|.pdf)$/i
      if (!allowedExtensions.exec(fileType)) {
        alert('Solo puede cargar archivos de extensión .jpeg  .jpg  .png .pdf')
        file.value = ''
        return
      }

      setCitaCreating(newCita)
    }
  }

  const OrientationImage = ({ target }) => {
    const { naturalHeight, naturalWidth } = target
    if (naturalWidth > naturalHeight) {
      target.style.height = '200px'
      target.style.width = '280px'
    }
  }

  const getDataForEmail = cita => {
    const body = {
      co_solicitud: id,
      name: user.nb_benefi ? user.nb_benefi.trim() : '',
      lastname: user.nb_apellid ? user.nb_apellid.trim() : '',
      email: user.tx_correo ? user.tx_correo.trim() : '',
      concepto: cita?.especialidad
    }

    const query = Object.entries(body).reduce((acc, [key, value], index) => {
      return `${acc}${index ? '&' : ''}${key}=${value}`
    }, '?')

    return query
  }


  /* const getDataForEmail = cita => {
    const name = user.nb_benefi ? user.nb_benefi.trim() : '',
    const lastname = user.nb_apellid ? user.nb_apellid.trim() : '',
    const body = {
      co_solicitud: id,
      
      coCita, 
      coCitaWeb, 
      paciente 
      especialista, 
      especialidad, 
      fecha, 
      hora, 
      lugar,

      concepto: cita?.especialidad
    }

    const query = Object.entries(body).reduce((acc, [key, value], index) => {
      return `${acc}${index ? '&' : ''}${key}=${value}`
    }, '?')

    return query
  } */
  // consulta el precio de la cita seleccionada
  const handleSubmit = async (newCita) => {
    const cita = newCita || citaCreating.cita
    setLoading(true)
    const [day, month, year] = cita.fecha_cita.split('/')
    const body = new FormData()

    body.append('fecha_cita', `${month}/${day}/${year}`)
    // body.append('fecha_cita', cita.fecha_cita)
    body.append('co_horario', cita.co_horario)
    body.append('co_prof_med', cita.co_prof_med)
    body.append('co_clinica', cita.co_clinica)
    body.append('co_especialidad', cita.co_especialidad)
    body.append('co_tipo_con', cita.co_tipo_con)

    // console.log('cita', cita, `api/cita/${cita.co_cita_web}`)
    // console.log('cita', cita.co_cita_web)
    console.log({cita})
    const dataEmail = getDataForEmail(cita)
    const url = `api/cita/${cita.co_cita_web}${dataEmail}`
    try {
      let response = await http.patch(url, body)
      if (response.status === 405) throw new Error('La Cita solicitada ya no está disponible, por favor refresque los horarios y seleccione otro de su preferencia.')
      // console.log('handleSubmit', { response })
      if (!response.data.cita) response = await http.patch(url, body)
      if (response.status === 405) throw new Error('La Cita solicitada ya no está disponible, por favor refresque los horarios y seleccione otro de su preferencia.')
      // if(!response.data.cita) response = await http.patch(url, body)

      const { status, data } = response
      if (status !== 200 || !data.cita || !data.cita.length) throw new Error()
      // console.log({ status, data })
      setCitaCreating(newCita => ({
        ...newCita,
        cita: {
          ...newCita.cita,
          monto: data.cita[0].monto,
          status: data.cita[0].status,
          fecha_actual: data.cita[0].fecha_actual,
          fecha_creado_web: data.cita[0].fecha_creado_web,
          fecha_editado_web: data.cita[0].fecha_editado_web
        },
        status: 1
      }))

      const { diff, rest, isValid } = getMilliseconds(data.cita[0].fecha_editado_web, data.cita[0].fecha_actual)
      setMiliseconds({ diff, rest, isValid })
      setSteps({ ...steps, selected: 4 })
      setAlert({ status: true, severity: 'success', msg: 'Los Datos de la Cita han sido guardados Exitosamente.' })
      setLoading(false)
    } catch (err) {
      const msg = err || 'disculpe, no se guardaron los datos de su solicitud, por favor intente mas tarde o comuníquese con nuestras oficinas.'
      console.error(msg)
      setAlert({ status: true, severity: 'error', msg })
      setLoading(false)
    }
  }

  const showFile = ({ file, direccion, id }) => {
    const src = file ? URL.createObjectURL(file) : 'https://ceygate.com/content/themes/arkahost/assets/images/default.jpg'
    const name = file ? file.type : direccion || ''
    // console.log('showFile', { src, name })
    const isPDF = /(.pdf)$/i

    if (isPDF.exec(name)) {
      return <PhotoFrameDocumentPDF file={src} />
    }

    return (
      <PreviewImg
        id={id}
        src={src}
        onLoad={OrientationImage}
      />
    )
  }

  const citaCreatingClean = () => setCitaCreating(citaCreatingInitial)

  // Steps
  const renderSedes = () => (<>
    <h1>Seleccione una Sede:</h1>
    <Sedes>
      {sedes.map((sede) => (
        <Sede
          key={sede.co_clinica}
          active={citaCreating.sede.co_clinica === sede.co_clinica ? 1 : 0}
          onClick={handleSede(sede.co_clinica)}
        >
          <h3>{sede.nb_clinica}</h3>
          <div>{sede.tx_direccion}</div>
        </Sede>
      ))}
    </Sedes>
  </>)

  const renderEspecialidades = () => (!citaCreating.sede.co_clinica
    ? <h1>Debe seleccionar primero la sede que requiere</h1>
    : (
      <>
        <h1>Seleccione el servicio que desea:</h1>
        {citaCreating.service.type !== '' ? <Volver onClick={goServiceType}>{'<- Regresar'}</Volver> : null}
        <Specialties>
          {!Object.keys(especialidades).length
            ? null
            : citaCreating.service.type !== ''
              ? especialidades[citaCreating.service.type].map(especialidad => (
                  <Specialtie
                    key={especialidad.co_especial}
                    active={citaCreating.service?.co_especial === especialidad.co_especial ? 1 : 0}
                    onClick={handleService(especialidad)}
                  >
                    {especialidad.nombre.trim()}
                  </Specialtie>
                ))
              : Object.keys(especialidades).map(especialidadType => (
                <Specialtie
                  key={especialidadType}
                  active={citaCreating.service?.type === especialidadType ? 1 : 0}
                  onClick={handleServiceType(especialidadType)}
                >
                  {especialidadType === 'null'
                    ? 'Otros'
                    : (especialidadType === 'Médico Cirujano')
                      ? 'Especialidades Médicas'
                      : especialidadType}
                </Specialtie>
              ))}
        </Specialties>
      </>)
  )

  const renderDoctores = () => (!citaCreating.service.name
    ? <h1>Debe seleccionar primero la especialidad que requiere</h1>
    : (
      <>
        <Title>{citaCreating.service.name.toUpperCase()}</Title>
        {/* <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: '10px' }}>
          <Button
            label='Semana actual'
            color={doctores.week === 'current' ?'primary' :'secondary'}
            onClick={handleServiceCurrentWeek}
          />
          <Button
            label='semana entrante'
            color={doctores.week === 'next' ?'primary' :'secondary'}
            onClick={handleServiceNextWeek}
          />
        </div> */}

        <DivWeeks>
          <h4>Seleccione una semana: </h4>
          <div>
            <InputSelect
              id='week'
              name='week'
              value={weeks.selected}
              options={weeks.options}
              handleChange={handleWeekDoctors}
            />
          </div>
        </DivWeeks>
        {!doctores.data.length
          ? <h1>No hay horarios disponibles, intente ver los horarios de la semana entrante</h1>
          : (
            <>
              <h1>Seleccione el horario y doctor de su preferencia:</h1>
              <ContainerProfesionals>
                <Pagination />

                <Row>
                  {doctores.data.slice(idx.start, idx.end).map(doctor => (
                    <CitaCard
                      key={doctor.co_prof_int}
                      cita={{ ...citaCreating.cita, profesional: doctor }}
                      fecha={citaCreating.fecha}
                      // horarios={horarios}
                      handleDatetime={handleDatetime}
                    />
                  ))}
                </Row>

                <Pagination />
              </ContainerProfesionals>
            </>)}
      </>)
  )

  const renderTiposCitas = () => (!citaCreating.fecha
    ? <h1>Debe seleccionar primero el horario y doctor de su preferencia:</h1>
    : (
      <>
        <h1>Seleccione un Tipo:</h1>
        <Sedes>
          {types.map(type => {
            // Entrega de resultados se quitó de la lista de selección 01-05-23
            return type.co_tipo_con !== 3 && (
            <Sede
              key={type.co_tipo_con}
              active={citaCreating.type.co_tipo_con === type.co_tipo_con ? 1 : 0}
              onClick={handleType(type)}
            >
              <h3>{type.tx_status}</h3>
              <div>{type.descripcion}</div>
            </Sede>)
          })}
        </Sedes>
      </>)
  )

  const renderResumen = () => (
    // !citaCreating.type
    // ? <h1>Debe completar los 4 pasos anteriores</h1>
    // : (
      <Resume>
        <h1>Información de la Cita:</h1>
        <CitaCard cita={citaCreating.cita} />
        
        <Button
          label='Estoy de acuerdo, ir al pago'
          color='primary'
          // disabled={!citaCreating?.cita?.monto}
          onClick={() => setSteps({ ...steps, selected: 5 })}
        />
      </Resume>
      // )
  )

  const renderPaymentComprobante = () => (<div>
    <Tooltip title='imagen de instrumento de pago' aria-label='imagen de instrumento de pago'>
      <LabelFile htmlFor='payment'>
        {citaCreating.cita.payment.file
          ? (
              showFile({
                file: citaCreating.cita.payment.file,
                id: 'comprobante_payment'
              }))
          : (
            <PreviewImg
              id='addimg_payment'
              src='https://ceygate.com/content/themes/arkahost/assets/images/default.jpg'
              onLoad={OrientationImage}
            />)}
        {/* <PreviewImg
            id='img-payment'
            src={citaCreating.cita.payment.file ? URL.createObjectURL(citaCreating.cita.payment.file) : 'https://ceygate.com/content/themes/arkahost/assets/images/default.jpg'}
            onLoad={OrientationImage}
          /> */}
      </LabelFile>
    </Tooltip>

    <File
      type='file'
      id='payment'
      name='payment'
      accept='.jpg,.jpeg,.png,.pdf'
      onChange={renderImage}
    />
  </div>
  )

  const renderPaymentC2P = () => (
    <Payment>
      <Grid container spacing={1}>
        <Grid container spacing={1}>
          <p>
          El Pago C2P (de comercio a persona) es una transacción que inicia el comercio y le solicita
          al cliente la autorización para cobrar mediante una clave de pago. Coloque sus datos de pago
          Móvil y obtenga la Clave de Pago dependiendo de cómo la genera su banco. 
          </p>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={3}>
            <InputSelect
              required  
              id='cedulaType'
              name='cedulaType'
              defaultValue='V'
              options={cedulaTypes}
              handleChange={handleChangePaymentC2P}
            />
          </Grid>
          <Grid item xs={9}>
            <InputText
              required  
              id='cedula'
              label='Número de cédula (sin puntos)'
              name='cedula'
              handleBlur={handleChangePaymentC2P}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputText
              required  
              id='celular'
              label='Número celular. Ej: 04241234567'
              name='celular'
              handleBlur={handleChangePaymentC2P}
            />
          </Grid>

          <Grid item xs={6}>
            <InputSelect
              required  
              id='banco'
              label='Seleccione el banco'
              name='banco'
              options={banksId}
              handleChange={handleChangePaymentC2P}
            />
          </Grid>
          <Grid item xs={6}>
            <InputText
              required  
              id='otp'
              label='Clave de Pago'
              name='otp'
              handleBlur={handleChangePaymentC2P}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <p>
          {searchBank(citaCreating.cita.payment.c2p.banco)?.otpGuide || 'No se ha seleccionado el banco'}
          </p>
        </Grid>
      </Grid>
    
      {loadingPayment
        ? <Loader />
        : (<>

          {citaCreating.cita.payment.c2p.banco === '105'
          ?<Button  
            label='Solicitar clave de pago'
            color='primary'
            disabled={false}
            onClick={handlePaymentC2PMercantilKey}
          />
          :null 
          }
          
          <Button
            label='Pagar Cita'
            color='success'
            disabled={!citaCreating?.cita?.monto && false}
            onClick={handlePaymentC2P}
          />
          </>)}
    </Payment>
  )

  const renderPaymentTDDMercantil = () => (
    <Payment>
      <Grid container spacing={1}>
        <Grid container spacing={1}>
            
          <Grid item xs={3}>
            <InputSelect
              required  
              id='cedulaType'
              name='cedulaType'
              defaultValue='V'
              options={cedulaTypes}
              handleChange={handleChangePaymentTDDMercantil}
            />
          </Grid>
          <Grid item xs={9}>
            <InputText
              required  
              id='cedula'
              label='Número de cédula (sin puntos)'
              name='cedula'
              handleBlur={citaCreating.cita.payment.tddMercantil.cardNumber
                ?handleChangePaymentTDDMercantilgetAuth
                :handleChangePaymentTDDMercantil 
              }
            />
          </Grid>

          <Grid item xs={4}>
            <InputSelect
              required  
              id='tddType'
              name='tddType'
              label='Tipo de Cuenta'
              options={tddTypes}
              handleChange={handleChangePaymentTDDMercantil}
            />
          </Grid>

          <Grid item xs={4}>
            
            <InputSelect
              id='expAno'
              label='Año Exp'
              name='expAno'
              options={getExpAno()}
              handleChange={handleChangePaymentTDDMercantil}
            />
          </Grid>

          <Grid item xs={4}>
            <InputSelect
              required  
              id='expMes'
              label='Mes Exp'
              name='expMes'
              options={expMes}
              handleChange={handleChangePaymentTDDMercantil}
            />
          </Grid>

          {/* <Grid item xs={7}>
            <InputText
              required  
              id='celular'
              label='Número celular. Ej: 584241234567'
              name='celular'
              handleBlur={handleChangePaymentTDDMercantil}
            />
          </Grid> */}
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={2}>
            <InputText
              required  
              id='cvv'
              label='cvv'
              name='cvv'
              handleBlur={handleChangePaymentTDDMercantil}
            />
          </Grid>
          <Grid item xs={10}>
            <InputText
              required  
              id='cardNumber'
              label='Número de tarjeta'
              name='cardNumber'
              handleBlur={handleChangePaymentTDDMercantilgetAuth}
            />
          </Grid>
        
          
          <Grid item xs={12}>
            <InputText
              required  
              id='key'
              label='Clave de Pago'
              name='key'
              handleBlur={handleChangePaymentTDDMercantil}
            />
          </Grid>
        </Grid>
      </Grid>
    
      {loadingPayment
        ? <Loader />
        : (<>
          {/* <Button
            label='Solicitar clave de pago'
            color='primary'
            disabled={true}
            onClick={handlePaymentTDDMercantilKey}
            /> */}

          <Button
            label='Pagar Cita'
            color='success'
            disabled={!citaCreating?.cita?.monto && false}
            onClick={handlePaymentTDDMercantil}
            />
          </>)}
    </Payment>
  )

  const renderPayment = () => (MAINTENANCE
    ?<h1>El modulo se encuentra en mantenimiento, no puede emitir pagos por los momentos</h1>
    :!citaCreating?.cita?.monto && false
    ? <h1>La cita no tiene monto a pagar</h1>
    :(
      <Payment>
        <h1>Monto a pagar: {citaCreating?.cita?.monto || 0}Bs</h1>
        
        <PaymentMethods>
          <PaymentOptions>
            <PaymentOption
              onClick={changeOptionPaymet(1)}
              active={citaCreating.cita.payment.type === 1 ? 1 : 0}
            > Pago Móvil C2P
            </PaymentOption>
            
            <PaymentOption
              onClick={changeOptionPaymet(2)}
              active={citaCreating.cita.payment.type === 2 ? 1 : 0}
            > Debito Mercantil
            </PaymentOption>
          </PaymentOptions>

          <section>
            {citaCreating.cita.payment.type === 1
              ?renderPaymentC2P()
              :citaCreating.cita.payment.type === 2
                ?renderPaymentTDDMercantil()
                :<h3>No hay opción seleccionada</h3>
            }
          </section>
        </PaymentMethods>

        <MyAlertPayment />
      </Payment>)
  )

  // Renders Especiales
  const renderAvalibleTime = () => (
    <Container>
      <ContainerIntro documents={documents}>
        <h2 style={{color:'#d93530'}}> Horario NO Permitido</h2>
        <p>
          Disculpe, en estos momentos se encuentra fuera del horario permitido para solicitar una cita
          atraves de la app, por favor intente luego dentro de los horarios establecidos:
        </p>

        <p><b>De Lunes a Viernes de 6:00a.m a 03:00p.m</b> </p>
      </ContainerIntro>
    </Container>
  )

  const renderInauthorized = () => (
    <Container>
      <ContainerIntro documents={documents}>
        <h2 style={{color:'#d93530'}}> Acceso NO Permitido</h2>
        <p>
          Disculpe, la cita a la que intenta acceder no es suya, por favor dirijasé a su listado de Citas.
        </p>
      </ContainerIntro>
    </Container>
  )

  const renderErrorGetCita = () => (
    <Container>
      <ContainerIntro documents={documents}>
        <h2 style={{color:'#d93530'}}> Error en la consulta </h2>
        <p>Disculpe, no se pudo obtener Información de la cita, por favor intente mas tarde</p>
        <p>Si el problema persiste comúniquese con nuestras oficinas</p>
      </ContainerIntro>
    </Container>
  )

  const showConsole = show => {
    if(!show) return
    console.group('CitaRequest')
    console.log({miliseconds, 'minutes': miliseconds/1000/60})
    console.log({available, citaCreating})
    console.groupEnd('CitaRequest')
  }
  showConsole(false)
  
  if(!available) return renderAvalibleTime()
  if(!authorized) return renderInauthorized()
  if(errorRequest) return renderErrorGetCita()

  return (
    <Container>
      {(!(citaCreating.cita.fecha_cita && citaCreating.cita.status === 'En creación'))
        ? null
        : (
          <ContainerTimer>
            {miliseconds.isValid && miliseconds.rest
              ? <Timer id={id} miliseconds={miliseconds.rest} citaCreatingClean={citaCreatingClean} />
              : <h4>Ya pasaron mas de ${minutesOfWaiting} minutos sin realizar el pago, espere un momento, se le creará una nueva solicitud</h4>}
          </ContainerTimer>)}

      {citaCreating.status === 3
        ? (
          <>
            <FinishTitle>La cita ya ha sido Creada</FinishTitle>
            <AlertSucces />

            <LinkDetail>
              <Link to={`/portal/citas/${citaCreating.cita.co_cita_web}`}>Ver detalle </Link>
            </LinkDetail>
          </>)
        // Cita en Creación
        // :(citaCreating.cita?.co_cita_web === 0) ? <Alert />
        : loading
          ? <Loader />
          : (
            <>
              <ContainerIntro documents={documents}>
                {requestIntro()}

                <Button
                  label='Continuar'
                  color='success'
                  onClick={() => setDocuments(true)}
                />
              </ContainerIntro>

              {steps &&
                <ContainerDocuments documents={documents}>
                  <Progress
                    steps={steps}
                    index={steps.selected}
                    changeStep={changeStep}
                  />

                  <DocumentName>
                    {steps.data[steps.selected]?.title}
                    {steps.data[steps.selected]?.required ? <Required /> : null}
                  </DocumentName>
                  
                  {/* {steps.data[steps.selected]?.description} */}

                  <Alert />

                  {(loading || loadingType)
                    ? <Loader />
                    :steps.selected === 0
                      ?renderSedes()
                      :steps.selected === 1  
                        ?renderEspecialidades()
                        :steps.selected === 2
                          ?renderDoctores()
                          :steps.selected === 3
                            ?renderTiposCitas()
                            :steps.selected === 4
                              ?renderResumen()
                              :steps.selected === 5
                                ?renderPayment()
                                :<></>
                  }
                </ContainerDocuments>}
            </>)}
    </Container>
  )
}

export default CitaRequest
