import styled from 'styled-components'

export const Container = styled.div`
  margin: 1rem 0;
  width: '100%';
  `

export const ContainerBtn = styled.div`
  width: '100%';
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Sedes = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
`

export const Sede = styled.div`  
    width: 200px;
    padding: 10px 5px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.gray};
    text-align: center;
    vertical-align: middle;
    cursor: pointer; 
    background-color: ${({ theme, active }) => active === 1 ? theme.color.bluePrimary : theme.color.white};
    color: ${({ theme, active }) => active === 1 && theme.color.white};

    &:hover{
        background-color: ${({ theme }) => theme.color.bluePrimary};
        color: ${({ theme }) => theme.color.white};
    }


    h3 {
      margin: 1rem 0 0.3rem
    }
`

export const SubTitle = styled.h2`
    margin: 1rem 0;
    width: 90%;
    font-size: 1.5rem;
    border-left: solid 2px ${({ theme }) => theme.color.bluePrimary};
    // margin: 0 auto 1.5rem;
    padding: 0 0.3rem;
    color: ${({ theme }) => theme.color.bluePrimary};
`

