import React, { useState, useEffect } from 'react'

import Layout from '@components/Layout'
import Loader from '@components/Loader'
import Select from '@components/inputs/Select'

import http from '@utils/fetch-client'
import config from '@utils/config'
import { usePagination } from '@hooks/usePagination'
import { useAlert } from '@hooks/useAlert'

import {
  Title, Content, Clinics, Row, RowInfo, Info, ContainerClinic,
  ContainerInfo, ContainerImg, Empty
} from './styles'

const Undefined = {
  state: 'Sin Estado Definido',
  city: 'Sin Ciudad Definida',
  service: 'Sin Servicio Definido'
}

const Direct = () => {
  const [clinics, setClinics] = useState({ search: [] })
  const [filters, setFilters] = useState({})
  const [loading, setLoading] = useState(true)
  const [Pagination, idx, resetClinics] = usePagination(clinics.search.length, 5)
  const [AlertInfo, setInfo] = useAlert({ isClose: false })
  // console.log('clincis', clinics)
  // console.log('filters', filters)
  const orderByField = (arr, field) => (
    arr.sort((a, b) => (
      (a[field] < b[field]) ? -1 : 1
    ))
  )

  // Get Clinics
  useEffect(() => {
    http.get('api/clinica/public')
      .then(({ status, data }) => {
        if (status !== 200) throw Error('Disculpe, no se logró obtener el directorio de las clínicas. Por favor intente más tarde.')
        if (data) {
          data = orderByField(data, 'nombre')
          setClinics({ data, search: data })
          setFilters(data.reduce((acc, curr) => {
            const estado = curr.estado || Undefined.state
            const ciudad = curr.ciudad || Undefined.city
            if (!acc.states.some(e => e === estado)) acc.states.push(estado)
            if (!acc.cities.some(e => e === ciudad)) acc.cities.push(ciudad)
            return acc
          }, { states: [], cities: [], services: [], keywords: {} }))
        }
      })
      .catch(err => {
        console.log('[Directory] err api/clinica/public', { err })
        setInfo({ status: true, severity: 'error', msg: 'Disculpe, ocurrió un error solicitando las clínicas.' })
      })
      .finally(() => setLoading(false))
  }, [setInfo])

  // Update Filters
  useEffect(() => {
    if (clinics.data) {
      setClinics({
        ...clinics,
        search: clinics.data.filter(({ estado, ciudad }) => {
          /* console.log('keywords', filters.keywords)
          console.log('estado', filters.keywords?.state)
          console.log('ciudad', filters.keywords?.city) */
          if (filters.keywords?.state && filters.keywords?.state !== estado) return false
          if (filters.keywords?.city && filters.keywords?.city !== ciudad) return false
          if (filters.keywords?.des && filters.keywords?.des !== ciudad) return false
          // if(filters.keywords?.service && filters.keywords?.service !== estado) return false
          return true
        })
      })
    }
  }, [filters])

  useEffect(() => { window.scrollTo(0, 0) }, [idx])

  const handleChangeSelect = (type) => ({ value }) => {
    if (value === Undefined[type] || value === '0') value = null
    const newFilters = JSON.parse(JSON.stringify(filters))
    newFilters.keywords[type] = value
    setFilters(newFilters)
    resetClinics()
  }

  const onErrorImg = ({ target }) => {
    target.onerror = null
    target.src = '/img-default.jpg'
  }

  const OrientationImage = ({ target }) => {
    const { naturalHeight, naturalWidth } = target
    if (naturalWidth > naturalHeight) {
      target.style.height = '150px'
      target.style.width = '200px'
    } else {
      target.style.height = '200px'
      target.style.width = '150px'
    }
  }

  if (loading) {
    return (
      <Layout title='Directorio' active=''>
        <Title>Instituciones Aliadas Actualmente</Title>
        <Content loading={loading.toString()}>
          <Loader />
        </Content>
      </Layout>
    )
  }

  return (
    <Layout title='Directorio' active=''>
      <Title>Instituciones Aliadas Actualmente</Title>
      <Content loading={loading.toString()}>
        {!clinics.data
          ? (
            <Empty>
              <h1>NO SE LOGRÓ OBTENER COMUNICACIÓN CON EL SERVIDOR</h1>
              <h3>(refresque la página o comuníquese con nuestro personal)</h3>
            </Empty>)
          : (
            <>
              <Clinics>
                <Row>
                  <Select
                    label='Todos los Estados'
                    name='estado'
                    options={filters?.states.map((stateDes) => ({ opcionID: stateDes, opcionDes: stateDes }
                    ))}
                    handleChange={handleChangeSelect('state')}
                  />
                  <Select
                    label='Todas las Ciudades'
                    name='ciudad'
                    options={filters?.cities.map((cityDes) => ({ opcionID: cityDes, opcionDes: cityDes }
                    ))}
                    handleChange={handleChangeSelect('city')}
                  />
                </Row>

                {!clinics.search.length
                  ? (
                    <Empty>
                      <h1>NO HAY ALIDOS RELACIONADOS CON SU BÚSQUEDA</h1>
                      <h3>(modifique los filtros para encontrar otras opciones)</h3>
                    </Empty>)

                  : (
                    <>
                      <Pagination />
                      <AlertInfo />
                      {clinics.search.slice(idx.start, idx.end).map(({ co_clinica: cod, imagen, nombre, direccion, ciudad, estado, rif, correo, telefono }) =>
                        <ContainerClinic key={cod}>
                          <ContainerImg>
                            <img
                              src={imagen ? `${config.statics.clinics}/${imagen}` : '/img-default.jpg'}
                              alt={`imagen de ${nombre}`}
                              onError={onErrorImg}
                              onLoad={OrientationImage}
                            />
                          </ContainerImg>
                          <ContainerInfo>
                            <Info title='true'><div>{nombre || 'N/D'}</div></Info>
                            <RowInfo>
                              <Info><div>Estado:</div> {estado || 'N/D'} </Info>
                              <Info><div>Ciudad:</div> {ciudad || 'N/D'} </Info>
                              <Info><div>RIF:</div> {rif || 'N/D'} </Info>
                            </RowInfo>
                            <Info><div>Dirección:</div> {direccion || 'N/D'} </Info>
                            <Info><div>Correos:</div> {correo || 'N/D'} </Info>
                            <Info><div>Telefonos:</div> {telefono || 'N/D'} </Info>
                          </ContainerInfo>
                        </ContainerClinic>
                      )}
                      <Pagination />
                    </>)}
              </Clinics>
            </>)}
      </Content>
    </Layout>
  )
}

export default Direct
