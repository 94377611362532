import React from 'react'

import ContactUsEmail from '@components/ContactUsEmail'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
// faFacebookF, faTwitter, faInstagram
import Tooltip from '@mui/material/Tooltip'
import {
  Container, Redes, Red, Logo, Username, Link
  // HeaderStyled, Img,
} from './styles'

const Contacts = () => {
  const handleClick = link => () => {
    console.log(link)
    if (link) window.open(link)
    else window.open('https://www.facebook.com/Apucv-Prensa-1682491995365601/')
  }

  const successClipboard = copied => {
    alert(`'${copied}' Copiado al portapapeles!`)
  }

  const failureClipboard = () => {
    alert('¡Ha fallado el copiado al portapapeles!')
  }

  const errorClipboard = () => {
    alert('Se ha producido un error al copiar al portapaples')
  }

  const copyClipboard = ({ target }) => {
    // var codigoACopiar = document.getElementById('textoACopiar');
    const seleccion = document.createRange()
    seleccion.selectNodeContents(target)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(seleccion)
    try {
      const res = document.execCommand('copy') // Intento el copiado
      if (res) successClipboard(target.innerText)
      else failureClipboard(target)
    } catch (err) { errorClipboard(err) }

    window.getSelection().removeRange(seleccion)
  }

  const contacts = [
    {
      type: 'red', name: 'Citas', username: 'semoippucv@gmail.com', icon: faGoogle
    },
    {
      type: 'red', name: 'Carta Aval', username: 'cartaavalsamhoi@gmail.com', icon: faGoogle
    },
    {
      type: 'red', name: 'Reembolso', username: 'samhoireembolsos@gmail.com', icon: faGoogle
    },
    {
      type: 'red', name: 'Montepio', username: 'montepio.apucv@gmail.com', icon: faGoogle
    },
    {
      type: 'phone',
      name: 'Clave de Emergencia',
      icon: faPhone,
      regular: ['04166215602', '0212-6935260'],
      whatsapp: [{ number: '04143724159', link: 'https://wa.link/r009fs' }]
    },
    {
      type: 'phone',
      name: 'SICOF',
      icon: faPhone,
      regular: ['0212-8204037'],
      whatsapp: []
    },
    {
      type: 'red', name: 'Registro y Control', username: 'rcontrolipp@gmail.com', icon: faGoogle
    },
    {
      type: 'red', name: 'Crédito y cobranza', username: 'credito.ipp@gmail.com', icon: faGoogle
    },
    {
      type: 'phone',
      name: 'Venemergencia',
      icon: faPhone,
      regular: ['0212-8221250', '0212-3135550'],
      whatsapp: []
    }
    /* {
      type:'phone', name: 'Atención de Telemedicina UCV', icon: faPhone,
      regular: ['02123135660'],
      whatsapp: [],
    }, */
  ]

  return (
    <Container>
      <Redes>
        {contacts.map(({ type, icon, name, username, regular, whatsapp }) =>
          type === 'red'
            ? (
              <Red key={username}>
                <Logo> <FontAwesomeIcon icon={icon} /> </Logo>
                <b>{name}: </b>
                <Tooltip title='Copiar en Portapapeles' aria-label={`Copiar ${username}`}>
                  <Username onClick={copyClipboard}> {username} </Username>
                </Tooltip>
              </Red>)

            : type === 'phone'
              ? (
                <Red key={name}>
                  <Logo> <FontAwesomeIcon icon={icon} /> </Logo>
                  <b>{name}: </b>

                  {regular.map(number =>
                    <Tooltip title='Copiar en Portapapeles' aria-label={`Copiar ${number}`} key={number}>
                      <Username onClick={copyClipboard}>{number}</Username>
                    </Tooltip>
                  )}

                  {whatsapp.map(({ number, link }) =>
                    <Tooltip
                      title='Ir a Whatsapp'
                      aria-label={`Whatsapp Clave de emergencia del IPP-UCV:  ${number}`}
                      key={number}
                    >
                      <Link onClick={handleClick(link)}>
                        <FontAwesomeIcon icon={faWhatsapp} /> {number}
                      </Link>
                    </Tooltip>
                  )}
                </Red>)

              : null
        )}
      </Redes>

      <ContactUsEmail />

    </Container>
  )
}

export default Contacts
