import { useState, useEffect } from 'react'

import Layout from '@components/Layout'
import Loader from '@components/Loader'
import Beneficiaries from '@components/Beneficiaries'
// import PhotoFrameDocumentPDF from '@components/PhotoFrameDocumentPDF'

import { useAlert } from '@hooks/useAlert'
import { useStateValue } from '@context'
import http from '@utils/fetch-client'
import { useEffectAbortable } from '@hooks/useEffectAbortable'

import { Container, Card, Body, BodyInfo, BodyBeneficiaries, Value, NoCobertura } from './styles'

const fieldsLabel = {
  hcm: 'Código',
  hcmb: 'Código',
  fun: 'Código',
  amparo: 'Amparo',
  fe_ingreso: 'Fecha de ingreso',
  cobertura: 'Cobertura',
  otorgado: 'Otorgado',
  transito: 'Transito',
  pagado: 'Pagado',
  saldo: 'Saldo',
  beneficiarios: 'beneficiarios'
}

const coberturasName = {
  hcm: 'HCM',
  hcmb: 'HCM Básico',
  fun: 'Funerario'
}

const fieldsByCoverage = {
  hcm: Object.keys(fieldsLabel),
  hcmb: Object.keys(fieldsLabel),
  fun: ['fun', 'amparo', 'fe_ingreso', 'beneficiarios']
}

const dataWithValidCoverage = (data) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    console.log({key, value})
    if(value[`amparo_${key}`] !== null) return {...acc, [key]:{...value}}
    return acc
  }, {})
}

const formatDataCoverage = data => {
  return Object.entries(data).map(([name, info]) => ({
    name,
    info: Object.entries(info).reduce((acc, [key, value]) => {
      if (!isValidPropCoverage(name, key)) return acc
      return Object.assign(acc, setPropCoverage(name, key, value))
    }, {})
  }))
}

const getFieldLabel = (name, key) => {
  key = key.replace(`_${name}`, '')
  return fieldsLabel[key]
}

const getMonto = monto => {
  return monto ? new Intl.NumberFormat('de-DE').format(monto) : 'N/D'
}

const getFieldValue = (name, key, value) => {
  if (typeof (value) === 'number') {
    return getMonto(value)
  } else if (key.replace(`_${name}`, '') === 'fe_ingreso') {
    return value ? value.split('T')[0] : 'N/D'
  }

  return value
}

const isValidPropCoverage = (name, key) => {
  key = key.replace(`_${name}`, '')
  return fieldsByCoverage[name].includes(key)
}

const setPropCoverage = (name, key, value) => ({
  [getFieldLabel(name, key)]: getFieldValue(name, key, value)
})

const Cobertura = () => {
  const [{ user, beneficiaries }] = useStateValue()
  const [Alert, setAlert] = useAlert({})
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState(user.co_benefi)
  const [isData, setIsData] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [coberturas, setCoberturas] = useState([])
  // console.log('Cobertura', { user, beneficiaries, coberturas })

  useEffectAbortable(({ aborted }) => {
    http.get('api/usuario/cobertura')
    .then(({ status, data }) => {
      if(aborted) return
      console.log({status, data})
      if (status !== 200 && status !== 400) throw new Error(data)
      if (status === 400) data = data.data
      const cover = dataWithValidCoverage(data)
      setCoberturas(formatDataCoverage(cover))
      setIsData(true)
      setIsEmpty(Object.keys(data).length ?false :true)
    })
    .catch(err => {
      console.error(`Error [Cobertura]: ${err}`)
      setAlert({ status: true, severity: 'error', msg: 'Disculpe no se lograron consultar sus <b>Coberturas</b>.' })
    })
    .finally(() => setLoading(false))
  }, [setAlert])

  const handleSelected = coBenefi => {
    setLoading(true)

    http.get(`api/usuario/cobertura?co_beneficiario=${coBenefi}`)
      .then(({ status, data }) => {
        console.log({status, data})
        if (status !== 200 && status !== 400) throw new Error(data)
        if (status === 400) data = data.data
        const cover = dataWithValidCoverage(data)
        setCoberturas(formatDataCoverage(cover))
        setIsData(true)
        setIsEmpty(Object.keys(data).length ?false :true)
        setAlert({ status: false })
      })
      .catch(err => {
        console.error(`Error [Cobertura]: ${err}`)
        setCoberturas([])
        setIsData(false)
        setAlert({ status: true, severity: 'error', msg: 'Disculpe no se lograron consultar sus <b>Coberturas</b>.' })
      })
      .finally(() =>{ 
        setSelected(coBenefi)
        setLoading(false)
      })
  }

  const noCobertura = () => {
    if(!isData) return false
    return coberturas.reduce((prev, curr) => prev && !curr, true)
  }

  const ListBeneficiaries = ({ beneficiaries }) => {
    if (!beneficiaries) return null
    return (
      <BodyBeneficiaries row>
        <Beneficiaries
          beneficiaries={[user].concat(beneficiaries)}
          selector
          selected={selected}
          handleSelected={handleSelected}
        />
      </BodyBeneficiaries>
    )
  }

  const ListOfCoverage = ({ coberturas }) => {
    return coberturas.map(({ name, info }) => {
      const { beneficiarios, ...rest } = info
      return (
        <Card key={name}>
          <h1>{coberturasName[name]}</h1>
          <Body>
            <BodyInfo>
              {Object.entries(rest).map(([label, value]) => (
                <Value key={`${name}_${label}`}>
                  <h3>{label}</h3>
                  <span>{value}</span>
                </Value>
              ))}
            </BodyInfo>

            {!beneficiarios
              ? null
              : (
                <BodyBeneficiaries>
                  <Beneficiaries beneficiaries={beneficiarios} />
                </BodyBeneficiaries>)}
          </Body>
        </Card>
      )
    })
  }
// console.log({coberturas, isEmpty})
  return (
    <Layout title='Cobertura' active='cobertura'>
      {loading
        ? <Loader />
        : (
          <Container>
            <Alert />
            {<ListBeneficiaries beneficiaries={beneficiaries} />}

            {<ListOfCoverage coberturas={coberturas} />}

            {isEmpty
              ?<NoCobertura>Usted no tiene ningun tipo de Cobertura.</NoCobertura>
              :noCobertura() && <NoCobertura>Usted no tiene Cobertura para hcm, hcmb ni fun.</NoCobertura> 
            }
          </Container>)}
    </Layout>
  )
}

export default Cobertura
