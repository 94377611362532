import { useState, useRef } from 'react'
import { navigate } from '@reach/router'
import { useStateValue } from '@context'

import Tooltip from '@mui/material/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { MenuContainer, Menu, MenuToogle, OptionDiv, OptionLi, Title } from './styles'

const MenuGuide = ({ optionMenu }) => {
  const [{ user }] = useStateValue()
  const [showMenu, setShowMenu] = useState(false)

  const isBeneficiarie = user.co_tipo === 1

  const refs = {
    introduccion: useRef(null),
    citas: useRef(null),
    samhoi: useRef(null),
    reembolsos: useRef(null),
    cartasAval: useRef(null)
  }

  const handleToogle = (id) => () => {
    const displayCurrent = refs[id].current.style.display
    refs[id].current.style.display = displayCurrent === 'none' ? '' : 'none'
  }

  const handleShowMenu = () => {
    setShowMenu(show => !show)
  }

  const handleHideMenu = () => {
    setShowMenu(show => !show)
  }

  const handleNavigation = (url) => () => {
    navigate(`/portal/guia/${url}`)
  }

  const isSelected = path => {
    return optionMenu === path
  }

  const Option = ({ label, path }) => (
    <OptionLi selected={isSelected(path)} onClick={handleNavigation(path)}>
      {label}
    </OptionLi>
  )
  return (
    <>
      <MenuContainer show={showMenu}>
        <Menu>
          <ul>
            <OptionDiv>
              <Title onClick={handleToogle('introduccion')}><h4>General</h4><span> {'>'} </span></Title>
              <ul ref={refs.introduccion}>
                <Option label='Introducción' path='general#general' />
                <Option label='Estructura de la App' path='general#estructura' />
                {/* <Option label='Documentos' path='general#documentos' /> */}
              </ul>
            </OptionDiv>

            <OptionDiv>
              <Title onClick={handleToogle('citas')}><h4>Citas</h4><span> {'>'} </span></Title>
              <ul ref={refs.citas}>
                <Option label='Introducción' path='citas#general' />
                {/* <Option label='Listado' path='citas#listado' /> */}
                <Option label='Solicitud' path='citas#solicitud' />
                {!isBeneficiarie && <Option label='Revisión' path='citas#revision' />}
                {/* <Option label='Detalle' path='citas#detalle' /> */}
              </ul>
            </OptionDiv>

            <OptionDiv>
              <Title onClick={handleToogle('samhoi')}><h4>SAMHOI</h4><span> {'>'} </span></Title>
              <ul ref={refs.samhoi}>
                <OptionDiv>
                  <Title onClick={handleToogle('reembolsos')}><h4>Reembolsos</h4><span> {'>'} </span></Title>
                  <ul ref={refs.reembolsos}>
                    <Option label='Introducción' path='reembolsos#general' />
                    <Option label='Listado' path='reembolsos#listado' />
                    <Option label='Solicitud' path='reembolsos#solicitud' />
                    <Option label='Detalle' path='reembolsos#detalle' />
                    <Option label='Modificar' path='reembolsos#modificar' />
                    {!isBeneficiarie && <Option label='Revisión' path='reembolsos#revision' />}
                  </ul>
                </OptionDiv>

                <OptionDiv>
                  <Title onClick={handleToogle('cartasAval')}><h4>Cartas Aval</h4><span> {'>'} </span></Title>
                  <ul ref={refs.cartasAval}>
                    <Option label='Introducción' path='cartasAval#general' />
                    <Option label='Listado' path='cartasAval#listado' />
                    <Option label='Solicitud' path='cartasAval#solicitud' />
                    <Option label='Detalle' path='cartasAval#detalle' />
                    <Option label='Modificar' path='cartasAval#modificar' />
                    {!isBeneficiarie && <Option label='Revisión' path='cartasAval#revision' />}
                  </ul>
                </OptionDiv>
              </ul>
            </OptionDiv>
          </ul>
        </Menu>
      </MenuContainer>

      <Tooltip title='Ver Indice de la Guía' aria-label='Ver Indice de la Guía'>
        <MenuToogle role='button' onClick={showMenu ? handleHideMenu : handleShowMenu}>
          <FontAwesomeIcon icon={showMenu ? faTimes : faBars} />
        </MenuToogle>
      </Tooltip>
    </>
  )
}

export default MenuGuide
