import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Button from '@components/Button'
import Loader from '@components/Loader'
import InputText from '@components/inputs/Text'
import InputSelect from '@components/inputs/Select'
import InputRadio from '@components/inputs/Radio'

import { useAlert } from '@hooks/useAlert'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { useStateValue } from '@context/index.js'
import http from '@utils/fetch-client'

import { Container, ContainerBtn } from './styles'
import useStyles from './stylesUI'

const initialState = {
  user: {
    coUsuario:0,
    email: '',
    phone: '',
    phoneHab: '',
    direccion: '',
    coEstado: '',
    estado: '',
    municipio: '',
    coMunicipio: '',
    parroquia: '',
    coParroquia: '',
    whatsapp: '',
    whatsappOpt: '',
    telegram: '',
    telegramOpt: '',
    instagram: '',
    valid: false,
    fetch: false
  },
  localidades: {
    data: { estados: [], municipios: [], parroquias: [] },
    input: { estados: [], municipios: [], parroquias: [] }
  },
  preguntas: [
    {
      co_pregunta:1,
      name: 'whatsapp',
      descripcion: '¿utiliza whatsapp?',
      respuesta: 0,
      opciones:[
        { 
          opcionID:1,
          opcionDes:'Sí',
        },
        {
          opcionID:2,
          opcionDes:'No',
        },
      ]
    },
    {
      co_pregunta:2,
      name: 'telegram',
      descripcion: '¿utiliza Telegram?',
      respuesta: 0,
      opciones:[
        { 
          opcionID:4,
          opcionDes:'Sí',
        },
        {
          opcionID:5,
          opcionDes:'No',
        },
      ]
    }
  ]
}

const redes = {
  whatsapp: {co_pregunta:1 ,haveOption: '1'},
  telegram: {co_pregunta:2 ,haveOption: '4'},
  instagram: {co_pregunta:3 ,haveOption: '7'},
}

const JornadaDataUser = ({handleStep}) => {
  const [{ user }, dispatch] = useStateValue()
  const classes = useStyles()
  const [dataUser, setDataUser] = useState(initialState.user)
  const [preguntas, setPreguntas] = useState(initialState.preguntas)
  const [localidades, setLocalidades] = useState(initialState.localidades)
  const [MyAlert, setInfo] = useAlert({})
  const [loading, setLoading] = useState(true)

  useEffectAbortable(({ aborted }) => {
    const url = `api/jornadas/user/${user['co_benefi']}`
    http.get(url)
    .then(({ status, data }) => {
      if(aborted) return
      if (status !== 200) throw new Error(data)
      
      setDataUser(state => ({...state, ...data.data, fetch: true, coUsuario: user['co_usuario']}))
    })
    .catch(err => {
      console.error(`Error [JornadaDataUser]: ${err}`)
      setInfo({ status: true, severity: 'error', msg: 'Disculpe ocurrió un error consultando información del <b>Beneficiario</b>, intente mas tarde.' })
    })
    .finally(() => setLoading(false))
  }, [setInfo])

  useEffectAbortable(({ aborted }) => {
    const url = `api/jornadas/localidades`
    http.get(url)
    .then(({ status, data }) => {
      if(aborted) return
      if (status !== 200) throw new Error(data)
      
      const newLocalidades = JSON.parse(JSON.stringify(localidades))
      newLocalidades.data = data.data
      newLocalidades.input.estados = data.data.estados
      newLocalidades.input.municipios = data.data.municipios
      newLocalidades.input.parroquias = data.data.parroquias
      setLocalidades(newLocalidades)
    })
    .catch(err => {
      console.error(`Error [JornadaDataUser]: ${err}`)
      setInfo({ status: true, severity: 'error', msg: 'Disculpe ocurrió un error consultando <b>las localidades</b>, intente mas tarde.' })
    })
    .finally(() => setLoading(false))
  }, [setInfo])

  console.log('JornadaDataUser', {dataUser, localidades, preguntas})  

  const validPhone = phone => {
    const regex = /^\d+$/
    const valid = regex.test(phone)
    return valid
  }

  const validEmail = value => {
    const regex = /^(.+@.+\..+)$/
    return regex.test(value)
  }

  const haveRed = (red) => {
    const p = preguntas.find(({co_pregunta}) => co_pregunta === redes[red].co_pregunta)
    console.log({p, redes, red})
    let valid = p.respuesta === redes[red].haveOption
    return valid
  }
  const redValueOption = (red) => {
    const p = preguntas.find(({co_pregunta}) => co_pregunta === redes[red].co_pregunta)
    return p.respuesta
  }

  const handleEmail = ({ name, value }) => {
    console.log({ name, value })
    setDataUser({ ...dataUser, [name]: value })
  }

  const handlePhone = ({ name, value }) => {
    setDataUser({ ...dataUser, [name]: value })
  }

  const handleChangeData = ({ name, value }) => {
    setDataUser({ ...dataUser, [name]: value })
  }

  const handleChangeWhatsapp = ({ name, value }) => {
    setDataUser({ ...dataUser, [name]: value })
  }

  const changeEstados = coEstado => {
    const newLocalidades = JSON.parse(JSON.stringify(localidades))
    newLocalidades.input.municipios = newLocalidades.data.municipios.filter(m => m['co_edo_geo'].toString() === coEstado)
    newLocalidades.input.parroquias = []

    setDataUser(state => ({...state, coEstado}))
    setLocalidades(newLocalidades)
  }

  const changeMunicipio = (coMunicipio) => {
    const newLocalidades = JSON.parse(JSON.stringify(localidades))
    newLocalidades.input.parroquias = newLocalidades.data.parroquias.filter(p => p['co_municipio'].toString() === coMunicipio)

    setDataUser(state => ({...state, coMunicipio}))
    setLocalidades(newLocalidades)
  }

  const changeParroquia = (coParroquia) => {
    setDataUser(state => ({...state, coParroquia}))
  }

  const handleChangeEstado = ({value}) => changeEstados(value)
  const handleChangeMunicipio = ({value}) => changeMunicipio(value)
  const handleChangeParroquia = ({value}) =>  changeParroquia(value)
  const handleChangeQuestion = ({name, value}) => {
    const index = preguntas.findIndex(({co_pregunta}) => co_pregunta.toString() === name)
    const newPreguntas = JSON.parse(JSON.stringify(preguntas))
    newPreguntas[index].respuesta = value

    const newDataUser = JSON.parse(JSON.stringify(dataUser))
    newDataUser[`${newPreguntas[index].name}Opt`] = value
    
    setPreguntas(newPreguntas)
    setDataUser(newDataUser)
  } 
  
  const validPerfil = () => {
    let valid = true
    const { email, phone, direccion, coEstado, coMunicipio, coParroquia } = dataUser
    if(!(phone && email && direccion && coEstado && coMunicipio && coParroquia)) valid = false
    // if(!validPhone(phone)) valid = false
    // if(!validEmail(email)) valid = false
    return valid
  }

  const submit = () => {
    setLoading(true)
    const { email, phone, phoneHab, direccion, coEstado, coMunicipio, coParroquia,
      whatsapp, telegram, whatsappOpt, telegramOpt
    } = dataUser
    let errorHTML = ''
    if(!direccion) errorHTML += '<li>Debe ingresar su <b>dirección de domicilio</b></li>'
    if(!coEstado) errorHTML += '<li>Debe Seleccionar el <b>estado</b> donde reside</li>'
    if(!coMunicipio) errorHTML += '<li>Debe ingresar su <b>municipio</b> donde reside</li>'
    if(!coParroquia) errorHTML += '<li>Debe ingresar su <b>parroquia</b> donde reside</li>'
    if(!coParroquia) errorHTML += '<li>Debe ingresar su <b>parroquia</b> donde reside</li>'
    if(!whatsappOpt || !telegramOpt) errorHTML += '<li>Debe responder <b>todas las preguntas</b></li>'
    
    if(!email) errorHTML += '<li>Debe ingresar su <b>correo electrónico</b></li>'
    else{ if(!validEmail(email)) errorHTML += '<li>El <b>correo electrónico no es válido</b>, verifique que el correo ingresado esté bien escrito.</li>' }
    
    if(!phone) errorHTML += '<li>Debe ingresar su número de <b>teléfono</b></li>'
    else{ if(!validPhone(phone)) errorHTML += '<li>El número de <b>telefono no es válido</b>, debe introducir solo números.  Ej: 04241234567</li>' }
    
    if(haveRed('whatsapp')){
      if(!whatsapp) errorHTML += '<li>Indicó que tiene <b>Whatsapp</b>, debe ingresar el número de teléfono. </li>'
      else{ if(!validPhone(whatsapp)) errorHTML += '<li>El número de <b>whatsapp no es válido</b>, debe introducir solo números.  Ej: 04241234567</li>' }
    }
    if(haveRed('telegram')){
      if(!telegram) errorHTML += '<li>Indicó que tiene <b>Telegram</b>, debe ingresar su nombre de usuario. </li>'
    }
    
    if (errorHTML) {
      setLoading(false)
      return setInfo({ status: true, severity: 'error', msg: errorHTML })
    }
    console.log('SUBMIT|Success', {dataUser, errorHTML})
    const url = `api/jornadas/user/${user['co_benefi']}`
    http.patch(url, JSON.stringify(dataUser), {
      'Content-Type': 'application/json'
    })
      .then(({ status, data }) => {
        // console.log('status', status)
        // console.log('data', data)
        if (status !== 200) throw Error('Disculpe, no se logró actualizar su información de contacto')
        setInfo({ status: true, severity: 'success', msg: 'Su Datos Personales <b>han sido actualizados exitosamente</b>.' })
        const dataUpdate = {
          tx_correo: email,
          tx_tel_cel: phone,
          tx_telf_hab: phoneHab
        }
        dispatch({ type: 'user', payload: { ...user, ...dataUpdate } })
        setTimeout(() => handleStep(), 1000) 
      })
      .catch(err => {
        setInfo({ status: true, severity: 'error', msg: err })
      }).finally(() => setLoading(false))
  }

  if(!dataUser.fetch) return (
    <Container>
      <Loader />
    </Container>
  )

  console.log({dataUser})
  return (
    <Container>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} md={6}>
          <InputText
            id='email'
            defaultValue={dataUser.email}
            handleBlur={handleEmail}
            label='Correo Electronico'
            name='email'
            required
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputText
            id='phone'
            defaultValue={dataUser.phone}
            handleBlur={handlePhone}
            label='Teléfono'
            name='phone'
            required
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputText
            id='phoneHab'
            defaultValue={dataUser.phoneHab}
            handleBlur={handlePhone}
            label='Teléfono Habitación'
            name='phoneHab'
          />
        </Grid>

        <Grid item xs={12}>
          <InputText
            id='direccion'
            defaultValue={dataUser.direccion}
            handleBlur={handleChangeData}
            label='Dirección'
            name='direccion'
            required
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputSelect
            required  
            id='estado'
            label='Seleccione Estado'
            name='coEstado'
            value={dataUser.coEstado}
            defaultOption={'0'}
            options={localidades.input.estados}
            handleChange={handleChangeEstado}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputSelect
            required  
            id='municipio'
            label='Seleccione Municipio'
            name='coMunicipio'
            value={dataUser.coMunicipio}
            defaultOption={'0'}
            options={localidades.input.municipios}
            handleChange={handleChangeMunicipio}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputSelect
            required  
            id='parroquia'
            label='Seleccione Parroquia'
            name='coParoquia'
            value={dataUser.coParroquia}
            defaultOption={'0'}
            options={localidades.input.parroquias}
            handleChange={handleChangeParroquia}
          />
        </Grid>

        {preguntas.map(p => (
          <Grid item xs={12} md={6}>
            <InputRadio
              required  
              id={p.co_pregunta}
              label={p.descripcion}
              name={p.co_pregunta}
              value={p.respuesta}
              defaultOption={'0'}
              options={p.opciones}
              handleChange={handleChangeQuestion}
            />
          </Grid>
        ))}

        {haveRed('whatsapp') && <Grid item xs={12} md={4}>
          <InputText
            required  
            id='whatsapp'
            label='Ingrese su número de whatsapp'
            name='whatsapp'
            defaultValue={dataUser.whatsapp}
            handleBlur={handleChangeWhatsapp}
          />
        </Grid>}

        {haveRed('telegram') && <Grid item xs={12} md={4}>
          <InputText
            required  
            id='telegram'
            label='Ingrese su username de Telegram'
            name='telegram'
            defaultValue={dataUser.telegram}
            handleBlur={handleChangeData}
          />
        </Grid>}
        
      </Grid>

      <div> {loading ? <Loader /> : <MyAlert />} </div>

      <ContainerBtn>
        <Button
          label='Guardar y Continuar'
          onClick={submit}
          color='primary'
          disabled={loading || !validPerfil()}
        />
      </ContainerBtn>
    </Container>
  )
}

export default JornadaDataUser
