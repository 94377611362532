import styled from 'styled-components'

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    padding: 2rem 0;
`

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    min-height: 200px;
    margin: 10px auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 13px 25px -20px #666;

    h1{
        margin: 0;
        padding: 5px 0;
        text-align: center;
        color: ${({ theme }) => theme.color.white};
        background-color: ${({ theme }) => theme.color.bluePrimary}
    
    }
`

export const Body = styled.div`
    background-color: #fafafa;
    padding: 10px 5px;
`
export const BodyInfo = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: #fafafa;
`

export const BodyBeneficiaries = styled.div`
    display: flex;
    flex-direction: ${({ row }) => row ? 'row' : 'column'};
    align-items: flex-start;
`

export const Value = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100px;
    margin: 5px 10px;
    padding: 10px;
    color: ${({ theme }) => theme.color.gray};
    background-color: ${({ theme }) => theme.color.white};
    font-weight: 500;
    border-radius: 10px;
    border: 2px solid #ededed;

    h3{
        margin: 0 0 10px 0;
        color: ${({ theme }) => theme.color.bluePrimaryOpacity};
        font-weight: bold;
    }
`

export const NoCobertura = styled.h1`
    margin: 50px auto;
    text-align: center;
`
