import React from 'react'
import { Container } from './styles'

const StatusDoc = ({ status }) => {
  const showStatus = (status) => {
    return (status === 1) ? 'Por Revisar'
      : (status === 2) ? 'Válido'
          : (status === 3) ? 'Inválido'
              : 'N/D'
  }

  return (
    <Container status={status}>
      {showStatus(status)}
    </Container>
  )
}

export default StatusDoc
