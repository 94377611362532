const postsMenu = [
  {
    section: 'Información Importante',
    options: [
      {
        id: 1,
        label: 'Noticia Emergente',
        route: '/noticias/1'
      }
    ]
  },
  {
    section: 'Sitios de Interes',
    options: [
      
    ]
  }
]
/* 
const postsMenu = [
  {
    section: 'Información Importante',
    options: [
      {
        id: 1,
        label: 'Noticia Emergente',
        route: '/noticias/1'
      },
      {
        id: 2,
        label: 'Manual de Usuario',
        route: '/noticias'
      },
      {
        id: 3,
        label: 'Preguntas Frecuentes',
        route: '/noticias'
      }
    ]
  },
  {
    section: 'Sitios de Interes',
    options: [
      {
        id: 4,
        label: 'Link 1',
        route: ''
      },
      {
        id: 5,
        label: 'Link 2',
        route: ''
      },
      {
        id: 6,
        label: 'Link 3',
        route: ''
      }
    ]
  }
] */

export default postsMenu
