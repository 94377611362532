import { useState } from 'react'
import Button from '@components/Button'
// import http from '@utils/fetch-client'
// import Status from '@utils/fetch-client'
import { Container, Status } from './styles'

const status = [
  { co_status: 1, tx_status: 'En Creación', descripcion: 'La solicitud no se ha terminado de crear.' },
  { co_status: 2, tx_status: 'En Espera', descripcion: 'La solicitud ha sido completada y esta esperando revisión de parte de un operador.' },
  { co_status: 3, tx_status: 'Confirmada', descripcion: 'Todos los documentos han sido aprobados y la solicitud esta siendo subida al sistema SAMHOI.' },
  { co_status: 4, tx_status: 'Rechazada', descripcion: 'Alguno de los documentos presenta un error y requiere acción de parte del usuario.' },
  { co_status: 5, tx_status: 'En SAMHOI', descripcion: 'La solicitud ya ha sido asociada con un elemento de la bbdd de salud.' }
]

const LegendRequest = ({ top }) => {
  const [show, setShow] = useState(false)

  const handleToogle = () => setShow(show => !show)
  /* const [status, setStatus] = useState([])
  const [loading, setLoading] = useState(true) */
  // console.log('status', status)

  /* useEffect(() => {
    http.get(`api/cita/status`)
    .then(({ status, data }) => {
      if (status !== 200) throw new Error(status)
      setStatus(data)
    })
    .catch(err => {
      const msg = 'disculpe, no se encontró cargar la leyenda de los estatus de las citas.'
      console.error(`${err}: ${msg}`)
    })
    .finally(() => setLoading(false))
  }, []) */

  const getColor = status => {
    return (status === -1) ? 'Estatus'
      : (status === 1) ? 'black'
          : (status === 2) ? 'inProgress'
              : (status === 3) ? 'success'
                  : (status === 4) ? 'danger'
                      : (status === 5) ? 'inProgress'
                          : (status === 6) ? 'success'
                              : 'secondary'
  }

  return (
    <Container top={top}>
      {/* {loading ? <h5>Cargando Leyenda...</h5>

      :!status.length ? <h3>No se logro cargar la leyenda de los Estatus, disculpe.</h3>

      :
       */}
      <Button
        label={show ? 'Ocultar leyenda de Status de Solicitudes' : 'Mostrar leyenda de Status de Solicitudes'}
        color='secondary'
        onClick={handleToogle}
      />

      {show && status.map(({ co_status, tx_status, descripcion }) =>
        <Status color={getColor(co_status)} key={co_status}>
          <span>{tx_status}</span>: {descripcion}.
        </Status>
      )}
    </Container>
  )
}

export default LegendRequest
