import styled from 'styled-components'

export const Container = styled.div`
    display: grid;
    grid-template-areas:
        "nav" 
        "main";
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr;
    
    @media(min-width: 750px){
        grid-template-areas:
            "nav" "main";
        grid-template-rows: 1fr;
        grid-template-columns: min-content 1fr;
        height: 100vh;
    }
`

export const Header = styled.div`
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    height: 50px;
    background-color: ${({ theme }) => theme.color.blueDark};
    overflow: hidden;

    svg {
        margin: 5px 5px;
        font-size: 25px;
        color: ${({ theme }) => theme.color.ligthGray};
        cursor: pointer;
        &:hover{
            color: ${({ theme }) => theme.color.white};
        };   
    }
    
    .bar{
        font-size: 30px;
    }

    @media (min-width: 750px) {
        display: flex;
    }
`

export const Img = styled.img`
    margin-top:10px;
    height: 60px;
    width: 60px;
/* 
    @media (min-width: 420px) {
        height: 75px;
        width: 75px;
    } */
`

export const Content = styled.div`
    grid-area: 'main';
    /* height:100vh; */
    /* overflow-y: auto; */
    display: flex;
    flex-direction: column;
`

export const Main = styled.div`
    overflow-y: auto;
    background-color: #f8f8f8;
    z-index: 0;

    @media (min-width: 750px) {
        height: calc(100vh - 75px);
    }
`
