import { useState, useRef } from 'react'
import { Link } from '@reach/router'
import Modal from '@mui/material/Modal'
import InputText from '@components/inputs/Text'
import Grid from '@mui/material/Grid'

import Button from '@components/Button'
import Filters from '@components/Filters'
import Tooltip from '@mui/material/Tooltip'
import { usePagination } from '@hooks/usePagination'
import { getDate } from '@utils/date'
import { useAlert } from '@hooks/useAlert'
// import Filters from '@components/Filters'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Rows, Body, Codigo, Item, Title, CreatedAt, ContainerModal } from './styles'

const NO_OP = () => {}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },

  title: {
    margin: '10px 0'
  },

  data: {
    color: 'gray',
    margin: '5px 0'
  }
}))

const TableJorHorarios = ({ name, data, action, handleClick = NO_OP, disabled=false }) => {
  const classes = useStyles()
  const [Pagination, idx] = usePagination(data.length, 6)
  const [myData, setMyData] = useState({ data, search: data })
  const [open, setOpen] = useState(false)
  const [username, setUsername] = useState({value:'', confirm:'', cita:{nombre:''}})
  const [AlertConfirm, setAlertConfirm] = useAlert({})
  const bodyRef = useRef(null)

  const handleToogle = () => {
    const display = bodyRef.current.style.display
    bodyRef.current.style.display = ['none', ''].includes(display) ? 'block' : 'none'
  }

  
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleChangeUsername = ({value}) => setUsername(state => ({...state, confirm:value}))
  const handleConfirm = (username, c) => () => {
    setUsername(state => ({...state, value:username, cita: c}))
    handleOpen()

    // handleClick(co_jor_cita, co_usuario, co_jor_horario, username, nombre)
  }
  const myHandleClick = () => {
    if(username.value === username.confirm){
      let {co_jor_cita, co_usuario, co_jor_horario, nombre} = username.cita
      handleClick(co_jor_cita, co_usuario, co_jor_horario, username.cita.username, nombre)
    }else{
      setAlertConfirm({ status: true, severity: 'error', msg: `${username.confirm} <b>NO</b> es el nombre de usuario o cedula de ${username.cita.nombre}` })
    }
  }
  
  // if (!name || name === 'null') return null

  return (
    <Container>
      <section>
        <Tooltip title={`Mostrar/Ocultar Citas (${name})`} aria-label={`Mostrar/Ocultar Citas (${name})`}>
          <h1 onClick={handleToogle}> Citas ({name}) ({myData.search.length} de {data.length})</h1>
        </Tooltip>

       {/*  {!data.length ? null
          : <Filters
            nameFilter='citas'
            data={data}
            handleSetData={setMyData}
            options={[
              { label: 'Profesional', name: 'medico' },
              { label: 'Paciente', name: 'paciente' },
              { label: 'Encargados', name: 'encargado' },
              { label: 'Códigos', name: 'co_cita_web' },
              { label: 'Fechas Creado', name: 'fecha_creado_web', typeValue: 'dateUTC' },
              { label: 'Fecha Citas', name: 'fecha_cita', typeValue: 'date' }
            ]}
            visible={false}
          />}
 */}
        <Body ref={bodyRef}>
          {!myData.search.length ? <h3>NO HAY CITAS</h3>
            : <>
              <Item header>
                {/* <Codigo>Código</Codigo> */}
                <Title> [Código Cita] Paciente </Title>
              </Item>

              <Rows>
                {myData.search.slice(idx.start, idx.end).map(c => (
                  // <Link to={`/admin/EMI/citas/${co_jor_cita}`} key={co_jor_cita}>
                    <Item key={c.co_jor_horario} >
                      {/* <Codigo> {co_cita_web} </Codigo> */}
                      <Title> {`[${c.co_jor_cita}] ${c.nombre || ''} ${c.apellido || ''}`} </Title>
                      {action &&
                        <Button
                          label={action}
                          color='primary'
                          disabled={disabled}
                          onClick={handleConfirm(c.username, c)}
                        />
                      }
                    </Item>
                  // </Link>
                ))}
              </Rows>
              <Pagination />
            </>}
        </Body>
      </section>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
    <ContainerModal className={classes.paper}>

        <Grid item xs={12}>
          <InputText
            id='username'
            value={username.confirm}
            handleChange={handleChangeUsername}
            label={`Ingrese el username o cédula de ${username.cita.nombre}`}
            name='username'
            required
          />
        </Grid>
        <Grid item xs={12}>
          <AlertConfirm />
        </Grid>
        <Button
          label='Cancelar'
          onClick={() => handleClose()}
          color='danger'
          // disabled={loading || !validPerfil()}
        />
        <Button
          label='Confirmar'
          onClick={myHandleClick}
          color='primary'
          // disabled={loading || !validPerfil()}
        />
    </ContainerModal>
      </Modal>
    </Container>


  )
}

export default TableJorHorarios
