import React from 'react'
import { Link } from '@reach/router'

import { getDateTimeUTC } from '@utils/date'
import { Container, Img, Body, Title, Content, Date } from './styles'

const PostPreview = ({ id, src, alt, title, content, fecha_editado }) => {
  const onErrorImg = ({ target }) => {
    target.onerror = null
    target.src = '/post-default.jpg'
  }

  return (
    <Link to={`/noticias/${id}`}>
      <Container>
        <Img src={src} alt={alt} onError={onErrorImg} />
        <Date>Fecha: {getDateTimeUTC(fecha_editado)}</Date>
        <Body>
          <Title> {title} </Title>
          <Content dangerouslySetInnerHTML={{ __html: content }} />
        </Body>
      </Container>
    </Link>
  )
}

export default PostPreview
