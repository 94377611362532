
import citaCreating from '@mocks/citaCreating'

const reducer = (state, action) => {
  const { type, payload } = action
  const newState = JSON.parse(JSON.stringify(state))

  switch (type) {
    case 'LOGIN':
      let { token, user: { usuario, titular, beneficiarios = [], sesiones = [] } } = payload
      if (token) {
        window.localStorage.setItem('token', token)
        window.localStorage.setItem('id', usuario.co_usuario)
        beneficiarios = beneficiarios.filter(
          ({ co_benefi }) => co_benefi !== usuario.co_benefi
        )
      }
      return {
        ...state,
        user: usuario,
        titular: titular,
        beneficiaries: beneficiarios,
        sesiones: sesiones,
      }

    case 'LOGOUT':
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('id')
      return {
        ...state,
        user: null
      }

    case 'user':
      return {
        ...state,
        user: payload
      }

    case 'nav':
      return {
        ...state,
        nav: payload
      }

    case 'reembolsoCreating':
      return {
        ...state,
        reembolsoCreating: payload
      }

    case 'citaCreating':
      return {
        ...state,
        citaCreating: payload
      }

    case 'citaCreating_clear':
      return {
        ...state,
        citaCreating: citaCreating
      }

    case 'modalImage':
      return {
        ...state,
        modalImage: payload
      }

    case 'admin_reembolso':
      newState.admin.reembolsoEdit = payload
      return newState

    case 'admin_reembolso_status':
      newState.admin.reembolsoEdit.co_status = payload
      return newState

    case 'filters_notifications':
      newState.filters.notifications = payload
      return newState

    case 'filters_citas':
      newState.filters.citas = payload
      return newState

    case 'filters_cartasAval':
      newState.filters.cartasAval = payload
      return newState

    case 'filters_reembolsos':
      newState.filters.reembolsos = payload
      return newState

    case 'filters_EMIcitaMedica_pendientes':
      newState.filters.EMIcitaMedica_pendientes = payload
      return newState

    case 'filters_EMIcitaMedica_asignadas':
      newState.filters.EMIcitaMedica_asignadas = payload
      return newState

    case 'filters_EMIlaboratorio_pendientes':
      newState.filters.EMIlaboratorio_pendientes = payload
      return newState
      
    case 'filters_EMIlaboratorio_listos':
      newState.filters.EMIlaboratorio_listos = payload
      return newState
    
    default:
      return state
  }
}

export default reducer
