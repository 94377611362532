import config from '@config'
import axios from 'axios'
const host = config.host
const readUrl = (url = '') => (
  url.startsWith('http://') || url.startsWith('https://') ? url : `${host}/${url}`
)

const getToken = () => {
  const tokenStorage = window.localStorage.getItem('token')
  const accessToken = tokenStorage ? `Bearer ${tokenStorage}` : ''
  return accessToken
}

/*

const service = axios.create({
  withCredentials:  true

})

const config = {
  headers = {
    Authorization: `Bearer ${token}`
  }
}
axios.post(baseUrl, newObject, config)
.then(res => res.data)
*/

// const myInterceptor = axios.interceptors.response.use(function () {/*...*/});
// axios.interceptors.response.eject(myInterceptor);

axios.interceptors.response.use(res => {
  if (!res) throw Error('No se obtuvo respuesta, por favor verifique su conexión a internet.')
  return res
}, error => error.response)

const get = (url = '', headers = {}) => axios.get(readUrl(url), {
  mode: 'cors',
  credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
    Authorization: getToken(),
    'Content-Type': 'application/json',
    ...headers
  }
})

const post = (url = '', body = {}, headers = {}) => axios.post(readUrl(url), body, {
  mode: 'cors',
  credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
    Authorization: getToken(),
    'Content-Type': 'application/json',
    ...headers
  }
})

const put = (url = '', body = {}, headers = {}) => axios.put(readUrl(url), body, {
  mode: 'cors',
  credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
    Authorization: getToken(),
    'Content-Type': 'application/json',
    ...headers
  }
})

const patch = (url = '', body = {}, headers = {}) => axios.patch(readUrl(url), body, {
  mode: 'cors',
  credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
    Authorization: getToken(),
    'Content-Type': 'application/json',
    ...headers
  }
})

const del = (url = '', headers = {}) => axios.delete(readUrl(url), {
  mode: 'cors',
  credentials: 'same-origin',
  // credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
    Authorization: getToken(),
    'Content-Type': 'application/json',
    ...headers
  }
})

const fetchClient = {
  get,
  post,
  put,
  patch,
  delete: del
}

export default fetchClient
