import styled from 'styled-components'

export const Container = styled.div`  
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
`

export const Img = styled.img`  
	animation: latidos 4s infinite;
    width: 100px;
    
    @keyframes latidos {
        from { transform: none; }
        50% { transform: scale(2); }
        to { transform: none; }
    }
`
/* En la clase corazon vamos a llamar latidos en la animación  */
/* .corazon {
	display: inline-block;
	font-size: 150px;
	text-shadow: 0 0 10px #222,1px 1px  0 #450505;
	color: red;
	animation: latidos .5s infinite;
	transform-origin: center;
}

body { text-align: center;
  background: #333; height: 230px;
} */
