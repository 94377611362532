import { useState } from 'react'
import { navigate } from '@reach/router'
import moment from 'moment'

import Layout from '@components/admin/Layout'
import Loader from '@components/Loader'
import Button from '@components/Button'
import DateRangeSelector from '@components/DateRangeSelector'
import PieChartsFull from '@components/charts/PieChartsFull'
import TableJorCitas from '@components/TableJorCitas'
import Filters from '@components/Filters'
import { getDate, getDateUTC } from '@utils/date'
import config from '@config'

import http from '@utils/fetch-client'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { useAlert } from '@hooks/useAlert'

import { Container } from './styles'

const initialState = { citas: { atender:[], espera:[], atendidos:[] } }

const JornadasCitas = ({ id }) => {
  const [loading, setLoading] = useState(true)
  const [citas, setCitas] = useState({ atender:[], espera:[], atendidos:[] })
  const [horario, setHorario] = useState({})  
  const [Alert, setAlert] = useAlert({})
  const [AlertToday, setAlertToday] = useAlert({status:true, severity:'info', isClose:false, 
    msg:'<b>Estas Citas no son de hoy</b> no podrá realizar acciones de asistencias'  
  })

  console.log({initialState})
  const orderByDate = (a, b) =>
    (Date.parse(a.fecha_cita) < Date.parse(b.fecha_cita)) ? -1 : 1

    useEffectAbortable(({ aborted }) => { // Obtener Cita
    http.get(`api/jornadas/citas/${id}`)
      .then(({ status, data }) => {
        console.log('[JornadasCitas] citas', { status, data })
        if (aborted) return
        if (status !== 200 || !Object.keys(data).length) throw JSON.stringify({ status, data })
        let dataCitas = data.data.citas.reduce((acc, curr) => {
          if(curr['fe_atendido']) acc.atendidos.push(curr)
          else acc.atender.push(curr)
          // else if(curr['fe_asistio']) acc.espera.push(curr)
          return acc
        }, { atender:[], espera:[], atendidos:[] })

        setHorario(data.data.horario)
        setCitas(dataCitas)
      })
      .catch(err => {
        console.error('[JornadasCitas] citas: ', err)
        setAlert({ status: true, severity: 'error', msg: JSON.parse(err).data.error })
      })
      .finally(() => setLoading(false))
  }, [id])

  const handleCitaEnEspera = (co_jor_cita, co_usuario, co_jor_horario, username, paciente) => {
    console.log({co_jor_cita, co_usuario, username})
    setLoading(true)
    http.patch(`api/jornadas/citas/asistencia/${co_jor_cita}/${co_jor_horario}`)
    .then(({ status, data }) => {
      console.log('[JornadasCitas] citas', { status, data })
      if (status !== 200 || !Object.keys(data).length) throw JSON.stringify({ status, data })
      data = data.data.reduce((acc, curr) => {
        if(curr['fe_atendido']) acc.atendidos.push(curr)
        else if(curr['fe_asistio']) acc.espera.push(curr)
        else acc.atender.push(curr)

        return acc
      }, { atender:[], espera:[], atendidos:[] })

      setAlert({ status: true, severity: 'success', msg: `<b>${paciente}</b> ha asistido a la cita <b>${co_jor_cita}</b> exitosamente.` })
      setCitas( data)
    })
    .catch(err => {
      console.error('[JornadasCitas] citas: ', err)
      setAlert({ status: true, severity: 'error', msg: err })
    })
    .finally(() => setLoading(false))
    
  }
  
  const handleCitaAtendido = (co_jor_cita, co_usuario, co_jor_horario, username, paciente) => {
    console.log({co_jor_cita, co_usuario, co_jor_horario, username})
    setLoading(true)
    http.patch(`api/jornadas/citas/atendida/${co_jor_cita}/${co_jor_horario}`)
    .then(({ status, data }) => {
      console.log('[JornadasCitas] citas', { status, data })
      if (status !== 200 || !Object.keys(data).length) throw JSON.stringify({ status, data })
      let dataCitas = data.data.citas.reduce((acc, curr) => {
        if(curr['fe_atendido']) acc.atendidos.push(curr)
        else acc.atender.push(curr)
        // else if(curr['fe_asistio']) acc.espera.push(curr)
        return acc
      },  { atender:[], espera:[], atendidos:[] })

      setAlert({ status: true, severity: 'success', msg: `<b>${paciente}</b> ha sido atendida en la cita <b>${co_jor_cita}</b> exitosamente.` })
      setHorario(data.data.horario)
      setCitas(dataCitas)
    })
    .catch(err => {
      console.error('[JornadasCitas] citas: ', err)
      setAlert({ status: true, severity: 'error', msg: err })
    })
    .finally(() => setLoading(false))
  }

  const isToday = fecha => {
    if(!fecha) return null
    const [day, month, year] = fecha.split('/')
    var fi = moment().format('YYYY-MM-DD')
    var ff = moment(`${year}-${month}-${day}`)
    const diff = ff.diff(fi, 'days')
    return diff === 0 ?true :false
  } 

  const goToRequest = coHorario => () => {
    window.open(`${config.host}/api/jornadas/excel/citas/laboratorio/${coHorario}`)
  }

  return (
    <Layout
      active='emi'
      title='EMI Citas'
    >
      <Container>
        <Alert />
        { isToday(horario?.fecha_cita) === false && <AlertToday /> }

        {loading
          ? <Loader />
          : (
            <>
              {/* <PieChartsFull
                data={Object.entries(citas).reduce((acc, [key, value]) => {
                  if (!key || key === 'null') return acc
                  return acc.concat({
                    title: key,
                    value: value.length
                  })
                }, [])}
                styles={{ width: 300, height: 200, marginBottom: '1rem' }}
              />
              */}
              {/* <Filters
                nameFilter='citas'
                data={Object.values(citas).reduce((acc, curr) => acc.concat(curr), [])}
                handleSetData={() => { }}
                options={[
                  { label: 'Profesional', name: 'medico' },
                  { label: 'Paciente', name: 'paciente' },
                  { label: 'Encargados', name: 'encargado' },
                  { label: 'Códigos', name: 'co_cita_web' },
                  { label: 'Fechas Creado', name: 'fecha_creado_web', typeValue: 'dateUTC' },
                  { label: 'Fecha Citas', name: 'fecha_cita', typeValue: 'date' }
                ]}
              />
               */}
              {horario?.clinica &&
               <h1>{`${horario?.clinica} | ${horario?.dia}  ${horario?.fecha_cita}`}</h1>
              }

              <div>
                <Button
                  label='Descargar Excel'
                  color='secondary'
                  style={{ width: '25%' }}
                  onClick={goToRequest(id)}
                />
              </div>
               
              <TableJorCitas 
                key={`joratender${Date().toString()}`} 
                name={'atender'} 
                data={citas.atender} 
                handleClick={handleCitaAtendido} 
                action={'asistió'} 
                // disabled={!isToday(horario?.fecha_cita)}
              />

              {/* <TableJorCitas key={`joratender2${Date().toString()}`} name={'en espera'} data={citas.espera} handleClick={handleCitaAtendido} /> */}
              
              
              <TableJorCitas 
                key={`joratender3${Date().toString()}`} 
                name={'asistieron'} 
                data={citas.atendidos} 
                handleClick={() => {}} 
                action={null} 
              />


            </>)}
      </Container>
    </Layout>
  )
}

export default JornadasCitas
