import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Button from '@components/Button'
import Loader from '@components/Loader'
import InputText from '@components/inputs/Text'

import { useAlert } from '@hooks/useAlert'
import { useStateValue } from '@context/index.js'
import http from '@utils/fetch-client'

import { Container, ContainerBtn } from './styles'
import useStyles from './stylesUI'

const initialStatePassword = { current: '', change: '', verify: '', validChange: true }

const ChangePassword = () => {
  const [{ user }] = useStateValue()
  const classes = useStyles()
  const [password, setPassword] = useState(initialStatePassword)
  const [MyAlert, setInfoPassword] = useAlert({})
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(false)

  useEffect(() => { setPassword(initialStatePassword) }, [])

  const handlePasswordCurrent = target => {
    console.log('target', target.value)
    if (!target.value) {
      setInfoPassword({ status: true, severity: 'error', msg: 'Debe ingresar la <b>Contraseña actual</b>' })
    }
    setPassword({ ...password, [target.name]: target.value })
  }

  const handlePassword = target => {
    if (!target.value) {
      if (password.verify) setInfoPassword({ status: true, severity: 'error', msg: 'Debe ingresar la contraseña a verificar.' })
      else setInfoPassword({ status: false, severity: '', msg: '' })
    } else if (target.value) {
      if (!password.verify) {
        setInfoPassword({ status: true, severity: 'info', msg: 'Para completar el cambio debe confirmar la contraseña y esta debe tener un mínimo de 8 caracteres' })
      } else {
        if (target.value === password.verify) {
          setInfoPassword({ status: true, severity: 'info', msg: 'Las contraseñas coinciden, ya puede <b>"Guardar Cambios"</b>' })
        } else setInfoPassword({ status: true, severity: 'error', msg: 'Las contraseñas no coinciden' })
      }
    }
    setPassword({ ...password, [target.name]: target.value })
  }

  const handlePasswordVerify = target => {
    if (!target.value) {
      if (password.change) setInfoPassword({ status: true, severity: 'info', msg: 'Para completar el cambio debe repetir la contraseña nueva' })
      else setInfoPassword({ status: false, severity: '', msg: '' })
    } else if (target.value) {
      if (!password.change) {
        setInfoPassword({ status: true, severity: 'error', msg: 'Debe ingresar la contraseña a verificar.' })
      } else {
        if (target.value === password.change) {
          setInfoPassword({ status: true, severity: 'info', msg: 'Las contraseñas coinciden, ya puede <b>"Cambiar contraseña"</b>' })
        } else setInfoPassword({ status: true, severity: 'error', msg: 'Las contraseñas no coinciden' })
      }
    }
    setPassword({ ...password, [target.name]: target.value })
  }

  const submitPassword = () => {
    setLoading(true)
    const { current, change, verify } = password
    const data = {
      pUsername: user.username,
      pCurrentPassword: current,
      pNewPassword: change
    }
    console.log({ data })
    let errorHTML = ''
    if (change.length < 8) errorHTML += '<li> La contraseña nueva debe tener un <b>mínimo de 8 caracteres</b>.</li>'
    if (!current) errorHTML += '<li> Debe ingresar la <b>contraseña actual</b>.</li>'
    if (!change) errorHTML += '<li> Debe ingresar la <b>contraseña nueva</b>.</li>'
    if (!verify) errorHTML += '<li> Debe ingresar la <b>verificación</b> de la contraseña nueva .</li>'
    if (change !== verify) errorHTML += '<li> La contraseña de <b>verificación</b> no conincide con la <b>contraseña nueva</b>.</li>'
    if (errorHTML) {
      setLoading(false)
      return setInfoPassword({ status: true, severity: 'error', msg: errorHTML })
    }

    http.patch('api/usuario/cambiarContrasena', data)
      .then(({ status, data }) => {
        console.log('status', status)
        console.log('data', data)
        if (status !== 200 || !data[0].valido) throw data[0].respuesta
        setInfoPassword({ status: true, severity: 'success', msg: 'Su contraseña ha sido actualizada <b>Exitosamente</b>.' })
        setPassword(initialStatePassword)
      })
      .catch(err => {
        console.error(`Err submitPassword: ${err}`)
        setInfoPassword({ status: true, severity: 'error', msg: `Disculpe, no se logró actualizar su contraseña: <b>${err}</b>` })
      }).finally(() => setLoading(false))
  }

  const handleShowForm = () => {
    setShowForm(true)
  }

  const handleHideForm = () => {
    setShowForm(false)
    setPassword(initialStatePassword)
  }

  if (!showForm) {
    return (
      <ContainerBtn>
        <Button
          label='Cambiar Contraseña'
          onClick={handleShowForm}
          color='primary'
          disabled={loading}
        />
      </ContainerBtn>
    )
  }

  return (
    <Container>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} md={4}>
          <InputText
            id='current'
            type='password'
            label='Contraseña actual'
            name='current'
            handleBlur={handlePasswordCurrent}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputText
            id='change'
            type='password'
            label='Contraseña nueva'
            name='change'
            handleBlur={handlePassword}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputText
            id='verify'
            type='password'
            label='Verificar contraseña nueva'
            name='verify'
            handleBlur={handlePasswordVerify}
          />
        </Grid>

        {/* <InputPassword
          value={password.verify}
          label='Repetir contraseña'
          name='verify'
        /> */}
      </Grid>

      <div> {loading ? <Loader /> : <MyAlert />} </div>

      <ContainerBtn>
        <Button
          label='Cancelar Cambio'
          onClick={handleHideForm}
          color='secondary'
          disabled={loading}
        />

        <Button
          label='Cambiar Contraseña'
          onClick={submitPassword}
          color='primary'
          disabled={loading}
        />
      </ContainerBtn>
    </Container>
  )
}

export default ChangePassword
