import React, { useState, useRef, useEffect } from 'react'

import Loader from '@components/Loader'
import Layout from '@components/Layout'
import Beneficiaries from '@components/Beneficiaries'
import ChangePassword from '@components/portal/ChangePassword'
import ChangeDataUser from '@components/portal/ChangeDataUser'

import { useAlert } from '@hooks/useAlert'
import { useStateValue } from '@context/index.js'
import config from '@utils/config'
import http from '@utils/fetch-client'

import {
  ContainerPerfil, Container, ContainerImg, ContainerData, Fullname, DataUser, Title,
  PreviewImg, LabelFile, File
} from './styles'

const initialStateInfo = {
  email: '',
  phone: '',
  phoneHab: '',
  fotoPerfil: '',
  file: '',
  valid: false
}

const Profile = () => {
  const [{ user, beneficiaries }, dispatch] = useStateValue()
  const [info, setInfo] = useState(initialStateInfo)
  const [loading, setLoading] = useState({ pic: false, info: false })
  const [MyAlertPic, setInfoPic] = useAlert({})
  const fotoPerfilRef = useRef(null)
  const isBeneficiary = user.co_tipo === 1
  const haveBeneficiaries = beneficiaries ? beneficiaries.length > 0 : false
  // console.log({ user, info, beneficiaries })

  useEffect(() => {
    setInfo({
      fotoPerfil: user.foto_perfil ? user.foto_perfil.trim() : '',
      file: user.file || '',
      valid: false
    })
  }, [user])

  const renderImage = ({ target }) => {
    setLoading({ ...loading, pic: true })
    const file = target.files[0]
    fotoPerfilRef.current.disabled = true

    const data = new FormData()
    data.append('foto_perfil', file)
    http.patch('api/usuario/subirFotoPerfil', data)
      .then(({ status, data }) => {
        if (status !== 200) throw Error('Disculpe, no se logró actualizar su foto de perfil')
        setLoading({ ...loading, pic: false })
        setInfoPic({ status: true, severity: 'success', msg: 'Su foto de perfil <b>ha sido actualizada exitosamente</b>.' })
        setInfo({ ...info, fotoPerfil: data.data.dir, file: URL.createObjectURL(file) })
        dispatch({
          type: 'user',
          payload: {
            ...user,
            fotoPerfil: data.data.dir,
            file: URL.createObjectURL(file)
          }
        })
        fotoPerfilRef.current.disabled = false
      })
      .catch(err => {
        setLoading({ ...loading, pic: false })
        setInfoPic({ status: true, severity: 'error', msg: err })
      })
  }

  return (
    <Layout title='Perfil' active='profile'>
      <>
        <ContainerPerfil>
          {loading.pic ? <Loader /> : <MyAlertPic />}

          <Container>
            <ContainerImg>
              <LabelFile htmlFor='perfil'>
                <PreviewImg
                  id='imgperfil'
                  src={info.file
                    ? info.file
                    : info.fotoPerfil ? `${config.statics.users}/${info.fotoPerfil}` : '/perfil-default.png'}
                />
              </LabelFile>

              <File
                type='file'
                id='perfil'
                name='perfil'
                accept='image/*'
                ref={fotoPerfilRef}
                onChange={renderImage}
              />
            </ContainerImg>

            <ContainerData>
              {isBeneficiary
                ? (
                  <>
                    <Fullname>
                      <b>{user.nb_benefi?.trim().toUpperCase()}</b> {user.nb_apellid?.trim()}
                    </Fullname>
                    <DataUser>
                      {user.co_tipo_ben === 1 ? 'Titular' : 'Dependiente'}   -
                      {(user.in_nacional + parseInt(user.nu_ced_ben)) || 'Sin Cedula'}
                    </DataUser>
                  </>)

                : (
                  <>
                    <Fullname> <b>{user.username}</b> </Fullname>
                    <DataUser> {user.tx_tipo} </DataUser>
                  </>)}

              {isBeneficiary && (<ChangeDataUser />)}

              <ChangePassword />

            </ContainerData>
          </Container>
        </ContainerPerfil>

        {(isBeneficiary && haveBeneficiaries) &&
          <>
            <Title>Beneficiarios: </Title>
            <Beneficiaries beneficiaries={beneficiaries} />
          </>}

      </>
    </Layout>
  )
}

export default Profile
