export const proccessRequest = {
  selected: 0,
  data: [
    {
      id: 0,
      required: true,
      type: 'text',
      title: 'Sede',
      name: 'sede',
      formats: '',
      description: 'Seleccione una sede'
    },
    {
      id: 2,
      required: true,
      type: '',
      title: 'Especialidad',
      name: 'especialidad',
      formats: '',
      description: 'A continucaión se le mostrará una lista de doctores con sus horarios disponibles.'
    },
    {
      id: 3,
      required: true,
      type: '',
      title: 'Fecha y Doctor',
      name: 'fecha-doctor',
      formats: '',
      description: 'En el siguiente espacio deberá ingresar la orden médica.'
    },
    {
      id: 4,
      required: true,
      type: '',
      title: 'Tipo de Solicitud',
      name: 'tipo',
      formats: '',
      description: 'Debe seleccionar un tipo.'
    },
    {
      id: 5,
      required: true,
      type: '',
      title: 'Resumen de Cita',
      name: 'cita',
      formats: '',
      description: 'Verifique los datos de su cita para proceder a pagarla antes de que culmine el tiempo de reserva.'
    },
    {
      id: 6,
      required: true,
      type: '',
      title: 'Métodos de Pago',
      name: 'pago',
      formats: '',
      description: 'Debe realizar su pago escogiendo un método.'
    }
  ]
}

export const typesRequest = [
  {
    co_type: 0,
    name: 'Primera consulta',
    description: 'Descripción'
  },
  {
    co_type: 1,
    name: 'Consulta suceiva',
    description: 'Descripción'
  },
  {
    co_type: 2,
    name: 'Evaluacion medica para gimnasio',
    description: 'Descripción'
  },
  {
    co_type: 3,
    name: 'Entrega de resultados (informe médico)',
    description: 'Descripción'
  }
]

export const requestIntro = () => (
  <>
    <h2>Instrucciones</h2>
    {/* <h4>Estimado usuario una vez finalizado el proceso de solicitud de cita favor subir la imagen con la
      constancia del pago realizado. Observaciones:
    </h4> */}

    <ul>
      <li> Para realizarse estudios  tales como: Mamografía, Densitometría ósea, rayos X e Imageneología, deberá consignar la orden médica.</li>
      <li> Para la consulta pre-operatoria odontológica, debe consignar los estudios requeridos para la misma (Placa del Tórax y Exámenes de Laboratorio)
      </li>

    </ul>
  </>
)
/* <h4>Estimado usuario a continuación para el proceso de solicitud de cita al final tendrá que subir un archivo del
    Instrumento de pago o la información de la transacción para llenar un formualrio, entonces se le recomienda
    estar atento para la realización del pago ya que hay un tiempo limitado para reservar la cita que seleccione.
    Algunas observaciones:
  </h4> */

export const cedulaTypes = [
  {opcionID:'V', opcionDes:'V', type: 'text'},
  {opcionID:'E', opcionDes:'E', type: 'text'},
  {opcionID:'C', opcionDes:'C', type: 'text'},
  {opcionID:'P', opcionDes:'P', type: 'text'},
]

export const tddTypes = [
  {opcionID:'CC', opcionDes:'Corriente', type: 'text'},
  {opcionID:'CA', opcionDes:'Ahorro', type: 'text'},
]

export const getExpAno = () => {
  let years = []
  let year = new Date().getFullYear()
  let yearMin = year - 15
  let yearMax = year + 30
  for(let i=yearMin; i<=yearMax; i++){ 
    years.push({opcionID:i, opcionDes:i, type: 'number'}) }
  return years
}

export const expMes = [
  {opcionID:'01', opcionDes:'01 Enero', type: 'text'},
  {opcionID:'02', opcionDes:'02 Febrero', type: 'text'},
  {opcionID:'03', opcionDes:'03 Marzo', type: 'text'},
  {opcionID:'04', opcionDes:'04 Abril', type: 'text'},
  {opcionID:'05', opcionDes:'05 Mayo', type: 'text'},
  {opcionID:'06', opcionDes:'06 Junio', type: 'text'},
  {opcionID:'07', opcionDes:'07 Julio', type: 'text'},
  {opcionID:'08', opcionDes:'08 Agosto', type: 'text'},
  {opcionID:'09', opcionDes:'09 Septiembre', type: 'text'},
  {opcionID:'10', opcionDes:'10 Octubre', type: 'text'},
  {opcionID:'11', opcionDes:'11 Noviembre', type: 'text'},
  {opcionID:'12', opcionDes:'12 Diciembre', type: 'text'},
]

const otpGuide = `No hay información de cómo obtener la clave de pago del banco seleccionado. Pregunte a su banco cómo obtener la clave de compra C2P o investigue en la web. `

export const banksId = [
  {
    opcionID:102, 
    idIpp:'D9210663-09E5-47D8-A64A-F971AC2F4BB4', 
    opcionDes:'BANCO VENEZUELA', 
    type: 'text',
    otpGuide: ''
  }, 
  {
    opcionID:104, 
    idIpp:'FF76AFB9-456B-4DBA-A908-54A9FED6EA3D', 
    opcionDes:'BANCO VENEZOLANO DE CRÉDITO', 
    type: 'text',
    otpGuide: ''
  }, 
  {
    opcionID:105, 
    idIpp:'BC5A972E-02E6-46EA-B68F-993A55E5FE38', 
    opcionDes:'BANCO MERCANTIL', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:108, 
    idIpp:'208D4B53-3953-4642-8F71-467CCD5A6580', 
    opcionDes:'BANCO PROVINCIAL', 
    type: 'number',
    otpGuide: "Expliación nueva"
  }, 
  {
    opcionID:114, 
    idIpp:'A831753A-B077-42F1-AF33-29B6255DC778', 
    opcionDes:'BANCARIBE', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:115, 
    idIpp:'76058210-2A8D-4902-A5E7-A3AD79A05F2D', 
    opcionDes:'BANCO EXTERIOR', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:116, 
    idIpp:'48224A90-EB2C-48D2-A107-C0D7AFABC18F', 
    opcionDes:'BANCO OCCIDENTAL DE DESCUENTO', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:128, 
    idIpp:'36327E87-2E4E-4F0C-A22A-196BD1F9B232', 
    opcionDes:'BANCO CARONÍ', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:134, 
    idIpp:'267FEBE8-B990-4C23-B59F-48A7A50DFD33', 
    opcionDes:'BANCO BANESCO', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:137, 
    idIpp:'31866A37-18F7-46CB-9C64-1B6C53A9BC38', 
    opcionDes:'BANCO SOFITASA', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:138, 
    idIpp:'4E8743CB-E91B-4908-A061-82F48402A1E6', 
    opcionDes:'BANCO PLAZA', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:151, 
    idIpp:'4139BCDC-D1A9-4615-B2E6-F9903214D0BA', 
    opcionDes:'BANCO FONDO COMUN', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:156, 
    idIpp:'07E273AA-FD2B-4D1C-A515-FC746BB12CC9', 
    opcionDes:'100% BANCO', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:157, 
    idIpp:'22F0EF8E-468E-41DA-A2E8-9BF0DC67E2F3', 
    opcionDes:'BANCO DEL SUR', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:163, 
    idIpp:'EA88D808-A6D9-46A8-9492-14D797E5366D', 
    opcionDes:'BANCO DEL TESORO', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:166, 
    idIpp:'5A45F3DB-C6F0-4847-9877-5B7053D72592', 
    opcionDes:'BANCO AGRICOLA DE VENEZUELA', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:168, 
    idIpp:'', 
    opcionDes:'BANCRECER', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:169, 
    idIpp:'', 
    opcionDes:'MIBANCO BANCO DE DESARROLLO', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:171, 
    idIpp:'2D3B4E9C-6A64-4DCA-AB58-228D5BC3B1D7', 
    opcionDes:'BANCO ACTIVO', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:172, 
    idIpp:'163C5EA2-1C49-E811-8010-000C29E6BD0F', 
    opcionDes:'BANCAMIGA', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:174, 
    idIpp:'E6550A2F-3638-4363-8D60-B309F271B077', 
    opcionDes:'BANPLUS', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:175, 
    idIpp:'A25B5E61-B124-E011-AEB7-000C29D10668', 
    opcionDes:'BANCO BICENTENARIO', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:177, 
    idIpp:'ED714277-AC11-E411-B725-001EC932AA56', 
    opcionDes:'BCO FUERZA ARMADA NAC. BOL', 
    type: 'number',
    otpGuide: ''
  }, 
  {
    opcionID:191, 
    idIpp:'4B42D711-2B43-456D-A0FD-90901409D96F', // hay dos 
    opcionDes:'BANCO NAC. DE CRËDITO',  
    type: 'number',
    otpGuide: `No hay información de cómbtener la clave de pago del banco seleccionado. Pregunte a su banco cómo obtener la clave de compra C2P o investigue en la web.`
  }, 
]

export const searchBank = id => banksId.find(({opcionID}) => opcionID === parseInt(id))
