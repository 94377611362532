import React from 'react'
import { Link } from '@reach/router'
import StatusRequest from '@components/StatusRequest'

import { getDateUTC } from '@utils/date'
import { Container, Name, Date, Status } from './styles'

const CartaAval = ({ carta, header }) => {
  const { co_solicitud, concepto, fecha_creado, fecha_editado, co_status_web } = carta
  // console.log('carta', carta)
  /*
  const getDate = value => {
    const regex = /\d{4}-\d{2}-\d{2}/
    return value.match(regex)[0]
  }
 */
  const setLink = (status, solicitud) => {
    return status === 1
      ? `/portal/samhoi/cartasAval/solicitud/${solicitud}`
      : `/portal/samhoi/cartasAval/${solicitud}`
  }

  return (
    <>
      {header
        ? <Container header={header}>
          <Name header={header}> {concepto} </Name>
          <Date header={header}> {fecha_creado} </Date>
          <Date header={header}> {fecha_editado} </Date>
          <Status header={header} status={-1}> {co_status_web} </Status>
          </Container>

        : <Link to={setLink(co_status_web, co_solicitud)}>
          <Container>
            <Name> {concepto} </Name>
            <Date> {getDateUTC(fecha_creado)} </Date>
            <Date> {getDateUTC(fecha_editado)} </Date>
            <Status> <StatusRequest status={co_status_web} /> </Status>
          </Container>
          </Link>}
    </>
  )
}

export default CartaAval
