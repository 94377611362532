import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import themeApp from '@context/theme'

const button = {
  success: styled(Button)({
    margin: '5px',
    // width: '100%',
    minWidth: '100px',
    color: themeApp.color.white,
    backgroundColor: themeApp.color.success,
    borderColor: themeApp.color.success,
    '&:hover': {
      backgroundColor: themeApp.color.successHover,
      borderColor: themeApp.color.successHover
    }
  }),
  primary: styled(Button)({
    margin: '5px',
    // width: '100%',
    minWidth: '100px',
    color: themeApp.color.white,
    backgroundColor: themeApp.color.primary,
    borderColor: themeApp.color.primary,
    '&:hover': {
      backgroundColor: themeApp.color.primaryHover,
      borderColor: themeApp.color.primaryHover
    }
  }),
  secondary: styled(Button)({
    margin: '5px',
    // width: '100%',
    minWidth: '100px',
    color: themeApp.color.white,
    backgroundColor: themeApp.color.gray,
    borderColor: themeApp.color.gray,
    '&:hover': {
      backgroundColor: themeApp.color.ligthGray,
      borderColor: themeApp.color.ligthGray
    }
  }),
  danger: styled(Button)({
    margin: '5px',
    // width: '100%',
    minWidth: '100px',
    color: themeApp.color.white,
    backgroundColor: themeApp.color.danger,
    borderColor: themeApp.color.danger,
    '&:hover': {
      backgroundColor: themeApp.color.dangerHover,
      borderColor: themeApp.color.dangerHover
    }
  }),
  ipp: styled(Button)({
    margin: '5px',
    // width: '100%',
    minWidth: '100px',
    color: themeApp.color.white,
    backgroundColor: themeApp.color.bluePrimary,
    borderColor: themeApp.color.bluePrimary,
    '&:hover': {
      backgroundColor: themeApp.color.bluePrimaryHover,
      borderColor: themeApp.color.bluePrimaryHover
    }
  })
}

const ButtonC = ({ type, label, icon, variant = 'contained', color, style, disabled, onClick }) => {
  const BootstrapButton = button[color]

  return (
    <BootstrapButton
      variant={variant}
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      {icon}
      {label}
    </BootstrapButton>
  )
}

export default ButtonC
