import React, { useState, useEffect } from 'react'

import Cita from '@components/portal/Cita'
import Loader from '@components/Loader'
import http from '@utils/fetch-client'
import { useAlert } from '@hooks/useAlert'
import { usePagination } from '@hooks/usePagination'

import { Container, Row, Empty } from './styles'

const CitasList = () => {
  // const [{ citas }] = useStateValue()
  const [citas, setCitas] = useState([])
  const [loading, setLoading] = useState(true)
  const [Alert, setAlert] = useAlert({})
  const [Pagination, idx] = usePagination(citas.length)

  const getDate = (fecha, fe) => {
    return fecha ? Date.parse(fecha)
      : fe ? Date.parse(fe) : 0
  }

  const orderByDate = (a, b) => {
    const fechaA = getDate(a.fecha_cita, a.fe_cita)
    const fechaB = getDate(b.fecha_cita, b.fe_cita)
    const fechaEditA = getDate(a.fecha_editado)
    const fechaEditB = getDate(b.fecha_editado)

    return (fechaA === 0 && fechaB === 0)
      ? ((fechaEditA > fechaEditB) ? -1 : 1)
      : (fechaA === 0) ? -1
          : (fechaB === 0) ? 1
              : (fechaA > fechaB) ? -1 : 1
  }

  useEffect(() => {
    http.get('api/cita/getAll')
      .then(({ status, data }) => {
        if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')
        console.log('citas', data)
        // data.enSam = data.enSam.map(cita => ({...cita, status: 'En SAM'}))
        // console.log('citas', data.enSam)
        const citas = data.sort(orderByDate)
        console.log('citas', citas)
        setCitas(citas)
      })
      .catch(err => {
        console.error(`[CitasList] api/cita: ${err}`)
      })
      .finally(() => setLoading(false))
  }, [])

  const handleDelete = co_cita_web => e => {
    e.stopPropagation()
    setLoading(true)
    console.log('handleDelete', { co_cita_web })

    http.delete(`api/cita/${co_cita_web}`)
      .then(({ status, data }) => {
        if (status !== 200) throw Error('Disculpe, no se logró eliminar la cita. Intente nuevamente o comuníquese con nuestros personal.')
        const newCitas = citas.filter(c => c.co_cita_web !== co_cita_web)
        setAlert({ status: true, severity: 'success', msg: `La solicitud de cita <b>${co_cita_web}</b> se ha eliminado exitosamente.` })
        setCitas(newCitas)
      })
      .catch(err => {
        console.error(`[CitasList] api/cita: ${err}`)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Container>
      <Alert />
      {loading ? <Loader />
        : !citas.length ? <Empty>Usted no tiene Citas</Empty>
            : <>
              <Cita
                header
                cita={{
                  service: { name: 'Servicio' },
                  profesional: { name: 'Profesional' },
                  date: 'Fecha',
                  status: 'Estatus'
                }}
              />

              <Row>
                {citas.slice(idx.start, idx.end).map(cita => (
                  <Cita
                    key={cita.co_cita_web}
                    cita={cita}
                    handleDelete={handleDelete(cita.co_cita_web)}
                    portal
                  />
                ))}
              </Row>

              <Pagination />
            </>}
    </Container>
  )
}

export default CitasList
