import { useEffect } from 'react'
import { useAlert } from '@hooks/useAlert'

import Layout from '@components/Layout'
import LayoutGuide from '@components/portal/guide/LayoutGuide'
import Player from '@components/Player'
// import PhotoFrameDocument from '@components/PhotoFrameDocument'

import { videos } from '@utils/static/guide'
// import config from '@utils/config'
import { useStateValue } from '@context'

const CitasGuide = ({ location }) => {
  const [{ user }] = useStateValue()
  const [AlertMaintenance] = useAlert({
    status: true, severity: 'info', isClose: false,
    msg: `<b> Módulo en mantenimiento: </b> Disculpe, se está realizando pruebas de seguridad para mantener 
    sus datos seguros al momento de pagar la cita, en un par de días estará disponible.`})


  useEffect(() => {
    window.location.hash = ''
    window.location.hash = location.hash
  }, [location.key, location.hash])

  const getOptionMenuOfPath = () => {
    const pathname = location.pathname.split('/')
    return `${pathname[pathname.length - 1]}${location.hash}`
  }

  const isBeneficiarie = user.co_tipo === 1

  /* return(
    <Layout title='Citas | Manual de Usuario' active='guia'>
      <LayoutGuide optionMenu={getOptionMenuOfPath()}>
        <div id='general' />
        <h1>CITAS</h1>
        <AlertMaintenance />
      </LayoutGuide>
    </Layout>
  ) */

  return (
    <Layout title='Citas | Manual de Usuario' active='guia'>
      <LayoutGuide optionMenu={getOptionMenuOfPath()}>
        <div id='general' />
        <h1>CITAS</h1>
        {/*
        <section id='listado'>
          <h2> LISTADO </h2>

        </section>
      */}
        <section id='solicitud'>
          <h2>SOLICITUD</h2>
          <Player video={videos.citas.solicitud} />
          <Player video={videos.citas.solicitudRevisada} />
        </section>

        {isBeneficiarie
          ? null
          : (
            <section id='revision'>
              <Player video={videos.citas.solicitudSimultanea} />
              <h2>REVISIÓN</h2>
              <Player video={videos.citas.revision} />
            </section>)}

      </LayoutGuide>
    </Layout>
  )
}

export default CitasGuide
