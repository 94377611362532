export const proccessRequest = {
  selected: 1,
  data: [
    {
      id: 1,
      required: true,
      type: 'text',
      title: 'Concepto',
      name: 'concepto',
      formats: 'JPG, JPEG, PNG, PDF',
      description: 'En el siguiente espacio deberá indicar una breve descripción sobre su solicitud de carta aval.'
    },
    {
      id: 2,
      required: true,
      type: 'file',
      title: 'Informe Médico',
      name: 'informe-medico',
      formats: 'JPG, JPEG, PNG, PDF',
      description: 'En el siguiente espacio deberá ingresar el Informe médico, ampliado y detallado, con fecha actualizada, no mayor a 5 días hábiles, con la firma y el sello húmedo del médico tratante.'
    },
    /* {
      id: 3,
      type: 'file',
      required: false,
      title: 'Informe de resultados',
      name: 'informe-resultados',
      formats: 'JPG, JPEG, PNG, PDF',
      description: 'En el siguiente espacio deberá ingresar Copia de los informes de los resultados de los exámenes complementarios que soporten el diagnóstico, si aplica.'
    }, */
    {
      id: 4,
      required: true,
      type: 'file',
      title: 'Presupuesto',
      name: 'presupuesto',
      formats: 'JPG, JPEG, PNG, PDF',
      description: 'En el siguiente espacio deberá ingresar el Presupuesto desglosado de la clínica afiliada, incluyendo honorarios médicos.'
    },
    {
      id: 5,
      required: true,
      type: 'file',
      title: 'Cédula de Identidad',
      name: 'cedula',
      formats: 'JPG, JPEG, PNG, PDF',
      description: 'En el siguiente espacio deberá ingresar la cédula de identidad del solicitante.'
    }
  ]
}

/* export const requestIntro = () => (<>
    <h2> Instrucciones </h2>

    <h4>
        El ingreso de los casos electivos se hará siempre por carta aval, bien sea para cirugía
        ambulatoria o procedimiento médico quirúrgico. Para su emisión el afiliado deberá consignar
        los siguientes recaudos:
    </h4>

    <ul>
        <li>
            Informe médico, ampliado y detallado, con fecha actualizada, no mayor a 5 días hábiles,
            con la firma y el sello húmedo del médico tratante.
        </li>

        <li> Copia de los informes de los resultados de los exámenes complementarios que soporten el diagnóstico. </li>

        <li> Presupuesto desglosado de la clínica afiliada, incluyendo honorarios médicos. </li>

        <li> Cédula de identidad del titular o del familiar. </li>
    </ul>
</>) */

export const requestIntro = () => (<>
  <h2> Instrucciones </h2>

  <h4>
    El ingreso de los casos electivos se hará siempre por carta aval, bien sea para cirugía ambulatoria, procedimiento médico quirúrgico y estudios. Para su emisión el afiliado deberá consignar los siguientes recaudos:
  </h4>

  <ul>
    <li>
      Informe médico, ampliado y detallado, con fecha actualizada, no mayor a 30 días hábiles, con la firma y el sello húmedo del médico tratante.
    </li>

    <li>
      Copia de los informes de los resultados de los exámenes complementarios que soporten el diagnóstico.
    </li>

    <li>
      Presupuesto desglosado de la clínica afiliada, incluyendo honorarios médicos.
    </li>

    <li> Cédula de identidad del titular o del familiar.</li>
  </ul>
</>)
