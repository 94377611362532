// import StatusRequest from '@components/StatusRequest'
import { useState, useRef } from 'react'
import { Link } from '@reach/router'

import Filters from '@components/Filters'
import Tooltip from '@mui/material/Tooltip'
import { usePagination } from '@hooks/usePagination'
import { getDate } from '@utils/date'
import { Container, Rows, Body, Item, Codigo, Title, CreatedAt, UpdatedAt } from './styles'

const TableCartas = ({ name, data }) => {
  const [Pagination, idx] = usePagination(data.length, 4)
  const [myData, setMyData] = useState({ data, search: data })
  const bodyRef = useRef(null)

  const handleToogle = () => {
    // console.log('handleToogle')
    const display = bodyRef.current.style.display
    bodyRef.current.style.display = ['none', ''].includes(display) ? 'block' : 'none'
  }

  return (
    <Container>
      <section>
        <Tooltip
          title={`Mostrar/Ocultar Cartas (${name})`}
          aria-label={`Mostrar/Ocultar Cartas (${name})`}
        >
          <h1 onClick={handleToogle}>
            Cartas ({name}) ({myData.search.length} de {data.length})
          </h1>
        </Tooltip>

        {!data.length
          ? null
          : <Filters
            nameFilter='cartasAval'
            data={data}
            handleSetData={setMyData}
            options={[
              { label: 'Encargados', name: 'encargado' },
              { label: 'Códigos', name: 'co_solicitud' },
              { label: 'Fechas', name: 'fecha_creado', typeValue: 'dateUTC' }
            ]}
            visible={false}
          />}

        <Body ref={bodyRef}>
          {!myData.search.length ? <h3>NO HAY CARTAS AVAL</h3>
            : <>
              <Item header>
                <Codigo>Código</Codigo>
                <Title>Titulo</Title>
                <CreatedAt>Fecha de Creación</CreatedAt>
                <UpdatedAt>Encargado</UpdatedAt>
                <UpdatedAt>Última Actualización</UpdatedAt>
              </Item>

              <Rows>
                {myData.search.slice(idx.start, idx.end).map((
                  { co_solicitud, concepto, encargado, fecha_creado, fecha_editado }
                ) => (
                  <Link to={`/admin/cartasAval/${co_solicitud}`} key={co_solicitud}>
                    <Item>
                      <Codigo>{co_solicitud}</Codigo>
                      <Title>{concepto}</Title>
                      <CreatedAt>{getDate(fecha_creado)}</CreatedAt>
                      <UpdatedAt>{encargado === -1 ? 'N/D' : encargado || 'N/D'}</UpdatedAt>
                      <UpdatedAt>{getDate(fecha_editado)}</UpdatedAt>
                    </Item>
                  </Link>
                ))}
              </Rows>

              <Pagination />
            </>}
        </Body>
      </section>

    </Container>

  )
}

export default TableCartas
