import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(min-width: 1100px){
        flex-direction: row;
    }
`

export const ContainerEditor = styled.div`
    width: 80%;
    margin: 0 auto 40px;
`

export const ContainerGuardar = styled.div`
    display: flex;
    margin: 10px;
    justify-content: flex-end;
`

export const ContainerPublished = styled.div`
    display: flex;
    margin: 10px;
    justify-content: center;
`

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    
    @media (min-width: 920px) {
        justify-content: space-around;
        flex-direction: row;
    }
`

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 920px) {
        flex-direction: row;
    }
`
export const Dates = styled.div`
    display: flex;
    justify-content: space-around;
    color: ${({ theme }) => theme.color.gray};
    font-weight: 500;
    width: 80%;
    margin: 0 auto 40px;
`

export const Estatus = styled.div`
    font-weight: bold;
    color: ${({ published, theme }) => published ? theme.color.success : theme.color.danger}
`

export const LabelFile = styled.label`
    margin: 10px auto;
    cursor: pointer;
`

export const File = styled.input`
    width: 0px;
    height: 0px;
`

export const PreviewImg = styled.img`
    width: 400px;
    height: 250px;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 5px 10px #666;
`

export const ContainerMyEditor = styled.div`
    width: 550px;
    margin: 20px;
`

export const ContainerModal = styled.div`
    width: calc(100% - 64px);
    top: calc(30%);

    @media(min-width: 520px){
        width: 300px;
        left: calc(50% - 150px);
    }
`

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0 0 0;
`
