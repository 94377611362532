import React from 'react'
import { Link } from '@reach/router'

import logo from './solologo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FooterStyled, Container, Img, Name, Redes } from './styles'

const Footer = ({ noOptions }) => {
  return (
    <FooterStyled>
      <Container>
        {noOptions
          ? <Img src={logo} alt='logo del IPP' />
          : (
            <Link to='/home'>
              <Img src={logo} alt='logo del IPP' />
            </Link>)}

        <Name>Instituto de Previsión del Profesorado de la UCV</Name>

        <Redes>
          {noOptions
            ? null
            : (
              <>
                <FontAwesomeIcon icon={faFacebookF} />
                <FontAwesomeIcon icon={faTwitter} />
                <FontAwesomeIcon icon={faInstagram} />
              </>)}
        </Redes>
      </Container>
    </FooterStyled>
  )
}

export default React.memo(Footer)
