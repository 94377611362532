import React from 'react'
import { Container } from './styles'

const InfoIsDev = ({ }) => {
  return (
      <Container>
        <p> Ambiente de Desarrollo</p>
      </Container>
  )
}

export default InfoIsDev
