import React, { useEffect } from 'react'
// import useObserver from '../../hooks/useObserver'
import { Paper } from '@mui/material'

import Carousel from 'react-material-ui-carousel'
import Layout from '@components/Layout'
import LoginHome from '@components/LoginHome'
import AboutUs from '@components/AboutUs'
import ServicesList from '@components/ServicesList'
// import ListOfClinics from '@components/ListOfClinics'
import Contacts from '@components/Contacts'
import Managers from '@components/Managers'
import Player from '@components/Player'
import { videos } from '@utils/static/guide'

import { useStateValue } from '@context'
import { aboutUs, services, objetivoGPS } from '@mocks/home'
import config from '@utils/config'

import {
  ImgContainer, Organization, Separador, Title,
  Services, ServiceContent, ServiceContentImg, ContainerAbout,
  SectionAbout, ContainerContact, Objective
} from './styles'

const HOST = config.statics.ipp

const Home = ({ location }) => {
  const [{ user }] = useStateValue()

  const items = [
    {
      id: 1,
      small: `${HOST}/entrada.jpg`,
      large: `${HOST}/ipp.jpg`
    },
    {
      id: 2,
      small: `${HOST}/entrada.jpg`,
      large: `${HOST}/entrada-sur-ipp.jpg`
    },
    {
      id: 2,
      small: `${HOST}/entrada.jpg`,
      large: `${HOST}/entrada-norte-ipp.jpg`
    },
    {
      id: 2,
      small: `${HOST}/entrada.jpg`,
      large: `${HOST}/api/static/semo.jpg`
    }
  ]

  useEffect(() => {
    const hash = location.hash
    window.location.hash = ''
    window.location.hash = hash
  }, [location.key, location.hash])

  return (
    <Layout
      title='Instituto de Previsión del Profesorado de la Universidad Central de Venezuela'
      subtitle='Pagina Web para la gestión de usuario en el Instituto de Previsión del Profesorado (IPP), para gestionar sus citas y solicitudes'
      active='home'
      goTop
    >
      {/* <ImgContainer img={{
            small: "https://univnoticias.com/wp-content/uploads/2020/03/Apucv-IPP-3.jpg",
            large: "http://190.202.23.156:5000/api/static/ipp.jpg"
        }}
      > */}
      <Carousel>
        {items.map(({ id, small, large }, i) =>
          <Paper key={id}>
            <ImgContainer img={{
              small: small,
              large: large
            }}
            />
          </Paper>
        )}
      </Carousel>

      {/* Login */}
      <LoginHome />

      {/* About */}
      <SectionAbout user={user} id='organization'>
        <ContainerAbout dangerouslySetInnerHTML={{ __html: aboutUs }} />
      </SectionAbout>

      <Title>¿Cómo Ingresar al Portal?</Title>
      <Player video={videos.general.login} />

      {/* Managers */}
      <Separador />
      <Title>Consejo de Directores del IPP</Title>
      <Managers />

      {/* Objetives and values */}
      <Separador />
      <Title>Gerencia de Protección Social</Title>
      <Objective>
        <h3>Objetivo</h3>
        <div dangerouslySetInnerHTML={{ __html: objetivoGPS }} />
      </Objective>
      <Organization>
        <span id='servicios' /> {/* Para la navegación */}
        <AboutUs />
      </Organization>

      {/* Services */}
      <Separador />
      <Services id='services'>
        <Title>Servicios</Title>

        <ServiceContent>
          <div dangerouslySetInnerHTML={{ __html: services }} />

          <ServiceContentImg>
            <div>
              <img
                src={`${HOST}/equipo-GPS.jpeg`} alt='Equipo GPS del IPP'
                style={{ width: '350px', height: '375px' }}
              />
              <span>Equipo GPS</span>
            </div>
          </ServiceContentImg>
        </ServiceContent>

        <ServicesList />
      </Services>

      <Services id='services'>
        <Title>Servicios En Línea</Title>
        <ServicesList online />
      </Services>

      {/* Contac us */}
      <Separador />
      <ContainerContact id='contact'>
        <Title>Contáctanos</Title>
        <Contacts />
      </ContainerContact>

    </Layout>
  )
}

export default Home
