import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px auto auto;
  grid-template-areas:  "head"
                        "main"
                        "foot";
  grid-auto-rows: minmax(100px, auto);

  a {
    text-decoration: none;
    color: inherit;
  }
`
export const MainContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`

export const Main = styled.main`
  grid-area: main;
  height: 100%;
  /* width: 100%; */
  min-height: 70vh;
  max-width: 1464px;
  margin: auto;
  overflow: hidden;
`
