import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, navigate } from '@reach/router'

import Grid from '@mui/material/Grid'
import Button from '@components/Button'
import Loader from '@components/Loader'
import InputText from '@components/inputs/Text'

import http from '@utils/fetch-client'
import { useAlert } from '@hooks/useAlert'
import { useLogin } from '@hooks/useLogin'

import { Container, Form, ContainerImg, Img, LoginInput, LoginBtn, DivMessage } from './styles'
import useStyles from './stylesUI'
import logo from './logo-ipp.png'

const formatEmail = email => {
  let [username, domain] = email.split('@')
  username = username.split('').map((e, i, a) => i > a.length / 3 ? '*' : e).join('')
  return `${username}@${domain}`
}

const Login = props => {
  const classes = useStyles()
  const [ingresar, setIngresar] = useState(!props.location.hash)
  const [loadingForgot, setLoadingForgot] = useState(false)
  const [AlertInfo, setInfo] = useAlert({})
  const { form, login, handleChange } = useLogin(setInfo)

  const sendPassword = () => {
    setLoadingForgot(true)
    console.log('sendPassword', form.usernameForgot)
    if (!form.usernameForgot) {
      setLoadingForgot(false)
      return setInfo({
        status: true, severity: 'error', msg: 'Por favor debe ingresar el nombre de usuario de la cuenta que desea recuperar.'
      })
    }

    http.patch('api/usuario/olvideContrasena', { username: form.usernameForgot })
      .then(({ status, data }) => {
        if (status !== 200) throw Error('Disculpe, ocurrió un error recuperando la contraseña. Por favor intente más tarde.')
        setInfo({
          status: true,
          severity: 'success',
          msg: `Su nueva contraseña ha sido enviada a <b>${formatEmail(data.tx_correo)}</b>`
        })
      })
      .catch(err => {
        console.error(err)
        setInfo({
          status: true,
          severity: 'error',
          msg: 'Ha Ocurrido un <b>error</b> en la aplicación. Intente más tarde o comuníquese a nuestras oficinas. Gracias.'
        })
      })
      .finally(() => setLoadingForgot(false))
  }

  const changeIngresar = stt => () => {
    setInfo({ status: false, severity: '', msg: '' })
    setIngresar(stt)
  }

  return (
    <>
      <Helmet>
        {/* <link  rel="icon"   href="/solologo.png" type="image/png" /> */}
        <link rel='icon' href='/solologo.png' />
        <title> Login | IPP-UCV </title>
        <meta name='description' content='Ingresar al Sistema de Gestion de Citas del IPP de la Universidad Central de Venezuela' />
      </Helmet>

      <Container>
        <Form>
          <ContainerImg>
            <Link to='/home'>
              <Img src={logo} alt='logo del IPP de la UCV' />
            </Link>
          </ContainerImg>
          <LoginInput>
            <Grid container className={classes.root} spacing={2}>
              {ingresar
                ? (
                  <>
                    <Grid item xs={12}>
                      <InputText
                        required
                        id='user'
                        name='username'
                        label='Usuario'
                        // value={form.username}
                        handleBlur={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputText
                        required
                        id='password'
                        name='password'
                        label='Contraseña'
                        type='password'
                        // value={form.password}
                        handleBlur={handleChange}
                      />
                    </Grid>
                    <p onClick={changeIngresar(false)}>¿Olvidó su contraseña?</p>
                  </>)

                : (
                  <>
                    {/* <Grid container className={classes.root} spacing={2}> */}
                    <Grid item xs={12}>
                      <InputText
                        required
                        id='username-forgot'
                        name='usernameForgot'
                        label='Nombre de Usuario (cédula)'
                        // value={form.usernameForgot}
                        handleBlur={handleChange}
                      />
                    </Grid>

                    {/* </Grid> */}
                    <p onClick={changeIngresar(true)}>Ingresar al Portal</p>
                  </>)}
            </Grid>

          </LoginInput>

          <DivMessage>
            <AlertInfo />
            {form.requesting && <Loader />}
            {loadingForgot && <Loader />}
          </DivMessage>

          <LoginBtn>
            {!loadingForgot &&
              <Button
                type='submit'
                onClick={ingresar ? login : sendPassword}
                label={ingresar ? 'Ingresar ' : 'Solicitar Credenciales'}
                color='success'
                disabled={form.requesting}
              />}
            <p onClick={() => navigate('home#contact')}>¿No tiene usuario? Comuníquese a nuestras oficinas.</p>
          </LoginBtn>

        </Form>
      </Container>
    </>
  )
}

export default Login
