import './index.css'
import React, { useState } from 'react'
import { useNavigate, Link } from "@reach/router"

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'pdfjs-dist/build/pdf.worker.entry'
import { StyleSheet } from '@react-pdf/renderer'
import { Document, Page, pdfjs } from 'react-pdf'

import { Container, Buttons } from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'
const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  workerSrc: "/pdf.worker.js" 
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
})

const PhotoFrameDocumentPDF = ({ file = '' }) => {
  const navigate = useNavigate()
  const [numPages, setNumPages] = useState(null)
  // const [file, setFile] = useState('http://10.10.10.215:5000/api/static/documentos/orden-medica-1-6-2021-714-40.pdf')
  const [pageNumber, setPageNumber] = useState(1)
  console.log('PDF', {numPages, file, pageNumber})

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages)
    setPageNumber(1)
  }

  const changePage = offset => setPageNumber(prevPageNumber => prevPageNumber + offset)
  const handlePreviousPage = () => changePage(-1)
  const handleNextPage = () => changePage(1)
  const handleView = () => file && navigate(file, { replace: false })
  
  console.log({pageNumber, options}, typeof file)
  
  return (  <>
    <Document
      file={file}
      // options={options}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <Page 
        height= {200}
        pageNumber={pageNumber} 
      />
    </Document>

    <Container>
      {numPages && <p>Página {pageNumber} de {numPages}</p>}
      <Buttons>
        <button
          disabled={!numPages || pageNumber === 1}
          onClick={handlePreviousPage}
        > Anterior
        </button>

        <button disabled={!file} > 
          <a href={file} target={'_blank'} >Ver</a>
        </button>

        <button
          disabled={!numPages || pageNumber === numPages}
          onClick={handleNextPage}
        > Siguiente
        </button>
      </Buttons>
    </Container>
    </>
  )
}

export default PhotoFrameDocumentPDF
