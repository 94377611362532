import { useState } from 'react'
import { navigate } from '@reach/router'
import moment from 'moment'

import Layout from '@components/admin/Layout'
import Loader from '@components/Loader'
import DateRangeSelector from '@components/DateRangeSelector'
import PieChartsFull from '@components/charts/PieChartsFull'
import TableJorCitasMedicas from '@components/TableJorCitasMedicas'
import Filters from '@components/Filters'
import { getDate, getDateUTC } from '@utils/date'
import InputSelect from '@components/inputs/Select'
import CitaCard from '@components/portal/CitaCard'
import Button from '@components/Button'

import http from '@utils/fetch-client'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { useAlert } from '@hooks/useAlert'
import { getWeek, getWeeksAppointment } from '@utils/date'
import { usePagination } from '@hooks/usePagination'

import { Container, DivWeeks,  Row, ContainerProfesionals } from './styles'
const INITIAL_STATE = { pacientes: { pendientes:[], listos:[], selected: {} } }
const CLINICAS = [
  { co_clinica:1, nb_clinica:'Caracas', nb_abrev_cli:'CCS' }, 
  { co_clinica:2, nb_clinica:'Maracay', nb_abrev_cli:'MCY' },
  { co_clinica:3, nb_clinica:'CEAPUCV Caracas', nb_abrev_cli:'CEA' }, 
  { co_clinica:4, nb_clinica:'EMI Caracas', nb_abrev_cli:'CCS', co_cli_med:1 }, 
  { co_clinica:5, nb_clinica:'EMI Maracay', nb_abrev_cli:'MCY', co_cli_med:2 }
]

const JornadasCitasMedicas = ({ coClinica }) => {
  const [loading, setLoading] = useState(true)
  const [pacientes, setPacientes] = useState(INITIAL_STATE.pacientes)
  const [doctores, setDoctores] = useState({week:'current', data: []})
  const [weeks, setWeeks] = useState({data:[], options:[], selected:1})
  const [steps, setSteps] = useState(1)
  const [Alert, setAlert] = useAlert({})
  const [Pagination, idx, resetDoctors] = usePagination(doctores.data.length, 3)

  // ?coClinica=1&coEspecial=D1058E49-FFBC-ED11-910B-000C2914A2E9&fecha_inicial=2023-04-27&fecha_final=2023-04-30
  useEffectAbortable(({ aborted }) => { // Obtener Cita
    http.get(`api/jornadas/${coClinica}/citas/medica/pacientes`)
      .then(({ status, data }) => {
        if (aborted) return
        if (status !== 200 || !Object.keys(data).length) throw JSON.stringify({ status, data })
        
        setPacientes(data.data)
      })
      .catch(err => {
        console.error('[JornadasCitasMedicas] citas: ', err)
        setAlert({ status: true, severity: 'error', msg: JSON.parse(err).data.error })
      })
      .finally(() => setLoading(false))
  }, [])

  useEffectAbortable(mount => { // Rellenar selector de semanas
    const weeksAux = getWeeksAppointment()
    const options = weeksAux.map((w, idx) => ({
      opcionID: idx+1, type: 'text',
      opcionDes: `${w[0][0]}-${w[0][1]}-${w[0][2]} al ${w[1][0]}-${w[1][1]}-${w[1][2]}`
    }))
    setWeeks({data:weeksAux, options, selected:1})
  }, [])

  const fetchDoctors = (week) => {
    setLoading(true)
    resetDoctors()
    const [fi, ff] = weeks.data[week]
    const fechaInicial = `${fi[2]}-${fi[1]}-${fi[0]}`
    const fechaFinal = `${ff[2]}-${ff[1]}-${ff[0]}`
    let especialidadID = 'D1058E49-FFBC-ED11-910B-000C2914A2E9'
    let clinicaID = CLINICAS[coClinica-1]?.co_cli_med
    const url = `api/jornadas/citas/medica/doctores?coClinica=${clinicaID}&coEspecial=${especialidadID}&fecha_inicial=${fechaInicial}&fecha_final=${fechaFinal}`
    http.get(url)
      .then(({ status, data }) => {
        if (status !== 200) throw new Error(status)
        let drs = data.data.reduce((acc, curr) => {
          return (Object.keys(curr.horarios).length) ? [...acc, curr] : acc
        }, [])

        if(especialidadID === 'A9041EE1-EBD0-47AD-A7C9-97FDDC26F168'){
          const noOdontologos = [ // "A9041EE1-EBD0-47AD-A7C9-97FDDC26F168"
            '6BBB2D17-3C32-49A1-BEF8-AE83EF8BE4BA',  //Arelys Gonzales
            'BDDE72B5-BCE5-43EE-A263-A94E9692F907' // Karina Gomez
          ]
          drs = drs.filter(e => { return !noOdontologos.includes(e.co_prof_int) })
        }

        setDoctores({week, data: drs})
        // setDoctores({week: week ?'next' :'current' ,data: drs})
      })
      .catch(err => {
        const msg = `disculpe, no se encontraron los doctores relacionados, por favor intente mas tarde o comuníquese con nuestras oficinas.`
        console.error(`${err}: ${msg}`)
        setAlert({ status: true, severity: 'error', msg: msg })
      })
      .finally(() => setLoading(false))
  }

  const handleWeekDoctors = ({name, value}) => {
    setWeeks(state => ({...state, selected:value}))
    fetchDoctors(value-1)
  }

  const handleDatetime = (profesional, date, time) => () => {
    setLoading(true)
    let fecha_cita = date.split(' ')
    let coJorCita = pacientes.selected['co_jor_cita']
    fecha_cita = fecha_cita[fecha_cita.length-1]
    let [dd, mm, aaaa] = fecha_cita.split('/')
    fecha_cita = `${mm}/${dd}/${aaaa}`

    console.log({profesional, date, time, fecha_cita}, date.split(' '))
    let body = {
      co_status: 1,
      fecha_cita,
      co_horario: time['co_horario'],
      co_prof_med:  profesional['co_prof_int'],
      co_tipo_con_web: 1,
      co_especialidad: profesional['co_especial'],
      co_clinica: profesional['co_clinica'],
      co_benefi: pacientes.selected.cita['co_beneficiario'],
      co_jor_cita: coJorCita
    }

    http.post(`api/jornadas/citas/medica`, body)
    .then(({ status, data }) => {
      console.log('[JornadasCitasLaboratorio] citas', { status, data })
      if (status !== 201) throw JSON.stringify({ status, data })
      
      let newPacientes  = JSON.parse(JSON.stringify(pacientes))
      let updatedIndex = newPacientes.pendientes.findIndex(e => e['co_jor_cita'] == coJorCita)
      let updatedPaciente = newPacientes.pendientes.splice(updatedIndex,1)[0]
      newPacientes.listos.push(updatedPaciente)
      newPacientes.selected = {}
    
      setAlert({ status: true, severity: 'success', msg: `A la cita EMI <b>${coJorCita}</b> se le asigno cita médica exitosamente.` })
      setPacientes(newPacientes)
      fetchDoctors(0)
      setWeeks(state => ({...state, selected:1}))
      setSteps(1)
    })
    .catch(err => {
      console.error('[JornadasCitasMedicas] citas: ', err)
      setAlert({ status: true, severity: 'error', msg: err })
    })
    .finally(() => setLoading(false))
  }

  const handleBack = () => setSteps(1)
  
  const handleCitaAsignar = (co_jor_cita, co_usuario, co_jor_horario, username, paciente, cita) => {
    let selected = { co_jor_cita, co_usuario, co_jor_horario, username, paciente, cita }
    setPacientes(state => ({...state, selected }))
    fetchDoctors(0)
    setSteps(2)
  }

  const renderDoctores = () => (
    <>
      <Button
        label='Volver'
        color='danger'
        style={{ width: '20%' }}
        // disabled={loadingSendEmail}
        onClick={handleBack}
      />

      <DivWeeks>
        <h4>Seleccione una semana: </h4>
        <div>
          <InputSelect
            id='week'
            name='week'
            value={weeks.selected}
            options={weeks.options}
            handleChange={handleWeekDoctors}
          />
        </div>
      </DivWeeks>
      {!doctores.data.length
        ? <h2>No hay horarios disponibles, intente ver los horarios de la semana entrante</h2>
        : (
          <>
            <h1>Seleccione el horario para {pacientes.selected?.paciente}:</h1>
            <ContainerProfesionals>
              <Pagination />

              <Row>
                {doctores.data.slice(idx.start, idx.end).map(doctor => (
                  <CitaCard
                    key={doctor.co_prof_int}
                    cita={{ profesional: doctor }}
                    // fecha={citaCreating.fecha}
                    // horarios={horarios}
                    handleDatetime={handleDatetime}
                    // all={true}
                    cupos={true}
                  />
                ))}
              </Row>

              <Pagination />
            </ContainerProfesionals>
          </>)}
  </>)

  return (
    <Layout
      active='emi'
      title='EMI Citas Médicas'
    >
      <Container>
        <h1> {CLINICAS[coClinica-1]?.nb_clinica} | Citas Médicas </h1>
        <Alert />

        {loading
          ? <Loader />
          : (
            <>
              {/* <PieChartsFull
                data={Object.entries(citas).reduce((acc, [key, value]) => {
                  if (!key || key === 'null') return acc
                  return acc.concat({
                    title: key,
                    value: value.length
                  })
                }, [])}
                styles={{ width: 300, height: 200, marginBottom: '1rem' }}
              />
 */}
              {/* <Filters
                nameFilter='citas'
                data={Object.values(citas).reduce((acc, curr) => acc.concat(curr), [])}
                handleSetData={() => { }}
                options={[
                  { label: 'Profesional', name: 'medico' },
                  { label: 'Paciente', name: 'paciente' },
                  { label: 'Encargados', name: 'encargado' },
                  { label: 'Códigos', name: 'co_cita_web' },
                  { label: 'Fechas Creado', name: 'fecha_creado_web', typeValue: 'dateUTC' },
                  { label: 'Fecha Citas', name: 'fecha_cita', typeValue: 'date' }
                ]}
              />
               */}
               {steps === 1
                ?<>
                  <TableJorCitasMedicas 
                    key={`jorCitaMedPend${Date().toString()}`} 
                    root={'EMIcitaMedica'} 
                    name={'pendientes'} 
                    data={pacientes.pendientes} 
                    handleClick={handleCitaAsignar} 
                    action={'asignar cita'} 
                    // disabled={!isToday(horario?.fecha_cita)}
                  />

                  <TableJorCitasMedicas 
                    key={`jorCitaMedCita${Date().toString()}`} 
                    root={'EMIcitaMedica'} 
                    name={'asignadas'} 
                    data={pacientes.listos} 
                    handleClick={() => {}} 
                    action={null} 
                  />
                </>
                :renderDoctores()
               }




            </>)}
      </Container>
    </Layout>
  )
}

export default JornadasCitasMedicas
