import styled from 'styled-components'

export const Container = styled.ul`
    padding: 20px 5px;

    @media (min-width: 600px) {
        padding: 20px 20px;
    }

    @media (min-width: 920px) {
        padding: 20px 60px;
    }
`

export const Row = styled.div`
    min-height: 250px;
`

export const Empty = styled.h2`
    color: ${({ theme }) => theme.color.gray};
    font-weight: 700;
`
