// eslint-disable-next-line react-hooks/exhaustive-deps
import { useState } from 'react'
import { Link, navigate } from '@reach/router'

import Layout from '@components/admin/Layout'
import Loader from '@components/Loader'
import Button from '@components/Button'
import TableAllies from '@components/TableAllies'

import http from '@utils/fetch-client'
import { useAlert } from '@hooks/useAlert'
import { usePagination } from '@hooks/usePagination'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { getDateUTC } from '@utils/date'

import {
  Container, Post, RowsServices, Title, CreatedAt,
  UpdatedAt, Status, Header
} from './styles'

const Home = () => {
  const [services, setServices] = useState([])
  const [servicesOnline, setServicesOnline] = useState([])
  const [clinics, setClinics] = useState([])
  const [loadingServices, setLoadingServices] = useState(true)
  const [loadingClinics, setLoadingClinics] = useState(true)
  const [Alert, setAlert] = useAlert({})
  const [PaginationServices, idxServices] = usePagination(services.length, 3)
  const [PaginationServicesOnline, idxServicesOnline] = usePagination(servicesOnline.length, 3)

  // console.log('services', services)
  useEffectAbortable(({ aborted }) => {
    http.get('api/servicio')
      .then(({ status, data }) => {
        if (aborted) return
        if (status !== 200) throw Error('Disculpe, ocurrió un error consultando los Servicios. Por favor intente más tarde.')
        setLoadingServices(false)
        const regular = data.filter(({ orden }) => orden !== 1)
        const online = data.filter(({ orden }) => orden === 1)
        setServices(regular)
        setServicesOnline(online)
      })
      .catch(err => {
        setLoadingServices(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }, [])

  useEffectAbortable(({ aborted }) => {
    http.get('api/clinica')
      .then(({ status, data }) => {
        if (aborted) return
        if (status !== 200) throw Error('Disculpe, ocurrió un error consultando las clínicas. Por favor intente más tarde.')
        setClinics(data)
      })
      .catch(err => {
        setAlert({ status: true, severity: 'error', msg: err })
      })
      .finally(() => setLoadingClinics(false) )
  }, [])

  const createService = () => {
    navigate('/admin/service/create')
  }

  return (
    <Layout
      active='home'
      title='Home'
    >
      <Alert />
      <Container>
        
        {loadingClinics ?<Loader />  :<TableAllies name='clinics' data={clinics} />}

        <section>
          <Header>
            <h1>Servicios</h1>
            <Button
              label='Agregar Servicio'
              color='success'
              onClick={createService}
            />
          </Header>

          <Post header>
            <Title>Titulo</Title>
            <CreatedAt>Fecha de creación</CreatedAt>
            <UpdatedAt>Fecha de modificación</UpdatedAt>
            <Status header> Estatus</Status>
          </Post>

          <RowsServices>
            {loadingServices ? <Loader />
              : services.slice(idxServices.start, idxServices.end).map(({
                co_servicio, titulo, fecha_creado, fecha_editado, publicado
              }) => (
                <Link to={`/admin/service/${co_servicio}`} key={co_servicio}>
                  <Post>
                    <Title>{titulo}</Title>
                    <CreatedAt>{getDateUTC(fecha_creado)}</CreatedAt>
                    <UpdatedAt>{getDateUTC(fecha_editado)}</UpdatedAt>
                    <Status published={publicado}>{publicado ? 'Publicado' : 'borrador'}</Status>
                  </Post>
                </Link>
              ))}
          </RowsServices>

          <PaginationServices />
        </section>

        <section>
          <Header>
            <h1>Servicios En línea</h1>
            <Button
              label='Agregar Servicio'
              color='success'
              onClick={createService}
            />
          </Header>

          <Post header>
            <Title>Titulo</Title>
            <CreatedAt>Fecha de creación</CreatedAt>
            <UpdatedAt>Fecha de modificación</UpdatedAt>
            <Status header> Estatus</Status>
          </Post>

          <RowsServices>
            {loadingServices ? <Loader />
              : servicesOnline.slice(idxServicesOnline.start, idxServicesOnline.end).map(({
                co_servicio, titulo, fecha_creado, fecha_editado, publicado
              }) => (
                <Link to={`/admin/service/${co_servicio}`} key={co_servicio}>
                  <Post>
                    <Title>{titulo}</Title>
                    <CreatedAt>{getDateUTC(fecha_creado)}</CreatedAt>
                    <UpdatedAt>{getDateUTC(fecha_editado)}</UpdatedAt>
                    <Status published={publicado}>{publicado ? 'Publicado' : 'borrador'}</Status>
                  </Post>
                </Link>
              ))}
          </RowsServices>

          <PaginationServicesOnline />
        </section>

      </Container>
    </Layout>
  )
}

export default Home
