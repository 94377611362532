import styled from 'styled-components'

export const Container = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  flex-direction: column;
`

export const ContainerSelected = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  button{
    order: 1;
  }
  
  @media (min-width: 720px) {
    flex-direction: row;
    align-items: flex-start;
    button{
      order: 2;
    }
  }
`

export const ContainerSelectedInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  order: 2;

  h4{
    color: ${({ theme }) => theme.color.gray};
  }
  
  div{
    position: relative;
    padding: 0.6rem;
    margin: 0.6rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.color.gray};
    
    div{
      position: absolute;
      top: -0.4rem;
      right: -0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.7rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.danger};
      color: ${({ theme }) => theme.color.white};
      /* text-align: center; */
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
  }

  @media (min-width: 720px) {
    justify-content: flex-start;
    order: 1;

  }
`

export const ContainerOptions = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`

export const Options = styled.div`
  margin: 0 1rem;
`
