import styled from 'styled-components'

export const NavBar = styled.ul`  
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;

    @media (min-width: 750px) {
        flex-direction: row;
    }
`

export const Option = styled.li`  
    margin: 0px 15px;
    padding: 10px 0;
    color: ${({ theme, highlight }) => (highlight) ? theme.color.bluePrimary : theme.color.bluePrimary};
    vertical-align: center;
    font-weight: bold;
    text-align: center;
    list-style-type: none;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 3px solid ${({ theme, selected }) => (selected) ? theme.color.bluePrimary : 'white'};
    &:hover{
      font-size: 17px;
      /* border-bottom: 3px solid ${({ theme }) => theme.color.bluePrimary}; */
      color: ${({ theme, highlight }) => (highlight) ? theme.color.bluePrimaryOpacity : theme.color.bluePrimary}
    };   
`
