import React, { useState, useEffect } from 'react'

import CartaAval from '@components/portal/CartaAval'
import Loader from '@components/Loader'

import http from '@utils/fetch-client'
import { useAlert } from '@hooks/useAlert'
import { usePagination } from '@hooks/usePagination'
import { useStateValue } from '@context/index.js'

import { Container, Row, Empty } from './styles'

const ListOfCartaAval = () => {
  const [{ user }] = useStateValue()
  const [cartas, setCartas] = useState([])
  const [loading, setLoading] = useState(true)
  const [Alert, setAlert] = useAlert({})
  const [Pagination, idx] = usePagination(cartas.length)

  useEffect(() => {
    // console.log({ user }, `api/solicitud?co_tipo=2&co_usuario=${user.co_usuario}`)

    http.get(`api/solicitud?co_tipo=2&co_usuario=${user.co_usuario}`)
      .then(({ status, data }) => {
        // console.log({ data })
        if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')
        setLoading(false)
        setCartas(data || [])
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }, [user, setAlert])

  return (
    <Container>
      <Alert />
      {loading
        ? <Loader />
        : cartas.length
          ? (
            <>
              <CartaAval
                header
                carta={{
                  concepto: 'Concepto',
                  fecha_creado: 'Fecha de Creación',
                  fecha_editado: 'Última actualización',
                  co_status_web: 'Estatus'
                }}
              />

              <Row>
                {cartas.slice(idx.start, idx.end).map(carta => (
                  <CartaAval
                    key={carta.co_solicitud}
                    carta={carta}
                  />
                ))}
              </Row>

              <Pagination />
            </>)
          : <Empty>Usted no tiene solicitudes de Carta Aval</Empty>}
    </Container>
  )
}

export default ListOfCartaAval
