import React from 'react'
import { Link, navigate } from '@reach/router'
import { useStateValue } from '@context'
// import userMock from '../../mocks/user'
import { NavBar, Option } from './styles'

const Nav = ({ active }) => {
  const [{ user }] = useStateValue()

  /* const login = () => {
        dispatch({
            type: 'LOGIN',
            payload: userMock,
        })
        navigate('/portal/perfil')
    } */

  const handleClick = () => {
    navigate('/home#organization', { replace: true })
  }

  return (
    <NavBar>
      <div onClick={handleClick}>
        <Option>Organización</Option>
      </div>

      <Link to='/home#services'>
        <Option>Servicios</Option>
      </Link>

      <Link to='/directory'>
        <Option>Aliados</Option>
      </Link>

      <Link to='/home#contact'>
        <Option>Contáctanos</Option>
      </Link>

      <Link to='/noticias'>
        <Option selected={active === 'noticias'}>Noticias</Option>
      </Link>
      {/*
            <Link to='/home/#contactanos'>
                <Option>Contáctanos</Option>
            </Link>
 */}
      {user
        ? (
          <Link to='/portal/perfil'>
            <Option> Solicitudes En Línea </Option>
          </Link>)
        // : <Option onClick={login} highlight={true}> Ingresar al Portal SGS </Option>
        : (
          <Link to='/login'>
            <Option highlight> Ingresar al Portal SGS </Option>
          </Link>)}
    </NavBar>
  )
}

export default Nav
