import React, { useState } from 'react'

import Layout from '@components/admin/Layout'
import Beneficiaries from '@components/Beneficiaries'
import DocumentVerification from '@components/admin/DocumentVerification'
import Button from '@components/Button'
import Loader from '@components/Loader'
import StatusRequest from '@components/StatusRequest'
import InputText from '@components/inputs/Text'

import http from '@utils/fetch-client'
import { useAlert } from '@hooks/useAlert'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { useStateValue } from '@context'
import { navigate } from '@reach/router'

import {
  StyledContainer, StyledContainerFinish,
  StyledContainerLink, StyledContainerSAMHOI,
  StyledSubTitle, StyledStatusMain, StyledRow
} from './styles'

const ReembolsosEdit = ({ id }) => {
  const [{ user }] = useStateValue()
  const [codigo, setCodigo] = useState('')
  const [reembolsoEdit, setReembolsoEdit] = useState({})
  const [usuario, setUsuario] = useState(null)
  const [owner, setOwner] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingLink, setLoadingLink] = useState(false)
  const [sendEmail, setSendEmail] = useState(false)
  const [Alert, setAlert] = useAlert({})
  const [AlertEnd, setAlertEnd] = useAlert({})

  const requestIsHold = reembolsoEdit.co_status_web === 2
  const requestIsValid = reembolsoEdit.co_status_web === 3
  const requestIsSAMHOI = reembolsoEdit.co_status_web === 5
  const isOwner = reembolsoEdit.co_encargado === user.co_usuario

  useEffectAbortable(({ aborted }) => { // Obtener información de la solicitud
    setLoading(true)
    http.get(`api/solicitud/${id}`)
      .then(({ status, data }) => {
        // console.log({ status, data })
        if (aborted) return
        if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')
        setLoading(false)
        setReembolsoEdit(data)
      })
      .catch(err => setAlert({ status: true, severity: 'error', msg: err }))
      .finally(() => setLoading(false))
  }, [id, setAlert])

  useEffectAbortable(({ aborted }) => { // Obtener información del usuario
    if (usuario === null && reembolsoEdit.co_usuario) {
      http.get(`api/usuario/${reembolsoEdit.co_usuario}`)
        .then(({ status, data }) => {
          // console.log({ status, data })
          if (aborted) return
          if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')
          setUsuario(data)
        })
        .catch(err => {
          setAlert({ status: true, severity: 'error', msg: err })
        })
    }
  }, [reembolsoEdit.co_usuario, setAlert])

  useEffectAbortable(({ aborted }) => {
    if (owner !== null || !Object.keys(reembolsoEdit).length) return
    if (reembolsoEdit.co_encargado) {
      // Se le informa al usuario si está a cargo o no de la solicitud
      if (isOwner) {
        setAlert({
          status: true,
          severity: 'success',
          msg: 'Usted es el operador encargado. Puede emitir acciones sobre esta Solicitud'
        })
      } else {
        setAlert({
          status: true,
          severity: 'warning',
          msg: 'Usted NO es el operador encargado. Puede Observar esta Solicitud pero sin emitir acciones'
        })
      }
      // Información del usuario encargado
      http.get(`api/usuario/${reembolsoEdit.co_encargado}`)
        .then(({ status, data }) => {
          if (aborted) return
          if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta de datos del encargado. Por favor intente más tarde.')
          setOwner(data)
        })
        .catch(err => setAlert({ status: true, severity: 'error', msg: err }))
    } else {
      const body = new FormData()
      body.append('co_encargado', user.co_usuario)

      http.patch(`api/solicitud/${id}/asignarEncargado`, body)
        .then(({ status, data }) => {
          if (aborted) return
          if (status === 403) {
            return setAlert({
              status: true,
              severity: 'info',
              msg: 'Esta solicitud ya tiene un operador encargado. Puede Observar esta Solicitud pero sin emitir acciones'
            })
          }
          if (status !== 200) throw Error('Disculpe, ocurrió un error asignando operador encargado. Por favor intente más tarde.')
          setAlert({ status: true, severity: 'success', msg: 'Esta Solicitud le ha sido asignada exitosamente' })
          setReembolsoEdit(state => ({ ...state, co_encargado: data.insertado.co_encargado }))
        })
        .catch(err => setAlert({ status: true, severity: 'error', msg: err }))
    }
  }, [reembolsoEdit, setAlert])

  const desasignOwner = () => {
    const body = new FormData()
      body.append('co_encargado', user.co_usuario)

      http.patch(`api/solicitud/${id}/desasignarEncargado`, body)
      .then(({ status, data }) => {
        console.log({status, data})
        if (status === 403) {
          return setAlert({
            status: true,
            severity: 'info',
            msg: 'Usted no es el operador operador encargado. NO Puede liberarla. Puede Observar esta Solicitud pero sin emitir acciones'
          })
        }
        if (status !== 200) throw Error('Disculpe, ocurrió un error liberando al operador encargado. Por favor intente más tarde.')
        setAlert({ status: true, severity: 'success', msg: 'Esta Solicitud se ha liberado exitosamente' })
        setReembolsoEdit(state => ({ ...state, co_encargado: data.insertado.co_encargado }))
        setTimeout(() => { navigate(-1) }, 1000 * 5)
      })
      .catch(err => setAlert({ status: true, severity: 'error', msg: err }))
  }

  const waitingDocuments = () => {
    // console.log(reembolsoEdit.documentos)
    const waiting = Object.values(reembolsoEdit.documentos).filter(e => e).reduce((prev, curr) =>
      prev || getStatus(curr) === 1
      , false)
    return waiting
  }

  const notifyUser = () => {
    const user = usuario.usuario
    const body = {
      co_solicitud: id,
      name: user.nb_benefi ? user.nb_benefi.trim() : '',
      lastname: user.nb_apellid ? user.nb_apellid.trim() : '',
      email: user.tx_correo ? user.tx_correo.trim() : '',
      concepto: reembolsoEdit.concepto,
      type: 'reembolsos'
    }

    const query = Object.entries(body).reduce((acc, [key, value], index) => {
      return `${acc}${index ? '&' : ''}${key}=${value}`
    }, '?')

    setLoading(true)
    http.patch(`api/solicitud/${id}/finalizarRevision${query}`)
      .then(({ status }) => {
        if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')
        setSendEmail(true)
        setReembolsoEdit(state => {
          const newReembolsoEdit = JSON.parse(JSON.stringify(state))
          newReembolsoEdit.co_status_web = getStatusGlobal(newReembolsoEdit.documentos)
          return newReembolsoEdit
        })
        setAlert({ status: true, severity: 'success', msg: 'La verificación de documentos ha finalizado con éxito y se le ha notificado al usuario.' })
      })
      .catch(err => {
        console.error(`Error endReview: ${err}`)
        setAlert({ status: true, severity: 'error', msg: err })
      })
      .finally(() => setLoading(false))
  }

  const endReview = () => { // Reportar al usuario
    if (waitingDocuments()) {
      setAlertEnd({ status: true, severity: 'error', msg: 'Para notificar la revisión de la solicitud no debe haber ningun documento En Espera' })
      return
    }else notifyUser()
  }

  const getStatus = files => { // Estado de un documento
    const valido = files.reduce((acc, curr) => acc && curr.co_status === 2, true)
    const rechazado = files.reduce((acc, curr) => acc || curr.co_status === 3, false)
    return valido ? 2 : rechazado ? 3 : 1
  }

  const getStatusGlobal = (documents = {}) => { // Estado de la solicitud
    const valido = Object.values(documents).filter(e => e).reduce((prev, curr) =>
      prev && getStatus(curr) === 2
      , true)
    const rechazado = Object.values(documents).filter(e => e).reduce((prev, curr) =>
      prev || getStatus(curr) === 3
      , false)
    return valido ? 3 : rechazado ? 4 : 2
  }

  const canBeReleased = (documents = {}) => { // Estado de la solicitud
    const onHold = Object.values(documents).filter(e => e).reduce((prev, curr) =>
      prev && getStatus(curr) === 1
      , true)

    return onHold 
  }

  const updateRequest = (name, files) => {
    setReembolsoEdit(state => {
      const newReembolsoEdit = JSON.parse(JSON.stringify(state))
      newReembolsoEdit.documentos[name] = files
      // newReembolsoEdit.co_status_web = getStatusGlobal(newReembolsoEdit.documentos)
      // if (newReembolsoEdit.co_status_web === 3) notifyUser()  // Notifica automaticamente si la sollicitud es válida
      return newReembolsoEdit
    })
  }

  const fetchLinkSamhoi = (co_solicitud, co_solicitud_salud) => {
    setLoadingLink(true)
    const data = new FormData()
    data.append('co_solicitud_salud', co_solicitud_salud)
    // console.log('enlazando')
    http.patch(`api/solicitud/${co_solicitud}/asociarSam`, data)
      .then(({ status, data }) => {
        // console.log({ status, data })
        if (status !== 200) throw Error(data.message || 'Disculpe, No se logró enlazar la solicitud. Por favor intente más tarde.')

        setAlertEnd({ status: true, severity: 'success', msg: 'Solicitud enlazada a SAMHOI <b>exitosamente</b>' })

        setReembolsoEdit(state => ({
          ...state,
          co_status_web: data.solicitud.co_status_web,
          tx_status: data.solicitud.tx_status,
          '': data.solicitud['']
        }))
      })
      .catch(err => setAlertEnd({ status: true, severity: 'error', msg: err }))
      .finally(() => setLoadingLink(false))
  }

  const fetchUnlinkSamhoi = (co_solicitud, co_solicitud_salud) => {
    setLoadingLink(true)
    const data = new FormData()
    data.append('co_solicitud_salud', co_solicitud_salud)
    // console.log('desenlazando')

    http.patch(`api/solicitud/${co_solicitud}/desasociarSam`, data)
      .then(({ status, data }) => {
        // console.log({ status, data })
        if (status !== 200) throw Error(data.message || 'Disculpe, No se logró desenlazar la solicitud. Por favor intente más tarde.')

        setAlertEnd({ status: true, severity: 'success', msg: 'Solicitud desenlazada de SAMHOI <b>exitosamente</b>' })
        setReembolsoEdit(state => ({ ...state, co_status_web: 3, tx_status: 'Válida' }))
      })
      .catch(err => setAlertEnd({ status: true, severity: 'error', msg: err }))
      .finally(() => setLoadingLink(false))
  }

  const handleLinkSamhoi = (link, co_solicitud, co_solicitud_salud) => () => {
    if (link && !co_solicitud_salud) {
      return setAlertEnd({
        status: true, severity: 'error', msg: 'No se ha ingresado el código de SAMHOI.'
      })
    }

    link ? fetchLinkSamhoi(co_solicitud, co_solicitud_salud)
      : fetchUnlinkSamhoi(co_solicitud, co_solicitud_salud)
  }

  const handleChangeCodigo = ({ value }) => setCodigo(value)

  return (
    <>
      <Layout
        active='reembolsos'
        title={`Reembolso ${id}`}
      >
        <StyledContainer>

          {loading ? <Loader />
            : !Object.keys(reembolsoEdit).length ? null : <>
              <Alert />

              {usuario && <>
                <StyledSubTitle>Beneficiario:</StyledSubTitle>
                <Beneficiaries beneficiaries={[usuario.usuario]} />
              </>}

              {owner && <>
                <StyledSubTitle>Operador encargado:</StyledSubTitle>
                <Beneficiaries beneficiaries={[owner.usuario]} />
              </>}
              
              {(isOwner && canBeReleased(reembolsoEdit.documentos)) && // Si es el encargado y no se ha tocado la solicitud
                <StyledContainerFinish>
                  <Button
                    label='Liberar Revisión de Solicitud'
                    color='secondary'
                    onClick={desasignOwner}
                  />
                </StyledContainerFinish>
              }

              <StyledStatusMain>
                <span>Este Reembolso está</span>
                <StatusRequest status={reembolsoEdit.co_status_web} />
              </StyledStatusMain>

              <StyledRow>Descripción del estatus: {reembolsoEdit.tx_status}</StyledRow>

              {Object.entries(reembolsoEdit.documentos).map(([name, documents]) =>
                <div key={name}>
                  <DocumentVerification
                    co_solicitud={id}
                    requestIsValid={requestIsValid || requestIsSAMHOI}
                    isOwner={isOwner}
                    requestName='Reembolso'
                    updateRequest={updateRequest}
                    document={{
                      name: name,
                      files: documents
                    }}
                  />
                </div>
              )}

              <AlertEnd />
              
              {requestIsHold &&
                <StyledContainerFinish>
                  <Button
                    label='Finalizar Revisión'
                    color='success'
                    onClick={endReview}
                    disabled={requestIsValid || requestIsSAMHOI || sendEmail || !isOwner}
                  />
                </StyledContainerFinish>
              }

              {requestIsValid && <StyledContainerLink>
                {loadingLink ? <Loader />
                  : <StyledContainerSAMHOI>
                    <div style={{ width: '350px' }}>
                      <InputText
                        id='código-samhoi'
                        label='referencia en SAMHOI (Ej:01111908-883161)'
                        name='codigoSamhoi'
                        value={codigo}
                        disabled={!isOwner}
                        handleChange={handleChangeCodigo}
                      />
                    </div>

                    <Button
                      label='Enlazar con SAMHOI'
                      color='success'
                      disabled={!isOwner}
                      onClick={handleLinkSamhoi(1, reembolsoEdit.co_solicitud, codigo)}
                    />
                  </StyledContainerSAMHOI>}
              </StyledContainerLink>}

              {requestIsSAMHOI && <StyledContainerLink>
                {loadingLink ? <Loader />
                  : <StyledContainerSAMHOI>
                    <div style={{ width: '350px' }}>
                      <InputText
                        id='código-samhoi'
                        label='referencia en SAMHOI (Ej:01111908-883161)'
                        name='codigoSamhoi'
                        value={reembolsoEdit['']}
                        disabled
                        handleChange={handleChangeCodigo}
                      />
                    </div>

                    <Button
                      label='Desenlazar de SAMHOI'
                      color='success'
                      disabled={!isOwner}
                      onClick={handleLinkSamhoi(0, reembolsoEdit.co_solicitud, codigo)}
                    />
                  </StyledContainerSAMHOI>}
              </StyledContainerLink>}

            </>}
        </StyledContainer>
      </Layout>
    </>
  )
}

export default ReembolsosEdit
