import styled from 'styled-components'

export const Content = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: ${({ loading }) => loading && 'center'};
    /* width: 100%; */

    @media (min-width: 768px) {
        flex-direction: row;
    }
`

export const Title = styled.h1`
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    color: ${({ theme }) => theme.color.bluePrimary};
    
    @media (min-width: 520px) {
        font-size: 3rem;
    }
`

export const Clinics = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 3rem);
    padding: 1.5rem;
`

export const ContainerClinic = styled.div`
    display:  flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    padding: 15px;
    margin-bottom: 50px;
    
    @media (min-width: 720px) {
        flex-direction: row;
        margin-bottom: 25px;
    }
`
export const ContainerImg = styled.div`
    display:  flex;
    justify-content: center;
    min-width: 230px;
    height: 230px;
    margin-bottom: 15px;

    img{
        width:150px;
        height:200px;
        border-radius: 20px;
        box-shadow: -5px -5px 10px ${({ theme }) => theme.color.shadow};

    }
`

export const ContainerInfo = styled.div`
    /* width: 100%; */
    padding: 0 30px;

    @media (min-width: 720px) {
        padding: 0;
    }
`

export const Row = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
    margin: 1.5rem 0;
    padding: 0.6rem;
    
`

export const Empty = styled.div`
    h1{
        text-align: center;
    }
    h3{
        text-align: center;
        font-weight: 500;
    }
`

export const RowInfo = styled.div`
    display:flex;
    /* width: 100%; */
    justify-content: space-between;
    flex-wrap: wrap;
`

export const Info = styled.div`
    display:flex;
    margin-bottom: 10px;
    
    div{
        color: ${({ theme, title }) => title ? theme.color.bluePrimary : '#444'}; 
        font-weight: ${({ title }) => title ? 700 : 500};
        margin-right: 5px;
        text-align: left;
        font-size: ${({ title }) => title ? '20px' : '17px'};
    }
`
