import { useState, useEffect } from 'react'
import { navigate } from '@reach/router'

import Button from '@components/Button'
import Layout from '@components/admin/Layout'
import Filters from '@components/Filters'
import Loader from '@components/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard, faRedo, faListAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons'

import http from '@utils/fetch-client'
import { getDateTimeUTC } from '@utils/date'
import { useStateValue } from '@context'
import { useAlert } from '@hooks/useAlert'
import { useEffectAbortable } from '@hooks/useEffectAbortable'

import { Container, Notification, NotificationInfo, NotificationButtons, Header, Icon } from './styles'

const getDate = fecha => {
  return fecha ? Date.parse(fecha) : 0
}

const order = (a, b) => {
  const fechaA = getDate(a.fecha_creado)
  const fechaB = getDate(b.fecha_creado)

  return (fechaA === 0)
    ? -1
    : (fechaB === 0)
        ? 1
        : (fechaA < fechaB) ? -1 : 1
}

const Notifications = () => {
  const [{ user }] = useStateValue()
  const [notifications, setNotifications] = useState({ data: [], search: [] })
  const [loading, setLoading] = useState(true)
  const [Alert, setAlert] = useAlert({})

  const fetchNotificationUnread = (aborted) => {
    setLoading(true)
    http.get('api/notificacion/sinLeer')
      .then(({ status, data }) => {
        // console.log('fetch', { status, data, aborted })
        if (aborted) return
        if (status !== 200) throw Error('Disculpe, ocurrió un error consultados los Post. Por favor intente más tarde.')

        if (user.co_tipo === 4) { // Operador SAMHOI NO - citas
          data = data.filter(n => n.co_tipo !== 3)
        } else if (user.co_tipo === 5) { // Operador SEMO - NO carta y reembolso
          data = data.filter(n => n.co_tipo !== 1 && n.co_tipo !== 2)
        }
        data = data.map(n => ({ ...n, encargado: n.encargado || -1 }))
        data = data.sort(order)
        setNotifications({ data, search: data })
      })
      .catch(err => {
        console.error('[Notification] GET sinLeer', err)
        setAlert({ status: true, severity: 'error', msg: 'Ha ocurrido un error solicitando las notificaiones. Por favor reintente o comuníquese con el personal de Sistemas.' })
      })
      .finally(() => setLoading(false))
  }

  useEffectAbortable(({ aborted }) => {
    fetchNotificationUnread(aborted)
  }, [])

  useEffectAbortable(({aborted}) => {
    const interval = setInterval(() => {
      fetchNotificationUnread(aborted)
    }, 1000 * 60 * 2)
    return () => clearInterval(interval)
  }, [])

  const getIcon = codNotification => {
    switch (codNotification.toString()) {
      case '1': return <FontAwesomeIcon icon={faRedo} />
      case '2': return <FontAwesomeIcon icon={faFileAlt} />
      case '3': return <FontAwesomeIcon icon={faListAlt} />
      default: return <FontAwesomeIcon icon={faClipboard} />
    }
  }

  const notificationVisited = (coNotificacion, referencia) => () => {
    setLoading(true)
    http.patch(`api/notificacion/${coNotificacion}/marcarLeida`)
      .then(({ status }) => {
        if (status !== 200) throw Error(`Disculpe, ocurrió un error marcando como vista la notificación #${coNotificacion} con referencia al código <b>${referencia}</b>. Por favor intente más tarde.`)

        setAlert({
          status: true,
          severity: 'success',
          msg: `La notificación #<b>${coNotificacion}</b> con referencia al código <b>${referencia}</b> ha sido agregada como <b>vista</b>`
        })

        setNotifications(state => {
          let newState = JSON.parse(JSON.stringify(state))
          newState = newState.data.filter(n => n.co_notificacion !== coNotificacion)
          console.log({ state, newState })
          return { ...state, data: newState }
        })
      })
      .catch(err => {
        console.error('[Notification] PATCH marcarLeida', err)
        setAlert({ status: true, severity: 'error', msg: 'Ha ocurrido un error solicitando las notificaiones. Por favor reintente o comuníquese con el personal de Sistemas.' })
      })
      .finally(() => setLoading(false))
  }

  const goNotified = (coNotificacion, ruta, referencia) => () => {

    http.patch(`api/notificacion/${coNotificacion}/marcarLeida`)
    navigate(`/${ruta}/${referencia}`)
  }

  const formatName = (name, lastName) => {
    name = name.charAt(0).toUpperCase() + name.split(' ')[0].slice(1).toLowerCase()
    lastName = lastName.charAt(0).toUpperCase() + lastName.split(' ')[0].slice(1).toLowerCase()
    return `${name} ${lastName}`
  }

  return (
    <Layout title={`Notificaciones (${notifications?.data?.length || 0})`}>
      <Container>
        <Alert />
        {loading
          ? <Loader />
          : !notifications.data.length
              ? (
                <Header>
                  <h1>No hay Notificaciones Pendientes </h1>
                </Header>)
              : (
                <section>
                  <Filters
                    nameFilter='notifications'
                    data={notifications.data}
                    handleSetData={setNotifications}
                    options={[
                      { label: 'Tipos', name: 'tx_tipo' },
                      { label: 'Encargados', name: 'encargado' },
                      { label: 'Códigos', name: 'referencia' },
                      { label: 'Fechas', name: 'fecha_creado', typeValue: 'dateUTC' },
                      { label: 'Nombres Beneficiarios', name: 'nb_benefi' },
                      { label: 'Apellidos Beneficiarios', name: 'nb_apellid' }
                    ]}
                  />

                  <Header>
                    <h1>Notificaciones ({notifications.search.length} de {notifications.data.length})</h1>
                  </Header>

                  {notifications.search.map(n =>
                    <Notification key={n.co_notificacion}>
                      <NotificationInfo>
                        <Icon> {getIcon(n.co_tipo)} </Icon>
                        <div>
                          <div>{`[${n.referencia}] ${getDateTimeUTC(n.fecha_creado)} 
                        ${n.encargado !== -1 ? `  |  Encargado: ${n.encargado}` : ''}
                      `}
                          </div>
                          <div>{formatName(n.nb_benefi, n.nb_apellid)} {n.mensaje}</div>
                        </div>
                      </NotificationInfo>

                      <NotificationButtons>
                        <Button
                          label='Ver'
                          color='success'
                          onClick={goNotified(n.co_notificacion, n.ruta, n.referencia)}
                        />
                        <Button
                          label='Vista'
                          color='secondary'
                          onClick={notificationVisited(n.co_notificacion, n.referencia)}
                        />
                      </NotificationButtons>
                    </Notification>
                  )}
                </section>)}
      </Container>
    </Layout>
  )
}

export default Notifications
