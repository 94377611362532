import moment from 'moment-timezone'

export const getDateTimeUTC = (dateString, language = 'es', timeZone = 'America/Caracas') => {
  if (!dateString) return 'sin fecha'
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
    timeZone: timeZone
  }
  try {
    const milliseconds = Date.parse(dateString)
    const date = new Intl.DateTimeFormat(language, options).format(milliseconds)
    // console.log('date', date)
    return date
  } catch {
    return 'fecha invalida'
  }
}

export const getDateUTC = (dateString, language = 'es', timeZone = 'America/Caracas') => {
  if (!dateString) return 'sin fecha'
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: timeZone
  }
  try {
    const milliseconds = Date.parse(dateString)
    const date = new Intl.DateTimeFormat(language, options).format(milliseconds)
    return date
  } catch {
    return 'fecha invalida'
  }
}

export const getDay = (dateString, language = 'es', timeZone = 'America/Caracas') => {
  if (!dateString) return 'sin fecha'
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: timeZone
  }
  try {
    const milliseconds = Date.parse(dateString)
    const date = new Intl.DateTimeFormat(language, options).format(milliseconds)
    return date
  } catch {
    return 'fecha invalida'
  }
}

export const nameDay = fecha => [
  'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'
][new Date(fecha)]

/* Calcular dias */
const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
const monthsNumString = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
const daysForMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
// const codDay = [6, 0, 1, 2, 3, 4, 5] // numero de dia para calendario que comienza en lunes

function leap (year) { // bisiesto
  return !!((year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0)))
}

const sumDays = (day, month, year, amountDays) => {
  let daysMonth = (leap(year) && month === 1) ? 29 : daysForMonth[month]
  amountDays += day
  while (amountDays > daysMonth) {
    amountDays -= daysMonth

    if (month === 11) { month = 0; year++ } else month++
    daysMonth = (leap(year) && month === 1) ? 29 : daysForMonth[month]
  }

  amountDays = (amountDays < 10) ? `0${amountDays}` : amountDays.toString()
  month++
  month = (month < 10) ? `0${month}` : month.toString()
  return [amountDays, month, year.toString()]
}

const subtractionDays = (day, month, year, amountDays) => {
  console.log({amountDays, day})
  if (amountDays >= day) {
    amountDays -= day
    if (month === 0) { month = 11; year-- } else month--
    let daysMonth = (leap(year) && month === 1) ? 29 : daysForMonth[month]

    while (amountDays > daysMonth) {
      amountDays -= daysMonth
      if (month === 0) { month = 11; year-- } else month--
      daysMonth = (leap(year) && month === 1) ? 29 : daysForMonth[month]
    }

    day = daysMonth - amountDays
  } else {
    day -= amountDays
  }

  day = (parseInt(day) < 10) ? `0${day}` : day.toString()
  month++
  month = (month < 10) ? `0${month}` : month.toString()
  return [day, month, year.toString()]
}

export const formatDate = (day, month, year, separator = '/') => {
  day = day.toString()
  month = months.toString()
  year = year.toString()
  return (`${day}${separator}${month}${separator}${year}`)
}

const getNextMonth = (month, year) => {
  month = parseInt(month)
  if(month === 12){
    month = 1
    year++
  }else{
    month ++
  }
  return [month, year]
}

export const getWeek = (countNextWeek=0, date) => {
  // console.log({countNextWeek, date})
  // let today = date ? new Date(date) :new Date()
  let tz = 'America/Caracas'
  let today = date ? moment(date).tz(tz) :moment().tz(tz)
  let codDay = today.get('weekday')
  let  day = today.get('date')
  let  month = today.get('month')
  let  year = today.get('year')
  let startNextWeek = ''
  let fi, ff
  // console.log({date, today, day, month, year})
  fi = subtractionDays(day, month, year, 0)
  ff = sumDays(day, month, year, 6 - codDay)

  while (countNextWeek > 0) {
    fi = subtractionDays(day, month, year, codDay)
    ff = sumDays(day, month, year, 6 - codDay)
    fi = sumDays(parseInt(fi[0]), parseInt(fi[1]) - 1, parseInt(fi[2]), 7)
    ff = sumDays(parseInt(ff[0]), parseInt(ff[1]) - 1, parseInt(ff[2]), 7)
    
    startNextWeek = `${ff[2]}-${ff[1]}-${ff[0]}`
    today = moment(startNextWeek).tz(tz)
    codDay = today.get('weekday')
    day = today.get('date')
    month = today.get('month')
    year = today.get('year')
    // codDay = today.getDay()
    // day = today.getDate()
    // month = today.getMonth()
    // year = today.getFullYear()
    
    countNextWeek--
  }

  return [fi, ff]
}

export const getWeeksMonth = (month, year) => {
  month = month || new Date().getMonth() + 1
  year = year || new Date().getFullYear()
  const date = `${year}/${month}/1`
  let weeks = []
  let weeksCount = 5

  while(weeksCount >= 0){
    weeks.unshift(getWeek(weeksCount, date))
    weeksCount--
  }

  weeks = weeks.reduce((acc, curr) => {
    const monthStart = curr[0][1]
    const monthEnd = curr[1][1]
    const weekOfTheMonth =  monthStart === monthsNumString[month-1] && monthEnd === monthsNumString[month-1]
    const weekEndOfTheMonth = monthStart === monthsNumString[month-1] && monthEnd === monthsNumString[month-0]

    if(weekOfTheMonth) return acc.concat([curr])
    if(weekEndOfTheMonth){
      curr[1][0] = daysForMonth[month-1].toString()
      curr[1][1] = monthsNumString[month-1]
      return acc.concat([curr])
    } 
    return acc
  }, [])
  
  return weeks
}

export const getWeeksAppointment = (day, month, year) => {
  const today = moment().tz('America/Caracas')
  day = day || today.format('D')
  month = month || today.format('MM')
  year = year || today.format('YYYY')
  day = parseInt(day)
  
  const monthCurrent = getWeeksMonth(month, year)
  
  let weeks = monthCurrent.reduce((acc, curr) => {
    const dayStart = parseInt(curr[0][0])
    const dayEnd = parseInt(curr[1][0])
    const nextWeek =  dayStart > day
    const lastWeek = dayEnd < day
    const startingWeek = dayStart === day
    // const endingWeek = dayEnd === day
    if(nextWeek) return acc.concat([curr])
    if(lastWeek) return acc
    if(startingWeek) return acc.concat([curr])
    // Caso faltante: está terminando la semana o está en progreso   
    curr[0][0] = day.toString()
    return acc.concat([curr])
  }, [])

  if(weeks.length === 2){ //si es la última semana manda el mes siguiente
    const [monthNext, yearNext] = getNextMonth(month, year)
    const weeksMonthNext = getWeeksMonth(monthNext, yearNext)
    weeks = weeks.concat(weeksMonthNext)
  }

  return weeks
}

export const getDate = fecha => {
  // const nameDays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
  if (!fecha) return 'Sin Fecha'

  let fechaString = fecha.split('T')[0]
  const [year, month, day] = fechaString.split('-')
  fechaString = `${day}/${month}/${year}`
  fecha = new Date(fechaString)
  // return `${fechaString} - ${nameDays[fecha.getDay()]}`
  return fechaString
}
