import styled from 'styled-components'

const width = '220px'

export const DocumentName = styled.h2`
    margin: auto;
    text-align: center;
    color: ${({ theme }) => theme.color.bluePrimary}
`

export const Instructions = styled.div`
    display: flex;
    flex-direction: column;
    /* width: ${width}; */
    
    h4 {
        font-weight: bold;
    }

    p{
        text-indent: 10px;
        margin: 0;
    }
`

export const Formats = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
    h4 {
        margin: 0; 
        font-weight: bold;
    }
    p{
        text-indent: 10px;
        margin: 0;
    }
`

export const Form = styled.form`
    display: ${({ loading }) => loading ? 'none' : 'flex'};
    flex-wrap: wrap;
    justify-content: ${({ documents }) => documents === 1 ? 'center' : 'space-around'};
    margin-top: 20px;
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`

export const LabelFile = styled.label`
    /* width: ${width}; */
    /* height: 280px; */
    margin: 10px 8px;
    cursor: pointer;
`

export const File = styled.input`
    width: 0px;
    height: 0px;
`

export const PreviewImg = styled.img`
    width: ${width};
    height: 280px;
    margin: auto;
    border-radius: 5%;
    box-shadow: 0 5px 10px #666;
`

export const Limitations = styled.div`
    width: ${width};
    display: flex;
    flex-direction: column;

    h4 {
        font-weight: bold;
    }
`

export const StatusDiv = styled.div`
    display: flex;
    justify-content: center;
`

export const ButtonDelete = styled.div`
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    background-color: #d32f2f;
    cursor: pointer;
    z-index: 1;
`

export const Buttons = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 20px;

    button{
        margin-top: 10px;
    }

    @media (min-width: 520px) {
        flex-direction: row;
    }
`
