import { useState } from 'react'

import Layout from '@components/admin/Layout'
import Beneficiaries from '@components/Beneficiaries'
import CitaCard from '@components/portal/CitaCard'
import Loader from '@components/Loader'
import StatusCita from '@components/StatusCita'
import Button from '@components/Button'
import PhotoFrameDocument from '@components/PhotoFrameDocument'
import Select from '@components/inputs/Select'
import InputText from '@components/inputs/Text'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Grid from '@mui/material/Grid'

import http from '@utils/fetch-client'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { useAlert } from '@hooks/useAlert'
import { formatMoney } from '@utils/formats'
import { useStateValue } from '@context'
import config from '@utils/config'
import useStyles from './stylesUI'
import { Container, Section, SectionPayment, Buttons, StatusMain, SubTitle } from './styles'

const initialForm = {
  co_banco: '',
  representante: '',
  monto: '',
  comprobante: ''
}
const CitasEdit = ({ id, request }) => {
  const classes = useStyles()
  const [{ user }] = useStateValue()
  const [loading, setLoading] = useState(true)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [form, setForm] = useState(initialForm)
  const [cita, setCita] = useState({})
  const [usuario, setUsuario] = useState(null)
  const [owner, setOwner] = useState(null)
  const [banks, setBanks] = useState([])
  const [Alert, setAlert] = useAlert({})
  const [AlertUser, setAlertUser] = useAlert({})
  const [AlertPayment, setAlertPayment] = useAlert({})

  const isConfirmed = cita.status === 'Confirmada'
  const isSAM = cita.status === 'En SAM'
  const isPaid = cita.status === 'Pagada'
  const isRejected = cita.status === 'Rechazada'
  const isOwner = cita.co_encargado === user.co_usuario

  console.log({ form, cita })
  /* console.log('usuario', usuario) */
  useEffectAbortable(({ aborted }) => { // Obtener Cita
    http.get(`api/cita/${id}`)
      .then(({ status, data }) => {
        if (aborted) return
        if (status !== 200 || !data.length) throw JSON.stringify({ status, data })
        setCita({ ...data[0], profesional: {} })
      })
      .catch(err => {
        console.error('[CitaDetail] Obtener Cita: ', err)
        setAlert({ status: true, severity: 'error', msg: `Ocurrió un error solicitando los datos de la Cita (COD ${id})` })
      })
      .finally(() => setLoading(false))
  }, [])

  useEffectAbortable(({ aborted }) => { // Obtener Usuario
    if (usuario === null && cita.co_usuario) {
      console.log(`api/usuario/${cita.co_usuario}`)
      http.get(`api/usuario/${cita.co_usuario}`)
        .then(({ status, data }) => {
          if (aborted) return
          if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')
          setUsuario(data)
        })
        .catch(err => {
          console.error(err)
          setAlertUser({ status: true, severity: 'error', msg: 'Disculpe, hubo un error solicitando el beneficiariod de esta solicitud' })
        })
    }
  }, [cita])

  useEffectAbortable(({ aborted }) => {
    if (owner !== null || !Object.keys(cita).length) return
    if (cita.co_encargado) {
      // Se le informa al usuario si está a cargo o no de la solicitud
      if (isOwner) {
        setAlert({
          status: true,
          severity: 'success',
          msg: 'Usted es el operador encargado. Puede emitir acciones sobre esta Solicitud'
        })
      } else {
        setAlert({
          status: true,
          severity: 'warning',
          msg: 'Usted NO es el operador encargado. Puede Observar esta Solicitud pero sin emitir acciones'
        })
      }
      // Información del usuario encargado
      http.get(`api/usuario/${cita.co_encargado}`)
        .then(({ status, data }) => {
          if (aborted) return
          if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta de datos del encargado. Por favor intente más tarde.')
          setOwner(data)
        })
        .catch(err => setAlert({ status: true, severity: 'error', msg: err }))
    } else {
      const body = new FormData()
      body.append('co_encargado', user.co_usuario)

      http.patch(`api/cita/${id}/asignarEncargado`, body)
        .then(({ status, data }) => {
          if (aborted) return
          if (status === 403) {
            return setAlert({
              status: true,
              severity: 'info',
              msg: 'Esta solicitud ya tiene un operador encargado. Puede Observar esta Solicitud pero sin emitir acciones'
            })
          }
          if (status !== 200) throw Error('Disculpe, ocurrió un error asignando operador encargado. Por favor intente más tarde.')
          setAlert({ status: true, severity: 'success', msg: 'Esta Solicitud le ha sido asignada exitosamente' })
          setCita(state => ({ ...state, co_encargado: data.insertado.co_encargado }))
        })
        .catch(err => setAlert({ status: true, severity: 'error', msg: err }))
    }
  }, [cita, setAlert])

  useEffectAbortable(({ aborted }) => { // Obtener Bancos
    http.get('api/cita/bancos')
      .then(({ status, data }) => {
        if (aborted) return
        if (status !== 200) throw Error('Disculpe, ocurrió un error cargando los bancos. Por favor intente más tarde.')
        setBanks(data)
      })
      .catch(err => {
        console.error(err)
        setAlertUser({ status: true, severity: 'error', msg: 'Disculpe, hubo un error cargando los bancos' })
      })
  }, [])

  const handleChange = ({ name, value }) => {
    setForm(form => ({ ...form, [name]: value }))
  }

  const formValid = () => {
    const valid = true
    if (!form.co_banco) return false
    if (!form.representante) return false
    if (!form.monto) return false
    if (!form.comprobante) return false

    return valid
  }

  const dataMails = () => {
    const user = usuario.usuario
    const body = {
      co_solicitud: id,
      name: user.nb_benefi ? user.nb_benefi.trim() : '',
      lastname: user.nb_apellid ? user.nb_apellid.trim() : '',
      email: user.tx_correo ? user.tx_correo.trim() : '',
      concepto: cita.especialidad
    }

    return Object.entries(body).reduce((acc, [key, value], index) => {
      return `${acc}${index ? '&' : ''}${key}=${value}`
    }, '?')
  }

  const handleConfirm = () => {
    setLoadingPayment(true)
    const query = dataMails()
    const data = new FormData()
    data.append('observaciones', cita.observaciones)
    data.append('co_usuario', cita.observaciones)

    http.patch(`api/cita/${cita.co_cita_web}/confirmar${query}`, data)
      .then(({ status, data }) => {
        console.log(status, data)
        if (status !== 200) throw data
        setCita({ ...cita, status: 'Confirmada' })
        setAlertPayment({ status: true, severity: 'success', msg: data.message })
      })
      .catch(err => {
        console.error(err)
        setAlertPayment({ status: true, severity: 'error', msg: 'Disculpe, hubo un error Confirmando la Cita' })
      })
      .finally(() => setLoadingPayment(false))
  }

  const handleReject = () => {
    setLoadingPayment(true)
    const query = dataMails()
    const data = new FormData()
    data.append('observaciones', cita.observaciones)

    http.patch(`api/cita/${cita.co_cita_web}/rechazar${query}`, data)
      .then(({ status, data }) => {
        if (status !== 200) throw data
        // console.log(status, data)
        setCita({ ...cita, status: 'Rechazada' })
        setAlertPayment({ status: true, severity: 'success', msg: data.message })
      })
      .catch(err => {
        console.error(err)
        setAlertPayment({ status: true, severity: 'error', msg: 'Disculpe, hubo un error rechazando la Cita' })
      })
      .finally(() => setLoadingPayment(false))
  }

  const handlePayment = () => {
    if (!formValid()) return setAlertPayment({ status: true, severity: 'error', msg: 'Disculpe, faltan datos del pago.' })
    setLoadingPayment(true)
    // const query = dataMails()
    const data = new FormData()
    data.append('co_banco', form.co_banco)
    data.append('representante', form.representante)
    data.append('monto', form.monto)
    data.append('comprobante', form.comprobante)

    // http.patch(`api/cita/${cita.co_cita_web}/crearFactura${query}`, data)
    http.patch(`api/cita/${cita.co_cita_web}/crearFactura`, data)
      .then(({ status, data }) => {
        console.log('handlePayment', { status, data })
        if (status !== 201) throw data
        setCita({ ...cita, factura: data.factura, status: 'En SAM' })
        setAlertPayment({ status: true, severity: 'success', msg: 'La cita ha sido facturada exitosamente' })
      })
      .catch(err => {
        console.error(err)
        setAlertPayment({ status: true, severity: 'error', msg: 'Disculpe, hubo un error Facturando la Cita' })
      })
      .finally(() => setLoadingPayment(false))
  }

  const handleObservation = ({ target }) => {
    // console.log(target)
    setCita({
      ...cita,
      observaciones: target.value
    })
  }

  return (
    <Layout
      active='citas'
      title={`Cita ${id}`}
    >
      <Container>
        <Alert />
        {loading
          ? <Loader />
          : !Object.keys(cita).length
              ? null
              : (
                <>
                  <AlertUser />
                  {usuario && (
                    <>
                      <SubTitle>Beneficiario:</SubTitle>
                      <Beneficiaries beneficiaries={[usuario.usuario]} />
                    </>)}

                  {owner && (
                    <>
                      <SubTitle>Operador encargado:</SubTitle>
                      <Beneficiaries beneficiaries={[owner.usuario]} />
                    </>)}

                  <Section>
                    <StatusMain>
                      <span>Esta Cita está</span>
                      <StatusCita status={cita.status} />
                    </StatusMain>
                    {/* <h1>Cita:</h1> */}
                  </Section>

                  <CitaCard cita={cita} />

                  <SectionPayment>
                    {cita.comprobante &&
                      <PhotoFrameDocument
                        cursor
                        src={`${config.statics.citas}/${cita.comprobante}`}
                        // alt={`${name}-${co_documento} del reembolso`}
                        description='Comprobante de Pago'
                      />
                    }

                    {isConfirmed || isSAM
                      ? (
                        <Buttons>
                          <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                              <Select
                                label='Seleccione el banco'
                                name='co_banco'
                                options={banks.map(b => ({ opcionID: b.co_banco, opcionDes: b.nb_banco }
                                ))}
                                disabled={!isOwner || isSAM}
                                value={isSAM ? cita?.factura?.co_banco : form.co_banco}
                                handleChange={handleChange}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <InputText
                                label='Titular de la cuenta'
                                name='representante'
                                value={isSAM ? cita?.tx_documento : form.representante}
                                disabled={!isOwner || isSAM}
                                handleChange={handleChange}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <InputText
                                label='Ingrese el monto'
                                name='monto'
                                value={isSAM ? formatMoney(cita?.factura?.mn_fact_ben) : form.monto}
                                disabled={!isOwner || isSAM}
                                handleChange={handleChange}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <InputText
                                label='Referencia del pago'
                                name='comprobante'
                                value={isSAM ? cita?.factura?.nu_registro : form.comprobante}
                                disabled={!isOwner || isSAM}
                                handleChange={handleChange}
                              />
                            </Grid>
                          </Grid>

                          <AlertPayment />
                          {loadingPayment && <Loader />}

                          {isConfirmed &&
                            <Button
                              label='Facturar y Crear'
                              color='success'
                              onClick={handlePayment}
                              disabled={!isOwner || loadingPayment}
                            />}
                        </Buttons>)

                      : isPaid || isRejected
                        ? (
                          <Buttons>
                            <div>
                              <TextareaAutosize
                                rowsmin={4}
                                aria-label='observaciones'
                                placeholder='Observación sobre la Confirmación o el Rechazo'
                                id='observacion'
                                value={cita.observaciones || ''}
                                onChange={handleObservation}
                                label='Observación de rechazo'
                                name='observation'
                                disabled={!isOwner || loadingPayment}
                              />
                            </div>

                            <AlertPayment />
                            {loadingPayment && <Loader />}

                            {isPaid /* Para el estado inicial */
                              ? (
                                <>
                                  <Button
                                    label='Rechazar'
                                    color='danger'
                                    onClick={handleReject}
                                    disabled={!isOwner || loadingPayment}
                                  />

                                  <Button
                                    label='Confirmar'
                                    color='success'
                                    onClick={handleConfirm}
                                    disabled={!isOwner || loadingPayment}
                                  />
                                </>)

                              : isRejected
                                ? (
                                  <Button
                                    label='Confirmar'
                                    color='success'
                                    onClick={handleConfirm}
                                    disabled={!isOwner || loadingPayment}
                                  />)

                                : null}
                            {/* <Button
                          label={(isConfirmed) ? 'Rechazar' : 'Confirmar'}
                          color={(isConfirmed) ? 'danger' : 'success'}
                          onClick={(isConfirmed) ?handleReject :handleConfirm}
                          disabled={requestIsValid || loadingPayment}
                        /> */}
                          </Buttons>)

                        : null}

                  </SectionPayment>

                </>)}
      </Container>
    </Layout>
  )
}

export default CitasEdit
