import styled from 'styled-components'

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* margin-bottom: 20px; */
`

export const Section = styled.div`
    display: flex;
    /* align-items: center; */
    width: 85%;
    margin: auto;
    padding: 5px 0;
    flex-direction: column;
    
    h1{
        color: ${({ theme }) => theme.color.bluePrimary}
    }

    @media(min-width: 750px){
        flex-direction: row;
    }
`

export const SectionPayment = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin: auto;
    padding: 5px 0;
    flex-direction: column;
    
    h1{
        color: ${({ theme }) => theme.color.bluePrimary}
    }

    @media(min-width: 750px){
        flex-direction: row;
    }
`

export const FormContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;

    @media(min-width: 750px){
        flex-direction: row;
    }
`

export const Form = styled.div`
    display: flex;
    width: 55%;
    margin: 0 0 10px 10px;
    color: ${({ theme }) => theme.color.gray};
    flex-direction: column;    
    
    div{
        display: flex;
        padding: 5px 0;
        b{
            margin-right: 10px;
        }
    }
`

export const FormVerify = styled.div`
    width: 100%;
`

export const StatusMain = styled.div`
    display: flex;
    padding: 5px 0;
    margin: 15px 0;
    font-size: 18px;
    border-left: solid 2px ${({ theme }) => theme.color.bluePrimary};

    span{
        padding: 0 5px;
    }
`

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-around;
    height: 30rem;

    @media(min-width: 750px){
        width: 40%;
    }
`

export const SubTitle = styled.h3`
    font-size: 18px;
    width: 85%;
    font-weight: 400;
    padding: 0 5px;
    margin: 0 auto;
    border-left: solid 2px ${({ theme }) => theme.color.bluePrimary};
`
