import Status from '@components/StatusDoc'
import FileVerification from '@components/admin/FileVerification'

import http from '@utils/fetch-client'

import { Title, Container } from './styles'

const DocumentVerification = ({
  co_solicitud, document,
  requestIsValid, isOwner, requestName, updateRequest
}) => {
  const { name, files } = document

  const handleToogle = (index, statusDoc, co_documento, observation) => {
    const url = statusDoc === 2 // es valido, por tanto se quiere rechazar
      ? `api/solicitud/${co_solicitud}/error/${co_documento}`
      : `api/solicitud/${co_solicitud}/validar/${co_documento}`

    const body = new FormData()
    body.append('observaciones', observation)
    return http.patch(url, body)
      .then(({ status }) => {
        if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')

        if (statusDoc === 2) {
          files[index].co_status = 3
          files[index].observaciones = ''
        } else {
          files[index].co_status = 2
        }

        if (updateRequest) updateRequest(name, files)
        return true
      })
      .catch(err => {
        console.log('err', err)
        return false
      })
  }

  const handleSave = index => (observation, co_documento) => {
    const body = new FormData()
    body.append('observaciones', observation)
    return http.patch(`api/solicitud/${co_solicitud}/error/${co_documento}`, body)
      .then(({ status }) => {
        if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')
        files[index].observaciones = observation

        if (updateRequest) updateRequest(name, files)
        return true
      })
      .catch(err => {
        console.log('err', err)
        return false
      })
  }

  const getStatus = () => {
    const valido = files.reduce((acc, curr) => acc && curr.co_status === 2, true)
    const rechazado = files.reduce((acc, curr) => acc || curr.co_status === 3, false)

    return valido ? 2 : rechazado ? 3 : 1
  }

  const getTitle = name => {
    name = name.charAt(0).toUpperCase() + name.split(' ')[0].slice(1).toLowerCase()
    return name.replace('-', ' ')
  }

  return (
    <Container>
      <Title>
        <h2> {getTitle(name)}
          <Status status={getStatus()} />
        </h2>
      </Title>

      {files.map((file, index) => (
        <FileVerification
          key={file.co_documento}
          file={file}
          index={index}
          name={name}
          requestIsValid={requestIsValid}
          isOwner={isOwner}
          requestName={requestName}
          handleToogle={handleToogle}
                // handleChange={handleChange}
          handleSave={handleSave(index)}
          description={getTitle(name)}
          co_solicitud={co_solicitud}
        />
      ))}
    </Container>
  )
}

export default DocumentVerification
