import { useEffect } from 'react'
import Layout from '@components/Layout'
import LayoutGuide from '@components/portal/guide/LayoutGuide'
// import PhotoFrameDocument from '@components/PhotoFrameDocument'
import Player from '@components/Player'
import { videos } from '@utils/static/guide'

import { useStateValue } from '@context'

const GuideGeneralOtros = ({ location }) => {
  const [{ user }] = useStateValue()

  useEffect(() => {
    window.location.hash = ''
    window.location.hash = location.hash
  }, [location.key, location.hash])

  const isBeneficiarie = user.co_tipo === 1

  const getOptionMenuOfPath = () => {
    const pathname = location.pathname.split('/')
    return `${pathname[pathname.length - 1]}${location.hash}`
  }

  return (
    <Layout title='General | Manual de Usuario' active='guia'>
      <LayoutGuide optionMenu={getOptionMenuOfPath()}>
        <div id='general' />
        <h1>GUÍA AL USUARIO</h1>

        <section id='estructura'>
          <h2> Estructura de la app </h2>
          {isBeneficiarie
            ? <Player video={videos.general.estructuraPrincipal} />
            : (
              <>
                <Player video={videos.general.estructuraPrincipalCompleta} />
                <Player video={videos.general.rutasProtegidas} />
              </>)}
        </section>

        {/*
        <section id='leyendas'>
          <h2> Leyendas </h2>

        </section>

        <section id='documentos'>
          <h2> Documentos </h2>

          <p id='des_documentos_status'> <b>STATUS:</b> A lo largo de los procesos de una solicitud sus documentos van a estar variando
            de estado conforme a lo sucedido en cada una de las etapas en las que está involucrado, a pesar de
            que en cada uno de los módulos del portal donde se hace mención a status se encuentra una
            <a href='#leyendas'>leyenda</a> correspondiente a los mismos, en esta sección se pretende
            explicar más a detalle cada status y tambien su comportamiento.
          </p>

        </section> */}
      </LayoutGuide>
    </Layout>
  )
}

export default GuideGeneralOtros
