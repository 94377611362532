import styled from 'styled-components'

export const ContainerDocument = styled.section`
    display: flex;
    justify-content: center; 
    flex-direction: column;
    margin-bottom: 150px;
    
    @media(min-width: 750px){
        flex-direction: row;    
        margin-bottom: 0;
    }
`

export const Document = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media(min-width: 750px){
        width: 50%;
    }
`

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;

    button{
        margin: 20px 20px 0 20px;
    }

    @media(min-width: 750px){
        width: 50%;
    }
`

export const Title = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
 
    span{
        font-size: 18px;
        margin-left: 10px;
    }
`

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    
    @media (min-width: 1000px){
        flex-direction: row; 
    }
`
