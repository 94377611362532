import styled from 'styled-components'

export const Container = styled.span`
  color: ${({ theme, status }) =>
    (status === 1) ? theme.color.inProgress
    : (status === 2) ? theme.color.success
    : (status === 3) ? theme.color.danger
    : theme.color.gray
  };
`
