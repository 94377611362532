import { useEffect } from 'react'
import Layout from '@components/Layout'
import LayoutGuide from '@components/portal/guide/LayoutGuide'
import PhotoFrameDocument from '@components/PhotoFrameDocument'
import { videos } from '@utils/static/guide'
import Player from '@components/Player'

import { useStateValue } from '@context'
import config from '@utils/config'
const imgSize = { width: '85%', height: '85%' }

const ReembolsosGuide = ({ location }) => {
  const [{ user }] = useStateValue()

  useEffect(() => {
    window.location.hash = ''
    window.location.hash = location.hash
  }, [location.key, location.hash])

  const isBeneficiarie = user.co_tipo === 1

  const getOptionMenuOfPath = () => {
    const pathname = location.pathname.split('/')
    return `${pathname[pathname.length - 1]}${location.hash}`
  }

  return (
    <Layout title='Reembolsos | Manual de Usuario' active='guia'>
      <LayoutGuide optionMenu={getOptionMenuOfPath()}>
        <div id='general' />
        <h1>REEMBOLSOS</h1>

        <section id='listado'>
          <h2> LISTADO </h2>

          <PhotoFrameDocument
            cursor width={imgSize.width} height={imgSize.height}
            src={`${config.statics.manual}/reembolsos/listado.jpg`}
            alt='Estructura de la vista con el listado de las solicitudes de Reembolso al IPP-UCV'
          />

          <ul>
            <li><b>Solicitar Reembolso:</b> Permite navegar hacia la <a href='#solicitud'>página de Solicitud</a>.</li>
            <li id='des_list_reembolso'><b>Reembolso:</b> Cada una de las filas de la tabla que representa un Reembolso, muestra
              informaciòn básica que ayuda buscar la solicitud deseada para emiir una acción sobre ella.
            </li>
            <li><b>Paginación:</b> Conjunto de botones para navegar entre el total de las solicitudes.</li>
            <li><b>Boton de leyenda:</b> Botón que sirve tanto para mostrar y ocultar la información de
              la <a href='#des_list_leyenda'>leyenda</a> respectivamente.
            </li>
            <li id='des_list_reembolso'><b>Leyenda:</b> Listado que identifica y describe los status mostrados en cada
              <a href='#des_list_reembolso'>Reembolso</a>.
            </li>
          </ul>
        </section>

        <section id='solicitud'>
          <h2>SOLICITUD</h2>
          <Player video={videos.cartasReembolsos.solicitud} />

          <p>Como primer paso para crear una solicitud de Reembolso debe agregarle una descripción como
            "concepto" de su solicitud, ésta servirá de identificador y luego no será modificable
          </p>

          <PhotoFrameDocument
            cursor width={imgSize.width} height={imgSize.height}
            src={`${config.statics.manual}/reembolsos/paso_descripcion.jpg`}
            alt='Vista del primer paso, descripción de solicitud de Reembolso al IPP-UCV'
          />

          <p>Luego en los pasos siguientes se encontrará con una vista similar a la que le mostraremos a continuación
            junto a su estructura y funcionamiento:
          </p>

          <PhotoFrameDocument
            cursor width={imgSize.width} height={imgSize.height}
            src={`${config.statics.manual}/reembolsos/cuadro_solicitud_detalles.jpg`}
            alt='Estructura de la vista al solicitar un Reembolso al IPP-UCV'
          />

          <ul>
            <li><b>Ruta:</b> Especifica en qué módulo de la aplicación se encuentra, incluye el código de la solicitud
              al final, con este código es que se identifica en Sistema.
            </li>
            <li id='des_navegador'><b>Navegador:</b> Herramienta para saltar al paso que se desee (No guarda estado de archivos que aún no
              hayan sido subidos clickeando el botón <a href='#des_btn_continuar'>CONTINUAR</a>).
            </li>
            <li><b>Nombre:</b> Descripción del tipo de documento, acompañado de un '*' si es obligatorio.</li>
            <li><b>Descripción:</b> Describe los detalles del documento que se requiere.</li>
            <li><b>Formatos Permitidos:</b> Los formatos de archivos que son permitidos enviar en determinado paso.</li>
            <li><b>Documentos:</b> Archivos relacionados al paso en que se encuentra (Nombre), se encuentran los
              que ya haya subido y los que estè seleccionando en el momento (<a href='s'>Ver Detalle de Status de Documentos</a>).
            </li>
            <li><b>Botón Regresar:</b> Regresa al paso anterior sin guardar los documentos que se acaban de montar y aún
              no han sido subidos o guardados con el botón <a href='#des_btn_continuar'>CONTINUAR</a>.
            </li>
            <li id='des_btn_continuar'><b>Botón Continuar:</b> Guarda y actualiza los archivos en el área de 'Documentos' y de ser exitoso avanza
              al siguiente paso, si no, informa el error y se mantiene en el mismo paso.
            </li>
          </ul>

          <p> <b>¡OJO!</b> EL último paso es muy importante, en este caso el botón <a href='#des_btn_continuar'>CONTINUAR</a> no solo sirve
            para guardar los documentos seleccionados sino que además al no tener paso siguiente como los demás
            pasos tambien hace el papel de Finalizar la solicitud, por lo que ademas de guardar los documentos
            relacionados al paso va a verificar si en los demás pasos ya se montó al menos una imagen; Si falla
            la conexión al servidor será notificado y se mantendrá en el mismo paso, pero si la conexión es exitosa
            entonces hay dos posibles escenarios:
          </p>

          <p> <b>1)</b> Se guardan los documentos pero se mantiene aún en el proceso de la solicitud porque algún
            otro paso está sin documentos (ambas acciones le son notificadas):
          </p>

          <PhotoFrameDocument
            cursor width={imgSize.width} height={imgSize.height}
            src={`${config.statics.manual}/reembolsos/ultimo_paso_incompleto.jpg`}
            alt='Vista cuando el último paso exitoso pero solicitud de Reembolso al IPP-UCV no finalizada'
          />

          <p> <b>Solución a caso 1:</b> dirígase a los paso que no ha terminado, cargue los documentos y para Finalizar
            y mandar a revisión de igual modo se dirije en el <a href='#des_navegador'>Navegador</a> al último paso
            y le da click a <a href='#des_btn_continuar'>CONTINUAR</a>:
          </p>

          <p> <b>2)</b> Se verificó que en los otros pasos ya habiá guardado al menos un documentos por lo que
            se asume que ha finalazido el proceso y su solicitud queda en espera por revisión, puede ir
            directamente al detalle clickeando "Ver detalle" o dirigiendose al <a href='#listado'>listado</a> :
          </p>

          <PhotoFrameDocument
            cursor width={imgSize.width} height={imgSize.height}
            src={`${config.statics.manual}/reembolsos/ultimo_paso_completo.jpg`}
            alt='Vista cuando el último paso exitoso y solicitud de Reembolso al IPP-UCV finalizada'
          />
        </section>

        <section id='detalle'>
          <h2>DETALLE</h2>

          <PhotoFrameDocument
            cursor width={imgSize.width} height={imgSize.height}
            src={`${config.statics.manual}/reembolsos/detalle_vista.jpg`}
            alt='Estructura de la vista con el listado de las solicitudes de Reembolso al IPP-UCV'
          />

          <ul>
            <li><b>Ruta:</b> Especifica en qué módulo de la aplicación se encuentra, incluye el código de la solicitud
              al final, con este código es que se identifica en Sistema.
            </li>
            <li id='des_navegador'><b>Leyendas:</b> Botones que ocultan o muestran respectivamente tanto
              los estado (status) de la Solicitud con su descripción como el de los documentos.
            </li>
            <li><b>Estado de la Solicitud:</b> Estado en el cual se encuentra una solicitud, este va depender del estado de
              cada uno de los documentos.
            </li>
            <li id='des_detail_file'><b>Archivo:</b> Archivo enviado que representa un <a href='#des_detail_doc'>documento</a>.</li>
            <li id='des_detail_doc'><b>Documento:</b> Tipo de documento que se especifica para la solicitud (obligatorio u opcional).
              Son colecciones, es decir, pueden tener multiples <a href='#des_detail_file'>archivos</a>.   Su Status va
              depender del Status de cada uno de sus archivos.
            </li>
          </ul>
        </section>

        <section id='modificar'>
          <h2>MODIFICAR</h2>

          <Player video={videos.cartasReembolsos.modificarSolicitud} />

          <p>Para poder modificar archivos en una solicitud ésta debe estar o en creación o rechazada,
            si la solicitud ya ha sido enviada y está en proceso de revisión o ya está Confrimada no es modificable
          </p>

          <p><b>En Caso de Creación:</b> tal cual como se muestra en la sección de <a href='#des_detail_file'>Solicitud</a>
            usted tiene la libertad de cargar, cambiar y eliminar archivos en cada paso (estén estos cargados temporalmente
            o ya subidos a internet)
          </p>

          <PhotoFrameDocument
            cursor width={imgSize.width} height={imgSize.height}
            src={`${config.statics.manual}/reembolsos/detalle_rechazado.jpg`}
            alt='Boton para modificar una solicitudes de Reembolso Rechazada en el IPP-UCV'
          />

          <p><b>En Caso de Rechazo:</b> Al final de la página de <a href='#detalle'>Detalle</a>, luego de todos los documentos
            le aparecerá un boton azul que lo va enviar de nuevo a la <a href='#solicitud'>página de Solicitud</a> como si la estuviera creando pero con la diferencia que
            todos los archivos tendrán su respectivo status debajo y se respetará lo antes mencionado, solo serán
            modificables aquellos archivos que estén rechazados y los que quiere montar nuevos (en creación).
          </p>

          <p>De igual modo que en la creación, al finalizar los cambios se dirije al último y pulsa el boton de continuar para así
            solicitar la revisión de su solicitud nuevamente.
          </p>

          <PhotoFrameDocument
            cursor width={imgSize.width} height={imgSize.height}
            src={`${config.statics.manual}/reembolsos/modificar_documento.jpg`}
            alt='Boton para modificar una solicitudes de Reembolso Rechazada en el IPP-UCV'
          />

        </section>

        {isBeneficiarie
          ? null
          : (
            <section id='revision'>
              <h2>REVISIÓN</h2>
              <Player video={videos.cartasReembolsos.revisionSolicitud} />
              <Player video={videos.cartasReembolsos.revisionSolicitudModificada} />
              <Player video={videos.cartasReembolsos.samhoi} />
            </section>)}
      </LayoutGuide>
    </Layout>
  )
}

export default ReembolsosGuide
