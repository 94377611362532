import useStyles from './stylesUI'

import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

const NO_OP = () => { }

export default function InputSelect({
  name, label, value, defaultValue, options, disabled,
  handleChange = NO_OP, handleBlur = NO_OP
}) {
  const classes = useStyles()
  const myHandleChange = ({ target }) => handleChange(target)
  const myHandleBlur = ({ target }) => handleBlur(target)

  return (
    <FormControl>
      <InputLabel id={`${name}-label`} htmlFor={`select-${name}`} className={classes.padding} />
      <Select
        className={classes.padding}
        native
        onChange={myHandleChange}
        onBlur={myHandleBlur}
        value={value}
        defaultValue={defaultValue}
        name={name}
        inputProps={{
          name: name,
          id: `select-${name}`
        }}
        disabled={disabled}
      >
        <option key='0' value='0'>{label}</option>
        {options && options.map(({ opcionID, opcionDes, type }) =>
          <option
            key={opcionID}
            value={opcionID}
            label={opcionDes}
            data-type={type}
          >
            {opcionDes}
          </option>
        )}
      </Select>
    </FormControl>
  )
}
