import React, { useState, useEffect } from 'react'
import http from '@utils/fetch-client'
import Service from '@components/Service'
import Loader from '@components/Loader'
import { MsgError, Container } from './styles'

const ServicesList = ({ online }) => {
  const [services, setServices] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    http.get('api/servicio/public')
      .then(({ status, data }) => {
        if (status !== 200) throw Error('Disculpe, ocurrió un error consultando los Servicios. Por favor intente más tarde.')
        const dataServices = online
          ? data.filter(({ orden }) => orden === 1)
          : data.filter(({ orden }) => orden !== 1)
        setServices(dataServices)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }, [online])

  const getNavigate = serviceID => {
    switch (serviceID.toString()) {
      case '2': return '/directory'

      default: return null
    }
  }

  const getMessageError = () => {
    let msg = 'Disculpe, en estos momentos no se lograron cargar nuestros Servicios'
    if (online) msg = msg.concat(' Em Línea')
    return msg
  }

  if (loading) return <Loader />

  return (
    !services.length
      ? <MsgError> {getMessageError()} </MsgError>
      : (
        <Container>
          {services.map(({ co_servicio, cuerpo, titulo, imagen }, index) =>
            <Service
              key={co_servicio}
              index={index}
              description={cuerpo}
              title={titulo}
              src={imagen}
              alt={`servicio - ${titulo}`}
              navigatePath={getNavigate(co_servicio)}
            />)}
        </Container>)
  )
}

export default ServicesList
