import styled from 'styled-components'

export const Leyenda = styled.section`
    display: flex;
    flex-direction: column;
    width: 90vw;
    max-width: 1000px;
    margin: ${({ top }) => top ? '20px auto 0' : '-20px auto 0'};

    @media (min-width: 920px) {
        width: 75%;
    }
`

export const Status = styled.div`
    padding: 4px 0;
    
    b{
        color: ${({ theme, color }) => theme.color[color]}
    }
`
