import React, { useEffect, useState } from 'react'
import { Link, navigate } from '@reach/router'

import Button from '@components/Button'
import Layout from '@components/admin/Layout'
import Loader from '@components/Loader'

// import news from '@mocks/news'
import http from '@utils/fetch-client'
import { getDateUTC } from '@utils/date'
import { useAlert } from '@hooks/useAlert'
import { usePagination } from '@hooks/usePagination'

import { Container, Rows, Header, Post, Title, CreatedAt, UpdatedAt, Status } from './styles'

const Posts = () => {
  const [news, setNews] = useState([])
  const [newEmergent, setNewEmergent] = useState({})
  const [loading, setLoading] = useState(true)
  const [Alert, setAlert] = useAlert({})
  const [Pagination, idx] = usePagination(news.length, 5)

  const handleSubmit = () => {
    navigate('/admin/posts/create')
  }

  useEffect(() => {
    http.get('api/post')
      .then(({ status, data }) => {
        if (status !== 200) throw Error('Disculpe, ocurrió un error consultados los Post. Por favor intente más tarde.')
        setLoading(false)
        setNews(data.filter(({ co_post }) => co_post !== 1))
        setNewEmergent(data.find(({ co_post }) => co_post === 1))
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }, [])

  return (
    <Layout
      active='post'
      title='Post'
    >
      <Container>
        <Alert />
        {loading ? <Loader />
          : !news.length ? null
              : <>
                <section>
                  <Header>
                    <h1>Noticia Emergente</h1>
                  </Header>

                  <Post header>
                    <Title>Titulo</Title>
                    <CreatedAt>Fecha de creación</CreatedAt>
                    <UpdatedAt>Fecha de modificación</UpdatedAt>
                    <Status header> Estatus</Status>
                  </Post>

                  <Link to={`/admin/posts/${newEmergent?.co_post}`} key={newEmergent?.co_post}>
                    <Post>
                      <Title>{newEmergent?.titulo}</Title>
                      <CreatedAt>{getDateUTC(newEmergent?.fecha_creado)}</CreatedAt>
                      <UpdatedAt>{getDateUTC(newEmergent?.fecha_editado)}</UpdatedAt>
                      <Status published={newEmergent?.publicado}>{newEmergent?.publicado ? 'Publicado' : 'borrador'}</Status>
                    </Post>
                  </Link>
                </section>
                <section>
                  <Header>
                    <h1>Lista de Post</h1>
                    <div>
                      <Button
                        label='Crear Post'
                        color='primary'
                        onClick={handleSubmit}
                      />
                    </div>
                  </Header>

                  <Post header>
                    <Title>Titulo</Title>
                    <CreatedAt>Fecha de creación</CreatedAt>
                    <UpdatedAt>Fecha de modificación</UpdatedAt>
                    <Status header> Estatus</Status>
                  </Post>

                  <Rows>
                    {news.slice(idx.start, idx.end).map(({ co_post, titulo, fecha_creado, fecha_editado, publicado }) => (
                      <Link to={`/admin/posts/${co_post}`} key={co_post}>
                        <Post>
                          <Title>{titulo}</Title>
                          <CreatedAt>{getDateUTC(fecha_creado)}</CreatedAt>
                          <UpdatedAt>{getDateUTC(fecha_editado)}</UpdatedAt>
                          <Status published={publicado}>{publicado ? 'Publicado' : 'borrador'}</Status>
                        </Post>
                      </Link>
                    ))}
                  </Rows>

                  <Pagination />
                </section>
              </>}
      </Container>
    </Layout>
  )
}

export default Posts
