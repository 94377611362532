import { useState } from 'react'
import { navigate } from '@reach/router'

import Layout from '@components/admin/Layout'
import Loader from '@components/Loader'
import DateRangeSelector from '@components/DateRangeSelector'
import PieChartsFull from '@components/charts/PieChartsFull'
import TableCita from '@components/TableCita'
import Filters from '@components/Filters'

import http from '@utils/fetch-client'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { useAlert } from '@hooks/useAlert'

import { Container } from './styles'

const Citas = () => {
  const [loading, setLoading] = useState(true)
  const [citas, setCitas] = useState({
    Paga: [],
    Confirmada: [],
    Rechazada: [],
    'En SAM': []
  })
  const [Alert, setAlert] = useAlert({})
  // const [citas, setCitas] = useState({})
  // console.log('Citas', citas)

  const orderByDate = (a, b) =>
    (Date.parse(a.fecha_cita) < Date.parse(b.fecha_cita)) ? -1 : 1

  useEffectAbortable(({ aborted }) => { // Obtener Cita
    http.get('api/cita/getAll')
      .then(({ status, data }) => {
        console.log('Citas', { status, data })
        if (aborted) return
        if (status !== 200 || !Object.keys(data).length) throw JSON.stringify({ status, data })

        data = Object.entries(data).reduce((acc, [key, value]) => {
          value = value.map(solicitud => ({
            ...solicitud,
            encargado: solicitud.encargado || -1,
            co_cita_web: solicitud.co_cita_web || -1,
            paciente: solicitud.paciente.trim() || -1,
            medico: solicitud.medico.trim() || -1
          }))

          const citas = { [key]: value.sort(orderByDate) }
          // console.log(citas)
          return Object.assign(acc, citas)
        }, {})

        // setCitas({...citas, ...data})
        setCitas(data)
      })
      .catch(err => {
        console.error('[CitaDetail] Obtener Cita: ', err)
        setAlert({ status: true, severity: 'error', msg: err })
      })
      .finally(() => setLoading(false))
  }, [])

  const goToReport = (fi, ff) => {
    navigate(`citas/report/${fi}/${ff}`)
  }

  return (
    <Layout
      active='citas'
      title='Citas'
    >
      <Container>
        <Alert />
        <DateRangeSelector handleClick={goToReport} />

        {loading
          ? <Loader />
          : (
            <>
              <PieChartsFull
                data={Object.entries(citas).reduce((acc, [key, value]) => {
                  if (!key || key === 'null') return acc
                  return acc.concat({
                    title: key,
                    value: value.length
                  })
                }, [])}
                styles={{ width: 300, height: 200, marginBottom: '1rem' }}
              />

              <Filters
                nameFilter='citas'
                data={Object.values(citas).reduce((acc, curr) => acc.concat(curr), [])}
                handleSetData={() => { }}
                options={[
                  { label: 'Profesional', name: 'medico' },
                  { label: 'Paciente', name: 'paciente' },
                  { label: 'Encargados', name: 'encargado' },
                  { label: 'Códigos', name: 'co_cita_web' },
                  { label: 'Fechas Creado', name: 'fecha_creado_web', typeValue: 'dateUTC' },
                  { label: 'Fecha Citas', name: 'fecha_cita', typeValue: 'date' }
                ]}
              />

              {Object.entries(citas).map(([key, value]) =>
                <TableCita key={key} name={key} data={value} />
              )}

            </>)}
      </Container>
    </Layout>
  )
}

export default Citas
