import styled from 'styled-components'

export const Container = styled.div`
    display: ${({ header }) => header ? 'none' : 'flex'};
    
    @media (min-width: 750px) {
        display: ${({ header }) => header ? 'flex' : 'none'} ;
    }
`

export const Notifications = styled.div`
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.danger};
    color: ${({ theme }) => theme.color.white};
    font-size: 11px;
    text-align: center;
`
