import React, { useState, useRef, useEffect } from 'react'

import Loader from '@components/Loader'
import Layout from '@components/Layout'
import InputCheck from '@components/inputs/Checks'
import Button from '@components/Button'
import PhotoFrameDocumentPDF from '@components/PhotoFrameDocumentPDF'
import CitaCard from '@components/portal/CitaCard'
import JornadaDataUser from '@components/portal/JornadaDataUser'
import JornadaHorarios from '@components/portal/JornadaHorarios'

import { useAlert } from '@hooks/useAlert'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { useStateValue } from '@context/index.js'
import config from '@utils/config'
import http from '@utils/fetch-client'

import {
  Container, ContainerBtn, ContainerData, ContainerCheck, ContainerDocument, Title,
  PreviewImg, LabelFile, File, SubTitle
} from './styles'

const initialState = {
  alerts: {
    consentimiento: { status:true, severity:'info', isClose:false, 
      msg:'<b>Nota:</b> Si no acepta el consentimiento no puede seguir adelante'  
    },
    culmino: { status:true, severity:'info', isClose:false, 
      msg:'<b>Nota:</b> Se informa a nuestros afiliados, que el otorgamiento de citas para la primera fase del EMI (Exámenes Clínicos) ha concluido. Continuaremos con la segunda y tercera fase de forma regular para todos aquellos docentes que cumplieron con la primera fase'  
    },
    cita: { status:true, severity:'success', isClose:false, 
      msg:'Su Solicitud de Cita de Laboratorio ha sido Confirmada. Le será enviado por whatsapp y correo la información sobre ella.'  
    }
  },
  info: {
    jornada:{}, 
    cita:{},
    steps: { total:3, current:1 }
  }
}

const Jornadas = () => {
  const [{ user }, dispatch] = useStateValue()
  const [info, setInfo] = useState(initialState.info)
  const [consentimiento, setConsentimiento] = useState(false)
  const [consentimientoHTML, setConsentimientoHTML] = useState('')
  const [loading, setLoading] = useState(true)
  const [MyAlertConsentimiento, setAlertConsentimiento] = useAlert(initialState.alerts.consentimiento)
  const [MyAlertCulmino, setAlertCulmino] = useAlert(initialState.alerts.culmino)
  const [MyAlertMain, setAlertMain] = useAlert({})
  const [MyAlertCita, setAlertCita] = useAlert(initialState.alerts.cita)

  const isCulmino = true
  const isBeneficiary = user.co_tipo === 1
  const isTitular = user?.co_tipo_ben === 1
  const isValid = isBeneficiary && isTitular
  const isJorInProgress = info.jornada.in_progreso && info.jornada?.in_progreso === 1
  const isCita = info.jornada.in_cita && info.jornada?.in_cita === 1
  const isConvencional = info.jornada.in_convencional && info.jornada?.in_convencional === 1
  const isJubiladoInvalid = info.jornada.in_jubilado_invalido && info.jornada?.in_jubilado_invalido === 1
  const isValidTotal = isValid && isJorInProgress && isCita && !isConvencional && !isJubiladoInvalid && !loading
  
  useEffectAbortable(({ aborted }) => {
    if(!isValid){
      setAlertMain({ status: true, severity: 'error', msg: 'Disculpe, debe ser un Beneficiario Titular para poder participar en el EMI.' })
      return setLoading(false)   
    } 
    http.get(`api/jornadas/1/user/${user['co_usuario']}`)
    .then(({ status, data }) => {
      if(aborted) return
      console.log({status, data})
      if (status !== 200) throw new Error(data)
      setInfo(state => ({...state, ...data.data}))
      if(!data.data.jornada.in_progreso) {
        return setAlertMain({ 
          status: true, 
          severity: 'info', 
          msg: `Esta <b>Jornada EMI</b> no se encuentra en progreso, comienza el ${data.data.jornada.fe_inicial.split('T')[0]} .` 
        })
      }
      let msg = ''
      if(!data.data.jornada.in_cita) msg = msg.concat(`<li><b>Usted no tiene permitido participar</b> en esta Jornada EMI.</li>`)
      if(data.data.jornada.in_convencional) msg = msg.concat(`<li>No pueden participar benediciarios de <b>dedicación convencional</b>.</li>`)
      if(data.data.jornada.in_jubilado_invalido) msg = msg.concat(`<li>El programa está dirigido a los <b>profesores titulares</b>.</li>`)
      if(msg) setAlertMain({ status: true, severity: 'info', msg: `<ul>${msg}</ul>` })
    })
    .catch(err => {
      console.error(`Error [Jornadas]: ${err}`)
      setAlertMain({ status: true, severity: 'error', msg: 'Disculpe ocurrió un error consultando información de la <b>Jornada EMI</b>, intente mas tarde.' })
    })
    .finally(() => setLoading(false))
  }, [setInfo, setAlertMain])
  
  useEffectAbortable(({ aborted }) => {
    http.get(`${config.statics.jornadas}/EMI-2023-1/consentimiento.html`, {headers: {'Content-Type': 'text/html'}})
    .then(({ status, data }) => {
      if(aborted) return
      console.log({status, data})
      if (status !== 200) throw new Error(data)

      setConsentimientoHTML(data)
    })
    .catch(err => {
      console.error(`Error [Jornadas]: ${err}`)
      setAlertConsentimiento({ status: true, severity: 'error', msg: 'Disculpe ocurrió un error consultando el consentimiento de la <b>Jornada EMI</b>, intente mas tarde.' })
    })
    .finally(() => setLoading(false))
  }, [setConsentimientoHTML, setAlertMain])
  
  const handleStep = () => {
    setInfo(state => ({...state, current: ++state.steps.current}))
  }
  
  const handleSuccess = () => {
    setInfo(state => ({...state, current: state.steps.total+1}))
  }

  const handleConsentimiento = (name, target) => {
    const { checked } = target
    setConsentimiento(checked)
  }
  
  const submitConsentimiento = () => {
    if(!consentimiento) return setAlertConsentimiento({ status: true, severity: 'error', msg: 'Debe tildar la casilla para dar su consentimiento y poder continuar.' })
    handleStep()
  }

  const getTextStep = () => (`Paso ${info.steps.current} de ${info.steps.total}`)

  if(isCulmino  && false) return (
    <Layout title='EMI' active='jornadas'>
      <Container>
        <Title>EMI</Title>
        { loading ?<Loader /> :<MyAlertCulmino /> }
      </Container>
    </Layout>
  )

  if(!isValidTotal && false) return (
    <Layout title='EMI' active='jornadas'>
      <Container>
        <Title>EMI</Title>
        { loading ?<Loader /> :<MyAlertMain /> }
      </Container>
    </Layout>
  )

  return (
    <Layout title='EMI' active='jornadas'>
      <>
        <Container>
          <Title>EMI</Title>
          <ContainerData>
          {info.steps.current === 1
            ?info.cita?.solicitar
              ?<>
                {/* <p> {info.jornada?.descripcion} </p> */}
                <SubTitle> {`Consentimiento lnformado`} </SubTitle>
                
                <ContainerDocument dangerouslySetInnerHTML={{ __html: consentimientoHTML }} />
                {/* <PhotoFrameDocumentPDF file={`${config.statics.jornadas}/EMI-2023-1/consentimiento.html`} /> */}

                <MyAlertConsentimiento />
                <ContainerCheck>
                  <InputCheck 
                    required
                    id='consentimiento'
                    options={[{optionID:1, opcionDes:'He leido el documento y doy mi consentimiento', checked:consentimiento}]}
                    // value={state.fullname}
                    handleChange={handleConsentimiento}
                    name='consentimiento'
                  />
                </ContainerCheck>
                
                <ContainerBtn>
                  <Button
                    label={'Continuar'}
                    color='primary'
                    onClick={submitConsentimiento}
                  />
                </ContainerBtn>
              </>
              :<> <CitaCard cita={info.cita} /> </>
            :info.steps.current === 2
              ?<>
                <SubTitle> {`Actualización de Data`} </SubTitle>
                <JornadaDataUser handleStep={handleStep} />
              </>
              :info.steps.current === 3
                ?<>
                  <JornadaHorarios handleStep={handleStep} />
                </>
                :<MyAlertCita />
          }
          </ContainerData>      
        </Container>
      </>
    </Layout>
  )
}

export default Jornadas
