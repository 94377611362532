import styled from 'styled-components'

/* export const Container = styled.span`
    color: ${({ theme, status }) =>
      (status === 1) ? theme.color.inProgress
      : (status === 2) ? theme.color.success
      : (status === 3) ? theme.color.danger
      : theme.color.gray
    };
` */
export const Container = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 90vw;
    max-width: 1000px;
    margin: ${({ top }) => top ? '20px auto 0' : '-25px auto 25px'};


    @media (min-width: 920px) {
        width: 75%;
    }
`

export const Status = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 0;
    font-size: 14px;

    b{
        font-size: 16px;
        color: ${({ theme, color }) => theme.color[color]}
    }

    span{
        color: ${({ theme, color }) => theme.color[color]};
        font-size: 16px;
        font-weight: 500
    }
`
