import { useEffect, useRef } from 'react'

function useIsMountedRef () {
  const isMountedRef = useRef(null)
  useEffect(() => {
    isMountedRef.current = true
    return () => { isMountedRef.current = false }
  })
  return isMountedRef
}

export function useEffectAbortable (effect, dependencies) {
  const status = {} // mutable status object
  useEffect(() => {
    status.aborted = false
    // pass the mutable object to the effect callback
    // store the returned value for cleanup
    const cleanUpFn = effect(status)
    return () => {
      // mutate the object to signal the consumer
      // this effect is cleaning up
      status.aborted = true
      // console.log('useEffectAbortable', {status})
      // console.log('typeof cleanUpFn', typeof cleanUpFn)
      if (typeof cleanUpFn === 'function') {
        // run the cleanup function
        cleanUpFn()
      }
    }
  }, [...dependencies])
}

export default useIsMountedRef
