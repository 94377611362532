import React, { useState, useEffect, useRef } from 'react'
import { navigate } from '@reach/router'

import Layout from '@components/admin/Layout'
import MyEditor from '@components/MyEditor'
import Button from '@components/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Loader from '@components/Loader'
import Modal from '@mui/material/Modal'

import http from '@utils/fetch-client'
import { getDateTimeUTC } from '@utils/date'
import { useAlert } from '@hooks/useAlert'
import config from '@utils/config'

import useStyles from './stylesUI'
import {
  Dates, ContainerEditor, ContainerGuardar, ContainerPublished, Header,
  Estatus, PreviewImg, LabelFile, File, ContainerModal, ModalBody,
  DeleteFile
} from './styles'

const PostEdit = ({ id }) => {
  const classes = useStyles()
  const [state, setState] = useState({
    titulo: '',
    bannerDes: '',
    cuerpo: undefined,
    file: null
  })
  const [loading, setLoading] = useState(true)
  const [Alert, setAlert] = useAlert({})
  const [AlertDelete, setAlertDelete] = useAlert({})
  const [open, setOpen] = useState(false)
  const deleteRef = useRef(null)
  // const {alt, content, src, titulo, published} = state
  const creating = (id === 'create')

  useEffect(() => {
    if (!creating) {
      http.get(`api/post/${id}`)
        .then(({ status, data }) => {
          if (status !== 200) throw Error(`Disculpe, ocurrió un error consultados el Post #${id}. Por favor intente más tarde.`)
          setLoading(false)
          setState(data[0])
        })
        .catch(err => {
          setLoading(false)
          setAlert({ status: true, severity: 'error', msg: err })
        })
    } else {
      setLoading(false)
      setState({ cuerpo: '', titulo: '' })
    }
  }, [id])

  const handlePublish = () => {
    setLoading(true)
    http.patch(`api/post/${id}/publicar`)
      .then(({ status, data }) => {
        if (status !== 200) throw Error(`Disculpe, ocurrió un error publicando el Post #${id}. Por favor intente más tarde.`)
        setLoading(false)
        setState(data.post)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }

  const handleUnpublish = () => {
    setLoading(true)
    http.patch(`api/post/${id}/despublicar`)
      .then(({ status, data }) => {
        if (status !== 200) throw Error(`Disculpe, ocurrió un error colocando el Post #${id} como borrador. Por favor intente más tarde.`)
        setLoading(false)
        setState(data.post)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }

  const handleUpdate = () => {
    setLoading(true)
    const body = new FormData()
    body.append('titulo', state.titulo)
    body.append('cuerpo', state.cuerpo)
    if (state.file) body.append('banner', state.file)
    http.patch(`api/post/${id}`, body)
      .then(({ status, data }) => {
        // console.log('status', status)
        // console.log('data', data)
        if (status !== 200) throw Error(`Disculpe, ocurrió un error publicando el Post #${id}. Por favor intente más tarde.`)
        setLoading(false)
        setState(data.post)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }

  const handleDelete = () => {
    const value = deleteRef.current.value
    let errorHTML = ''
    if (!value) errorHTML += '<li>Debe ingresar el codigo del Post para eliminarlo.</li>'
    else if (value !== id) errorHTML += '<li>El codigo ingresado no coincide con el del Post.</li>'

    if (errorHTML) {
      setAlertDelete({ status: true, severity: 'error', msg: errorHTML })
    } else {
      setOpen(false)
      setLoading(true)
      http.delete(`api/post/${id}`)
        .then(({ status, data }) => {
          if (status !== 200) throw Error(`Disculpe, ocurrió un error eliminando el Post #${id}. Por favor intente más tarde.`)
          navigate('/admin/posts')
        })
        .catch(err => {
          setLoading(false)
          setAlert({ status: true, severity: 'error', msg: err })
        })
    }
  }

  const handleChange = ({ target }) => {
    setState({ ...state, [target.name]: target.value })
  }

  const handleContent = content => {
    setState({ ...state, cuerpo: content })
  }

  const handleSubmitCreate = () => {
    setLoading(true)
    let errorHTML = ''
    if (!state.titulo) errorHTML += '<li>Debe ingresarle un <b>titulo</b> al Post.</li>'
    if (!state.cuerpo) errorHTML += '<li>Debe ingresarle un <b>contenido</b> al Post.</li>'

    if (errorHTML) {
      setLoading(false)
      return setAlert({ status: true, severity: 'error', msg: errorHTML })
    }

    const body = new FormData()
    body.append('titulo', state.titulo)
    body.append('cuerpo', state.cuerpo)
    if (state.file) body.append('banner', state.file)

    http.put('api/post', body)
      .then(({ status, data }) => {
        if (status !== 201) throw Error('Disculpe, ocurrió un error creando el Post. Por favor intente más tarde.')
        setLoading(false)
        setAlert({ status: true, severity: 'success', msg: `El Post #${data.co_post} ha sido creado.` })
        navigate(`/admin/posts/${data.co_post}`)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }

  const handleDeleteFile = () => {
    setState({
      ...state,
      file: null,
      banner: ''
    })
  }

  const renderImage = ({ target }) => {
    const file = target.files[0]
    if (file) {
      setState({
        ...state,
        file: file,
        banner: URL.createObjectURL(file)
      })
    }
  }

  const onErrorImg = ({ target }) => {
    target.onerror = null
    target.src = '/post-default.jpg'
  }

  const isPostEmergent = () => parseInt(id) === 1

  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false)

  return (
    <Layout
      active='post'
      title={`Post ${id}`}
    >
      {loading ? <Loader />
        : <ContainerEditor>
          <Header>
            <Grid item xs={12} md={3}>
              {!creating &&
                <Estatus published={state.publicado}>
                  {state.publicado ? `Publicado - Post#${id}` : `Borrador - Post#${id}`}
                </Estatus>}
            </Grid>

            <Grid item xs={12} md={3}>
              {!creating &&
                <ContainerPublished>
                  <Button
                    label={state.publicado ? 'Borrador' : 'Publicar'}
                    color={state.publicado ? 'secondary' : 'success'}
                    onClick={state.publicado ? handleUnpublish : handlePublish}
                  />
                </ContainerPublished>}
            </Grid>

            {!isPostEmergent() &&
              <Grid item xs={12} md={3}>
                {!creating &&
                  <ContainerPublished>
                    <Button
                      label='Eliminar'
                      color='danger'
                      onClick={handleOpenModal}
                    />
                  </ContainerPublished>}
              </Grid>}

            <Grid item xs={12} md={3}>
              <ContainerGuardar>
                {creating
                  ? <Button
                    label='Crear Post'
                    color='success'
                    onClick={handleSubmitCreate}
                  />

                  : <Button
                    label='Guardar Cambios'
                    color='primary'
                    onClick={handleUpdate}
                  />}
              </ContainerGuardar>
            </Grid>
          </Header>

          <Alert />

          {!creating &&
            <Dates>
              <div><b>Creado:</b>  {getDateTimeUTC(state.fecha_creado)} </div>
              <div><b>Modificado:</b> {getDateTimeUTC(state.fecha_editado)} </div>
            </Dates>}

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              {/* <Grid item xs={12} md={8}> */}
              <TextField
                required
                id='titulo'
                value={state.titulo}
                className={classes.formControl}
                onChange={handleChange}
                label='Título'
                name='titulo'
              />
            </Grid>
            {/*
                        <Grid item xs={12} md={4} >
                            <TextField
                                id="alt"
                                value={state.bannerDes}
                                className={classes.formControl}
                                onChange={handleChange}
                                label="Descripción Img"
                                name="alt" />
                        </Grid> */}
          </Grid>

          <Grid container className={classes.root} spacing={2}>
            {isPostEmergent() &&
              <DeleteFile onClick={handleDeleteFile}>Eliminar imagen</DeleteFile>}
            <LabelFile htmlFor='document'>
              <PreviewImg
                id='img'
                src={state.file ? URL.createObjectURL(state.file)
                  : state.banner ? `${config.statics.posts}/${state.banner}`
                    : '/post-default.jpg'}
                onError={onErrorImg}
              />
            </LabelFile>

            <File
              type='file'
              id='document'
              name='document'
              accept='image/*'
              onChange={renderImage}
            />
          </Grid>

          {state.cuerpo === undefined ? null
            : <MyEditor
              id={id}/*
                            titulo={state.titulo}
                            src={src}
                            alt={alt} */
              content={state.cuerpo}
              handleContent={handleContent}
            />}
        </ContainerEditor>}

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <ContainerModal className={classes.paper}>
          <h2 id='simple-modal-title' className={classes.title}> ¿Seguro quiere eliminar este Post? </h2>
          <ModalBody id='simple-modal-description'>
            <TextField
              required
              id='delete'
              ref={deleteRef}
              className={classes.formControl}
              label='Ingrese el codigo del Post'
              onChange={({ target }) => deleteRef.current.value = target.value}
              name='delete'
            />

            <AlertDelete />
            <Button
              label='Eliminar'
              color='danger'
              onClick={handleDelete}
            />
          </ModalBody>
        </ContainerModal>
      </Modal>
    </Layout>
  )
}

export default PostEdit
