import React from 'react'
import { Container } from './styles'

const StatusRequest = ({ header, status }) => {
  // console.log('status', status)
  const showStatus = () => {
    if (typeof status === 'number') {
      return (status === -1) ? 'Estatus'
        : (status === 1) ? 'En creación'
            : (status === 2) ? 'En Espera'
                : (status === 3) ? 'Confirmada'
                    : (status === 4) ? 'Rechazada'
                        : (status === 5) ? 'En SAMHOI'
                            : 'N/D'
    } else {
      return status
    }
  }

  const getStatusNumber = () => {
    if (typeof status === 'number') {
      return status
    } else {
      return (status === 'En creación') ? 1
        : (status === 'En Espera') ? 2
            : (status === 'Confirmada') ? 3
                : (status === 'Rechazada') ? 4
                    : (status === 'En SAMHOI') ? 5
                        : 'N/D'
    }
  }

  return (
    <Container header={header} status={getStatusNumber(status)}>
      {showStatus()}
    </Container>
  )
}

export default StatusRequest
