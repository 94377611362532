import styled from 'styled-components'

export const HeaderStyled = styled.header`  
    position: relative;
    z-index: 10;
    box-shadow: 0 1px 6px 0 ${({ theme }) => theme.color.shadow};
    box-shadow: ${({ showMenu }) => showMenu ? '0' : `0 1px 6px 0 ${({ theme }) => theme.color.shadow}`};
    /* grid-area: head; */
`

export const Container = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 20px;
    /* width: calc(100vw - 20px); */
    max-width: 1200px;
    margin: auto;

    @media (min-width: 850px) {
        flex-direction: row;
    }
`

export const Logo = styled.div`
    display: flex;
    align-items: center;
`

export const Img = styled.img`
    width: 80px;
    height: 80px;
`

export const NameSystem = styled.h3`
    color: ${({ theme }) => theme.color.bluePrimary};
    vertical-align: middle;
    font-size: 20px;
    margin: 0 10px
`

export const NavContainer = styled.nav`  
    display: none;

    @media (min-width: 850px) {
        display: flex;
        flex-direction: row;
        height: 100%;
    }
`

export const DropDown = styled.div`  
    svg {
        margin: 5px 15px;
        font-size: 2.5rem;
        color: ${({ theme }) => theme.color.gray};
        cursor: pointer;
        &:hover{
            color: ${({ theme }) => theme.color.bluePrimary};
        };   
    }

    @media (min-width: 850px) {
        display: none;
    }
`

export const NavDropDown = styled.div`
    position: relative;
    padding-bottom: ${({ showMenu }) => showMenu ? '30px' : '0px'};
    border-bottom: ${({ showMenu }) => showMenu ? `1px solid ${({ theme }) => theme.color.ligthGray}` : ''};
    box-shadow: ${({ showMenu }) => showMenu ? '0 5px 6px 0 rgba(32,33,36,0.28)' : ''};
    background-color: white;

    @media (min-width: 850px) {
        display: none;
    }
`
