import React, { useState, useRef } from 'react'
import { navigate } from '@reach/router'

import Layout from '@components/admin/Layout'
import Loader from '@components/Loader'
import Button from '@components/Button'
import Select from '@components/inputs/Select'
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import http from '@utils/fetch-client'
import { getDateTimeUTC } from '@utils/date'
import { useAlert } from '@hooks/useAlert'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import config from '@utils/config'

import {
  ContainerEditor, ContainerGuardar, Info, Dates, ContainerPublished,
  Header, Estatus, PreviewImg, LabelFile, File, ContainerModal, ModalBody
} from './styles'
import useStyles from './stylesUI'

const ClinicEdit = ({ id }) => {
  const classes = useStyles()
  const [state, setState] = useState({ proveedor: '', file: '' })
  const [clinics, setClinics] = useState([])
  const [clinic, setClinic] = useState({})
  const [Alert, setAlert] = useAlert({})
  const [loading, setLoading] = useState(true)
  const [AlertDelete, setAlertDelete] = useAlert({})
  const [open, setOpen] = useState(false)
  const deleteRef = useRef(null)

  const isCreating = () => (id === 'create')

  useEffectAbortable(({ aborted }) => {
    if (isCreating()) {
      http.get('api/clinica/proveedores')

        .then(({ status, data }) => {
          if (aborted) return
          if (status !== 200) throw Error(`Disculpe, ocurrió un error consultados el Post #${id}. Por favor intente más tarde.`)
          if (data) {
            data.sort((a, b) => {
              if (a.nb_proveedor.trim() < b.nb_proveedor.trim()) return -1
              if (a.nb_proveedor.trim() > b.nb_proveedor.trim()) return 1
              else return 0
            })
            setClinics(data)
          }
        })
        .catch(err => {
          setAlert({ status: true, severity: 'error', msg: err })
        })
        .finally(() => setLoading(false))
    } else {
      http.get(`api/clinica/${id}`)
        .then(({ status, data }) => {
          if (status !== 200) throw Error(`Disculpe, ocurrió un error consultados la clinica #${id}. Por favor intente más tarde.`)
          if (data) setClinic(data[0])
        })
        .catch(err => {
          setAlert({ status: true, severity: 'error', msg: err })
        })
        .finally(() => setLoading(false))
    }
  }, [id])

  const handleChangeSelect = target => {
    console.log(target.name)
    console.log(target.value)
    setState({ ...state, [target.name]: target.value })
  }

  const handleSubmitCreate = () => {
    console.log('Creando...')
    console.log('state', state)
    setLoading(true)
    let errorHTML = ''
    if (!state.proveedor) errorHTML += '<li>Debe seleccionar una <b>clínica</b>.</li>'
    if (!state.file) errorHTML += '<li>Debe agregarle una imagen a la Clínica.</li>'

    if (errorHTML) {
      setLoading(false)
      return setAlert({ status: true, severity: 'error', msg: errorHTML })
    }

    const body = new FormData()
    body.append('co_clinica_salud', state.proveedor)
    body.append('imagen', state.file)

    http.put('api/clinica/', body)
      .then(({ status, data }) => {
        console.log('status', status)
        console.log('data', data)
        if (status !== 201) throw Error('Disculpe, ocurrió un error creando el Post. Por favor intente más tarde.')
        setLoading(false)
        setAlert({ status: true, severity: 'success', msg: 'La Clínica ha sido agregada.' })
        navigate(`/admin/clinic/${data.clinica.co_clinica}`)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }

  const handlePublish = () => {
    setLoading(true)
    http.patch(`api/clinica/${id}/publicar`)
      .then(({ status, data }) => {
        if (status !== 200) throw Error(`Disculpe, ocurrió un error publicando la CLínica #${id}. Por favor intente más tarde.`)
        setLoading(false)
        setClinic(data.clinica)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }

  const handleUnpublish = () => {
    setLoading(true)
    http.patch(`api/clinica/${id}/despublicar`)
      .then(({ status, data }) => {
        if (status !== 200) throw Error(`Disculpe, ocurrió un error colocando la Clínica #${id} como borrador. Por favor intente más tarde.`)
        setLoading(false)
        setClinic(data.clinica)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }

  const handleUpdate = () => {
    console.log('handleUpdate')
    setLoading(true)
    if (state.file) {
      const body = new FormData()
      // body.append('titulo', state.titulo)
      body.append('imagen', state.file)
      http.patch(`api/clinica/${id}`, body)
        .then(({ status, data }) => {
          console.log('status', status)
          console.log('[pages ClinicEdit] data', data)
          if (status !== 200) throw Error(`Disculpe, ocurrió un error actualizando la Clínica #${id}. Por favor intente más tarde.`)
          setLoading(false)
          setAlert({ status: true, severity: 'success', msg: 'La Clínica ha sido actualizada.' })
          setClinic(data.clinica)
        })
        .catch(err => {
          setLoading(false)
          setAlert({ status: true, severity: 'error', msg: err })
        })
    } else {
      setLoading(false)
      setAlert({ status: true, severity: 'error', msg: 'No se han realizado cambios' })
    }
  }

  const handleDelete = () => {
    const value = deleteRef.current.value
    let errorHTML = ''
    if (!value) errorHTML += '<li>Debe ingresar el codigo de la Clínica para eliminarlo.</li>'
    else if (value !== id) errorHTML += '<li>El codigo ingresado no coincide con el de la Clínica.</li>'

    if (errorHTML) {
      setAlertDelete({ status: true, severity: 'error', msg: errorHTML })
    } else {
      setOpen(false)
      setLoading(true)
      http.delete(`api/clinica/${id}`)
        .then(({ status, data }) => {
          if (status !== 200) throw Error(`Disculpe, ocurrió un error eliminando la Clínica #${id}. Por favor intente más tarde.`)
          navigate('/admin/home')
        })
        .catch(err => {
          setLoading(false)
          setAlert({ status: true, severity: 'error', msg: err })
        })
    }
  }

  const renderImage = ({ target }) => {
    const file = target.files[0]
    if (file) {
      setState({ ...state, file: file, src: URL.createObjectURL(file) })
    }
  }

  const onErrorImg = ({ target }) => {
    target.onerror = null
    target.src = '/img-default.jpg'
  }

  const OrientationImage = ({ target }) => {
    const { naturalHeight, naturalWidth } = target
    if (naturalWidth > naturalHeight) {
      target.style.height = '200px'
      target.style.width = '280px'
    } else {
      target.style.height = '280px'
      target.style.width = '200px'
    }
  }

  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false)

  return (
    <Layout
      active='home'
      title={`Clínica ${id}`}
    >
      {loading ? <Loader />
        : <ContainerEditor>
          <Header>
            <Grid item xs={12} md={3}>
              {!isCreating() &&
                <Estatus published={clinic.publicado}>
                  {clinic.publicado ? `Publicado - Clínica#${id}` : `Borrador - Clínica#${id}`}
                </Estatus>}
            </Grid>

            <Grid item xs={12} md={3}>
              {!isCreating() &&
                <ContainerPublished>
                  <Button
                    label={clinic.publicado ? 'Borrador' : 'Publicar'}
                    color={clinic.publicado ? 'secondary' : 'success'}
                    onClick={clinic.publicado ? handleUnpublish : handlePublish}
                  />
                </ContainerPublished>}
            </Grid>

            <Grid item xs={12} md={3}>
              {!isCreating() &&
                <ContainerPublished>
                  <Button
                    label='Eliminar'
                    color='danger'
                    onClick={handleOpenModal}
                  />
                </ContainerPublished>}
            </Grid>

            <Grid item xs={12} md={3}>
              <ContainerGuardar>
                {isCreating()
                  ? <Button
                    label='Agregar Clínica'
                    color='success'
                    onClick={handleSubmitCreate}
                  />

                  : <Button
                    label='Guardar Cambios'
                    color='primary'
                    onClick={handleUpdate}
                  />}
              </ContainerGuardar>
            </Grid>
          </Header>
          <Alert />

          {/*   <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} md={7}>
                        <TextField required
                            id="name"
                            value={name}
                            className={classes.formControl}
                            onChange={handleChange}
                            label="Nombre"
                            name="name" />
                    </Grid>

                    <Grid item xs={12} md={5} >
                        <TextField
                            id="email"
                            value={email}
                            className={classes.formControl}
                            onChange={handleChange}
                            label="Correo electronico"
                            name="email" />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField required
                            id="adrress"
                            value={adrress}
                            className={classes.formControl}
                            onChange={handleChange}
                            label="Dirección Principal"
                            name="adrress" />
                    </Grid>

                    <Grid item xs={12} md={4} >
                        <TextField
                            id="phone"
                            value={phone}
                            className={classes.formControl}
                            onChange={handleChange}
                            label="Telefono"
                            name="phone" />
                    </Grid>
                </Grid> */}

          {isCreating()
            ? <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Select
                label='Seleccione la Institución'
                name='proveedor'
                options={clinics.map(({ co_proveedor, nb_proveedor }) => ({ opcionID: co_proveedor, opcionDes: nb_proveedor }
                ))}
                handleChange={handleChangeSelect}
              />
            </div>
            : !Object.keys(clinic).length ? <Loader />
              : <div>
                <Dates>
                  <div><b>Creado:</b>  {getDateTimeUTC(clinic.fecha_creado)} </div>
                  <div><b>Modificado:</b> {getDateTimeUTC(clinic.fecha_editado)} </div>
                </Dates>

                <Info><span>Nombre:</span>  {clinic.nombre}</Info>
                <Info><span>Dirección:</span> {clinic.direccion} </Info>
                <Info><span>Ciudad:</span> {clinic.ciudad} </Info>
                <Info><span>Estado:</span> {clinic.estado} </Info>
                <Info><span>RIF:</span> {clinic.rif} </Info>
                <Info><span>Correos:</span> {clinic.correo} </Info>
                <Info><span>Telefonos:</span> {clinic.telefono} </Info>
              </div>}

          <div>
            <Grid container className={classes.root} spacing={2}>
              <LabelFile htmlFor='document'>
                <PreviewImg
                  id='img'
                  src={state.file ? state.src
                    : clinic.imagen ? `${config.statics.clinics}/${clinic.imagen}`
                      : '/img-default.jpg'}
                  onError={onErrorImg}
                  onLoad={OrientationImage}
                />
              </LabelFile>

              <File
                type='file'
                id='document'
                name='document'
                accept='image/*'
                onChange={renderImage}
              />
            </Grid>

            {/*
                    <MyEditor
                        id={id}
                        title={title}
                        src={src}
                        alt={alt}
                        content={state.description}
                        handleContent={handleContent}
                    /> */}
          </div>
        </ContainerEditor>}

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <ContainerModal className={classes.paper}>
          <h2 id='simple-modal-title' className={classes.title}> ¿Seguro quiere eliminar esta Clínica? </h2>
          <ModalBody id='simple-modal-description'>
            <TextField
              required
              id='delete'
              ref={deleteRef}
              className={classes.formControl}
              label='Ingrese el codigo de la Clínica'
              onChange={({ target }) => deleteRef.current.value = target.value}
              name='delete'
            />

            <AlertDelete />
            <Button
              label='Eliminar'
              color='danger'
              onClick={handleDelete}
            />
          </ModalBody>
        </ContainerModal>
      </Modal>
    </Layout>
  )
}

export default ClinicEdit
