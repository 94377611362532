import { useState, useEffect } from 'react'
import Button from '@components/Button'
import { Container, Status } from './styles'
import http from '@utils/fetch-client'

const LegendCita = ({ top }) => {
  const [status, setStatus] = useState([])
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)

  const handleToogle = () => setShow(show => !show)
  // console.log('status', status)

  useEffect(() => {
    http.get('api/cita/status')
      .then(({ status, data }) => {
        if (status !== 200) throw new Error(status)
        // console.log({ status, data })
        setStatus(data)
      })
      .catch(err => {
        const msg = 'disculpe, no se encontró cargar la leyenda de los estatus de las citas.'
        console.error(`${err}: ${msg}`)
      })
      .finally(() => setLoading(false))
  }, [])

  const getColor = status => {
    /* return (status === -1)
      ? 'Estatus'
      : (status === 1)
        ? 'black'
        : (status === 2)
          ? 'inProgress'
          : (status === 3)
            ? 'success'
            : (status === 4)
              ? 'danger'
              : (status === 5)
                ? 'inProgress'
                : (status === 6)
                  ? 'success'
                  : 'secondary'
 */
    switch (status) {
      case -1: return 'Estatus'
      case 1: return 'black'
      case 2: return 'inProgress'
      case 3: return 'success'
      case 4: return 'danger'
      case 5: return 'inProgress'
      case 6: return 'success'
      default: return 'secondary'
    }
  }

  return (
    <Container top={top}>
      {loading
        ? <h5>Cargando Leyenda...</h5>
        : !status.length
          ? <h3>No se logro cargar la leyenda de los Estatus, disculpe.</h3>
          : (
            <>
              <Button
                label={show ? 'Ocultar leyenda de Status de Citas' : 'Mostrar leyenda de Status de Citas'}
                color='secondary'
                onClick={handleToogle}
              />

              {show && status.map(({ co_status, tx_status, descripcion }) =>
                <Status color={getColor(co_status)} key={co_status}>
                  <span>{tx_status}</span>: {descripcion}.
                </Status>
              )}
            </>)}
    </Container>
  )
}

export default LegendCita
