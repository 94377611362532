import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'

import { makeStyles } from '@material-ui/core/styles'
import { Container } from './styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#fff',
    '& > * + *': {
      margin: 'auto'
    }
  }
}))

const Loader = ({ disabled }) => {
  const classes = useStyles()

  return (
    // <div className={classes.root}>
    <Container disabled={disabled ? 1 : 0}>
      <CircularProgress />
    </Container>
    // </div>
  )
}

export default Loader
