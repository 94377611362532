import styled from 'styled-components'

export const PhotoFrame = styled.img`  
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    margin: ${({ margin }) => margin};
    object-fit: fill;
    border-radius: 50%;
    border: 0px solid #666;
    // box-shadow: 0 5px 10px #666;
`
