import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  z-index: 2000;
  border-radius: 1rem;
  border: 0.1rem solid ${({ theme }) => theme.color.shadow};
  box-shadow: 4px 4px 5px ${({ theme }) => theme.color.shadow};
  font-size: 0.8rem;
  font-weight: 700;
  background: lightgray;
  color: gray;
`
