import React, { useState, useRef } from 'react'
// import { navigate } from '@reach/router'

import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import Layout from '@components/admin/Layout'
import InputText from '@components/inputs/Text'
import Button from '@components/Button'
import ChangeDataUser from '@components/portal/ChangeDataUser'

/* import Loader from '@components/Loader'
import DateRangeSelector from '@components/DateRangeSelector'
import PieChartsFull from '@components/charts/PieChartsFull'
import Filters from '@components/Filters' */

import http from '@utils/fetch-client'
import { useAlert } from '@hooks/useAlert'
import useStyles from './stylesUI'

import { Container, Body, ConatinerButton } from './styles'

const successClipboard = copied => {
  alert(`'${copied}' Copiado al portapapeles!`)
}

const failureClipboard = () => {
  alert('¡Ha fallado el copiado al portapapeles!')
}

const errorClipboard = () => {
  alert('Se ha producido un error al copiar al portapaples')
}

const INITIAL_STATE = { 
  forgot : { username: '', password: null },
  userData: { username:'', tx_correo:'', tx_tel_cel:'', tx_telf_hab:'', valid:false }
}

const Users = () => {
  const classes = useStyles()
  const [forgot, setForgot] = useState(INITIAL_STATE.forgot)
  const [userData, setUserData] = useState(INITIAL_STATE.userData)
  const [loadingForgot, setLoadingForgot] = useState(false)
  const [loadingChangeUserData, setLoadingChangeUserData] = useState(false)
  
  const [Alert, setAlert] = useAlert({})
  const [AlertUserData, setAlertUserData] = useAlert({})
  const bodyRefPassword = useRef(null)
  const bodyRefData = useRef(null)

  const handleTooglePassword = () => {
    const display = bodyRefPassword.current.style.display
    bodyRefPassword.current.style.display = ['none', ''].includes(display) ? 'block' : 'none'
  }
  
  const handleToogleData = () => {
    const display = bodyRefData.current.style.display
    bodyRefData.current.style.display = ['none', ''].includes(display) ? 'block' : 'none'
  }

  const handleChangeUsername = ({ value }) => {
    setForgot({ username: value, password: null })
  }

  const handleChangeUsernameData = ({ value }) => {
    setUserData({ ...INITIAL_STATE.userData, username: value })
  }
  
  const copyClipboard = ({ target }) => {
    // var codigoACopiar = document.getElementById('textoACopiar');
    const seleccion = document.createRange()
    seleccion.selectNodeContents(target)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(seleccion)
    try {
      const res = document.execCommand('copy') // Intento el copiado
      if (res) successClipboard(target.innerText)
      else failureClipboard(target)
    } catch (err) { errorClipboard(err) }

    window.getSelection().removeRange(seleccion)
  }

  const ChangeUserData = () => {
    console.log({userData})
    setLoadingChangeUserData(true)
    http.get(`api/usuario/username/${userData.username}`)
      .then(({ status, data }) => {
        console.log('[User] UserData', { status, data })
        if (status !== 200) throw Error(`Disculpe, ocurrió un error consultando los datos del username '${userData.username}'. Por favor intente más tarde.`)
        if (data && !data.hasOwnProperty('usuario')) throw Error(`Disculpe, no se encontró un usuario con el username '${userData.username}'. Por favor intente más tarde.`)
        setUserData(data.usuario)
      })
      .catch(err => {
        setAlertUserData({ status: true, severity: 'error', msg: err })
      })
      .finally(() => setLoadingChangeUserData(false))
  }

  const changePassword = () => {
    setLoadingForgot(true)
    const data = new FormData()
    data.append('username', forgot.username)
    http.patch('api/usuario/olvideContrasena/admin', data)
      .then(({ status, data }) => {
        console.log('[User] Forgot', { status, data })
        if (status !== 200) throw Error(`Disculpe, ocurrió un error reestableciendo la contraseña de '${forgot.username}'. Por favor intente más tarde.`)
        const u = data
        const fullname = `${u.nb_benefi} ${u.nb_apellid}`
        setForgot({ ...forgot, password: u.password })
        setAlert({
          status: true,
          severity: 'success',
          msg: `La contraseña de ${fullname} (${u.username}) ha sido cambiada a <b>${u.password}</b>`
        })
      })
      .catch(err => {
        setAlert({ status: true, severity: 'error', msg: err })
      })
      .finally(() => setLoadingForgot(false))
  }

  const setUserFullname = user => {
    if(!user.username) return 'NO se ha buscado Beneficiaro'
    let nombre = user['nb_benefi'] ?user['nb_benefi'].trim() :''
    let apellido = user['nb_apellid'] ?user['nb_apellid'].trim() :''
    let cedula = user['username']
    return `${nombre} ${apellido} (${cedula})`
  }

  return (
    <Layout
      active='users'
      title='Usuarios'
    >
      <Container>
        <section>
          <Tooltip
            title='Mostrar/Ocultar Reasignar contrseña'
            aria-label='Mostrar/Ocultar Reasignar contrseña'
          >
            <h1 onClick={handleTooglePassword}> Beneficiario - Reasignar contraseña </h1>
          </Tooltip>

          <Body ref={bodyRefPassword}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={6}>
                <InputText
                  required
                  id='username'
                  name='username'
                  label='Nombre de Usuario (cedula)'
                  // value={form.password}
                  handleBlur={handleChangeUsername}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  type='submit'
                  onClick={changePassword}
                  label='Reasignar contraseña'
                  color='primary'
                  disabled={loadingForgot}
                />
              </Grid>
            </Grid>
            <Alert />

            {forgot.password &&
              <ConatinerButton>
                <div onClick={copyClipboard}> {forgot.password} </div>
              </ConatinerButton>}

          </Body>

        </section>

        <section>
          <Tooltip
            title='Mostrar/Ocultar Actualizar Datos'
            aria-label='Mostrar/Ocultar Actualizar Datos'
          >
            <h1 onClick={handleToogleData}> Beneficiario - Actualizar Datos </h1>
          </Tooltip>

          <Body ref={bodyRefData}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={6}>
                <InputText
                  required
                  id='username'
                  name='username'
                  label='Nombre de Usuario (cedula)'
                  // value={form.password}
                  handleBlur={handleChangeUsernameData}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Button
                  onClick={ChangeUserData}
                  label='Buscar Beneficiario'
                  color='secondary'
                  disabled={loadingChangeUserData}
                />
              </Grid>

              <AlertUserData />
              
              <Grid item xs={12}>
                <h3>{setUserFullname(userData)}</h3>
              </Grid>
              <Grid item xs={12}>
                <ChangeDataUser userData={userData} />
              </Grid>
              
            </Grid>    
          </Body>

        </section>

      </Container>
    </Layout>
  )
}

export default Users
