import useStyles from './stylesUI'
import TextField from '@mui/material/TextField'

const NO_OP = () => { }

export default function InputText ({
  name, label, value, defaultValue,
  type = 'text', disabled, style, required,
  handleChange = NO_OP, handleBlur = NO_OP
}) {
  const classes = useStyles()

  const myHandleChange = ({ target }) => handleChange(target)
  const myHandleBlur = ({ target }) => handleBlur(target)
  // console.log({defaultValue})
  
  const inputProps = type === 'date' ?{} :{
    autocomplete: 'new-password',
    form: {
      autocomplete: 'off'
    }
  }

  return (
    <TextField
      className={[classes.porciento, classes.marginHorizontal, classes.textField]}
      id={`text-${name}`}
      type={type}
      name={name}
      value={value}
      defaultValue={defaultValue}
      label={label}
      format={"DD-MM-YYYY"}
      onChange={myHandleChange}
      onBlur={myHandleBlur}
      disabled={disabled}
      style={style}
      required={required}
      inputProps={inputProps}
    />
  )
}

/*
 */
