import styled from 'styled-components'

export const Container = styled.div`  
    position: fixed;
    z-index: 100000000000000;
    padding: 1rem;
    font-weight: 500;
    margin-left: 1rem;
    right: 1rem;
    bottom: 1rem;
    color: white;
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.color.danger};
`
