import reembolsos from '@mocks/reembolsos'
import citas from '@mocks/citas'
import cartasAval from '@mocks/cartasAval'
import citaCreating from '@mocks/citaCreating'
// import {getUser} from '@utils/getUser'

const initialState = {
  user: undefined,
  titular: null,
  beneficiaries: null,
  reembolsos: reembolsos,
  reembolsoCreating: {
    id: 1,
    name: 'Consulta Odontologica - Ortodoncia',
    date: '29/08/2020',
    status: 1,
    documents: [
      {
        id: 1,
        name: 'Concepto',
        horizontal: false
      },
      {
        id: 2,
        name: 'Informe Médico',
        horizontal: false
      },
      {
        id: 3,
        name: 'Orden Médica',
        horizontal: false
      },
      {
        id: 4,
        name: 'Factura Fiscal',
        horizontal: true
      },
      {
        id: 5,
        name: 'Cédula',
        horizontal: true
      },
      {
        id: 6,
        name: 'Instrumento de Pago',
        horizontal: false
      }
    ]
  },
  cartasAval: cartasAval,
  cartasAvalCreating: {
    id: 1,
    name: 'Consulta Odontologica - Ortodoncia',
    date: '29/08/2020',
    status: 1,
    documents: [
      {
        id: 1,
        name: 'Informe Médico',
        horizontal: false
      },
      {
        id: 2,
        name: 'INforme de resultados',
        horizontal: false
      },
      {
        id: 3,
        name: 'Presupuesto',
        horizontal: true
      },
      {
        id: 4,
        name: 'Cédula',
        horizontal: true
      }
    ]
  },
  citaCreating,
  citas: citas,
  admin: {
    reembolsoEdit: {}
  },
  filters: {
    notifications: {},
    citas: {},
    cartasAval: {},
    reembolsos: {},
    EMIcitaMedica_pendientes: {},
    EMIcitaMedica_asignadas: {},
    EMIlaboratorio_pendientes: {},
    EMIlaboratorio_listos: {},
  },
  modalImage: {}
}

console.log('initialState.user', initialState.user)

export default initialState
