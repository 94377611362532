import { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@mui/material/Tooltip'
import { useEventListener } from '@hooks/useEventListener'

import { Container } from './styles'

const Layout = () => {
  const containerRef = useRef(null)

  useEventListener('scroll', () => {
    const scrollTo = document.documentElement.scrollTop
    if (containerRef.current) {
      if (scrollTo > 500) {
        if (['', 'none'].some(d => d === containerRef.current.style.display)) {
          containerRef.current.style.display = 'flex'
        }
      } else {
        if (['', 'flex'].some(d => d === containerRef.current.style.display)) {
          containerRef.current.style.display = 'none'
        }
      }
    }
  })

  const handleGoToTheTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <Tooltip ref={containerRef} title='Ir al tope' aria-label='Ir al top de la página'>
      <Container role='button' onClick={handleGoToTheTop}>
        <FontAwesomeIcon icon={faArrowUp} />
      </Container>
    </Tooltip>
  )
}

export default Layout
