const production = process.env.NODE_ENV === 'production'
// const production = true
const hostname = window.location.hostname.split('.')[0]
console.log('first hostname', hostname)
let host = ''

if(!production && hostname !== 'localhost'){
  host = 'https://190.0.0.4/apidev'
}else{ 
  const port = production ? '5000' : '5050'
  host = hostname === 'app' ? `https://app.apucvipp.org.ve`
    : hostname === '190' ? `http://190.0.0.4:${port}`
    : hostname === 'localhost'
      ?`http://localhost:${port}`
      :`http://190.0.0.4:${port}`
}
  
// ? `http://190.202.23.156:${port}`
// : `http://10.10.10.215:${port}`


const config = {
  ENV:  production ?'production' :'development',
  isDev: production ?false :true,
  maintenance: false,
  host: host,
  statics: {
    root: `${host}/api/static`,
    clinics: `${host}/api/static/clinicas`,
    citas: `${host}/api/static/citas`,
    directors: `${host}/api/static/directores`,
    documents: `${host}/api/static/documentos`,
    ipp: `${host}/api/static/ipp`,
    jornadas: `${host}/api/static/jornadas`,
    manual: `${host}/api/static/manual`,
    posts: `${host}/api/static/post`,
    services: `${host}/api/static/servicios`,
    users: `${host}/api/static/usuarios`
  }
}

export default config
