import React from 'react'
import { Router, Redirect } from '@reach/router'

import NotFound from '@pages/NotFound'
import Home from '@pages/admin/Home'
import ServiceEdit from '@pages/admin/ServiceEdit'
import ClinicEdit from '@pages/admin/ClinicEdit'
import Citas from '@pages/admin/Citas'
import CitasEdit from '@pages/admin/CitasEdit'
import CitasReport from '@pages/admin/CitasReport'
import Jornadas from '@pages/admin/Jornadas'
import JornadasCitas from '@pages/admin/JornadasCitas'
import JornadasCitasMedicas from '@pages/admin/JornadasCitasMedicas'
import JornadasCitasLaboratorio from '@pages/admin/JornadasCitasLaboratorio'
import Reembolsos from '@pages/admin/Reembolsos'
import ReembolsosEdit from '@pages/admin/ReembolsosEdit'
import ReembolsosReport from '@pages/admin/ReembolsosReport'
import CartasAval from '@pages/admin/CartasAval'
import CartasAvalEdit from '@pages/admin/CartasAvalEdit'
import CartasAvalReport from '@pages/admin/CartasAvalReport'
import Posts from '@pages/admin/Posts'
import PostEdit from '@pages/admin/PostEdit'
import Notifications from '@pages/admin/Notifications'
import Users from '@pages/admin/Users'

const Admin = () => {
  return (
    <Router>
      {/* <Dashboard path='/' /> */}
      <NotFound default />
      <Redirect from='/' to='home' noThrow />
      <Home path='home' />
      <ServiceEdit path='service/:id' />
      <ClinicEdit path='clinic/:id' />

      <Citas path='citas' />
      <CitasEdit path='citas/:id' />
      <CitasReport path='citas/report/:fi/:ff' />
      {/* <CitasEdit path='citas/request/:id' reques /> */}
      
      <Jornadas path='EMI' />
      <JornadasCitas path='EMI/citas/:id' />
      <JornadasCitasLaboratorio path='EMI/:coClinica/citas/laboratorio' />
      <JornadasCitasMedicas path='EMI/:coClinica/citas/medicas' />

      <Reembolsos path='reembolsos' />
      <ReembolsosEdit path='reembolsos/:id' />
      <ReembolsosReport path='reembolsos/report/:fi/:ff' />

      <CartasAval path='cartasAval' />
      <CartasAvalEdit path='cartasAval/:id' />
      <CartasAvalReport path='cartasAval/report/:fi/:ff' />

      <Posts path='posts' />
      <PostEdit path='posts/:id' />
      <Notifications path='notifications' />

      <Users path='users' />
      {/* <UsersEdit path='users/:id' /> */}
    </Router>
  )
}

export default Admin
