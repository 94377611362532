import styled from 'styled-components'

export const MenuContainer = styled.div`
  display: ${({ show }) => show ? 'flex' : 'none'};
  position: fixed;
  justify-content: center;
  min-width: 100vw;
  height: calc(100vh - 100px);
  padding-right: 15px;
  color: ${({ theme }) => theme.color.gray};
  background-color: ${({ theme }) => theme.color.white};

  @media (min-width: 720px) {
      position: relative;
      display: flex;
      min-width: 200px;
      box-shadow: 0 0 10px -2px;
  }
`

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.gray};
  width: 100%;
  max-width: 350px;

  ul{
      list-style-type: none;
      font-weight: 400;
      padding-left: 15px;
      margin-top: 0;
  }

`

export const OptionDiv = styled.div`
  font-weight: 500;
  font-size: 18px;
  padding-top: 20px;
`

export const OptionLi = styled.li`
  cursor: pointer;
  padding-top: 5px;
  color: ${({ selected, theme }) => selected && theme.color.bluePrimary};
  font-weight: ${({ selected }) => selected && '500'};
  /* font-weight: 500; */
  :hover{
      color: ${({ theme }) => theme.color.bluePrimary};
  }
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  h4{
      margin: 0;
  }

  :hover{
      color: ${({ theme }) => theme.color.bluePrimary};
  }
`

export const MenuToogle = styled.div`
  position: fixed;
  bottom: 70px;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  z-index: 2000;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.shadow};
  box-shadow: 2px 2px 5px ${({ theme }) => theme.color.shadow};
  font-size: 30px;
  background: white;
  cursor: pointer;   

  @media (min-width: 720px) {
      display: none;
  }
`
