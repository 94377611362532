import React from 'react'
import { Link } from '@reach/router'

import InputText from '@components/inputs/Text'
import Loader from '@components/Loader'
import Button from '@components/Button'
import Grid from '@mui/material/Grid'

import { useAlert } from '@hooks/useAlert'
import { useLogin } from '@hooks/useLogin'
import { useStateValue } from '@context'

import { Login, LoginInput, DivMessage, LoginBtn } from './styles'
import useStyles from './stylesUI'

const LoginHome = () => {
  const classes = useStyles()
  const [{ user }] = useStateValue()
  const [AlertInfo, setInfo] = useAlert({})
  const { form, login, handleChange } = useLogin(setInfo)

  return (
    <Login id='login' user={user}>
      <LoginInput>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <InputText
              required
              id='user'
              name='username'
              label='Usuario'
              // value={form.username}
              handleBlur={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              required
              id='password'
              name='password'
              label='Contraseña'
              type='password'
              // value={form.password}
              handleBlur={handleChange}
            />
          </Grid>
        </Grid>
        <Link to='/login#recuperar'> ¿Olvidó su contraseña? </Link>
      </LoginInput>

      <DivMessage>
        <AlertInfo />
        {form.requesting && <Loader />}
      </DivMessage>

      <LoginBtn>
        <Button
          type='submit'
          onClick={login}
          label='Ingresar al Portal'
          color='success'
          disabled={form.requesting}
        />
      </LoginBtn>

      <Link to='/home#contact'>¿No tiene usuario? Comuníquese a nuestras oficinas.</Link>
    </Login>
  )
}

export default LoginHome
