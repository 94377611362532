import { Link, navigate } from '@reach/router'

import Layout from '@components/Layout'
import ActivityChart from '@components/portal/ActivityChart'
import CartaAvalRequest from '@components/portal/CartaAvalRequest'
import CartaAvalDetail from '@components/portal/CartaAvalDetail'
import ListOfCartaAval from '@components/portal/ListOfCartaAval'
import LegendRequest from '@components/LegendRequest'
import LegendDoc from '@components/LegendDoc'

const CartaAval = ({ id, solicitud, detail }) => {
  const handleClick = () => {
    navigate('/portal/samhoi/cartasAval/solicitud')
  }

  const showTitle = () => solicitud
    ? <div><Link to='/portal/samhoi/cartasAval'> Carta Aval </Link> / Solicitud {id && `[cód: ${id}]`}</div>
    : detail
      ? <div><Link to='/portal/samhoi/cartasAval'> Carta Aval </Link> / Detalle [cód: {id}] </div>
      : 'Cartas Aval'

  return (
    <Layout title='SAMHOI' active='samhoi'>
      {detail
        ? (
          <>
            <LegendRequest top />
            <LegendDoc top />
          </>)
        : null}

      <ActivityChart
        title={showTitle()}
        label='Solicitar'
        labelTooltip='Solicitar Carta Aval'
        handleClick={(solicitud || detail) ? null : handleClick}
      >
        {solicitud
          ? <CartaAvalRequest id={id} />
          : detail
            ? <CartaAvalDetail id={id} />
            : <ListOfCartaAval />}
      </ActivityChart>

      {!solicitud && !detail
        ? <LegendRequest />
        : null}
    </Layout>
  )
}

export default CartaAval
