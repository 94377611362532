import styled from 'styled-components'

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    border-radius: 30px;
    box-shadow: 0px 13px 25px -20px #666;
    /* border: 2px solid ${({ theme }) => theme.color.ligthGray}; */
    margin: 0 auto 30px;
    padding-bottom: 10px;

    @media(min-width: 620px){
        flex-direction: row;
    }
`

export const ProfesionalInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h3{
        text-align: center;
        color: ${({ theme }) => theme.color.bluePrimary};
    }

    img{
        width: 100px;
        height: 110px;
        border-radius: 50%;
    }

    @media(min-width: 620px){
        width: 200px;
    }
`

export const Info = styled.div`
    color: ${({ theme }) => theme.color.gray};
    font-weight: 600;
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: space-around;
    font-size: 20px;
    padding-left: 5px;

    div{
        padding: 5px;
    }

    @media(min-width: 620px){
        margin: 0;
    }
`

export const Datetime = styled.div`
    width: 100%;
`

export const Schedule = styled.div`
    
    div{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
`

export const DateC = styled.h4`
    width: 100%;
    text-align: center;
`

export const Time = styled.div`
    width: 70px;
    padding: 10px 5px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.bluePrimary};
    text-align: center;
    vertical-align: middle;
    cursor: pointer; 
    background-color: ${({ theme, active }) => active === 1 ? theme.color.bluePrimary : theme.color.white};
    color: ${({ theme, active }) => active === 1 ? theme.color.white : theme.color.bluePrimary};



    &:hover{
        background-color: ${({ theme }) => theme.color.bluePrimary};
        color: ${({ theme }) => theme.color.white};
    }
`

export const TimeDisabled = styled.div`
    width: 70px;
    /* opacity: 0.3; */
    padding: 10px 5px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.danger};
    text-align: center;
    vertical-align: middle;
    background-color: ${({ theme }) => theme.color.lightGray};
    color: ${({ theme }) => theme.color.danger};
`

export const TimeIPP = styled.div`
    width: 70px;
    opacity: 0.5;
    padding: 10px 5px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.bluePrimary};
    text-align: center;
    vertical-align: middle;
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.bluePrimary};
`

export const TimeDisabledIPP = styled.div`
    width: 70px;
    opacity: 0.5;
    padding: 10px 5px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.danger};
    text-align: center;
    vertical-align: middle;
    background-color: ${({ theme }) => theme.color.lightGray};
    color: ${({ theme }) => theme.color.danger};
`

export const TimeReserved = styled.div`
    width: 70px;
    opacity: 0.5;
    padding: 10px 5px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.warning};
    text-align: center;
    vertical-align: middle;
    background-color: ${({ theme }) => theme.color.lightGray};
    color: ${({ theme }) => theme.color.warning};
`
export const Status = styled.li`  
    color: ${({ theme, status }) =>
        (status === 1) ? theme.color.bluePrimary
        :theme.color.danger
    }
`


export const Label = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
