import React, { useState, useEffect } from 'react'
import config from '@utils/config'
import { getDate } from '@utils/date'
import { formatMoney } from '@utils/formats'
import { useAlert } from '@hooks/useAlert'
import moment from 'moment-timezone'

import {
  Card, ProfesionalInfo, Info, Datetime, Schedule, DateC,
  Time, TimeDisabled, TimeIPP, TimeDisabledIPP, TimeReserved,
  Status, Label
} from './styles'
// import { getDay } from '../../../utils/date'

const arrivalOrderEspecial = [
  'EMI', // EMI
  'AF45C98A-EB49-47AD-BB9E-3A104C63E736', // MEDICINA INTERNA
  '2CBEC350-64C8-4311-AEFC-D64FB5238684', // OTORRINOLARINGOLOGÍA
]

const arrivalOrderProf = [
  '5AAEA956-2172-EC11-90EB-000C29CBF719', // GINECOLOGÍA CON LA DRA GARCES MARIA DEYANIRA
  '63EFB1C3-91B6-EC11-90F5-000C2914A2E9', // CIRUGIA BUCAL CON LA DRA. BARRIOS JANET
  'DDBB15A1-23B9-E911-817E-000C29E6BD0F'// GASTROENTEROLOGÍA  DRA. TORRES ELIZABETH
]

const ESPECIALIDADES = {
  reflexologia: '59DFE9CD-8433-4C1A-91DB-AB74B0FF6357'
}
// REFLEXOLOGÍA 59DFE9CD-8433-4C1A-91DB-AB74B0FF6357 , Martes en la tarde  y Miércoles en la mañana , desactivar el jueves que se maneja por la Gerencia de Proteccion Social .

const CitaCard = ({ cita, fecha, handleDatetime, all=false, cupos=false }) => {
  const [myCita, setMyCita] = useState(cita)
  const [AlertArrival] = useAlert({
    status: true,
    severity: 'warning',
    isClose: false,
    msg: `<span> <b>Orden de llegada:</b>  Esta consulta se atiende por orden de llegada, por tanto puede que la hora que seleccione no sea exactamente la de atención el día de la cita.</span>`
  })
  let {
    profesional, date, time, monto = 0, nb_dia_semana: dia, co_jor_cita: coJorCita,
    especialidad, hora_cita: horaCita, fecha_cita: fechaCita,
    tipo_consulta: tipoConsulta, clinica, direccion,
    medico, medico_perfil: medicoPerfil, medico_sexo: medicoSexo
  } = myCita
  profesional = profesional || {co_especial:'EMI'}
  const { horarios, ...profesionalRest } = profesional
  // console.log({profesional, profesionalRest, cita})

  // console.log({myCita, horarios})
  useEffect(() => {
    setMyCita(cita)
  }, [cita, setMyCita])
  
  const isEMI = () => (profesional.co_especial === 'EMI')

  const isMiddle = (index, times) => (
    index < times.length / 2
  )

  const isAfternoom = (time) => {
    const regex = new RegExp('pm', 'gi')
    const test = regex.test(time.hh_cita)
    return test 
  }

  const isMonday = (date) => {
    const regex = new RegExp('lunes', 'gi')
    const test = regex.test(date)
    return test 
  }

  const isTuesday = (date) => {
    const regex = new RegExp('martes', 'gi')
    const test = regex.test(date)
    return test 
  }

  const isWednesday = (date) => {
    const regex = new RegExp('miercoles', 'gi')
    const regex2 = new RegExp('miércoles', 'gi')
    const test = regex.test(date) || regex2.test(date)
    return test 
  }

  const isThursday = (date) => {
    const regex = new RegExp('jueves', 'gi')
    const test = regex.test(date)
    return test 
  }

  const isArrivalOrden = () => {
    let arrival = false
    arrival = arrivalOrderEspecial.includes(profesional.co_especial)
    if(!arrival) arrival = arrivalOrderProf.includes(profesional.co_prof_int)

    return arrival
  }

  const getHourOfCita = hhCita => {
    if(!hhCita) return [0, 0]
    let [hora, minutos] = hhCita.split(':')
    if(minutos){
      let [min, mer] = minutos.split(' ')
      hora = parseInt(hora)
      min = parseInt(min)
      if(['pm', 'PM'].includes(mer) && hora != 12) hora += 12
      else hora += 0
      return [hora, min]
    }else {
      let [hh, mer] = hora.split(' ')
      hora = parseInt(hh)
      if(['pm', 'PM'].includes(mer) && hora != 12) hora += 12
      else hora += 0
      return [hora, 0]
    }
  }

  const hourValid = (diffFecha, hhCita, feriado) => {
    let valid = true
    if(feriado) return false
    if(diffFecha < 0) return false
    
    let [ hourC, minC ] = getHourOfCita(hhCita)
    let today = moment().tz('America/Caracas')
    // valida si ya pasó el día

    let hour = today.hour() + 1
    let min = today.minutes() + 15
    if(min > 60){
      hour++
      min -= 60
    }


    // ¿ya pasó la hora?
    // if(hour > hourC) valid = false
    // ¿pasó el minuto?
    // if(hour === hourC && min >= minC) valid = false
    // console.log({diffFecha, hhCita, feriado, valid})
    return valid
  }

  // Render Times
  const renderTimesWeb = ({time, profesionalRest, date, fecha, cupo}) => {
    if(!hourValid(time.diff_fecha, time.hh_cita, time.feriado)) return null

    return (
      time.reservado 
      ?<TimeReserved key={profesionalRest.co_prof_int + date + time.hh_cita}>
        {time.hh_cita}
      </TimeReserved>
      :time.disponible
        ? (
          <Time
            key={profesionalRest.co_prof_int + date + time.hh_cita}
            active={(fecha?.cod === (profesionalRest.co_prof_int + date + time.hh_cita)) ? 1 : 0}
            onClick={handleDatetime(profesionalRest, date, time)}
          > {cupo || time.hh_cita}
          </Time>)

        : (
          <TimeDisabled key={profesionalRest.co_prof_int + date + time.hh_cita}>
            {cupo || time.hh_cita}
          </TimeDisabled>)
    )
  }

  const renderTimesIpp = ({time, profesionalRest, date, fecha}) => {
    if(!hourValid(time.diff_fecha, time.hh_cita, time.feriado)) return null

    return (
      time.reservado 
      ?<TimeReserved key={profesionalRest.co_prof_int + date + time.hh_cita}>
        {time.hh_cita}
      </TimeReserved>
      :time.disponible
        ? (
          <TimeIPP key={profesionalRest.co_prof_int + date + time.hh_cita}>
            {time.hh_cita}
          </TimeIPP>)

        : (
          <TimeDisabledIPP key={profesionalRest.co_prof_int + date + time.hh_cita}>
            {time.hh_cita}
          </TimeDisabledIPP>)
    )
  }

  // Render types organization of schedules
  const renderAllCupos = ({profesionalRest, date, times}) => {
    let cupos = { morning:[], afternoom:[] }
    times.forEach(t => {
      isAfternoom(t) ?cupos.afternoom.push(t) :cupos.morning.push(t)
    })

    return (
      <Schedule key={profesionalRest.co_prof_int + date}>
        <DateC> {`${date}`} </DateC>
        <div>
          {profesionalRest.co_prof_int === "17051A86-0943-EC11-90E9-000C29CBF719"
          ?<p>
          La consulta de Nutrición con el Profesional Dr. Luis Mediavilla ,debe ser referida por la Dra. Torres Elizabeth
          </p>

          :<>
            {!cupos.morning.length ?null
              :<div>
                <Label> Mañana (cupos): </Label>
                {cupos.morning.map((time, idx) =>
                  renderTimesWeb({time, profesionalRest, date, fecha, cupo:++idx})
                )}
              </div>
            }
            {!cupos.afternoom.length ?null
              :<div>
                <Label> Tarde (cupos): </Label>
                {cupos.afternoom.map((time, idx) =>
                  renderTimesWeb({time, profesionalRest, date, fecha, cupo:++idx})
                )}
              </div>
            }
          </>
          }
        </div>
      </Schedule>
    )
  }

  const renderAllWeb = ({profesionalRest, date, times}) => (
    <Schedule key={profesionalRest.co_prof_int + date}>
      <DateC> {`${date}`} </DateC>
      <div>
        {profesionalRest.co_prof_int === "17051A86-0943-EC11-90E9-000C29CBF719"
        ?<p>
        La consulta de Nutrición con el Profesional Dr. Luis Mediavilla ,debe ser referida por la Dra. Torres Elizabeth
        </p>
        :times.map((time, index) => {
          // console.log({time, index}) 
          return renderTimesWeb({time, profesionalRest, date, fecha, cupos:true})
        })}
      </div>
    </Schedule>
  )

  const renderFirstMiddle = ({profesionalRest, date, times}) => (
    <Schedule key={profesionalRest.co_prof_int + date}>
      <DateC> {`${date}`} </DateC>
      <div>
        {profesionalRest.co_prof_int === "17051A86-0943-EC11-90E9-000C29CBF719"
        ?<p>
        La consulta de Nutrición con el Profesional Dr. Luis Mediavilla ,debe ser referida por la Dra. Torres Elizabeth
        </p>
        :times.map((time, index) => {
          // console.log({time, index}) 
          return isMiddle(index, times)
            ? renderTimesWeb({time, profesionalRest, date, fecha})
            : renderTimesIpp({time, profesionalRest, date, fecha}) 
        })}
      </div>
    </Schedule>
  )

  const renderLastMiddle = ({profesionalRest, date, times}) => (
    <Schedule key={profesionalRest.co_prof_int + date}>
      <DateC> {`${date}`} </DateC>
      <div>
        {profesionalRest.co_prof_int === "17051A86-0943-EC11-90E9-000C29CBF719"
        ?<p>
        La consulta de Nutrición con el Profesional Dr. Luis Mediavilla ,debe ser referida por la Dra. Torres Elizabeth
        </p>
        :times.map((time, index) => (
          isMiddle(index, times)
            ? renderTimesIpp({time, profesionalRest, date, fecha})
            : renderTimesWeb({time, profesionalRest, date, fecha})
        ))}
      </div>
    </Schedule>
  )

  const renderAfternoom = ({profesionalRest, date, times}) => (
    <Schedule key={profesionalRest.co_prof_int + date}>
      <DateC> {`${date}`} </DateC>
      <div>
        {profesionalRest.co_prof_int === "17051A86-0943-EC11-90E9-000C29CBF719"
        ?<p>
        La consulta de Nutrición con el Profesional Dr. Luis Mediavilla ,debe ser referida por la Dra. Torres Elizabeth
        </p>
        :times.map((time) => (
          isAfternoom(time)
            ?renderTimesWeb({time, profesionalRest, date, fecha})
            :renderTimesIpp({time, profesionalRest, date, fecha})
        ))}
      </div>
    </Schedule>
  )

  const renderMorning = ({profesionalRest, date, times}) => (
    <Schedule key={profesionalRest.co_prof_int + date}>
      <DateC> {`${date}`} </DateC>
      <div>
        {profesionalRest.co_prof_int === "17051A86-0943-EC11-90E9-000C29CBF719"
        ?<p>
        La consulta de Nutrición con el Profesional Dr. Luis Mediavilla ,debe ser referida por la Dra. Torres Elizabeth
        </p>
        :times.map((time) => (
          isAfternoom(time)
            ?renderTimesIpp({time, profesionalRest, date, fecha})
            :renderTimesWeb({time, profesionalRest, date, fecha})
        ))}
      </div>
    </Schedule>
  )

  const renderAllIPP = ({profesionalRest, date, times}) => (
    <Schedule key={profesionalRest.co_prof_int + date}>
      <DateC> {`${date}`} </DateC>
      <div>
        {profesionalRest.co_prof_int === "17051A86-0943-EC11-90E9-000C29CBF719"
        ?<p>
        La consulta de Nutrición con el Profesional Dr. Luis Mediavilla ,debe ser referida por la Dra. Torres Elizabeth
        </p>
        :times.map((time) => ( renderTimesIpp({time, profesionalRest, date, fecha}) ))}
      </div>
    </Schedule>
  )

  return (
    <>
      <Card>
        <ProfesionalInfo>
          <img
            src={medicoPerfil
              ? `${config.statics.doctors}/${medicoPerfil}`
              : medicoSexo === 'F' ? '/doctora-default.jpg' : '/doctor-default.png'}
            alt={`foto de ${medico}`}
          />
          <h3>{horarios ? profesionalRest.medico : medico || 'N/D'}</h3>
          {horarios &&
            <ul>
              <Status status={1}> Disponible</Status>  
              <Status> Ocupado </Status>  
            </ul>
          }
        </ProfesionalInfo>

        {horarios
          ? (
            <Datetime>
              { isArrivalOrden() && <AlertArrival /> }
              {Object.entries(horarios).map(([date, times]) => {
                if(profesionalRest.co_especial === ESPECIALIDADES.reflexologia){
                  if(isTuesday(date)) return renderAfternoom({profesionalRest, date, times}) 
                  if(isWednesday(date)) return renderMorning({profesionalRest, date, times}) 
                  if(isThursday(date)) return renderAllIPP({profesionalRest, date, times}) 
                }
                if(cupos) return renderAllCupos({profesionalRest, date, times})
                if(all) return renderAllWeb({profesionalRest, date, times})
                else return renderFirstMiddle({profesionalRest, date, times})
              })}
            </Datetime>)

          : (
            <div>
            { isArrivalOrden() && <AlertArrival /> }
            <Info>
              {isEMI() && <div><b>Código:</b> {coJorCita}</div>}
              {!isEMI() && <div><b>Servicio:</b> {especialidad || 'N/D'}</div>}
              {!isEMI() && <div><b>Tipo:</b> {tipoConsulta || 'N/D'}</div>}
              <div><b>Sede:</b> {clinica || 'N/D'}</div>
              <div><b>Dirección:</b> {direccion || 'N/D'}</div>
              <div><b>Fecha:</b> {date || (fechaCita ? getDate(fechaCita) : 'N/D')} </div>
              {dia && <div><b>Día:</b> {dia}</div>}
              <div><b>Hora:</b> {horaCita || time} </div>
              {!isEMI() && <div><b>Monto:</b> {formatMoney(monto)} </div>}
            </Info>
            </div>
          )
        }
      </Card>
    </>
  )
}

export default CitaCard
