import React, { useState } from 'react'

import Loader from '@components/Loader'
import Layout from '@components/Layout'
import Grid from '@mui/material/Grid'
import InputText from '@components/inputs/Text'
import Button from '@components/Button'

import { useAlert } from '@hooks/useAlert'
import { useStateValue } from '@context/index.js'
import http from '@utils/fetch-client'

import {
  ContainerPerfil, ContainerData, Title, Paragrah, ContainerBtn
} from './styles'
import useStyles from './stylesUI'

const ChangeDataUser = () => {
  const [{ user }, dispatch] = useStateValue()
  const classes = useStyles()
  const [password, setPassword] = useState({ current: '', change: '', verify: '', validChange: true })
  const [loading, setLoading] = useState({ pic: false, info: false, password: false })
  const [finish, setFinish] = useState(false)
  const [info, setInfo] = useState({
    email: user.tx_correo ? user.tx_correo.trim() : '',
    phone: user.tx_tel_cel ? user.tx_tel_cel.trim() : '',
    phoneHab: user.tx_telf_hab ? user.tx_telf_hab.trim() : ''
  })
  const [MyAlertPassword, setInfoPassword] = useAlert({})
  const logout = () => dispatch({ type: 'LOGOUT' })
  const isBenefi = user.co_tipo === 1
  // console.log({ user, info, password })

  const validEmail = value => {
    const regex = /^(.+@.+\..+)$/
    return regex.test(value)
  }

  const handleEmail = ({ name, value }) => {
    setInfo({ ...info, [name]: value })
  }

  const handlePasswordCurrent = ({ name, value }) => {
    setPassword({ ...password, [name]: value })
  }

  const handlePassword = ({ name, value }) => {
    if (!value) {
      if (password.verify) setInfoPassword({ status: true, severity: 'error', msg: 'Debe ingresar la contraseña a verificar.' })
      else setInfoPassword({ status: false, severity: '', msg: '' })
    } else if (value) {
      if (!password.verify) {
        setInfoPassword({ status: true, severity: 'info', msg: 'Para completar el cambio debe confirmar la contraseña y esta debe tener un mínimo de 8 caracteres' })
      } else {
        if (value === password.verify) {
          setInfoPassword({ status: true, severity: 'info', msg: 'Las contraseñas coinciden, ya puede <b>"Guardar Cambios"</b>' })
        } else setInfoPassword({ status: true, severity: 'error', msg: 'Las contraseñas no coinciden' })
      }
    }
    setPassword({ ...password, [name]: value })
  }

  const handlePasswordVerify = ({ name, value }) => {
    if (!value) {
      if (password.change) setInfoPassword({ status: true, severity: 'info', msg: 'Para completar el cambio debe confirmar la contraseña' })
      else setInfoPassword({ status: false, severity: '', msg: '' })
    } else if (value) {
      if (!password.change) {
        setInfoPassword({ status: true, severity: 'error', msg: 'Debe ingresar la contraseña a verificar.' })
      } else {
        if (value === password.change) {
          setInfoPassword({ status: true, severity: 'info', msg: 'Las contraseñas coinciden, puede continuar' })
        } else setInfoPassword({ status: true, severity: 'error', msg: 'Las contraseñas no coinciden' })
      }
    }
    setPassword({ ...password, [name]: value })
  }

  const submitPassword = () => {
    setLoading({ ...loading, password: true })
    const { current, change, verify } = password
    const dataPassword = {
      pUsername: user.username,
      pCurrentPassword: current,
      pNewPassword: change
    }
    const dataUser = {
      nuevoCorreo: info.email,
      celular: info.phone,
      habitacion: info.phoneHab
    }

    // console.log(dataPassword)
    // console.log(dataUser)
    let errorHTML = ''
    if(user.co_tipo === 1){
      if (!validEmail(info.email)) errorHTML += '<li> El correo ingresado no es válido.</li>'
    }
    if (change.length < 8) errorHTML += '<li> La contraseña nueva debe tener un <b>mínimo de 8 caracteres</b>.</li>'
    if (!current) errorHTML += '<li> Debe ingresar la <b>contraseña actual</b>.</li>'
    if (!change) errorHTML += '<li> Debe ingresar la <b>contraseña nueva</b>.</li>'
    if (!verify) errorHTML += '<li> Debe ingresar la <b>verificación</b> de la contraseña nueva .</li>'
    if (change !== verify) errorHTML += '<li> La contraseña de <b>verificación</b> no conincide con la contraseña nueva.</li>'
    if (current === change) errorHTML += '<li> Su contraseña no debe ser igual a la anterior.</li>'
    if (change === user.username) errorHTML += '<li> Su contraseña no debe ser igual a su nombre de usuario.</li>'
    if (errorHTML) {
      setLoading({ ...loading, password: false })
      return setInfoPassword({ status: true, severity: 'error', msg: errorHTML })
    }

    Promise.all([
      http.patch('api/usuario/cambiarContrasena', dataPassword),
      http.patch('api/usuario/actualizarInformacionContacto', dataUser)
    ]).then(res => {
      // console.log({ res })
      const statusOk = res.reduce((acc, { status }) => acc && status === 200, true)
      const data = res.reduce((acc, { data }) => acc.concat(data), [])
      return { status: statusOk ? 200 : 400, data }
    }).then(({ status, data }) => {
      console.log({ status, data })
      if (status !== 200) throw Error('No se recibieron los dos status 200 ')
      if (!data[0]?.valido) throw Error(data[0].respuesta)
      setInfoPassword({
        status: true,
        severity: 'success',
        msg: `Su información ha sido actualizada <b>Exitosamente</b>. Será redirigido al 
          Login para que inicie sesión con su nueva contraseña.`
      })
      setFinish(true)
      setPassword({ current: '', change: '', verify: '', validChange: true })
      setTimeout(() => { logout() }, 1000 * 5)
    }).catch(err => {
      setLoading({ ...loading, password: false })
      console.error(`Err submitPassword: ${err}`)
      console.error(`Err name: ${err.name}`)
      console.error(`Err msg: ${err.message}`)
      const msg = err.name === 'Error'
        ? err.message
        : `Disculpe, no se logró actualizar su información. Por favor intente nuevamente, si el 
        error persiste comuníquese con nuestro personal.`
      setInfoPassword({ status: true, severity: 'error', msg })
    }).finally(() => setLoading({ ...loading, password: false }))
  }

  const showWelcome = () => {
    const welcome = user?.in_sexo === 'F' ? 'Bienvenida' : 'Bienvenido'
    return `${welcome} al portal del apucv-ipp`
  }

  const showNames = () => {
    if(!user.nb_apellid) return user.username

    const prename = user?.in_sexo === 'F' ? 'Sra' : 'Sr'
    const name = user?.nb_benefi || ''
    const lastname = user?.nb_apellid || ''
    return `${prename} ${name} ${lastname} `
  }

  const showInfoEmail = () => {
    if(!isBenefi) return ''
    return ` y a ingresar su correo electrónico, si ya tiene uno asignado verifique que sea el que usa actualmente, si no lo es, por favor modifíquelo`
  }

  return (
    <Layout title='Cambio de Datos' active='' noOptions>
      <ContainerPerfil>
        <Title> {showWelcome()} </Title>

        <Paragrah>
          {` ${showNames()} antes de comenzar con el uso del portal 
          le invitamos a cambiar la contraseña que le ha sido asignada${showInfoEmail()}.`}
        </Paragrah>

        <ContainerData>
          {!finish &&
            <Grid container className={classes.root} spacing={2}>
              { isBenefi && 
                <Grid item xs={12} md={6}>
                  <InputText
                    id='email'
                    value={info.email}
                    handleChange={handleEmail}
                    label='Correo Electronico'
                    name='email'
                    autoComplete='off'
                    type='text'
                    inputProps={{
                      autoComplete: 'off'
                    }}
                    />
                </Grid>
              }

              { !isBenefi && <Grid item xs={0} md={3}></Grid> }

              <Grid item xs={12} md={6}>
                <InputText
                  required
                  id='current'
                  type='password'
                  // value={password.current}
                  handleBlur={handlePasswordCurrent}
                  label='Contraseña actual'
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name='current'
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputText
                  required
                  id='change'
                  type='password'
                  // value={password.change}
                  handleBlur={handlePassword}
                  label='Contraseña nueva'
                  name='change'
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputText
                  required
                  id='verify'
                  type='password'
                  // value={password.verify}
                  handleBlur={handlePasswordVerify}
                  label='Repetir contraseña'
                  name='verify'
                />
              </Grid>
            </Grid>}

          <div> {loading.password ? <Loader /> : <MyAlertPassword />} </div>

          {!finish &&
            <ContainerBtn>
              <Button
                label='Cerrar Sesion'
                onClick={logout}
                color='danger'
              />

              <Button
                label='Cambiar Contraseña'
                onClick={submitPassword}
                color='primary'
              />
            </ContainerBtn>}
        </ContainerData>
      </ContainerPerfil>
    </Layout>
  )
}

export default ChangeDataUser
