import React, { useState } from 'react'
import Alert from '@mui/material/Alert'

const NO_OP = () => { }

export const useAlert = ({ status = false, severity = 'success', msg = '', isClose = true }) => {
  const [info, setInfo] = useState({ status, severity, msg, isClose })

  /* Opción para que se vea más limpio en los archivos que se llama el hook
    const setState = (status, severity, msg) => {
        setInfo({status, severity, msg})
    } */
  const handleClose = () => {
    return isClose
      ? () => setInfo({ status: false, severity: '', msg: '' })
      : NO_OP
  }

  const MyAlert = () => {
    if (!info.status) return null
    
    if (isClose) return (
      <Alert
        severity={info.severity}
        onClose={handleClose()}
        style={{ margin: '10px 0' }}
      >
        <span dangerouslySetInnerHTML={{ __html: info.msg }} />
      </Alert>
    )
    else return (
      <Alert
        severity={info.severity}
        style={{ margin: '10px 0' }}
      >
        <span dangerouslySetInnerHTML={{ __html: info.msg }} />
      </Alert>
    ) 
  }

  return ([MyAlert, setInfo])
}
