import styled from 'styled-components'

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;

    h1{
        color: ${({ theme }) => theme.color.bluePrimary}
    }
`

export const ContainerStatus = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
`

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 0 20px;

   

    
    @media(min-width: 720px){
        align-items: flex-start;
        flex-direction: row;
        
    }
`

export const InfoDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 18px;
    /* height: 100%; */
`

export const Form = styled.div`
    display: flex;
    color: ${({ theme }) => theme.color.gray};
    flex-direction: column;    
    
    div{
        display: flex;
        padding: 5px 0;
        b{
            margin-right: 10px;
        }
    }
`
