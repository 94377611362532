import styled from 'styled-components'

export const Container = styled.article`  
    display: flex;
    flex-direction: column;
    /* width:100%; */
    height: 420px;
    overflow: hidden;
    border-radius:10px;
    box-shadow: 2px 2px 5px 0px ${({ theme }) => theme.color.shadow};
    margin-bottom: 50px;
    cursor: pointer;
`

export const Img = styled.img`  
    width:100%;
    height: 220px;
    object-fit: fill;
`

export const Body = styled.div`  
    overflow: hidden;
    height: 200px;
    margin: 0 20px 20px 20px;
`

export const Title = styled.h2`  
    color: ${({ theme }) => theme.color.grayDark};
    font-weight: bold;
`

export const Content = styled.p`  
    margin-left: 10px;
    height: auto;
    text-align: justify;
    opacity: 0.75;
`

export const Date = styled.div`  
    color: ${({ theme }) => theme.color.gray};
    font-weight: 500;
    width: 100%;
    max-width: 700px;
    text-align: left;
`
