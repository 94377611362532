import styled from 'styled-components'

export const NavBar = styled.ul`  
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;

    position: relative;
    top: 25px;
    

    @media (min-width: 850px) {
        align-items: flex-start;
        flex-direction: row;
    }
`

export const SubNavBar = styled.ul`  
    display: none;
    padding: 0px;
    position: relative;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #fff;
    margin: 0;
`

export const Option = styled.li`  
    /* width: 100px; */

    margin: 0px 15px;
    padding: 10px 0;
    color: ${({ theme, logout }) => (logout) ? theme.color.danger : theme.color.bluePrimary};
    // background-color: ${({ theme }) => theme.color.white};
    vertical-align: center;
    font-weight: bold;
    text-align: center;
    list-style-type: none;
    text-decoration: none;
    /* background-color: ${({ theme }) => theme.color.blue}; */
    cursor: pointer;
    border-bottom: 3px solid ${({ theme, selected }) => (selected) ? theme.color.bluePrimary : 'white'};
    &:hover{
        font-size: 17px;
        color: ${({ theme, logout }) => (logout) ? theme.color.danger : theme.color.bluePrimary}
    };   
`

export const OptionImg = styled.li` 
    display: flex;
    flex-direction: column;
    margin: 0px 15px;
    vertical-align: center;
    list-style-type: none;
    text-decoration: none;
    // background-color: ${({ theme }) => theme.color.white};
    cursor: pointer;

    a{
        height:0
    }
 
    &:hover{
        scale: 1.1
    };   

    span {
        color: ${({ theme }) => theme.color.gray};
        font-size: 0.8rem;
    }
`

export const Img = styled.img`  
    width: 3rem;    
    height: 3rem;    
    border-radius: 30px;
    // position: relative;
    // top: -13px;
    cursor: pointer;

    &:hover{
        box-shadow: 0 5px 10px -5px ${({ theme }) => theme.color.shadow};
    };
`
