import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 50px;
  padding: 0 20px;
`

export const Line = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 25px;
  background-color: ${({ theme, check }) => (check) ? theme.color.bluePrimary : 'lightgray'};

  @media (min-width: 520px) {
    height: 8px;   
  }
`

export const CircleStyled = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid lightgray;
  background-color: ${({ theme, check }) => (check) ? theme.color.bluePrimary : 'lightgray'};
  margin: 5px;
  cursor: ${({ loading }) => (loading) ? 'not-allowed' : 'pointer'};

  .full{display: none}
  
  div{
    width: 60px;
    position: relative;
    top: 30px;
    font-size: 15px;
    font-weight: 600;
    margin: 0px;
    color: ${({ theme }) => theme.color.bluePrimary};
  }

  @media (min-width: 520px) {
    width: 25px;
    height: 25px;
    
    div{
      font-size: 20px;
      left: -15px;
    }   

    .full{display: block}
    .number{display: none}
  }
`
