import React from 'react'
import config from '@utils/config'
import {
  Container, Beneficiarie, Img, Body, Info, Contact,
  Selector, SelectorItem, SelectorInfo
} from './styles'

const NO_OP = () => { }

const formatBenediciarie = b => ({
  coBenefi: b.co_benefi,
  fotoPerfil: b.foto_perfil,
  nbBenefi: b.nb_benefi,
  nbApellid: b.nb_apellid,
  username: b.username,
  inNacional: b.in_nacional,
  nuCedBen: b.nu_ced_ben,
  txCorreo: b.tx_correo,
  txTelCel: b.tx_tel_cel,
  txTelfHab: b.tx_telf_hab,
  nbParent: b.nb_parent
})

const Beneficiaries = ({ beneficiaries = [], selector, selected, handleSelected = NO_OP }) => {
  const myHandleClick = coBenefi => () => {
    return coBenefi === selected ? NO_OP : handleSelected(coBenefi)
  }

  const getName = (type, name, lastname, username) => {
    return !name && !lastname
      ? <h4><b>{username}</b></h4>
      : type === 'selector'
        ? (
          <>
            <h4> <b>{name}</b> </h4>
            <h4> {lastname} </h4>
          </>)
        : <h4> <b>{name}</b> {lastname} </h4>
  }

  return (
    <Container>
      {selector
        ? (
          <Selector>
            {beneficiaries.map(b => {
              const { coBenefi, nbBenefi, nbApellid, username, fotoPerfil } = formatBenediciarie(b)
              return (
                <SelectorItem
                  key={coBenefi}
                  selected={coBenefi === selected}
                  onClick={myHandleClick(coBenefi)}
                >
                  <Img
                    src={fotoPerfil ? `${config.statics.users}/${fotoPerfil}` : '/perfil-default.png'}
                  />
                  <Body>
                    <SelectorInfo>
                      {getName('selector', nbBenefi, nbApellid, username)}
                    </SelectorInfo>
                  </Body>
                </SelectorItem>
              )
            })}
          </Selector>)

        : (
          <ul>
            {beneficiaries.map(b => {
              const {
                coBenefi, fotoPerfil, nbBenefi, nbApellid, username,
                inNacional, nuCedBen, txCorreo, txTelCel, txTelfHab, nbParent
              } = formatBenediciarie(b)

              return (
                <Beneficiarie key={coBenefi}>
                  <Img
                    src={fotoPerfil ? `${config.statics.users}/${fotoPerfil}` : '/perfil-default.png'}
                  />
                  <Body>
                    <Info>
                      {getName('list', nbBenefi, nbApellid, username)}
                      <p> {nuCedBen && inNacional + parseInt(nuCedBen)} </p>
                      <p> - {nbParent || 'Sin Parentesco'} </p>
                    </Info>
                    <Contact> {txCorreo || 'Sin correo'}
                      - {txTelCel || 'Sin Teléfono'}
                      - {txTelfHab || 'Sin Teléfono de Habitación'}
                    </Contact>
                  </Body>
                </Beneficiarie>
              )
            })}
          </ul>)}
    </Container>
  )
}

export default Beneficiaries
