import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import StatusCita from '@components/StatusCita'
import Button from '@components/Button'
import { getDate } from '@utils/date'
import { Container, Name, Profesional, Date, DivCod, DivStatus } from './styles'

const NO_OP = () => {}

const Cita = ({ cita, header, handleDelete = NO_OP, portal }) => {
  const {
    co_cita_web: coCitaWeb, co_cita_salud: coCitaSalud, service, especialidad,
    profesional, doctor, medico, date, fecha_cita: fechaCita, fecha_cita: feCita, status
  } = cita
  const [visibleDelete, setVisibleDelete] = useState(false)
  const itsErasable = portal && status === 'En creación'

  const showDate = (fecha, fe) => {
    return fecha
      ? getDate(fecha)
      : fe ? getDate(fe) : 'N/D'
  }

  const setLink = (status, id) => {
    return !id
      ? ''
      : status === 'En creación'
        ? `/portal/citas/solicitud/${id}`
        : `/portal/citas/${id}`
  }

  const goToLink = (status, coCitaWeb, coCitaSalud) => () => {
    const id = coCitaWeb || coCitaSalud
    navigate(setLink(status, id))
  }

  const handleMouseOver = () => {
    setVisibleDelete(true)
  }

  const handleMouseLeave = () => {
    setVisibleDelete(false)
  }

  return (
    <>
      {header
        ? (
          <Container header={header}>
            <DivCod header> Cód </DivCod>
            <Name header={header}> {service?.name} </Name>
            <Profesional header={header}> {profesional?.name} </Profesional>
            <Date header={header}> {date || showDate(fechaCita, feCita)} </Date>
            <DivStatus> <StatusCita header={header} status={-1} /> </DivStatus>
          </Container>)

        // : <Link to={setLink(status, co_cita_web)}>
        : (
          <Container
            id={`cita-${coCitaWeb}`}
            onMouseOver={itsErasable ? handleMouseOver : NO_OP}
            onMouseLeave={itsErasable ? handleMouseLeave : NO_OP}
            onClick={goToLink(status, coCitaWeb, coCitaSalud)}
          >
            <DivCod> {cita?.co_cita_web || '-'} </DivCod>
            <Name> {service?.name || especialidad || 'N/D'} </Name>
            <Profesional> {profesional?.name || doctor || medico.trim() || 'N/D'} </Profesional>
            <Date> {date || showDate(fechaCita, feCita)} </Date>

            {itsErasable && visibleDelete
              ? (
                <DivStatus>
                  <Button
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    color='danger'
                    style={{ width: '30px' }}
                    onClick={handleDelete}
                  />
                </DivStatus>)

              : (
                <DivStatus>
                  <StatusCita status={status} />
                </DivStatus>)}

          </Container>)}
      {/* </Link>} */}
    </>
  )
}

export default Cita
