import styled from 'styled-components'

export const Container = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 50px auto;

    h1{
        color: ${({ theme }) => theme.color.bluePrimary}
    }

    a{
        text-decoration: none;
        color: ${({ theme }) => theme.color.black};
    }

    section{
        margin-bottom: 50px;
        padding: 1px 20px;
        margin-bottom: 50px;
        border-radius: 10px;
        background: white;
    }
`

export const Body = styled.div`
    padding-bottom: 1rem;
`
