import React from 'react'
import { Container, Line, CircleStyled } from './styles'
import Tooltip from '@mui/material/Tooltip'

const Circle = ({ index, check, name, onClick, loading }) => (
  <>
    {(index !== 1) && <Line check={check} />}
    <Tooltip title={name} aria-label={name}>
      <CircleStyled check={check} onClick={onClick} loading={loading ? 1 : 0}>
        <div className='full'>{`Paso ${index}`}</div>
        <div className='number'>{index}</div>
      </CircleStyled>
    </Tooltip>
  </>
)

const Progress = ({ steps, changeStep, index, loading }) => {
  return (
    <Container>
      {steps.data.map(({ id, title }, idx) => (
        <Circle
          key={id}
          index={idx + 1}
          check={idx <= index}
          name={title}
          onClick={!loading ? changeStep(idx) : () => { }}
          loading={loading}
        />
      ))}
    </Container>
  )
}

export default Progress
