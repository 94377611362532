import { useState, useEffect } from 'react'
import http from '@utils/fetch-client'
import { navigate } from '@reach/router'

import { Container } from './styles'

const Timer = ({ id, miliseconds, citaCreatingClean }) => {
  const [time, setTime] = useState(miliseconds)

  /* useEffect(() => {
      if(time > 0){
          const interval = setInterval(() => {
            setTime(time => time - 1000)
          }, 1000)
          return () => clearInterval(interval)
      }
    }, [id])
 */
  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() =>
        setTime(time => time - 1000)
      , 1000)
      return () => clearInterval(interval)
    }
  }, [time])

  const deleteAndCreateCita = async () => {
    try {
      const { status, data } = await http.delete(`api/cita/${id}`)
      console.log('delete', { status, data })
      if (status !== 200) throw new Error(status)

      const res = await http.put('api/cita')
      console.log('create', { res })
      if (res.status !== 201) throw new Error(res.status)
      // debugger
      citaCreatingClean()
      navigate(`/portal/citas/solicitud/${res.data.co_cita}`)
    } catch (err) {
      const msg = 'disculpe, NO se logró crear la solicitud, por favor intente mas tarde o comuníquese con nuestras oficinas.'
      console.error(`${err}: ${msg}`)
    }
  }

  if (time <= 0) deleteAndCreateCita()

  const showTime = (miliseconds) => {
    const secondsTotal = miliseconds / 1000
    let minutes = parseInt(secondsTotal / 60)
    let seconds = parseInt(secondsTotal - (minutes * 60))

    minutes = `${minutes < 10 ? '0' : ''}${minutes}`
    seconds = `${seconds < 10 ? '0' : ''}${seconds}`
    return `${minutes}:${seconds}`
  }

  return (
    <>
      <Container>
        {time <= 0
          ? 'Ya pasaron mas de 10 minutos sin realizar el pago, espere un momento, se le creará una nueva solicitud.'
          : `Tiempo restante para el pago: ${showTime(time)}`}
      </Container>
    </>
  )
}

export default Timer
