import React, { useState, useEffect } from 'react'
import { valores } from '@mocks/home'
import Tooltip from '@mui/material/Tooltip'

import { Container, Header, Title, Subtitle, Content, NavContainer, Nav } from './styles'

const AboutUs = () => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(index => (index === (valores.length - 1)) ? 0 : index + 1)
    }, 15000)
    return () => clearInterval(interval)
  }, [])

  const handleClick = idx => () => {
    setIndex(idx)
  }

  return (
    <Container>
      <Header>
        <Title> {valores[index].title} </Title>
        <Subtitle> {valores[index].subtitle} </Subtitle>
      </Header>

      <Content dangerouslySetInnerHTML={{ __html: valores[index].content }} />

      <NavContainer>
        {valores.map(({ id, title, subtitle }, idx) =>
          <Tooltip title={subtitle} aria-label={subtitle} key={id}>
            <Nav
              selected={idx === index}
              onClick={handleClick(idx)}
            />
          </Tooltip>
        )}
      </NavContainer>
    </Container>
  )
}
export default AboutUs
