import React, { useState } from 'react'
import { PieChart } from 'react-minimal-pie-chart'
import { Container, Legend, LegendItem, LegendColor, LegendLabel } from './styles'

const colors = {
  // Citas
  'En creación': '#9e9e9e',
  Pagada: '#388e3c',
  Pagado: '#388e3c',
  Confirmada: '#1976d2',
  Rechazada: '#d32f2f',
  'En SAM': '#444',
  // Reembolsos
  'En Espera': '#1976d2',
  Confirmadas: '#388e3c',
  Rechazadas: '#d32f2f',
  'En SAMHOI': '#444',
  'Aprobado Finanza/Pendiente Generar Pago': '#4fbd55',

  Valida: '#388e3c',
  'Esperando revision': '#1976d2'
}

const FullOption = ({ data = null, styles, mayor }) => {
  const [selected, setSelected] = useState(null)
  const [hovered, setHovered] = useState(undefined)
  console.log('FullOption', {data}, PieChart?.defaultProps)
  const myData = data.map((entry, i) => {
    return (hovered === i)
      ? { ...entry, color: 'grey' }
      : { ...entry, color: colors[entry.title] }
  })

  const lineWidth = 60


  return (
    <Container>
      {data === null
        ? null
        : !data.length
            ? <h4>No hay datos para mostrar en la gráfica</h4>
            : <>
              <PieChart
                style={{
                  fontFamily:
                  '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                  fontSize: '8px',
                  ...styles
                }}
                data={myData}
                radius={45}
                lineWidth={60}
                segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                segmentsShift={(index) => (index === selected ? 6 : 1)}
                animate
                label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%'}
                labelPosition={100 - lineWidth / 2}
                labelStyle={{
                  fill: '#fff',
                  opacity: 0.75,
                  pointerEvents: 'none'
                }}
                onClick={(_, idx) => setSelected(idx === selected ? undefined : idx)}
                onMouseOver={(_, idx) => setHovered(idx)}
                onMouseOut={() => setHovered(undefined)}
              />

              <Legend style={{ ...styles }}>
                {data.map(({ title, value }, index) =>
                  <LegendItem key={title}>
                    <LegendColor color={colors[title]} />
                    <LegendLabel bold={index === selected}> {title} ({value}) </LegendLabel>
                  </LegendItem>
                )}
                <LegendItem>
                  <LegendColor />
                  <LegendLabel> Total {data.reduce((acc, { value }) => acc + value, 0)} </LegendLabel>
                </LegendItem>
              </Legend>
              </>}
    </Container>
  )
}

export default FullOption
