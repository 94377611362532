import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(0,0,0,0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  /* padding: 50px; */
  overflow: auto;
`

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.color.white};
  
  h2{
    margin: 5px auto;
  }
`

export const Image = styled.img`
  min-width: 60%;
  max-width: 90%;
  max-height: 85%;
  /* margin: 20px; */
  border-radius: 20px;
  border: 3px solid ${({ theme }) => theme.color.black};
`

export const BtnClose = styled.div`
  position: relative;
  top: -25px;
  /* right: calc(-50% + 50px); */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  color: ${({ theme }) => theme.color.white};
  font-size: 24px;
  font-weight: 600;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.black};
  box-shadow: 0px 3px 5px 0px ${({ theme }) => theme.color.shadow};
  cursor: pointer;
`
