import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 15,
    width: '100%',
    padding: 4
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  root: {
    flexGrow: 1
  },

  paper: {
    margin: 'auto',
    padding: '10px',
    marginTop: '15vh',
    // left: '50%',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    height: 'auto'
  },

  paperImportant: {
    margin: 'auto',
    marginTop: '15vh',
    maxHeight: '60%',
    // left: '50%',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #fff',
    boxShadow: theme.shadows[5]
  },

  title: {
    margin: '10px 0'
  },
  data: {
    color: 'gray',
    margin: '5px 0'
  },

  control: {
    padding: theme.spacing(2)
  },
  porciento: {
    width: '100%',
    marginBottom: 15
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  formControlPadding: {
    marginBottom: 15,
    width: '100%',
    padding: 4
  },
  padding: {
    marginBottom: 15
  },
  btnRegistrar: {
    marginBottom: 15
  }
}))

export default useStyles
