import styled from 'styled-components'

export const Container = styled.li`  
    display: flex;
    flex-direction: column;
    cursor: ${({ header }) => header ? '' : 'pointer'};
    padding: 20px 0;
    justify-content: space-between;
    align-items: center;

    &:hover{
        background-color: ${({ header }) => header ? '' : '#eee'};
    }

    @media (min-width: 600px) {
        flex-direction: row;
        padding: 20px 5px;
    }
`

export const Name = styled.div`  
    width: 200px;
    font-weight: ${({ header }) => header && 'bold'};
    color: ${({ theme, header }) => header && theme.color.black};
    text-align: center;
    @media (min-width: 600px) {
        text-align: left;
    }
`
export const Profesional = styled.div`  
    width: 200px;
    font-weight: ${({ header }) => header && 'bold'};
    color: ${({ theme, header }) => header && theme.color.black};
    text-align: center;
`

export const Date = styled.div`  
    color: ${({ theme, header }) => header && theme.color.black};
    width: 165px;
    text-align: center;
    font-weight: ${({ header }) => header && 'bold'};
`

export const DivStatus = styled.div`  
    width: 100px;
    display: flex;
    justify-content: center;
`

export const DivCod = styled.div`  
    color: ${({ theme, header }) => header && theme.color.black};
    font-weight: ${({ header }) => header && 'bold'};
    width: 80px;
    display: flex;
    justify-content: center;
`
