import styled from 'styled-components'
import theme from '../../../context/theme'

export const Container = styled.div`
    width: 85%;
    margin: 50px auto;

    h1{
        color: ${({ theme }) => theme.color.bluePrimary}
    }

    a{
        text-decoration: none;
        color: ${({ theme }) => theme.color.black};
    }

    section{
        margin-bottom: 50px;
        padding: 1px 20px;
        margin-bottom: 50px;
        border-radius: 10px;
        background: white;
    }
`

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @media(min-width: 520px ){
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Notification = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px auto;
    width: 85%;
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 50px;
    border: solid 1.5px #ddd;
    font-weight: ${({ header }) => header ? '500' : ''};
    font-size: ${({ header }) => header ? '18px' : '15px'};
    
    div{
        display: flex
    }
    
    button{
        font-size: 10px;
        min-width: 70px;
        margin: 5px ;
    }
    
    &:hover{
        /* background-color: ${({ header }) => header ? '' : '#eee'};     */
        border: solid 1.5px ${({ theme }) => theme.color.bluePrimary};
    }
    
    @media(min-width: 750px ){  
        flex-direction: row;
        justify-content: space-between;
    }
`
export const NotificationInfo = styled.div`
    display: flex;
    /* justify-content: center; */
    align-items: center;

    >div{
        display: flex;
        flex-direction: column;
    }
`

export const NotificationButtons = styled.div`
    margin: auto;
    
    @media(min-width: 750px ){  
        margin: 0;
    }
`

export const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    color: ${({ theme }) => theme.color.gray};

`
