import styled from 'styled-components'

export const ImgContainer = styled.div`
    width: 100%;
    height: 500px;
    position: relative;
    /* z-index: -2; */
    /* background-color: lightskyblue; */
    background-color: #00001F;
    background-image: url(${({ img }) => img.small});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 

    @media (min-width: 500px) {
        background-image: url(${({ img }) => img.large});
    }
`

/* export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: fill;
` */

export const Organization = styled.section`
    position: relative;
    /* top: -50px; */
    background-color: ${({ theme }) => theme.color.white};
    /* background: rgb(15,92,127); */
    /* background: linear-gradient(180deg,rgba(15,92,127,1) -80%,rgba(255,255,255,1) 70%); */
    height: 350px;
    max-width: 950px;
    margin: 40px auto 100px;
    box-shadow: 0 0.3rem 0.6rem ${({ theme }) => theme.color.shadow};
    
    @media (min-width: 650px) {
        height: 250px;
    }
`

export const Objective = styled.div`
    max-width: 900px;
    margin: 20px auto;
    h3{
        color: ${({ theme }) => theme.color.bluePrimary};
        margin: 10px 0;
        font-size: 30px;
    }
`

export const Login = styled.form`
    display: none;
    position: relative;
    /* z-index: -1; */
    bottom: 450px;
    left: calc(50% - 280px);
    border-radius: 5px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.color.white};
    min-height: 280px;
    max-width: 250px;
    box-shadow: 0 5px 10px ${({ theme }) => theme.color.shadow};
    flex-direction: column;
    justify-content: space-between;
    
    a{
        margin: 0 0 0 auto;
        font-size: 11px;
        cursor: pointer;
        &:hover{
            color: ${({ theme }) => theme.color.bluePrimary}
        };
    }

    span{
        font-size: 12px;
    }
    
    @media (min-width: 650px) {
        display: ${({ user }) => user ? 'none' : 'flex'};
        left: calc(85% - 280px);;
    }

    @media (min-width: 850px) {
        display: ${({ user }) => user ? 'none' : 'flex'};
        left: calc(90% - 280px);;
    }

`

export const LoginInput = styled.div`
    padding: 10px 0;
    display: flex;
    flex-direction: column;

`

export const DivMessage = styled.div`
    width: 100%;
`

export const LoginBtn = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    button{
        width: 100%
    }
`

export const SectionAbout = styled.section`
    min-height: 200px;
    width: 85%;
    max-width: 1000px;
    margin: auto;

    p{
        text-indent: 10px;
        text-align: justify;
    }
    
    a{
        margin:auto;
        text-indent: 10px;
        text-align: justify;
        margin-bottom: 15px;
    }
    
    @media (min-width: 650px) {
        margin-top: ${({ user }) => user ? '30px' : '-300px'};
    }
`

export const ContainerAbout = styled.div`
    margin-bottom: 30px;    
`

export const Services = styled.section`
    /* margin-top: ${({ user }) => user ? '0px' : '-300px'}; */
    margin: 100px 0; 
    min-height: 100px;
`
export const ServiceContent = styled.div`
    margin-bottom: 50px;

    p{
        width: 85%;
        max-width: 1000px;
        margin:auto;
        text-indent: 10px;
        text-align: justify;
        margin-bottom: 15px;
    }
`

export const ServiceContentImg = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    color: ${({ theme }) => theme.color.gray};

    div{
        display: flex;
        flex-direction: column;
        margin: 15px 25px;
    }
    img{
        width: 400px;
        height: 250px;
        border-radius: 10px;
        box-shadow: 5px 5px 8px rgb(32 33 36 / 28%);

    }
`

export const Clinics = styled.section`
    margin: 100px 0; 
`

export const ContainerClinicas = styled.div`
    width: 90vw;
    margin: auto;
    display: flex; 
    flex-wrap: wrap;
    justify-content: space-around;

    img{
        cursor: pointer;
        width: 150px;
        height: 150px;
        margin: 0;
        
        &:hover{
            width: 155px;
            height: 155px;
        }
    }
`

export const ContainerContact = styled.section`
    margin: 15px 0;
    min-height: 700px;
`

export const Title = styled.h2`
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    color: ${({ theme }) => theme.color.bluePrimary};
    
    @media (min-width: 520px) {
        font-size: 3rem;
    }
`

export const Separador = styled.hr`
    width: 80%;
    @media (min-width: 720px) {
        margin: 3rem auto;
    }
`
