import styled from 'styled-components'

export const Container = styled.div`
  width: '100%';
  `

export const ContainerBtn = styled.div`
  width: '100%';
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
