
const profesionals = [
  {
    id: 1,
    name: 'José Gregorio',
    perfil: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyNEGT9Vrp85i4_gSVhWe9i5Yif6hlX0Y97g&usqp=CAU',
    horarios: [
      {
        date: '27/01/2021',
        times: ['12:00pm', '01:00pm', '01:30pm', '03:30pm', '04:00pm', '04:30pm']
      }
    ]
  },
  {
    id: 2,
    name: 'María',
    perfil: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSs3bVV0N5moOu6CN7rC1UmF4mYSUtfoV4mUw&usqp=CAU',
    horarios: [
      {
        date: '27/01/2021',
        times: ['12:00pm', '01:00pm', '02:00pm', '03:00pm', '04:00pm']
      }
    ]
  },
  {
    id: 3,
    name: 'José',
    perfil: 'https://tu-buendoctor.com/images/2020/11/03/doctor-1.jpg',
    horarios: [
      {
        date: '27/01/2021',
        times: ['12:00pm', '01:00pm', '03:00pm', '03:30pm', '04:00pm', '05:00pm']
      },
      {
        date: '28/01/2021',
        times: ['12:00pm', '01:00pm']
      }
    ]
  },
  {
    id: 4,
    name: 'José María',
    perfil: 'https://previews.123rf.com/images/tuktukdesign/tuktukdesign1606/tuktukdesign160600052/58451144-doctor-icon-woman-female-lady-doctor-icon-in-glyph-vector-illustration-.jpg',
    horarios: [
      {
        date: '27/01/2021',
        times: ['12:00pm', '01:00pm', '02:00pm']
      }
    ]
  }

]

export default profesionals
