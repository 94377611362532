import { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { useStateValue } from '@context'
import http from '@utils/fetch-client'
import { useEffectAbortable } from '@hooks/useEffectAbortable'

import { Container, Notifications } from './styles'

const NotificationsIcon = ({ header }) => {
  const [{ user }] = useStateValue()
  const [notifications, setNotifications] = useState(null)

  const fetchNotificationUnread = (aborted) => {
    setNotifications(null)
    // console.log('fetch NIcon',{aborted})
    http.get('api/notificacion/sinLeer')
      .then(({ status, data }) => {
        // console.log('fetch NIcon response',{aborted})
        if (aborted) return
        if (status !== 200) throw Error('Disculpe, ocurrió un error consultados las Notificaiones. Por favor intente más tarde.')

        if (user.co_tipo === 4) { // Operador SAMHOI NO citas
          data = data.filter(u => u.co_tipo !== 3)
        } else if (user.co_tipo === 5) { // Operador SEMO NO carta y reembolso
          data = data.filter(u => u.co_tipo !== 1 && u.co_tipo !== 2)
        }

        setNotifications(data.length)
      })
      .catch(err => {
        console.error('[NotificationsIcon] GET sinLeer', err)
        setNotifications('error')
      })
  }

  useEffectAbortable(({ aborted }) => {
    setNotifications(null)
    fetchNotificationUnread(aborted)
    // console.log('fetch NIcon',{aborted})
    /* http.get('api/notificacion/sinLeer')
    .then(({ status, data }) => {
      // console.log('fetch NIcon response',{aborted})
      if(aborted) return
      if (status !== 200) throw Error('Disculpe, ocurrió un error consultados las Notificaiones. Por favor intente más tarde.')
      setNotifications(data.length)
    })
    .catch(err => {
      console.error('[NotificationsIcon] GET sinLeer',err)
      setNotifications('error')
    }) */
  }, [])

  useEffectAbortable(({aborted}) => {
    const interval = setInterval(() => {
      fetchNotificationUnread(aborted)
    }, 1000 * 60 * 2)
    return () => clearInterval(interval)
  }, [])

  const showNotifications = notifications => {
    return notifications === null
      ? '...'
      : notifications === 'error'
        ? 'E'
        : notifications
  }

  return (
    <Container header={header}>
      <Notifications>{showNotifications(notifications)}</Notifications>
      <FontAwesomeIcon icon={faBell} />
    </Container>
  )
}
/*
function propsAreEqual(prev, next) {
  return true
}

const MemoNotificationsIcon = memo(NotificationsIcon, propsAreEqual)

export default MemoNotificationsIcon */
export default NotificationsIcon
