import { useState } from 'react'
import { navigate } from '@reach/router'

import Layout from '@components/admin/Layout'
import Loader from '@components/Loader'
import DateRangeSelector from '@components/DateRangeSelector'
import PieChartsFull from '@components/charts/PieChartsFull'
import TableCartas from '@components/TableCartas'
import Filters from '@components/Filters'

import http from '@utils/fetch-client'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { useAlert } from '@hooks/useAlert'

import { Container } from './styles'

const CartasAval = () => {
  const [cartas, setCartas] = useState({})
  const [loading, setLoading] = useState(true)
  const [Alert, setAlert] = useAlert({})

  useEffectAbortable(({ aborted }) => {
    http.get('api/solicitud?co_tipo=2')
      .then(({ status, data }) => {
        if (aborted) return
        if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')
        setLoading(false)

        data = data.map(solicitud => ({
          ...solicitud,
          encargado: solicitud.encargado || -1,
          co_solicitud: solicitud.co_solicitud || -1
        }))

        data = {
          'En Espera': data.filter(c => c.co_status_web === 2),
          Confirmadas: data.filter(c => c.co_status_web === 3),
          Rechazadas: data.filter(c => c.co_status_web === 4),
          'En SAMHOI': data.filter(c => c.co_status_web === 5)
        }

        // console.log({ data })
        setCartas(data)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }, [])

  const goToReport = (fi, ff) => {
    navigate(`cartasAval/report/${fi}/${ff}`)
  }

  return (
    <Layout
      active='cartas-aval'
      title='Cartas Aval'
    >
      <Container>
        <Alert />
        <DateRangeSelector handleClick={goToReport} />
        {loading
          ? <Loader />
          : (
            <>
              <PieChartsFull
                data={Object.entries(cartas).reduce((acc, [key, value]) => {
                  return acc.concat({
                    title: key,
                    value: value.length
                  })
                }, [])}
                styles={{ width: 300, height: 200, marginBottom: '1rem' }}
              />

              <Filters
                nameFilter='cartasAval'
                data={Object.values(cartas).reduce((acc, curr) => acc.concat(curr), [])}
                handleSetData={() => { }}
                options={[
                  { label: 'Encargados', name: 'encargado' },
                  { label: 'Códigos', name: 'co_solicitud' },
                  { label: 'Fechas', name: 'fecha_creado', typeValue: 'dateUTC' }
                ]}
              /> 

              {Object.entries(cartas).map(([key, value]) =>
                <TableCartas key={key} name={key} data={value} />
              )}
            </>)}
      </Container>
      <Alert />
    </Layout>
  )
}

export default CartasAval
