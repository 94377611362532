import React, { useState, useEffect, useRef } from 'react'

import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import InputText from '@components/inputs/Text'
import Button from '@components/Button'
import Loader from '@components/Loader'
import StatusDoc from '@components/StatusDoc'
import Required from '@components/portal/Required'
import PhotoFrameDocumentPDF from '@components/PhotoFrameDocumentPDF'

import config from '@utils/config'

import {
  DocumentName, Container, Instructions, Formats, Buttons, Form, PreviewImg, LabelFile,
  File, StatusDiv, ButtonDelete
} from './styles'

const InputFile = ({
  type, statusRequest, document, changeStep, continueStep, updateDocument, deleteDocument,
  title, description, required, formats, index, loading
}) => {
  const [state, setState] = useState({ inputTextName: '', inputText: '' })
  const [myLoading, setMyLoading] = useState(true)
  const { documentos } = state
  const form = useRef(null) 
  console.log({documentos})
  useEffect(() => {
    const docs = Object.prototype.hasOwnProperty.call(document, 'documentos') ? [...document.documentos] : []
    const [inputTextName, inputText] = type === 'text' ? Object.entries(document)[0] : ['', '']
    docs.push({})
    setState({
      ...document,
      inputTextName: inputTextName, // evitar uncontrolled input (text)
      inputText: inputText,
      documentos: docs
    })
    setMyLoading(false)
  }, [document, type])

  const renderImage = (index, nombre, coDocumento) => async ({ target }) => {
    console.log({ index, nombre, coDocumento })
    const file = target.files[0]

    if (file) {
      const fileType = file.type
      const allowedExtensions = /(.jpg|.jpeg|.png|.pdf)$/i
      if (!allowedExtensions.exec(fileType)) {
        alert('Solo puede cargar archivos de extensión .jpeg  .jpg  .png .pdf')
        file.value = ''
        return
      }
      if (coDocumento) await updateDocument(state, file, nombre, coDocumento)

      setState(state => {
        console.log({ state })
        const { documentos: newDocuments } = state
        console.log({ newDocuments })
        newDocuments[index].file = file
        if (newDocuments.length - 1 === index) newDocuments.push({})
        return { ...state, documentos: newDocuments }
      })
    }
  }

  const handleDelete = ({ index, documentos, nombre, coDocumento }) => async ({ target }) => {
    console.log({ index, documentos, nombre, coDocumento })

    if (coDocumento) deleteDocument({ documentos, nombre, coDocumento })
    else {
      setState(state => {
        let { documentos: newDocuments } = state
        newDocuments = newDocuments.filter((d, docIndex) => docIndex !== index)
        return { ...state, documentos: newDocuments }
      })
    }
  }

  const OrientationImage = ({ target }) => {
    const { naturalHeight, naturalWidth } = target
    if (naturalWidth > naturalHeight) {
      target.style.height = '200px'
      target.style.width = '280px'
    } else {
      target.style.height = '280px'
      target.style.width = '200px'
    }
  }

  const handleChangeText = ({ value }) => {
    console.log({value})
    setState({ ...state, inputText: value })
  }

  const showFile = ({ file, direccion, id }) => {
    console.log('showFile', { file, direccion, id })
    const src = file ? URL.createObjectURL(file) : direccion ? `${config.statics.documents}/${direccion}` : ''
    // const src = file ? file : direccion ? `${config.statics.documents}/${direccion}` : ''
    const name = file ? file.type : direccion || ''
    console.log('showFile', { src, name })
    
    const isPDF = /(.pdf)$/i
    if (isPDF.exec(name)) return <PhotoFrameDocumentPDF file={src} />

    return (
      <PreviewImg
        id={id}
        src={src}
        onLoad={OrientationImage}
      />
    )
  }

  const isTypeFile = () => type === 'file'

  const itsErased = status => {
    let erasable = false

    if (statusRequest === 1) { // En creación
      erasable = true
    } else if (statusRequest === 4) {
      erasable = ![1, 2].includes(status)
    }

    return erasable
  }

  // const isRequestEdit = status => [1,4].includes(status)
  console.log({ state, documentos })
  return (
    <>
      <DocumentName>
        {title}
        {required ? <Required /> : null}
      </DocumentName>

      <Instructions>
        <h4> Descripción </h4>
        <p> {description} </p>
      </Instructions>

      {isTypeFile() &&
        <Formats>
          <h4> Formatos permitidos: </h4>
          <p> {formats}</p>
        </Formats>}

      <Form ref={form} loading={loading ? 1 : 0} documents={documentos?.length}>
        {isTypeFile()
          ? (!documentos && myLoading)
              ? <Loader />
              : (
                <>
                  {documentos.map((documento, index) => {
                    const name = (documentos.length === index + 1) ? 'Agregar Archivo' : `${title}${index + 1}`
                    const { file, direccion, nombre, co_documento: coDocumento, co_status: coStatus } = documento

                    return (
                      <Container key={name}>
                        {(itsErased(coStatus) && (file || direccion)) &&
                          <Tooltip title={`Eliminar document${title.replace(' ', '-')}${index + 1}`} aria-label={`Eliminar document${title.replace(' ', '-')}${index + 1}`}>
                            <ButtonDelete
                              onClick={handleDelete({ index, documentos, nombre, coDocumento })}
                            >X
                            </ButtonDelete>
                          </Tooltip>}

                        <Tooltip title={name} aria-label={name}>
                          <LabelFile htmlFor={`document${title.replace(' ', '-')}${index + 1}`}>
                            {file || direccion
                              ? showFile({ file, direccion, id: `img${title.replace(' ', '-')}${index + 1}` })
                              : (
                                <PreviewImg
                                  id={`addimg${index + 1}`}
                                  src='/img-default.jpg'
                                  onLoad={OrientationImage}
                                />)}
                          </LabelFile>
                        </Tooltip>

                        <File
                          type='file'
                          id={`document${title.replace(' ', '-')}${index + 1}`}
                          name={`document${title.replace(' ', '-')}${index + 1}`}
                          accept='.jpg,.jpeg,.png,.pdf'
                          disabled={[1, 2].includes(coStatus)}
                          onClick={({ target }) => { target.value = null }}
                          onChange={renderImage(index, nombre, coDocumento)}
                        />

                        {(statusRequest !== 1 && coStatus) &&
                          <StatusDiv>
                            <StatusDoc status={coStatus} />
                          </StatusDiv>}
                      </Container>
                    )
                  })}
                </>)

          : (
            <Grid item xs={12} md={6}>
              <InputText
                id={Object.keys(document)[0]}
                // value={state.inputText}
                handleBlur={handleChangeText}
                label={`Ingrese ${state.inputTextName}`}
                name={state.inputTextName}
                required
              />
            </Grid>)}
      </Form>

      <Buttons>
        <Button
          label='Regresar'
          color='danger'
          disabled={index === 0 || loading}
          onClick={changeStep}
        />

        <Button
          label='Continuar'
          color='success'
          disabled={loading}
          onClick={continueStep(state, type, state.inputText)}
        />
      </Buttons>
    </>
  )
}

export default InputFile
