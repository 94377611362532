import { memo, useState, useEffect } from 'react'
import { Container } from './styles'

const IsOffline = ({ children }) => {
  const [online, setOnline] = useState(navigator ? navigator.onLine : true)

  useEffect(() => {
    if (!window) return

    window.addEventListener('online', goOnline)
    window.addEventListener('offline', goOffline)

    return () => {
      window.removeEventListener('online', goOnline)
      window.removeEventListener('offline', goOffline)
    }
  })

  function goOnline (e) {
    setOnline(true)
  }

  function goOffline (e) {
    setOnline(false)
  }

  if (online) return null

  return <Container> Usted no tiene conexión a internet </Container>
  // return <span> {children} </span>;
}

export default memo(IsOffline)
