import { useState } from 'react'

import Layout from '@components/admin/Layout'
import Loader from '@components/Loader'
import TableReembolsos from '@components/TableReembolsos'
import PieChartsFull from '@components/charts/PieChartsFull'

import http from '@utils/fetch-client'
import { useEffectAbortable } from '@hooks/useEffectAbortable'

import { Container, Title } from './styles'

const ReembolsosReport = ({ fi, ff }) => {
  const [loading, setLoading] = useState(true)
  const [reembolsos, setReembolsos] = useState({})

  const orderByDate = (a, b) => (Date.parse(a.fecha_cita) < Date.parse(b.fecha_cita)) ? -1 : 1

  useEffectAbortable(({ aborted }) => { // Obtener Cita
    // http.get(`api/cita/reporte?fecha_inicial=2021-04-22&fecha_final=2021-06-22`)
    const url = `api/solicitud/reporte?fecha_inicial=${fi}&fecha_final=${ff}&co_tipo=1`
    http.get(url)
      .then(({ status, data }) => {
        console.log({ status, data })
        if (aborted) return
        if (status !== 200 || !Object.keys(data).length) throw JSON.stringify({ status, data })
        data = Object.entries(data).reduce((acc, [key, value]) => {
          const reembolsos = { [key.trim()]: value.sort(orderByDate) }
          return Object.assign(acc, reembolsos)
        }, {})

        setReembolsos(data)
      })
      .catch(err => console.error('[ReembolsosReport] Obtener Cita: ', err))
      .finally(() => setLoading(false))
  }, [])

  return (
    <Layout
      active='reembolsos'
      title='Reembolsos'
    >
      <Container>
        {loading
          ? <Loader />
          : (
            <>
              <Title>
                <h1 style={{ marginBottom: 0 }}> Reporte de Reembolsos </h1>
                <h1> Desde: {fi}     Hasta: {ff} </h1>
              </Title>

              <PieChartsFull
                data={Object.entries(reembolsos).reduce((acc, [key, value]) => {
                  return !value.length
                    ? acc
                    : acc.concat({
                      title: key,
                      value: value.length
                    })
                }, [])}
                styles={{ width: 300, height: 200, marginBottom: '1rem' }}
              />

              {Object.entries(reembolsos).map(([key, value]) =>
                <TableReembolsos key={key} name={key} data={value} />
              )}
            </>)}

      </Container>
    </Layout>
  )
}

export default ReembolsosReport
