import styled from 'styled-components'

/* export const Container = styled.img`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    display: ${({ display }) => !display && 'none'};
    margin: 30px;
    border: 0px solid #666;
    box-shadow: 0 2px 5px #666;
    cursor: ${({ cursor }) => cursor ? 'pointer' : 'inherit'};
`
 */

export const Container = styled.div`  
    display: flex;
    flex-direction: column;
    cursor: 'pointer';
    align-items: center;

    div{
    }
    `

export const Buttons = styled.div`  
    width: 100%;
    display: flex;
    justify-content: space-around;

    button{
        margin: 0 5px;
    }
`
