import styled from 'styled-components'

export const Login = styled.form`
    display: none;
    position: relative;
    /* z-index: -1; */
    bottom: 450px;
    left: calc(50% - 280px);
    border-radius: 5px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.color.white};
    min-height: 280px;
    max-width: 250px;
    box-shadow: 0 5px 10px ${({ theme }) => theme.color.shadow};
    flex-direction: column;
    justify-content: space-between;
    
    a{
        margin: 0 0 0 auto;
        font-size: 11px;
        cursor: pointer;
        &:hover{
            color: ${({ theme }) => theme.color.bluePrimary}
        };
    }

    span{
        font-size: 12px;
    }
    
    @media (min-width: 650px) {
        display: ${({ user }) => user ? 'none' : 'flex'};
        left: calc(85% - 280px);;
    }

    @media (min-width: 850px) {
        display: ${({ user }) => user ? 'none' : 'flex'};
        left: calc(90% - 280px);;
    }

`

export const LoginInput = styled.div`
    padding: 10px 0;
    display: flex;
    flex-direction: column;

`

export const DivMessage = styled.div`
    width: 100%;
`

export const LoginBtn = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    button{
        width: 100%
    }
`
