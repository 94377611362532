import styled from 'styled-components'

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
`

export const ContainerDocuments = styled.section`
`

export const FinishTitle = styled.h2`
    color: ${({ theme }) => theme.color.bluePrimary};
`

export const LinkDetail = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;

    a{
        color: ${({ theme }) => theme.color.gray};
        font-weight: 500;
        font-size: 18px;
        &:hover{
            color: ${({ theme }) => theme.color.bluePrimary};
        }
    }
`

export const ContainerIntro = styled.section`
    display: ${({ isIntro }) => isIntro ? 'none' : 'flex'};
    flex-direction: column;
    padding: 0 10px;
    text-align: justify;

    h2{
        text-align: center;
        color: ${({ theme }) => theme.color.bluePrimary}
    }

    h4{ text-indent: 10px; }
    
    ul{ margin-bottom: 30px; }

    li{ padding: 5px 0; }

    @media (min-width: 520px) {
        padding: 0 50px;
    }
`

export const StatusMain = styled.div`
    display: flex;
    padding: 5px 0;
    width: 85%;
    margin: 15px auto;
    font-size: 18px;
    border-left: solid 2px ${({ theme }) => theme.color.bluePrimary};

    span{
        padding: 0 5px;
    }
`

/* export const Progress = styled.div`
    position: absolute;
    width: 80%;
    height: 40px;
    transform: translateX(-20%);
    ` */
