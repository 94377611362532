import React, { useState } from 'react'
import { ContainerDocument, Document, Info, Buttons } from './styles'
import useStyles from './stylesUI'

import Status from '@components/StatusDoc'
import Button from '@components/Button'
import PhotoFrameDocument from '@components/PhotoFrameDocument'
import TextField from '@mui/material/TextField'
import { useAlert } from '@hooks/useAlert'
import config from '@utils/config'

const FileVerification = ({
  index, co_solicitud, name, file = {}, handleToogle, handleSave,
  onlyButton, requestIsValid, isOwner, requestName, description
}) => {
  const classes = useStyles()
  const [Alert, setAlert] = useAlert({})
  const { co_documento, direccion, co_status, tx_status } = file
  const [loading, setLoading] = useState(false)
  const [observation, setObservation] = useState(file.observaciones || '')

  const disableButtons = !isOwner || requestIsValid || loading

  const myHandleToogle = (index, stt) => async () => {
    setLoading(true)
    try {
      const response = await handleToogle(index, stt, co_documento, observation)
      if (response) setAlert({ status: true, severity: 'success', msg: `El archivo se ha ${stt === 2 ? 'Rechazado' : 'Validado'} exitosamente` })
      else setAlert({ status: true, severity: 'error', msg: `No se logró ${stt === 2 ? 'Rechazar' : 'Validar'} el documento, por favor comuníquese con el personal de Sistemas.` })
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setAlert({ status: true, severity: 'error', msg: 'Ha Ocurrido un error en la Aplicación por favor comuníquese con el personal de Sistemas.' })
      console.error(err)
      return false
    }
  }

  const myHandleSave = async () => {
    setLoading(true)
    try {
      const response = await handleSave(observation, co_documento)
      if (response) setAlert({ status: true, severity: 'success', msg: 'Cambios en la Observación guardados exitosamente.' })
      else setAlert({ status: true, severity: 'error', msg: 'No se logró actualizar la observación, por favor comuníquese con el personal de Sistemas.' })
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setAlert({ status: true, severity: 'error', msg: 'Ha Ocurrido un error en la Aplicación por favor comuníquese con el personal de Sistemas.' })
      console.error(err)
      return false
    }
  }

  const myHandleChange = event => {
    setObservation(event.target.value)
  }

  const getDescription = () => {
    return `${description} (ID ${co_documento}) | ${requestName} (ID ${co_solicitud})`
  }

  return (
    <>
      <ContainerDocument key={co_documento}>
        {!onlyButton &&
          <Document>
            <PhotoFrameDocument
              cursor
              src={`${config.statics.documents}/${direccion}`}
              // alt={`${name}-${co_documento} del reembolso`}
              description={getDescription()}
            />
          </Document>}

        <Info>
          <Status status={co_status} tx_status={tx_status} />

          <TextField
            id={`observation-${name}-${co_documento}`}
            value={observation}
            className={classes.formControl}
            onChange={myHandleChange}
            label='Observación de rechazo'
            name='observation'
            disabled={disableButtons}
          />

          <Buttons>
            {co_status === 1 /* Para el estado inicial */
              ? <>
                <Button
                  label='Rechazar'
                  color='danger'
                  onClick={myHandleToogle(index, 2)}
                  disabled={disableButtons}
                />

                <Button
                  label='Validar'
                  color='success'
                  onClick={myHandleToogle(index, 3)}
                  disabled={disableButtons}
                />
              </>

              : <>
                {co_status === 3 &&
                  <Button
                    label='Guardar'
                    color='primary'
                    onClick={myHandleSave}
                    disabled={disableButtons}
                  />}

                <Button
                  label={(co_status === 2) ? 'Rechazar' : 'Validar'}
                  color={(co_status === 2) ? 'danger' : 'success'}
                  onClick={myHandleToogle(index, co_status)}
                  disabled={disableButtons}
                />
              </>}
          </Buttons>

          <Alert />
        </Info>

      </ContainerDocument>
    </>
  )
}

export default FileVerification
