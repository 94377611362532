import './styles.css'
import React from 'react'
import { Draft, Editor, EditorState, ContentState, RichUtils, convertToRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import htmlToDraft from 'html-to-draftjs'

class EditorComponent extends React.Component {
  constructor (props) {
    super(props)
    console.log('props', props)
    console.log(props.content)
    this.state = { editorState: EditorState.createEmpty() }
    this.focus = () => this.refs.editor.focus()
  }

	componentDidMount = () => {
	  if (this.props.content) {
	    const blocksFromHtml = htmlToDraft(this.props.content)
	    const { contentBlocks, entityMap } = blocksFromHtml
	    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
	    const editorState = EditorState.createWithContent(contentState)
	    this.setState({ editorState: editorState })
	  }
	}

	onChange = (editorState) => {
	  console.log('editorState', editorState)
	  // let html = stateToHTML(contentState);
	  console.log(stateToHTML(editorState.getCurrentContent()))
	  /* const MAX_LENGTH = 10;
		const length = editorState.getCurrentContent().getPlainText('').slice(0,10);
		 */
	  this.setState({ editorState })
	  this.props.handleContent(stateToHTML(editorState.getCurrentContent()))
	}

	toggleBlockType = (blockType) => {
	  this.onChange(
	    RichUtils.toggleBlockType(
	      this.state.editorState,
	      blockType
	    ))
	}

	toggleInlineStyle = (inlineStyle) => {
	  this.onChange(
	    RichUtils.toggleInlineStyle(
	      this.state.editorState,
	      inlineStyle
	    )
	  )
	}

	render () {
	  const { editorState } = this.state

	  // If the user changes block type before entering any text, we can
	  // either style the placeholder or hide it. Let's just hide it now.
	  let className = 'RichEditor-editor'
	  const contentState = editorState.getCurrentContent()
	  if (!contentState.hasText()) {
	    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
	      className += ' RichEditor-hidePlaceholder'
	    }
	  }

	  return (
  <div className='RichEditor-root'>
    <BlockStyleControls
      editorState={editorState}
      onToggle={this.toggleBlockType}
    />
    <InlineStyleControls
      editorState={editorState}
      onToggle={this.toggleInlineStyle}
    />
    <div className={className} onClick={this.focus}>
      <Editor
        blockStyleFn={getBlockStyle}
        customStyleMap={styleMap}
        editorState={editorState}
        onChange={this.onChange}
        placeholder=''
        ref='editor'
      />
    </div>
  </div>
	  )
	}
}

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
}

function getBlockStyle (block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote'
    case 'new-block-type-name':
      return {
        component: CustomComponent,
        editable: false
      }
    default: return null
  }
}

const CustomComponent = ({ children }) => {
  return (
    <div>
      <span> 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥  </span>
      {children}
      <span> 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥  </span>
    </div>
  )
}
/*
const blockRenderMap = Immutable.Map({
	'new-block-type-name': {
		element: CustomComponent
	}
});
const extendedBlockRenderMap = Draft.DefaultDraftBlockRenderMap.merge(blockRenderMap);
*/

const StyleButton = ({ style, onToggle, active, label }) => {
  const onMouseDown = (e) => {
    e.preventDefault()
    onToggle(style)
  }

  const className = !active ? 'RichEditor-styleButton' : 'RichEditor-styleButton RichEditor-activeButton'

  return (
    <span className={className} onMouseDown={onMouseDown}>
      {label}
    </span>
  )
}

const BLOCK_TYPES = [
  // {label: 'H1', style: 'header-one'},
  { label: 'Título2', style: 'header-two' },
  { label: 'T3', style: 'header-three' },
  { label: 'T4', style: 'header-four' },
  { label: 'T5', style: 'header-five' },
  { label: 'T6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'Lista sin orden', style: 'unordered-list-item' },
  { label: 'Lista ordenada', style: 'ordered-list-item' }
  // {label: 'Code Block', style: 'code-block'},
  // {label: 'Fire', style:'new-block-type-name'}
]

const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <div className='RichEditor-controls'>
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      )}
    </div>
  )
}

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' }
  // {label: 'Monospace', style: 'CODE'},
]

const InlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle()
  return (
    <div className='RichEditor-controls'>
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
							  )}
    </div>
  )
}

export default EditorComponent

/*
ReactDOM.render(
	<RichEditorExample />,
	document.getElementById('target')
); */
