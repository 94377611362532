import React from 'react'
import { Container } from './styles'

const StatusCita = ({ header, status }) => {
  const showStatus = () => {
    if (typeof status === 'number') {
      return (status === -1) ? 'Estatus'
        : (status === 1) ? 'En creación'
            : (status === 2) ? 'Paga'
                : (status === 3) ? 'Confirmada'
                    : (status === 4) ? 'Rechazada'
                        : (status === 5) ? 'Reprogramada'
                            : (status === 6) ? 'En SAM'
                                : 'N/D'
    } else { return status || (([null, 'null'].includes(status)) ? 'No Web' : 'N/D') }
  }

  const getStatusNumber = () => {
    if (typeof status === 'number') {
      return status
    } else {
      return (status === 'En creación') ? 1
        : (['Paga', 'Pagada'].includes(status)) ? 2
            : (status === 'Confirmada') ? 3
                : (status === 'Rechazada') ? 4
                    : (status === 'Reprogramada') ? 5
                        : (status === 'En SAM') ? 6
                            : 'N/D'
    }
  }

  return (
    <Container header={header} status={getStatusNumber(status)}>
      {showStatus()}
    </Container>
  )
}

export default StatusCita
