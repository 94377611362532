import styled from 'styled-components'

export const Container = styled.div`  
    width: 95vw;
    margin: 50px auto;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    box-shadow: 5px 5px 30px ${({ theme }) => theme.color.shadow};
    margin-top: ${({ mt }) => mt};
    overflow: auto;

    @media (min-width: 920px) {
        width: 80%;
    }
`

export const Header = styled.div`  
    display: flex;
    /* min-width: 750px; */
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    /* width:100%; */
    height: 50px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.bluePrimary};
    font-size: 20px;
`

export const Add = styled.div`  
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* width: 50px; */
    height: 50px;
    padding: 0 10px;
    font-size: 25px;
    /* border: 1px solid ${({ theme }) => theme.color.gray};  */
    border-radius: 25px;
    position: relative;
    top: 25px;
    right: 20px;
    color: ${({ theme }) => theme.color.bluePrimary};
    box-shadow: 0 0 10px ${({ theme }) => theme.color.shadow};
    background-color: ${({ theme }) => theme.color.white};
    
    @media (min-width: 920px) {
        right: 50px;
    }
`

export const AddLabel = styled.span`  
    font-size: 18px;
    margin-left: 5px;
`

export const Body = styled.div`  
    /* min-width: 750px; */
    height: auto;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: ${({ theme }) => theme.color.white}; */
`
