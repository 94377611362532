import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 2rem;
    margin-bottom: 3rem;
    background-color: #f7faff;
    border-radius: 2rem;
`

export const Link = styled.a`
  cursor: 'pointer';
`
