import { useState, useRef } from 'react'
import { Link } from '@reach/router'

import Filters from '@components/Filters'
import Tooltip from '@mui/material/Tooltip'
import { usePagination } from '@hooks/usePagination'
import { getDate } from '@utils/date'
// import Filters from '@components/Filters'
import { Container, Rows, Body, Codigo, Item, Title, CreatedAt } from './styles'

const TableCita = ({ name, data }) => {
  const [Pagination, idx] = usePagination(data.length, 4)
  const [myData, setMyData] = useState({ data, search: data })
  const bodyRef = useRef(null)

  const handleToogle = () => {
    const display = bodyRef.current.style.display
    bodyRef.current.style.display = ['none', ''].includes(display) ? 'block' : 'none'
  }

  if (!name || name === 'null') return null

  return (
    <Container>
      <section>
        <Tooltip title={`Mostrar/Ocultar Citas (${name})`} aria-label={`Mostrar/Ocultar Citas (${name})`}>
          <h1 onClick={handleToogle}> Citas ({name}) ({myData.search.length} de {data.length})</h1>
        </Tooltip>

        {!data.length ? null
          : <Filters
            nameFilter='citas'
            data={data}
            handleSetData={setMyData}
            options={[
              { label: 'Profesional', name: 'medico' },
              { label: 'Paciente', name: 'paciente' },
              { label: 'Encargados', name: 'encargado' },
              { label: 'Códigos', name: 'co_cita_web' },
              { label: 'Fechas Creado', name: 'fecha_creado_web', typeValue: 'dateUTC' },
              { label: 'Fecha Citas', name: 'fecha_cita', typeValue: 'date' }
            ]}
            visible={false}
          />}

        <Body ref={bodyRef}>
          {!myData.search.length ? <h3>NO HAY SOLICITUDES</h3>
            : <>
              <Item header>
                {/* <Codigo>Código</Codigo> */}
                <Title> [Código] Servicio </Title>
                <CreatedAt> Profesional </CreatedAt>
                <CreatedAt> Paciente </CreatedAt>
                <CreatedAt> Encargado </CreatedAt>
                <CreatedAt> Cita </CreatedAt>
              </Item>

              <Rows>
                {myData.search.slice(idx.start, idx.end).map((
                  { co_cita_web, especialidad, medico, paciente, encargado, fecha_cita, hora_cita }
                ) => (
                  <Link to={`/admin/citas/${co_cita_web}`} key={co_cita_web}>
                    <Item key={co_cita_web}>
                      {/* <Codigo> {co_cita_web} </Codigo> */}
                      <Title>[{co_cita_web}] {especialidad}</Title>
                      <CreatedAt> {medico}</CreatedAt>
                      <CreatedAt> {paciente}</CreatedAt>
                      <CreatedAt> {encargado === -1 ? 'N/D' : encargado || 'N/D'} </CreatedAt>
                      <CreatedAt> {getDate(fecha_cita)} {hora_cita || ''} </CreatedAt>
                    </Item>
                  </Link>
                ))}
              </Rows>
              <Pagination />
            </>}
        </Body>
      </section>
    </Container>

  )
}

export default TableCita
