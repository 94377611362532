import React, { useState } from 'react'
import { Container } from './styles'
import ReactPlayer from 'react-player/youtube'
import { platforms } from '@utils/static/guide'

const stylePlayer = {
  margin: '1.5rem auto'
}

const Player = ({ width = '85%', url, title, platform, video = {}, style = {} }) => {
  const [error, setError] = useState(false)
  url = url || video.url
  title = title || video?.title || '[Video sin titulo]'
  platform = platform || video?.platform || platforms[0] // YouTube default
  console.log({url, title, platform})

  if (error) {
    return (
      <Container>
        <h4> <b style={{ color: '#911d1d' }}>X</b> {title} </h4>
        <div> Disculpe ha ocurrido un error cargando el video de Youtube,
          pruebe ingresando al <a style={{ cursor: 'pointer' }} href={url}>link directamente</a> o visitando
          <a href='https://www.youtube.com/channel/UCbIn7Lcgc3xZoSzhFF9I73g'> el canal</a>.
          Si el problema persiste revise su computador y navegador, o intente mas tarde.
        </div>
      </Container>
    )
  }

  return (
    <ReactPlayer
      url={url}
      width={width}
      style={{ ...stylePlayer, ...style }}
      controls
      onError={err => {
        console.log('[Player] Error', err)
        setError(true)
      }}
    />
  )
}
export default Player
