import styled from 'styled-components'

export const ContainerModal = styled.div`
    width: calc(100% - 64px);
    height: 40%;
    top: calc(30%);

    @media(min-width: 520px){
        width: 300px;
        left: calc(50% - 150px);
    }
`

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0 0;
`

export const ContainerModalImportant = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media(min-width: 650px){
      width: 650px;
      /* min-height: 500px; */
      /* left: calc(-150px); */
  }
`

export const Header = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: auto;
  /* min-height: 60px; */
  background-color: ${({ theme }) => theme.color.bluePrimary};

  h3{
    color: ${({ theme }) => theme.color.white};
    margin: auto;
    padding: 5px 0;
    text-align: center;
  }
  
  @media(min-width: 520px){
    /* min-height: 100px; */
    flex-direction: row;
  }
`
export const HeaderImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HeaderTitle = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const Img = styled.img`
  /* position: absolute; */
  z-index: 1;
  width: 65px;
  height: 65px;
  margin: auto;
  padding: 0 10px;

  @media(min-width: 520px){
    width: 80px;
    height: 80px;
  }
`

export const ImgNotice = styled.img`
  /* position: absolute; */
  z-index: 1;
  width: 70%;
  /* height: 65px; */
  margin: 30px auto 0;
  border-radius: 20px;
  box-shadow: 5px 5px 5px 3px ${({ theme }) => theme.color.shadow};
`

export const MsgNotice = styled.div`
  z-index: 2;
  padding: 10px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  padding: 15px;
  margin: 0 auto;
  border-top: 1px solid ${({ theme }) => theme.color.gray};
  border-top-width: 90%;
  text-align: center;

  h5{
    color: ${({ theme }) => theme.color.gray};
    margin: 10px 10px 0;
  }
`
