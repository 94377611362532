import { useState, useEffect } from 'react'
import InputText from '@components/inputs/Text'
import Button from '@components/Button'
import { Container } from './styles'

const Contacts = ({ handleClick }) => {
  const [dateReport, setDateReport] = useState({ fi: '', ff: '' })

  useEffect(() => {
    const today = new Date()
    let day = today.getDate()
    let month = today.getMonth() + 1 // current month

    day = day < 10 ? `0${day}` : day
    month = month < 10 ? `0${month}` : month
    const fi = `${today.getFullYear()}-${month}-${day}`
    const ff = `${today.getFullYear()}-${month}-${day}`
    setDateReport({ fi, ff })
  }, [])

  const handleDateReport = ({ name, value }) => {
    setDateReport(state => ({ ...state, [name]: value }))
  }

  const isValidReport = () => {
    const { fi, ff } = dateReport
    return (fi !== '' && ff !== '')
  }

  const myHandleClick = () => {
    const { fi, ff } = dateReport
    if (isValidReport()) handleClick(fi, ff)
  }
  // console.log({dateReport})

  return (
    <Container>
      <InputText
        type='date'
        name='fi'
        label='Fecha inicial para el reporte'
        value={dateReport.fi}
        defaultValue=''
        handleChange={handleDateReport}
        style={{ width: 250 }}
      />

      <InputText
        type='date'
        name='ff'
        label='Fecha final para el reporte'
        value={dateReport.ff}
        handleChange={handleDateReport}
        style={{ width: 250 }}
      />

      <Button
        label='Ver Reporte'
        color='success'
        disabled={!isValidReport()}
        onClick={myHandleClick}
      />
    </Container>
  )
}

export default Contacts
