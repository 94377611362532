import { useState, useRef } from 'react'
import { navigate } from '@reach/router'

import Layout from '@components/admin/Layout'
import Loader from '@components/Loader'
import DateRangeSelector from '@components/DateRangeSelector'
import PieChartsFull from '@components/charts/PieChartsFull'
import TableJorHorarios from '@components/TableJorHorarios'
import Filters from '@components/Filters'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import InputText from '@components/inputs/Text'
import Button from '@components/Button'
import useStyles from './stylesUI'

import http from '@utils/fetch-client'
import { useEffectAbortable } from '@hooks/useEffectAbortable'
import { useAlert } from '@hooks/useAlert'

import { Container, Body } from './styles'

const initialState = {
  jornada: {
    fe_inicial: ''
  }
}

const Jornadas = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [loadingData, setLoadingData] = useState(false)
  const [horarios, setHorarios] = useState([])
  const [jornada, setJornada] = useState(initialState.jornada)
  const [Alert, setAlert] = useAlert({})
  const [AlertData, setAlertData] = useAlert({})
  const bodyRef = useRef(null)

  /* const [horarios, setHorarios] = useState({
    Paga: [],
    Confirmada: [],
    Rechazada: [],
    'En SAM': []
  }) */
  // const [citas, setCitas] = useState({})
  // console.log('Citas', citas)

  const orderByDate = (a, b) =>
    (Date.parse(a.fecha_cita) < Date.parse(b.fecha_cita)) ? -1 : 1

  useEffectAbortable(({ aborted }) => { // Obtener Cita
    http.get('api/jornadas/1/horarios ')
      .then(({ status, data }) => {
        console.log('[Jornadas]', { status, data })
        if (aborted) return
        if (status !== 200 || !Object.keys(data).length) throw JSON.stringify({ status, data })

        setHorarios(data.data.horarios)
        setJornada(data.data.jornada)
      })
      .catch(err => {
        console.error('[Jornadas]: ', err)
        setAlert({ status: true, severity: 'error', msg: err })
      })
      .finally(() => setLoading(false))
  }, [])

  const handleToogle = () => {
    const display = bodyRef.current.style.display
    bodyRef.current.style.display = ['none', ''].includes(display) ? 'block' : 'none'
  }

  const handleDate = ({name, value}) => {
    console.log({name, value})
    setJornada(state => ({...state, [name]: value }))
  }

  const goToCitasMedica = clinicaID => () => navigate(`/admin/EMI/${clinicaID}/citas/medicas`)
  const goToCitasLaboratorio  = clinicaID => () => navigate(`/admin/EMI/${clinicaID}/citas/laboratorio`)
  
  const submitJornada = () => {
    setLoadingData(true)
    let body = {
      fecha: jornada?.fe_inicial?.replaceAll('-', '')
    }
    http.put('api/jornadas/1', body)
    .then(({ status, data }) => {
      if (status !== 200) throw '<b>Ha ocurrido un error</b> guardando los cambios, comuníquese con el personal de Sistemas'
      setAlertData({ status: true, severity: 'success', msg: 'Los cambios se <b>guardaron exitosamente</b>' })
    })
    .catch(err => {
      console.error('[Jornadas] submitJornada: ', err)
      setAlertData({ status: true, severity: 'error', msg: err })
    })
    .finally(() => setLoadingData(false))
  }

  // console.log({jornada})

  return (
    <Layout
      active='emi'
      title='EMI'
    >
      <Container>
        <Alert />

        {loading
          ? <Loader />
          : (
            <>
              {/* <PieChartsFull
                data={Object.entries(citas).reduce((acc, [key, value]) => {
                  if (!key || key === 'null') return acc
                  return acc.concat({
                    title: key,
                    value: value.length
                  })
                }, [])}
                styles={{ width: 300, height: 200, marginBottom: '1rem' }}
              />
              */}
              {/* <Filters
                nameFilter='citas'
                data={Object.values(citas).reduce((acc, curr) => acc.concat(curr), [])}
                handleSetData={() => { }}
                options={[
                  { label: 'Profesional', name: 'medico' },
                  { label: 'Paciente', name: 'paciente' },
                  { label: 'Encargados', name: 'encargado' },
                  { label: 'Códigos', name: 'co_cita_web' },
                  { label: 'Fechas Creado', name: 'fecha_creado_web', typeValue: 'dateUTC' },
                  { label: 'Fecha Citas', name: 'fecha_cita', typeValue: 'date' }
                ]}
              />
               */}
              <section>
              <Tooltip
                title='Mostrar/Ocultar Información'
                aria-label='Mostrar/Ocultar Información'
              >
                <h1 onClick={handleToogle}>Información </h1>
              </Tooltip>
              {loadingData ?<Loader />
                :<Body ref={bodyRef}>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} md={6}>
                      <InputText
                        type='date'
                        name='fe_inicial'
                        label='Fecha inicial'
                        value={jornada.fe_inicial}
                        // defaultValue={jornada.fe_inicial}
                        handleChange={handleDate}
                        style={{ width: 200 }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Button
                        type='submit'
                        onClick={submitJornada}
                        label='Guardar cambios'
                        color='primary'
                        disabled={loadingData}
                      />
                    </Grid>
                  </Grid>
                  <AlertData />
                </Body>
              }
              </section>

              <section>
                <TableJorHorarios key={'laboratorio'} name={'laboratorio'} data={horarios} />
              </section>

              <section>
                <h1> Resultados EMI </h1>
                <p> Registrar recepción de exámenes de laboratorio </p>
                  <Grid item xs={12} md={6}>
                    <Button
                      type='submit'
                      onClick={goToCitasLaboratorio(4)}
                      label='Caracas'
                      color='secondary'
                      disabled={loadingData}
                    />
                    <Button
                      type='submit'
                      onClick={goToCitasLaboratorio(5)}
                      label='Maracay'
                      color='secondary'
                      disabled={loadingData}
                    />
                  </Grid>
              </section>
              
              <section>
                <h1> Citas Médicas </h1>
                <p> Asignar Cita a pacientes con resultados de laboratorio </p>
                  <Grid item xs={12} md={6}>
                    <Button
                      type='submit'
                      onClick={goToCitasMedica(4)}
                      label='Caracas'
                      color='secondary'
                      disabled={loadingData}
                    />
                    <Button
                      type='submit'
                      onClick={goToCitasMedica(5)}
                      label='Maracay'
                      color='secondary'
                      disabled={loadingData}
                    />
                  </Grid>
              </section>

            </>)}
      </Container>
    </Layout>
  )
}

export default Jornadas
