import styled from 'styled-components'

export const MsgError = styled.h3`
    margin: auto;
    text-align: center;
`

export const Container = styled.div`
    min-height: 500px;
    padding: 0rem 1rem;
`
