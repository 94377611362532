const theme = {
  color: {
    black: '#222',
    white: 'rgba(252, 252, 252)',
    gray: '#9e9e9e',
    grayDark: '#444',
    ligthGray: '#cdcdcc',
    danger: '#d32f2f',
    dangerHover: '#f03737',
    warning: '#fb9100',
    inProgress: '#0288d1',
    inProgressHover: '#029aed',
    success: '#388e3c',
    successHover: '#46b04b',
    primary: '#1976d2',
    primaryHover: '#2196f3',
    secondary: '#6c757d',
    secondaryHover: '#79838c',

    bluePrimary: 'rgba(58,92,127)',
    bluePrimaryHover: '#4b76a3',
    bluePrimaryOpacity: 'rgba(58,92,127,0.6)',
    blueDark: 'rgba(40,65,89)',
    shadow: 'rgba(32, 33, 36, 0.28)'
  }

}

export default theme
