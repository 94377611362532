import { useState } from 'react'
import { useStateValue } from '@context/index.js'
import Tooltip from '@mui/material/Tooltip';
import http from '@utils/fetch-client'
import { useAlert } from '@hooks/useAlert'
import { navigate } from '@reach/router'

import Loader from '@components/Loader'
import PhotoFrameProfile from '@components/PhotoFrameProfile'
import Modal from '@components/Modal'
import { Container, User, UserPhoto, UserInfo } from './styles'

const UserSession = ({ }) => {
  const [{sesiones, user}, dispatch] = useStateValue()
  const [open, setOpen] = useState(false)
  const [loadingChangeUser, setLoadingChangeUser] = useState(false)
  const [Alert, setAlert] = useAlert({})

  const handleChangeUser = username => () => {
    setLoadingChangeUser(true)
    const data = new FormData()
    data.append('username', username)
    http.post('api/login/beneficiario', data)
      .then(({ status, data }) => {
        console.log({status, data})
        if (status !== 200) throw new Error(status)
        // setOpen(false)
        dispatch({type: 'LOGIN', payload: data})
        navigate(0)
        // navigate(`/portal/citas/solicitud/${data.co_cita}`)
      })
      .catch(err => {
        setLoadingChangeUser(false)
        const msg = 'Disculpe, NO se logró realizar el cambio de usuario.'
        console.error(`${err}: ${msg}`)
        setAlert({ status: true, severity: 'error', msg: msg })
      })
  }


  return (<>
      <Tooltip title="Cambiar usuario">
      <Container onClick={() => setOpen(true)} >
        <PhotoFrameProfile 
          src={user['foto_perfil']} 
          size={'40px'} 
          margin={'0 5px 0 0'} 
          />
        {/* <p> {user['username']} </p> */}
        <p> {user['nb_benefi'].trim()}</p>
      </Container>
      </Tooltip>


      <Modal
        open={open}
        setOpen={setOpen}
        title={'Cambiar de usuario'}
      >
          {loadingChangeUser 
            ?<Loader />
            :sesiones.map(e => (
              <User onClick={handleChangeUser(e['username'])} key={e['username']} >
                <UserPhoto>
                  <PhotoFrameProfile 
                    src={e['foto_perfil']} 
                    size={'40px'} 
                    margin={'0 5px 0 0'} 
                  />
                </UserPhoto>
                <UserInfo>
                  <b>{e['username']}</b>
                  <div>{e['nb_benefi']}</div>
                  <div>{e['nb_apellid']}</div>
                </UserInfo>
              </User>
            ))
          
          }
      </Modal>
  </>)
}

export default UserSession
