import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around; 
    align-items: center;
    /* min-height: 550px; */
    padding: 0 20px;
    max-width: 1200px;
    margin: auto;
    flex-wrap: wrap;

    @media (min-width: 750px) {
        flex-direction: row;
    }

    h3{
        margin: 5px 0;
        color: ${({ theme }) => theme.color.bluePrimary}
    }
`

export const CardManager = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 120px;
    /* width: 300px; */
    /* 
    height: 250px; */
    margin: 15px 30px 15px 90px;
    
    /* 
    @media (min-width: 750px) {
        flex-direction: row;
    }
    */
    `

export const ContainerInfo = styled.div`
    /* position: relative; */
    /* z-index: -1; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* left: -60px; */
    width: 275px;
    /* height: 80px; */
    /* background-color: ${({ theme }) => theme.color.lightGray}; */
    background-color: rgba(0,0,0,0.7);
    color: ${({ theme }) => theme.color.white};
    /* text-shadow: solid 1px black; */
    /* border-top-left-radius: 10px; */
    border-top-right-radius: 50px;
    
    &:hover{
        background-color: rgba(0,0,0,0.8);

    }
    /* 
    @media (min-width: 750px) {
        flex-direction: row;
    }
 */
`

export const Img = styled.img`
    position: absolute;
    left: -60px;
    /* align-self: center}; */
    top: 0%;
    width: 120px;
    height: 120px;
    border-radius: 50%;
`

export const Name = styled.p`
    margin: 5px 10px 0;
    font-weight: 500;
    margin-left: 62px;
`

export const Email = styled.span`
    margin: 0 5px 0 0;
    font-size: 14px;
    font-weight: 400;
    margin-left: 62px;
    cursor: copy;
`

export const JobTitle = styled.span`
    background-color: rgba(58,92,127,0.6);
    font-weight: 500;
    /* width: 100%; */
    margin-top: 2px;
    padding: 2px 2px 2px 62px; 
`
