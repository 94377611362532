import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

export default function InputRadio({ name, label, value, options, handleChange }) {
  const myHandleChange = ({ target }) => handleChange(target)

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{label}</FormLabel>
      <RadioGroup
        id={`radio-${name}`}
        aria-label={label}
        name={name}
        value={value.toString()}
        onChange={myHandleChange}
      >
        {options && options.map(({ opcionID, opcionDes }) => (
          <FormControlLabel
            key={opcionID}
            value={opcionID.toString()}
            control={<Radio />}
            label={opcionDes}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
