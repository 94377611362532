import styled from 'styled-components'

export const SendMailContainer = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin: 10px auto;
    border: 3px solid #9e9e9e;
    border-radius: 20px;
`

export const SendMail = styled.div`
    max-width: 400px;
    display:flex;
    height: auto;
    flex-direction: column;
    margin: 10px;
    margin-bottom: 0px;
    
`

export const PersonalInfo = styled.div`
  @media (min-width: 520px) {
      width: 150px;
  }
`

export const Message = styled.div`
    padding:0 10px;
`

export const Button = styled.div`
    padding:10px;
    text-align: center;
    font-weight: 500;
    width: 90%;
    margin: 10px auto;
    color: ${({ theme }) => theme.color.bluePrimary};
    border: 1px solid ${({ theme }) => theme.color.bluePrimary};
    border-radius: 10px;
    cursor: pointer; 

    &:hover{
        color: ${({ theme }) => theme.color.white};
        background-color: ${({ theme }) => theme.color.bluePrimary};
    }

    &:disabled{
      opacity: 0.5;
    }
`
