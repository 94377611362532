import styled from 'styled-components'

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
`

export const ContainerTimer = styled.div`
    color: ${({ theme }) => theme.color.danger};
`

export const ContainerDocuments = styled.section`
    display: ${({ documents }) => documents ? '' : 'none'};
`

export const FinishTitle = styled.h2`
    color: ${({ theme }) => theme.color.bluePrimary};
`

export const DocumentName = styled.h2`
    margin: auto;
    text-align: center;
    color: ${({ theme }) => theme.color.bluePrimary}
`

export const LinkDetail = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;

    a{
        color: ${({ theme }) => theme.color.gray};
        font-weight: 500;
        font-size: 18px;
        &:hover{
            color: ${({ theme }) => theme.color.bluePrimary};
        }
    }
`

export const ContainerIntro = styled.section`
    display: ${({ documents }) => documents ? 'none' : 'flex'};
    flex-direction: column;
    padding: 0;
    text-align: justify;

    h2{
        text-align: center;
        color: ${({ theme }) => theme.color.bluePrimary}
    }

    h4{ text-indent: 10px; }
    
    ul{ margin-bottom: 30px; }

    li{ padding: 5px 0; }

    @media (min-width: 520px) {
        padding: 0 50px;
    }
`

export const Sedes = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
`

export const Sede = styled.div`  
    width: 200px;
    padding: 10px 5px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.gray};
    text-align: center;
    vertical-align: middle;
    cursor: pointer; 
    background-color: ${({ theme, active }) => active === 1 ? theme.color.bluePrimary : theme.color.white};
    color: ${({ theme, active }) => active === 1 && theme.color.white};

    &:hover{
        background-color: ${({ theme }) => theme.color.bluePrimary};
        color: ${({ theme }) => theme.color.white};
    }
`
export const Volver = styled.span`
    color: ${({ theme }) => theme.color.bluePrimary};
    cursor: pointer;
    margin-bottom: 5px;
`

export const Specialties = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
`
export const Specialtie = styled.div`  
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 5px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.gray};
    text-align: center;
    cursor: pointer; 
    background-color: ${({ theme, active }) => active === 1 ? theme.color.bluePrimary : theme.color.white};
    color: ${({ theme, active }) => active === 1 && theme.color.white};

    &:hover{
        background-color: ${({ theme }) => theme.color.bluePrimary};
        color: ${({ theme }) => theme.color.white};
    }
`

export const ContainerProfesionals = styled.div`
    margin-top: 40px;
`

export const Title = styled.div`
    color: ${({ theme }) => theme.color.bluePrimary};
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
`

export const Resume = styled.div`
    display: flex;
    flex-direction: column;
`

export const Payment = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
    
    @media(min-width: 720px){
        width: 80%;
    }
`

export const PaymentMethods = styled.div`
    margin-bottom: 30px;

    section{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-top: 10px;
        span{
            width: 100px;
            text-align: center;
            color: ${({ theme }) => theme.color.bluePrimary};
            font-weight: bold;
        }

    }

    @media(min-width: 720px){
        section{
            flex-direction: row;
        }    
    }
`

export const Form = styled.form`
    width: 80%;
    margin: auto;
`

export const PreviewImg = styled.img`
    height: 250px;
    width: 210px;
    margin: auto;
    border-radius: 5%;
    box-shadow: 0 5px 10px #666;
`

export const LabelFile = styled.label`
    margin: 10px auto;
    cursor: pointer;
`

export const File = styled.input`
    width: 0px;
    height: 0px;
`

export const PaymentOptions = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 25px;
`


export const DivWeeks = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 0.3rem;
    
    div{
        margin: 0rem 0.5rem;
    }
`

export const PaymentOption = styled.div`
    width: 200px; 
    padding: 10px 5px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.gray};
    text-align: center;
    vertical-align: middle;
    cursor: pointer; 
    background-color: ${({ theme, active }) => active === 1 ? theme.color.bluePrimary : theme.color.white};
    color: ${({ theme, active }) => active === 1 && theme.color.white};

    &:hover{
        background-color: ${({ theme }) => theme.color.bluePrimary};
        color: ${({ theme }) => theme.color.white};
    }
`

export const Row = styled.div`
    min-height: 250px;
`

/*
export const Progress = styled.div`
    position: absolute;
    width: 80%;
    height: 40px;
    transform: translateX(-20%);
`
*/
