export const setUrl = (url='', host='') => {
    if(!url) return ''
    return url.startsWith('http://') || url.startsWith('https://') || url.startsWith('/') 
        ? url 
        : `${host}/${url}`
}


export const isObjectEmpty = (objectName) => {
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    )
}