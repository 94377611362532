import styled from 'styled-components'

export const Container = styled.h4`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.danger};
`
