import config from '@utils/config'
const host = config.statics.directors

const managers = [
 
  {
    co_manager: 1,
    name: 'Prof.José Gregorio Afonso',
    jobTitle: 'Presidente',
    email: 'gregorioafonso68@gmail.com',
    src: `${host}/jose_gregorio_afonso.jpg`
  },
  {
    co_manager: 2,
    name: 'Prof.Luis Crespo',
    jobTitle: 'Vicepresidente',
    email: 'lucreca11@gmail.com',
    src: `${host}/luis_crespo.jpeg`
  },
  {
    co_manager: 3,
    name: 'Profª.Lisbeth Esaá',
    jobTitle: 'Secretaria de la Junta Directiva',
    email: 'lisbethesaa@gmail.com',
    src: `${host}/lisbeth_esaa.jpeg`
  }
]

export default managers
/*  ª  */
