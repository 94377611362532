import { Leyenda, Status } from './styles'
import { Link, navigate } from '@reach/router'

import Layout from '@components/Layout'
import ActivityChart from '@components/portal/ActivityChart'
import ReembolsoRequest from '@components/portal/ReembolsoRequest'
import ReembolsoDetail from '@components/portal/ReembolsoDetail'
import ListOfReembolsos from '@components/portal/ListOfReembolsos'
import LegendRequest from '@components/LegendRequest'
import LegendDoc from '@components/LegendDoc'

const Reembolsos = ({ id, solicitud, detail }) => {
  console.log({id, solicitud, detail})
  const handleClick = () => {
    navigate('/portal/samhoi/reembolsos/solicitud')
  }

  const showTitle = () => solicitud
    ? <div><Link to='/portal/samhoi/reembolsos'> Reembolsos </Link> / Solicitud {id && `[cód: ${id}]`}</div>
    : detail
      ? <div><Link to='/portal/samhoi/reembolsos'> Reembolsos </Link> / Detalle [cód: {id}] </div>
      : 'Reembolsos'

  return (
    <Layout title='SAMHOI' active='samhoi'>

      {detail
        ? <>
          <LegendRequest top />
          <LegendDoc top />
          </>
        : null}

      <ActivityChart
        title={showTitle()}
        label='Solicitar'
        labelTooltip='Solicitar Reembolso'
        handleClick={(solicitud || detail) ? null : handleClick}
      >
        {solicitud
          ? <ReembolsoRequest id={id} />
          : detail
            ? <ReembolsoDetail id={id} />
            : <ListOfReembolsos />}
      </ActivityChart>

      {!solicitud && !detail
        ? <LegendRequest />
        : null}
    </Layout>
  )
}

export default Reembolsos
