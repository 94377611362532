import React from 'react'
import { Container, CardManager, ContainerInfo, Img, Name, Email, JobTitle } from './styles'
import managers from '@mocks/managers'
import Tooltip from '@mui/material/Tooltip'

const Managers = () => {
  const successClipboard = copied => {
    alert(`'${copied}' Copiado al portapapeles!`)
  }

  const failureClipboard = () => {
    alert('¡Ha fallado el copiado al portapapeles!')
  }

  const errorClipboard = () => {
    alert('Se ha producido un error al copiar al portapaples')
  }

  const copyClipboard = ({ target }) => {
    // var codigoACopiar = document.getElementById('textoACopiar');
    const seleccion = document.createRange()
    seleccion.selectNodeContents(target)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(seleccion)
    try {
      const res = document.execCommand('copy') // Intento el copiado
      if (res) successClipboard(target.innerText)
      else failureClipboard(target)
    } catch (err) { errorClipboard(err) }

    window.getSelection().removeRange(seleccion)
  }

  return (
    <Container>
      {managers.map(({ co_manager, name, jobTitle, email, src }) =>
        <CardManager key={co_manager}>
          <ContainerInfo>
            <Img
              alt={`${name}, ${jobTitle} en el Consejo de Directores del IPP de la UCV`}
              src={src}
            />
            <Name>{name}</Name>
            <Tooltip title='Copiar en Portapapeles' aria-label={`Copiar ${email}`}>
              <Email onClick={copyClipboard}>{email}</Email>
            </Tooltip>
            <JobTitle>{jobTitle}</JobTitle>
          </ContainerInfo>
        </CardManager>
      )}
    </Container>
  )
}

export default Managers
