// import {useState, useEffect} from 'react'
import { useState } from 'react'
import Button from '@components/Button'

import { Container, Status } from './styles'
// import http from '@utils/fetch-client'
// import Status from '@utils/fetch-client'

const status = [
  { co_status: 1, tx_status: 'Por Revisar', descripcion: 'Documento pendiente por revisar por el operador.' },
  { co_status: 2, tx_status: 'Válido', descripcion: 'Documento validado por el operador.' },
  { co_status: 3, tx_status: 'Inválido', descripcion: 'Documento rechazado por el operador.' }
]

const LegendDoc = ({ top }) => {
  const [show, setShow] = useState(false)

  const handleToogle = () => setShow(show => !show)
  /* const [status, setStatus] = useState([])
  const [loading, setLoading] = useState(true) */
  // console.log('status', status)

  /* useEffect(() => {
    http.get(`api/cita/status`)
    .then(({ status, data }) => {
      if (status !== 200) throw new Error(status)
      setStatus(data)
    })
    .catch(err => {
      const msg = 'disculpe, no se encontró cargar la leyenda de los estatus de las citas.'
      console.error(`${err}: ${msg}`)
    })
    .finally(() => setLoading(false))
  }, []) */

  const getColor = status => {
    return (status === -1) ? 'Estatus'
      : (status === 1) ? 'inProgress'
          : (status === 2) ? 'success'
              : (status === 3) ? 'danger'
                  : 'secondary'
  }

  return (
    <Container top={top}>
      {/* {loading ? <h5>Cargando Leyenda...</h5>

      :!status.length ? <h3>No se logro cargar la leyenda de los Estatus, disculpe.</h3>

      :
       */}

      <Button
        label={show ? 'Ocultar leyenda de Status de Documentos' : 'Mostrar leyenda de Status de Documentos'}
        color='secondary'
        onClick={handleToogle}
      />

      {show && status.map(({ co_status, tx_status, descripcion }) =>
        <Status color={getColor(co_status)} key={co_status}>
          <span>{tx_status}</span>: {descripcion}.
        </Status>
      )}
    </Container>
  )
}

export default LegendDoc
