import React from 'react'
import { Link } from '@reach/router'
import { Container, Option } from './styles'

const PostMenu = ({ name, options }) => {
  return (
    <Container>
      {name}

      {options.map(({ id, label, route }) => (
        <Link to={route} key={id}>
          <Option> {label} </Option>
        </Link>
      ))}
    </Container>
  )
}

export default PostMenu
