import styled from 'styled-components'

export const Container = styled.li`  
    display: flex;
    flex-direction: column;
    cursor: ${({ header }) => header ? '' : 'pointer'};
    padding: 20px 0;
    justify-content: space-between;
    align-items: center;

    &:hover{
        background-color: ${({ header }) => header ? '' : '#eee'};
    }

    @media (min-width: 600px) {
        flex-direction: row;
        padding: 20px 5px;
    }
`

export const Name = styled.div`  
    width: 350px;
    font-weight: ${({ header }) => header && 'bold'};
    color: ${({ theme, header }) => header && theme.color.black};
    text-align: center;
    @media (min-width: 600px) {
        text-align: left;
    }
`

export const Date = styled.div`  
    color: ${({ theme, header }) => header && theme.color.black};
    width: 165px;
    text-align: center;
    font-weight: ${({ header }) => header && 'bold'};
`

export const Status = styled.div`  
    width: 100px;
    font-weight: ${({ header }) => header ? 'bold' : '500'};
    justify-content: space-between;
    text-align: center;
    color: ${({ theme, status }) =>
        (status === -1) ? theme.color.black
        : (status === 1) ? theme.color.inProgress
        : (status === 2) ? theme.color.success
        : (status === 3) ? theme.color.danger
        : theme.color.gray
    }
`
