import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 550px;
    padding: 0 20px;
    max-width: 1200px;
    margin: auto;

    h3{
        margin: 5px 0;
        color: ${({ theme }) => theme.color.bluePrimary}
    }

    @media (min-width: 850px) {
        flex-direction: row;
    }
`

export const Redes = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0px;
    height: 100%;
    
    @media (min-width: 850px) {
        width: 50%;
    }
`

export const Red = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    /* width: 150px; */
    padding: 5px 5px;
    margin: 10px;
    cursor: ${({ link }) => link ? 'pointer' : 'auto'};
    font-size: 18px;

    b{
        margin-right: 5px;
    }

    &:hover{
        font-size: 18.1px;
    }
    
    @media (min-width: 520px) {
        font-size: 20px;
        &:hover{
            font-size: 20.2px;
        }
    }
`

export const Logo = styled.div`
    margin-right: 10px;

`

export const Link = styled.div`
    cursor: pointer;

`

export const Username = styled.div`
    cursor: copy;
    padding-right: 10px;
`

export const SendMailContainer = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin: 10px auto;
    border: 3px solid #9e9e9e;
    border-radius: 20px;
`

export const SendMail = styled.div`
    max-width: 400px;
    display:flex;
    height: auto;
    flex-direction: column;
    margin: 10px;
    margin-bottom: 0px;
    
    @media (min-width: 520px) {
        flex-direction: row;
    }
`

export const PersonalInfo = styled.div`
  @media (min-width: 520px) {
      width: 150px;
  }
`

export const Message = styled.div`
    padding:0 10px;
`

export const Button = styled.div`
    padding:10px;
    text-align: center;
    font-weight: 500;
    width: 90%;
    margin: 10px auto;
    color: ${({ theme }) => theme.color.bluePrimary};
    border: 1px solid ${({ theme }) => theme.color.bluePrimary};
    border-radius: 10px;
    cursor: pointer; 

    &:hover{
        color: ${({ theme }) => theme.color.white};
        background-color: ${({ theme }) => theme.color.bluePrimary};
    }

    &:disabled{
      opacity: 0.5;
    }
`
