import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Link } from '@reach/router'

import Layout from '@components/Layout'
import PostMenu from '@components/PostMenu'
import Loader from '@components/Loader'

import http from '@utils/fetch-client'
import { getDateTimeUTC } from '@utils/date'
import config from '@utils/config'

import postsMenu from '@mocks/postsMenu'
// import posts from '@mocks/news'

import {
  Content, Post, Back, Title, PostContent, Img, Date,
  ContainerMenu, PostsMenu
} from './styles'

const PostDetail = ({ id }) => {
  const [post, setPost] = useState(null)
  const [loading, setLoading] = useState(true)
  console.log('PostDetail', id)
  console.log('post', post)
  useLayoutEffect(() => window.scrollTo(0, 0), [])

  useEffect(() => {
    const post = {}
    const url = `api/post/${id}`
    setLoading(true)
    http.get(url)
      .then(({ status, data }) => {
        console.log('status', status)
        console.log('data', data)
        if (status !== 200) throw Error(`Disculpe, ocurrió un error consultados el Post #${id}. Por favor intente más tarde.`)
        setPost(data[0])
      })
      .catch(err => console.log(`[PostDetail] ${url}: `, err))
      .finally(() => setLoading(false))
    setPost(post)
  }, [id])

  const onErrorImg = ({ target }) => {
    target.onerror = null
    target.src = '/post-default.jpg'
  }

  if (loading) {
    return (
      <Layout title={`Noticia  #${id}`}>
        <Content>
          <Loader />
        </Content>
      </Layout>
    )
  }

  return (
    <Layout title={`Noticia  #${id}`}>
      <Content>
        {!post
          ? <h2> {`Disculpe la Noticia #${id} no fue encontrada, por favor recargue la página o vuelva más tarde`}</h2>
          : (
            <>
              <Post>
                <Back><Link to='/noticias'>{'<-- Volver a Noticias'} </Link></Back>
                <Title>{post.titulo}</Title>
                <Img
                  src={post.banner ? `${config.statics.posts}/${post.banner}` : '/post-default.jpg'}
                  alt={post.alt}
                  onError={onErrorImg}
                />
                <Date>Fecha: {getDateTimeUTC(post.fecha_editado)}</Date>
                <PostContent dangerouslySetInnerHTML={{ __html: post.cuerpo }} />
              </Post>

              <PostsMenu>
                <ContainerMenu>
                  {postsMenu.map(({ section, options }) => (
                    <PostMenu key={section} name={section} options={options} />
                  ))}
                </ContainerMenu>
              </PostsMenu>
            </>)}
      </Content>
    </Layout>
  )
}

export default PostDetail
