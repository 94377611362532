import React, { useState, useEffect } from 'react'
import { Link, navigate } from '@reach/router'

import InputFile from '@components/portal/InputFile'
import Progress from '@components/portal/Progress'
import Button from '@components/Button'
import Loader from '@components/Loader'
import StatusRequest from '@components/StatusRequest'

import { useStateValue } from '@context/index.js'
import { useAlert } from '@hooks/useAlert'
import { requestIntro, proccessRequest } from '@utils/static/cartasAval'
import http from '@utils/fetch-client'

import { Container, ContainerDocuments, StatusMain, FinishTitle, LinkDetail, ContainerIntro } from './styles'

const CartaAvalRequest = ({ id }) => {
  const [{ user }] = useStateValue()
  const [carta, setCarta] = useState(null)
  const [steps, setSteps] = useState(proccessRequest)
  const [isIntro, setIsIntro] = useState(false)
  const [loading, setLoading] = useState(true)
  const [AlertInfo, setAlertInfo] = useAlert({})
  const [AlertFinish, setAlertFinish] = useAlert({})
  const [AlertComplete] = useAlert({
    status: true,
    severity: 'success',
    isClose: false,
    msg: `<span> Su Solicitud de <b>Carta Aval</b> fue completada exitosamente. Por favor espere a que culmine el proceso de Revisión por nuestro personal.</span>.  Puede estar atento en la página de <b>detalle</b> sobre su estado.  Le estaremos notificacndo a su correo (${user.tx_correo || 'No tenemos su correo registrado'})`
  })
  console.log('carta', carta)

  useEffect(() => {
    if (!id){
      setCarta({})
    }else{
      http.get(`api/solicitud/${id}`)
      .then(({ status, data }) => {
        console.log(status, data)
        if (status !== 200) throw new Error(status)
        setLoading(false)
        setCarta(data)
      })
      .catch(err => {
        const msg = 'disculpe, no se encontró la solicitud, por favor intente mas tarde o comuníquese con nuestras oficinas.'
        console.error(`${err}: ${msg}`)
        setLoading(false)
        setAlertInfo({ status: true, severity: 'error', msg: msg })
      })
    }
  }, [id, setAlertInfo])

  const orderByCod = (a, b) => {
    if (a.co_documento > b.co_documento) return 1
    if (a.co_documento < b.co_documento) return -1
    return 0
  }

  // console.log('user', user)

  const handleIsIntro = () => {
    setLoading(false)
    setIsIntro(!isIntro)
  }
  const changeStep = index => () => setSteps({ ...steps, selected: index })

  const continueStep = (index, name, title) => (document, type, concepto) => () => {
    setLoading(true)
    console.log('document', document)
    if (type === 'file') {
      const isLast = index === (steps.data.length - 1)
      const data = new FormData()
      data.append('nombre', name)
      document.documentos.forEach(({ file }) => {
        if (file) data.append('archivos', file)
      })

      if (!data.getAll('archivos').length) {
        if (isLast) {
          if (validRequest()) {
            const { ...newCarta } = carta
            newCarta.co_status_web = 2

            const body = {
              co_solicitud: id,
              name: user.nb_benefi ? user.nb_benefi.trim() : '',
              lastname: user.nb_apellid ? user.nb_apellid.trim() : '',
              email: user.tx_correo ? user.tx_correo.trim() : '',
              concepto: carta.concepto,
              type: 'cartasAval'
            }

            const query = Object.entries(body).reduce((acc, [key, value], index) => {
              return `${acc}${index ? '&' : ''}${key}=${value}`
            }, '?')
            // console.log('query', query)
            http.patch(`api/solicitud/${carta.co_solicitud}/finalizarSubida${query}`)
              .then(({ status }) => {
                // console.log(status, data)
                if (status !== 200) {
                  setAlertFinish({ status: true, severity: 'error', msg: 'Ocurrió un error finalizando la solicitud. Por intente luego o comuníquese con nuestro personal.' })
                  return
                }
                setCarta(newCarta)
              })
          } else {
            setAlertFinish({ status: true, severity: 'error', msg: 'Todos los pasos son requeridos. Por favor verifíquelos para poder finalizar la solicitud.' })
          }
        } else {
          setAlertInfo({ status: true, severity: 'error', msg: 'No hay imágenes nuevas para subir. Puede navegar arriba dandole click a los pasos.' })
        }
        setLoading(false)
      } else {
        http.post(`api/solicitud/${id}/subir`, data)
          .then(({ status, data }) => {
            // console.log(status, data)

            if (status !== 200 || !data.hasOwnProperty('data')) throw new Error(data.message)
            const { ...newCarta } = carta
            const documentsOld = document.documentos.filter(({ co_documento }) => !!co_documento)
            newCarta.documentos[name] = documentsOld.concat(data.data).sort(orderByCod)

            if (isLast) { // ultimo paso
              setAlertInfo({ status: true, severity: 'success', msg: `Documentos de <b>${title}</b> enviados exitosamente.` })
              setCarta(newCarta)

              if (validRequest()) {
                newCarta.co_status_web = 2

                const body = {
                  co_solicitud: id,
                  name: user.nb_benefi ? user.nb_benefi.trim() : '',
                  lastname: user.nb_apellid ? user.nb_apellid.trim() : '',
                  email: user.tx_correo ? user.tx_correo.trim() : '',
                  concepto: carta.concepto,
                  type: 'cartasAval'
                }

                const query = Object.entries(body).reduce((acc, [key, value], index) => {
                  return `${acc}${index ? '&' : ''}${key}=${value}`
                }, '?')
                console.log('query', query)
                console.log(`api/solicitud/${carta.co_solicitud}/finalizarSubida${query}`)

                http.patch(`api/solicitud/${carta.co_solicitud}/finalizarSubida${query}`)
                  .then(({ status }) => {
                    if (status !== 200) {
                      setAlertFinish({ status: true, severity: 'error', msg: 'Ocurrió un error finalizando la solicitud. Por intente luego o comuníquese con nuestro personal.' })
                      return
                    }
                    setCarta(newCarta)
                  })
              } else {
                setAlertFinish({ status: true, severity: 'error', msg: 'Todos los pasos son requeridos. Por favor verifíquelos para poder finalizar la solicitud.' })
              }
            } else {
              setSteps({ ...steps, selected: index + 1 })
              setAlertInfo({ status: true, severity: 'success', msg: `Documentos de <b>${title}</b> enviados exitosamente.` })
              setCarta(newCarta)
            }
            setLoading(false)
          })
          .catch(err => {
            console.error(`Error continueStep: ${err}`)
            setAlertInfo({ status: true, severity: 'error', msg: err })
            setLoading(false)
          })
      }
    } else {
      setAlertInfo({ status: true, severity: 'error', msg: 'No se puede modificar el concepto.' })
      setLoading(false)
    }
  }

  const updateDocument = (document, file, nombre, co_documento) => {
    setLoading(true)
    const data = new FormData()
    data.append('archivos', file)
    data.append('nombre', nombre)
    http.patch(`api/solicitud/${carta.co_solicitud}/actualizar/${co_documento}`, data)
      .then(({ status, data }) => {
        if (status !== 200 || !data.hasOwnProperty('data')) throw new Error(data.message)
        const { ...newCarta } = carta
        const documentsOld = document.documentos.filter(doc => !!(doc.co_documento && doc.co_documento !== co_documento))
        newCarta.documentos[nombre] = documentsOld.concat(data.data).sort(orderByCod)

        setAlertInfo({ status: true, severity: 'success', msg: 'Documento <b>actualizado</b> exitosamente.' })
        setCarta(newCarta)
        setLoading(false)
      })
      .catch(err => {
        console.error(`Error updateDocument: ${err}`)
        setAlertInfo({ status: true, severity: 'error', msg: err })
        setLoading(false)
      })
  }

  const deleteDocument = ({ documentos, nombre, coDocumento }) => {
    setLoading(true)
    return http.delete(`api/solicitud/${carta.co_solicitud}/eliminarDocumento/${coDocumento}`)
      .then(({ status, data }) => {
        if (status !== 200) throw new Error(data.message)

        setAlertInfo({ status: true, severity: 'success', msg: data.message })
        setCarta(state => {
          const { documentos: newDocuments } = state
          newDocuments[nombre] = documentos.filter(d => d?.co_documento !== coDocumento)
          newDocuments[nombre].pop() // Elimina el último elemento, el de "Agregar Archivo"
          return { ...state, documentos: newDocuments }
        })
      })
      .catch(err => {
        console.error(`Error updateDocument: ${err}`)
        setAlertInfo({ status: true, severity: 'error', msg: err })
      })
      .finally(() => setLoading(false))
  }

  const createCarta = (document, type, concepto) => () => {
    setLoading(true)
    if (!concepto) return setAlertInfo({ status: true, severity: 'error', msg: 'Por favor, debe indicar cual es el concepto de su solicitud para poder iniciarla.' })

    const body = new FormData()
    body.append('co_usuario', user.co_usuario)
    body.append('co_tipo', 2)
    body.append('concepto', concepto)
    http.put('api/solicitud', body)
      .then(({ status, data }) => {
        if (status !== 201) throw new Error('Error en la creación de la solicitud de Carta Aval.')
        navigate(`/portal/samhoi/cartasAval/solicitud/${data.co_solicitud}`)
      })
      .catch(err => {
        console.log(`Error createCarta: ${err}`)
        setAlertInfo({ status: true, severity: 'error', msg: 'Disculpe, ha ocurrido un error en la comunicación con el servidor. Por favor intente mas tarde y si el problema persiste comuníquese a nuestras oficinas' })
        setLoading(false)
      })
  }

  const validRequest = () => {
    return steps.data.reduce((acc, { type, name, required }) => {
      if (!required) return acc && true

      if (type === 'file') {
        return acc && Boolean(carta.documentos[name])
      } else {
        return acc && Boolean(carta[name])
      }
    }, true)
  }

  return (
    <>
      {!carta?.co_status_web ? null
        : <StatusMain>
          <span>Esta Solicitud está</span>
          <StatusRequest status={carta.co_status_web} />
        </StatusMain>}

      <Container>
        {!carta ? <Loader disabled={!loading} />

          : ![0, 1, 4].includes(carta.co_status_web || 0)
            ? <> <FinishTitle>Esta solicitud ha finalizado</FinishTitle>
              <AlertComplete />

              <LinkDetail>
                <Link to={`/portal/samhoi/cartasAval/${carta.co_solicitud}`}>Ver detalle </Link>
              </LinkDetail>
            </>

            // editar reembolso asociado al id
            : id
              ? <>
                {steps && <ContainerDocuments>
                  <Progress
                    steps={steps}
                    index={steps.selected}
                    changeStep={changeStep}
                    loading={loading}
                  />

                  <AlertInfo />
                  <AlertFinish />
                  <Loader disabled={!loading} />

                  {carta &&
                    <InputFile
                      index={steps.selected}
                      type={steps.data[steps.selected].type}
                      title={steps.data[steps.selected].title}
                      description={steps.data[steps.selected].description}
                      required={steps.data[steps.selected].required}
                      formats={steps.data[steps.selected].formats}
                      statusRequest={carta.co_status_web}
                      document={steps.data[steps.selected].type === 'file'
                        ? { documentos: carta.documentos[steps.data[steps.selected].name] || [] }
                        : { [steps.data[steps.selected].name]: carta[steps.data[steps.selected].name] }}
                      changeStep={changeStep(steps.selected - 1)}
                      continueStep={continueStep(steps.selected, steps.data[steps.selected].name, steps.data[steps.selected].title)}
                      updateDocument={updateDocument}
                      deleteDocument={deleteDocument}
                      loading={loading}
                    />}
                </ContainerDocuments>}
              </>

              // crear una solicitud de reembolso
              : <>
                <ContainerIntro isIntro={isIntro}>
                  {requestIntro()}
                  <Button
                    label='Continuar'
                    color='success'
                    onClick={handleIsIntro}
                  />
                </ContainerIntro>

                <ContainerIntro isIntro={!isIntro}>
                  <AlertInfo />
                  {loading ? <Loader />
                    : <InputFile
                      title={steps.data[0].title}
                      type={steps.data[0].type}
                      description={steps.data[0].description}
                      document={{ concepto: '' }}
                      changeStep={handleIsIntro}
                      continueStep={createCarta}
                    />}
                </ContainerIntro>
              </>}

      </Container>
    </>
  )
}

export default CartaAvalRequest
