import styled from 'styled-components'

export const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    /* background-color: #fafafa; */
    background-color: #f8f8f8;
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    padding: 20px;
    color: ${({ theme }) => theme.color.gray};
    font-weight: 500;

    @media (min-width: 520px){
        box-shadow: 0 5px 6px 0 rgba(32,33,36,0.28);
        border-radius: 20px;
        width: calc(450px - 40px);
        height: 600px;
    }

    p{
        cursor: pointer;
        &:hover{
            color:${({ theme }) => theme.color.bluePrimary};
        }
    }
`

export const ContainerImg = styled.div`
    height: 150px;
    @media (min-width: 520px){
        height: 50px;
    }
`

export const Img = styled.img`
    width: 150px; 
    height: 150px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;

    @media (min-width: 520px){
        width: 200px; 
        height: 200px;
        position: relative;
        top: -100px;
    }
`

export const LoginInput = styled.div`
    padding: 10px 0;
    display: flex;
    flex-direction: column;

`

export const LoginBtn = styled.div`
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    button{
        width: 100%
    }
`

export const DivMessage = styled.div`
    width: 100%;
`
