import styled from 'styled-components'

export const Content = styled.section`
    display: flex;
    flex-direction: column;
    align-items: ${({ loading }) => loading && 'center'};
    justify-content: ${({ loading }) => loading && 'center'};
    width: 100%;
    min-height:  50vh;

    @media (min-width: 768px) {
        flex-direction: row;
    }
`

export const Title = styled.h1`
    color: ${({ theme }) => theme.color.bluePrimary};
    text-align: center;
`

export const Posts = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 40px);
    padding: 20px;

    @media (min-width: 768px) {
        width: calc(70% - 40px);
        /* width: 70%; */
    }
`

export const PostContainer = styled.section`
    width: 80%;

    @media (min-width: 768px) {
        padding-right: 15%;
    }
`

export const PostsMenu = styled.div`
    width: 30%;
    top: 70px;
    /* height: calc(100vh - 70px); */
    position: sticky;
    flex-shrink: 0;
    display: none;
    
    @media (min-width: 768px) {
        display: block;
    }
`

export const PostsMenuContainer = styled.section`
    width: calc(100% - 30px);
    margin: 0px;
    border-left: 2px solid ${({ theme }) => theme.color.shadow};
    padding-left: 20px;
`
