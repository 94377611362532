import styled from 'styled-components'

export const Container = styled.div`
    width: 80%;
    margin: 0 auto;
`

export const Beneficiarie = styled.li`
    display: flex;
    padding: 20px 0;
    margin-bottom: 10px;
    text-decoration: none;
`

export const Body = styled.div`
    display: flex;
    margin-left: 15px;
    flex-direction: column;
    justify-content: center;
`

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    h4{
        font-size: 18px;
        margin: 0px;
        color: ${({ theme }) => theme.color.black};
    }

    p{ 
        margin: 0 0 0 10px;
        color: ${({ theme }) => theme.color.gray};
    }

    @media(min-width: 620px){
        flex-direction: row;
        margin: 0 0 0 10px;
    }
`

export const Contact = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.color.gray};
    font-weight: 500;

    @media(min-width: 620px){
        flex-direction: row;
        margin: 0 0 0 10px;
    }
`

export const Img = styled.img`
    width: 60px;    
    height: 60px;    
    border-radius: 30px;
    box-shadow: 0 3px 15px -5px #666;
`

export const Selector = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 1rem;
`

export const SelectorItem = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 0 1rem;
    cursor: ${({ selected }) => selected ? 'default' : 'pointer'};
    border-bottom: 0.3rem solid ${({ theme, selected }) => selected ? theme.color.bluePrimary : 'transparent'};
`

export const SelectorInfo = styled.div`
    h4{
        margin: 0
    }
`
