import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 50px auto;

    a{
        text-decoration: none;
        color: ${({ theme }) => theme.color.black};
    }

    section{
        overflow: auto;
        margin-bottom: 50px;
        padding: 1px 20px;
        margin-bottom: 50px;
        border-radius: 10px;
        background: white;
    }
`

export const Title = styled.div`
    h1{
        text-align: center;
        color: ${({ theme }) => theme.color.bluePrimary}
    }    
`
