import { useStateValue } from '@context'
import { Container, ContainerImage, BtnClose, Image } from './styles'

const ModalImage = () => {
  const [{ modalImage }, dispatch] = useStateValue()

  const onErrorImg = ({ target }) => {
    target.onerror = null
    target.src = '/post-default.jpg'
  }

  const handleClose = () => {
    dispatch({
      type: 'modalImage',
      payload: {
        ...modalImage,
        open: false
      }
    })
  }

  const handleClickImage = () => {
    window.open(modalImage.src)
  }

  return (
    !modalImage.open ? <></>
      : <Container>
        <ContainerImage>
          <Image
            src={modalImage.src}
            alt={modalImage.description || 'Imagen en un modal'}
            onClick={handleClickImage}
            onError={onErrorImg}
          />
          <BtnClose onClick={handleClose}> X </BtnClose>

          <h2>{modalImage.description || 'No tiene descripción'}</h2>
        </ContainerImage>
      </Container>
  )
}

export default ModalImage
