import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'

export default function Checks({ name, label, options = {}, handleChange }) {
  const myHandleChange = ({ target }) => handleChange(name, target)
  // console.log({options})
  return (
    <>
      <FormLabel component='legend'>{label}</FormLabel>
      {!Object.keys(options).length ? null
        : Object.values(options).map(({ optionID, name, opcionDes, checked }) => (
          <FormControlLabel
            key={optionID}
            control={
              <Checkbox
                checked={checked}
                onChange={myHandleChange}
                name={opcionDes}
                color='primary'
              />
            }
            label={opcionDes}
          />
        ))}
    </>
  )
}
