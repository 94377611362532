import styled from 'styled-components'

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
`

export const ContainerTypeDocument = styled.section`
    display: flex;
    flex-direction: column;
`

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2{
        margin: auto;
    }
    
    span{
        font-size: 18px;
        font-weight: 500;
        /* color: ${({ theme, status }) =>
            (status === 0) ? theme.color.danger
            : (status === 1) ? theme.color.inProgress
            : theme.color.success
        } */
    }
`

export const Files = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`

export const ContainerDocument = styled.div`
    display: flex;
    flex-direction: column;
`

export const Document = styled.div`
    /* width: 240px;
    height: 340px; */
    margin: 15px 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        cursor: pointer;
    }
`

export const Info = styled.div`
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    b{
        font-weight: 500;
    }
`

export const Separador = styled.hr`
    width: 80%;
    margin: 20px auto;
    color: ${({ theme }) => theme.color.lightGray};

    @media (min-width: 720px) {
        margin: 50px auto;
    }
`
export const StatusMain = styled.div`
    display: flex;
    padding: 5px 0;
    margin: 15px 0;
    font-size: 18px;
    border-left: solid 2px ${({ theme }) => theme.color.bluePrimary};

    span{
        padding: 0 5px;
    }
`

export const StyledRow = styled.div`
    padding: 5px 0;
    width: 85%;
    margin: 5px auto 15px;
`
/*

document.querySelectorAll("modal-container img").forEach(e => {
    e.addEventListener('click', function(ev){
        ev.stopPropagation()
        this.parentNode.classList.add("active")
    })
})

document.querySelectorAll("modal-container").forEach(e => {
    e.addEventListener('click', function(ev){
        this.classList.remove("active")
    })
})
*/
