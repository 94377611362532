import styled from 'styled-components'

export const FooterStyled = styled.footer`
  grid-area: foot;
  z-index:100;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.bluePrimary};
`

export const Container = styled.div`
  /* height: 100%; */
  width: calc(100% - 20px);
  max-width: calc(1200px - 20px);
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  
  @media (min-width: 720px) {
    flex-direction: row;
  }
  `

export const Img = styled.img`
  height: 60px;
  width: 60px;

  @media (min-width: 420px) {
    height: 70px;
    width: 70px;
  }
`

export const Name = styled.div`
    color: ${({ theme }) => theme.color.ligthGray};
    padding: 10px 0;
    text-align: center;
`

export const Redes = styled.div`
  display: flex;

  svg {
    height: 30px;
    width: 30px;
    margin: 5px 15px;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.color.ligthGray};
    cursor: pointer;
    &:hover{
      color: white;
      font-size: 1.6rem;
    };   
  }

`
