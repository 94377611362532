import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'

import Layout from '@components/Layout'
import Loader from '@components/Loader'
import PostPreview from '@components/PostPreview'
import PostMenu from '@components/PostMenu'

import http from '@utils/fetch-client'
import config from '@utils/config'
import handleError from '@utils/handleError'
import postsMenu from '@mocks/postsMenu'
import { usePagination } from '@hooks/usePagination'
import { useAlert } from '@hooks/useAlert'

import { Title, Content, Posts, PostContainer, PostsMenuContainer, PostsMenu } from './styles'

const Post = ({ page }) => {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const [Pagination, idx] = usePagination(posts.length, 4)
  const [AlertInfo, setInfo] = useAlert({ isClose: false })

  useEffect(() => {
    console.log('aqui')
    http.get('api/post/public')
      .then(({ status, data }) => {
        if (status !== 200 || !data) throw Error('Disculpe, no se lograron obtener las noticias. Por favor intente más tarde.')
        console.log({ status, data })
        setPosts(data.filter(({ co_post }) => co_post !== 1))
      })
      .catch(err => {
        console.log({ err })
        setInfo({ status: true, severity: 'error', msg: 'Disculpe, ocurrió un error al solicitar las noticias' })
        handleError({ err, file: 'Post', ref: 'GET api/post/public' })
      })
      .finally(() => setLoading(false))
  }, [setInfo])

  useEffect(() => { window.scrollTo(0, 0) }, [idx])

  const handleChange = (event, value) => {
    navigate(`/noticias/page/${value}`)
  }

  if (loading) {
    return (
      <Layout>
        <Content loading={loading.toString()}>
          <Loader />
        </Content>
      </Layout>
    )
  }

  return (
    <Layout>
      <Content>
        {!posts.length
          ? <AlertInfo />
          : (
            <>
              <Posts>
                <Pagination />

                {posts.slice(idx.start, idx.end).map(({ co_post, banner, alt, titulo, cuerpo, fecha_editado }) =>
                  <PostContainer key={co_post}>
                    <PostPreview
                      id={co_post}
                      src={banner ? `${config.statics.posts}/${banner}` : '/post-default.jpg'}
                      alt={alt}
                      title={titulo}
                      content={cuerpo}
                      fecha_editado={fecha_editado}
                    />
                  </PostContainer>
                )}

                <Pagination />
              </Posts>

              <PostsMenu>
                <PostsMenuContainer>
                  {postsMenu.map(({ section, options }) => (
                    <PostMenu key={section} name={section} options={options} />
                  ))}
                </PostsMenuContainer>
              </PostsMenu>
            </>)}
      </Content>
    </Layout>
  )
}

export default Post
