import React, { useState, useEffect } from 'react'

import Reembolso from '@components/portal/Reembolso'
import Loader from '@components/Loader'

import http from '@utils/fetch-client'
import { useAlert } from '@hooks/useAlert'
import { usePagination } from '@hooks/usePagination'
import { useStateValue } from '@context/index.js'

import { Container, Row, Empty } from './styles'

const ListOfReembolsos = () => {
  const [{ user }] = useStateValue()
  const [reembolsos, setReembolsos] = useState([])
  const [loading, setLoading] = useState(true)
  const [Alert, setAlert] = useAlert({})
  const [Pagination, idx] = usePagination(reembolsos.length)
  // console.log('ListOfReembolsos', { reembolsos })
  useEffect(() => {
    http.get(`api/solicitud?co_tipo=1&co_usuario=${user.co_usuario}`)
      .then(({ status, data }) => {
        if (status !== 200) throw Error('Disculpe, ocurrió un error en la consulta. Por favor intente más tarde.')
        setLoading(false)
        setReembolsos(data)
      })
      .catch(err => {
        setLoading(false)
        setAlert({ status: true, severity: 'error', msg: err })
      })
  }, [user, setAlert])

  return (
    <Container>
      <Alert />
      {loading
        ? <Loader />
        : reembolsos.length
          ? (
            <>
              <Reembolso
                header
                reembolso={{
                  concepto: 'Concepto',
                  fecha_creado: 'Fecha de Creación',
                  fecha_editado: 'Última actualización',
                  co_status_web: 'Estatus'
                }}
              />

              <Row>
                {reembolsos.slice(idx.start, idx.end).map(reembolso => (
                  <Reembolso
                    key={reembolso.co_solicitud}
                    reembolso={reembolso}
                  />
                ))}
              </Row>

              <Pagination />
            </>)
          : <Empty>Usted no tiene solicitudes de reembolso.</Empty>}
    </Container>
  )
}

export default ListOfReembolsos
