import React from 'react'
import { Router, Redirect } from '@reach/router'

import CartaAval from '@pages/portal/CartaAval'
import Citas from '@pages/portal/Citas'
import NotFound from '@pages/NotFound'
import Cobertura from '@pages/portal/Cobertura'
import Jornadas from '@pages/portal/Jornadas'
import Profile from '@pages/portal/Profile'
import Reembolsos from '@pages/portal/Reembolsos'

import UserSession from '@components/portal/UserSession'
import Guide from './Guide'

const Portal = () => (<>
  <Router>
    <NotFound default />
    <Redirect from='/' to='perfil' noThrow />
    <Profile path='perfil' />
    <Cobertura path='cobertura' />

    <Guide path='/guia/*' />

    <Jornadas path='EMI' />

    <Citas path='citas' />
    <Citas path='citas/:id' detail />
    <Citas path='citas/solicitud' solicitud />
    <Citas path='citas/solicitud/:id' solicitud />

    <Reembolsos path='samhoi/reembolsos' />
    <Reembolsos path='samhoi/reembolsos/:id' detail />
    <Reembolsos path='samhoi/reembolsos/solicitud' solicitud />
    <Reembolsos path='samhoi/reembolsos/solicitud/:id' solicitud />

    <CartaAval path='samhoi/cartasAval' />
    <CartaAval path='samhoi/cartasAval/:id' detail />
    <CartaAval path='samhoi/cartasAval/solicitud' solicitud />
    <CartaAval path='samhoi/cartasAval/solicitud/:id' solicitud />
  </Router>

  <UserSession />
</>)

export default Portal
