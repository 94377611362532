import logo from './logo-ipp.png'
import { Container, Img } from './styles'

const LoadingMain = () => (
  <Container>
    <Img src={logo} alt='logo del IPP' />
  </Container>
)

export default LoadingMain
