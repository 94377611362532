import React, { useState } from 'react'
import { Link } from '@reach/router'

import Nav from '@components/Nav'
import NavPortal from '@components/NavPortal'

import logo from './logo-ipp.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { HeaderStyled, Container, Logo, NameSystem, DropDown, NavDropDown, NavContainer, Img } from './styles'

const Header = ({ active, noOptions }) => {
  const [showMenu, setShowMenu] = useState(false)

  const toogleMenu = () => setShowMenu(!showMenu)

  const isPortal = window.location.pathname.match('portal')

  return (
    <HeaderStyled showMenu={showMenu}>
      <Container>
        <Logo>
          {noOptions
            ? <Img src={logo} alt='logo del IPP' />
            : (
              <Link to='/home'>
                <Img src={logo} alt='logo del IPP' />
              </Link>)}
          {isPortal && <NameSystem>SGS-IPP</NameSystem>}
        </Logo>

        {noOptions
          ? null
          : (
            <>
              <NavContainer>
                {isPortal
                  ? <NavPortal toogleMenu={toogleMenu} active={active} />
                  : <Nav toogleMenu={toogleMenu} active={active} />}
              </NavContainer>

              <DropDown onClick={toogleMenu}>
                <FontAwesomeIcon icon={faBars} />
              </DropDown>
            </>)}
      </Container>

      <NavDropDown showMenu={showMenu}>
        {(showMenu && isPortal)
          ? <NavPortal toogleMenu={toogleMenu} />
          : (showMenu && !isPortal) ? <Nav toogleMenu={toogleMenu} /> : null}
      </NavDropDown>
    </HeaderStyled>
  )
}

export default React.memo(Header)
